import { Grid } from "@material-ui/core";

import CustomCard from "components/CustomCard";

import { formataDataExtenso } from "utils/StrUtils";

export const Secao1Card = ({ contestacaoParam, vigenciaParam }) => {
  const anoVigencia = contestacaoParam.anoVigencia;
  const anoFap = contestacaoParam.anoVigencia - 1;

  return (
    <CustomCard title={"Introdução"}>
      <Grid container>
        <Grid item sm={12} style={{ marginLeft: 30 }}>
          <p>
            Esta aplicação permite que sejam realizadas contestações sobre razões relativas a divergências quanto aos elementos previdenciários que compõem o cálculo do FAP{anoFap}
            , vigência {anoVigencia}, conforme o art. 305, §6º do Regulamento da Previdência Social, com redação dada pelo Decreto nº. 10.410, de 2020.
          </p>
          <p>A contestação de cada ítem deve ser acompanhada da respectiva justificativa. Cada justificativa deve conter até 5.000 caracteres (inclui espaço, pontuação, etc.).</p>
          <p>
            Após a revisão e transmissão da contestação, o número de protocolo, data e horário serão gerados automaticamente. Ao final, o relatório é disponibilizado para
            impressão.
          </p>
          <p>
            A contestação pode ser reaberta dentro do período definido para transmissão da contestação do <span className="text-bold">FAP{anoFap}</span>,
            <span className="text-bold"> vigência {anoVigencia}</span>
          </p>
          <p>Será analisada apenas a última versão da contestação encaminhada dentro do período de transmissão definido.</p>
          <p>
            * Período para transmissão desta contestação:{" "}
            <span className="text-bold">
              {formataDataExtenso(vigenciaParam.dataInicioContestacao)} a {formataDataExtenso(vigenciaParam.dataFimContestacao)}{" "}
            </span>
          </p>
          <p>* Segundo a Portaria nº {vigenciaParam.portariaFap}</p>
        </Grid>
      </Grid>
    </CustomCard>
  );
};
