import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";
import { formataCEP, formataCNPJRaiz } from "utils/StrUtils";

export function formataEndereco({ logradouro, bairro, municipio, estado, cep }) {
  logradouro = logradouro ? logradouro + ", " : "";
  bairro = bairro ? bairro + ", " : "";
  municipio = municipio ? municipio + " - " : "";
  estado = estado ? estado : "";
  cep = cep ? " CEP: " + formataCEP(cep) : "";
  const endereco = logradouro + bairro + municipio + estado + cep;
  return endereco === "" ? null : endereco;
}

export const createModel = ({ vigencias = [], empresas = [] }, isAcessoEmpresa = false) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    disabled: isAcessoEmpresa,
    required: !isAcessoEmpresa,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  estabelecimentos: createField("estabelecimentos", "Estabelecimentos:", {
    type: FieldType.SELECT,
    filterOptions: true,
    required: isAcessoEmpresa,
    placeholder: "Selecione o estabelecimento",
    errorMessage: "Selecione o estabelecimento"
  }),
  empresas: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.SELECT,
    options: empresas.map((e) => ({
      value: String(e.cnpj),
      label: formataCNPJRaiz(String(e.cnpj)) + " - " + e.nome
    })),
    disabled: !isAcessoEmpresa,
    required: isAcessoEmpresa,
    placeholder: "Selecione o CNPJ raiz",
    errorMessage: "Selecione o CNPJ raiz"
  })
});
