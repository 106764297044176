import React from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import { FormErrorPanel } from "components/Form";
import { LabelValue } from "components/LabelValue";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import JulgamentoApi from "services/api/JulgamentoApi";

import SumarioDataGrid from "./SumarioDataGrid";

const isAguardaLiberacao = (status) => "AGUARDANDO_LIBERACAO" === status;

export const ResumoJulgamento = ({ resultadoJulgamento = {} }) => {
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [resumo, setResumo] = React.useState();
  const [exibeSucesso, setExibeSucesso] = React.useState(false);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  React.useEffect(() => {
    (async () => await obterDadosJulgamentoPorId())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function obterDadosJulgamentoPorId() {
    _setLoading(true);
    let res = await JulgamentoApi.obterPorIdAdm(resultadoJulgamento.id);
    if (!res.erros) {
      setResumo(res);
    } else {
      setFormErrors(res.erros);
      setResumo(null);
    }
    _setLoading(false);
  }

  async function liberarRecalculo() {
    _setLoading(true);

    const res = await JulgamentoApi.liberarRecalculo(resultadoJulgamento.id);
    if (!res.erros) {
      setResumo(res);
      setExibeSucesso(true);
    } else {
      setFormErrors(res.erros);
    }
    setLoading(false);
  }

  async function gerarRelatorioPdf() {
    _setLoading(true);
    const res = await JulgamentoApi.downloadRelatorioPdfResultadoJulgamento(resumo.id, resumo.cnpjRaiz, resumo.anoVigencia);
    if (res.erros) {
      setFormErrors(res.erros);
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <FormErrorPanel messages={formErrors} />
      <Grid container spacing={1}>
        {resumo && (
          <React.Fragment>
            <Grid item sm={5}>
              <Grid container>
                <LabelValue columns={4} label="Status" value={resumo?.status?.descricao} orientation={"inlineLabel"} />
                <LabelValue columns={8} label="Tipo Processo" value={resumo?.tipoProcesso?.descricao} orientation={"inlineLabel"} />
                <LabelValue columns={4} label="Gera Reprocessamento" value={resumo.geraReprocessamento} orientation={"inlineLabel"} dataType={"boolean"} />
                <LabelValue columns={4} label="Data Processamento" value={resumo.dataProcessamento} orientation={"inlineLabel"} dataType={"date"} />
                <LabelValue columns={4} label="Número Decisão" value={resumo.numeroDecisao} orientation={"inlineLabel"} />
                <LabelValue columns={4} label="Número Processo" value={resumo.numeroProcesso} orientation={"inlineLabel"} />
                <LabelValue columns={12} label="Considerações" value={resumo.consideracoes} orientation={"scrollTextLabelFormatted"} maxHeight="300px" />
              </Grid>
            </Grid>

            <Grid item sm={7}>
              <SumarioDataGrid resultadoJulgamento={resumo} />
            </Grid>

            <Grid item sm={12}>
              <Grid container spacing={1} justifyContent={"flex-end"}>
                <Grid item>
                  <BrButton secondary small onClick={gerarRelatorioPdf} disabled={isAguardaLiberacao(resumo.status?.codigo)}>
                    Visualizar relatório
                  </BrButton>
                </Grid>
                <Grid item>
                  <BrButton primary small onClick={liberarRecalculo} disabled={!isAguardaLiberacao(resumo.status?.codigo)}>
                    Liberar para recálculo
                  </BrButton>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
};
