import React from "react";

const NoMatch = (props) => {
  return (
    <React.Fragment>
      <h1>404 - Página não encontrada!</h1>
    </React.Fragment>
  );
};

export default NoMatch;
