import React from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, convertToSelectOptions, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import ModalConfirm from "components/ModalConfirm";
import Loading from "components/Loading";

import { isVigenciaContestacaoPorEstabelecimento } from "utils/VigenciaUtils";
import { TipoSituacaoGestaoProcessoContestacao } from "utils/TipoUtils";
import { formataCNPJ, formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";
import { getGestaoContestacaoColumns } from "./utils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";
import EmpresasApi from "services/api/EmpresasApi";

import { createConsultaGestaoContestacaoModel } from "./model";
import DistribuirModal from "./components/DistribuirModal";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import ModalSumario from "./components/ModalSumario";

const MSG_MODAL_REABRIR = "Deseja reabrir o(s) processo(s) selecionados?";
const actions = {
  DISTRIBUIR: "DISTRIBUIR",
  REABRIR: "REABRIR"
};

const columns = getGestaoContestacaoColumns();

export const GestaoContestacao = ({ userData }) => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [fields, setFields] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [action, setAction] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [contestacaoSumario, setContestacaoSumario] = React.useState();

  React.useEffect(() => {
    async function _initForm() {
      setLoading(true);
      if (vigencias.length > 0) {
        const init = createConsultaGestaoContestacaoModel({ fields: {}, vigencias: vigencias });
        setFields(init);
        setRows([]);
      }
      setLoading(false);
    }
    _initForm();
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    if (fields.estabelecimentos.id !== field.id) {
      _setLoading(true);
      const ativaEstabs = isVigenciaContestacaoPorEstabelecimento(fields.anoVigencia.value);
      toggleRequired(fields.anoVigencia, fields.cnpjRaiz);
      toggleEstabelecimentos(fields.estabelecimentos, ativaEstabs);
      const resEstab = await obterEstabelecimentos(fields.cnpjRaiz.value, fields.anoVigencia.value, ativaEstabs);
      if (resEstab.erros) {
        setFormErrors(resEstab.erros);
      } else {
        fields.estabelecimentos.options = resEstab;
      }
      setRows([]);
      _setLoading(false);
    }

    setSuccessMessage(null);
    setFields(() => ({ ...fields }));
  }

  const toggleEstabelecimentos = (estabelecimentos = {}, ativaEstabs = false) => {
    if (ativaEstabs) {
      estabelecimentos.required = false;
      estabelecimentos.disabled = false;
    } else {
      estabelecimentos.required = false;
      estabelecimentos.disabled = true;
    }
    estabelecimentos.options = [];
    estabelecimentos.value = "";
  };

  const toggleRequired = (anoVigencia, cnpjRaiz) => {
    cnpjRaiz.required = !!cnpjRaiz.value;
    anoVigencia.required = !cnpjRaiz.value;
  };

  async function obterEstabelecimentos(cnpjConsulta, anoVigencia, ativaEstabs = false) {
    const isCnpjRaiz = cnpjConsulta.length === 8;
    if (isCnpjRaiz && ativaEstabs) {
      const resEstab = await EmpresasApi.obterEstabelecimentos(cnpjConsulta, anoVigencia);
      if (resEstab.erros) {
        return resEstab;
      }
      return convertToSelectOptions(resEstab, formataCNPJ, formataCNPJ14Digitos);
    }
    return [];
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      await obterContestacoes();
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  async function obterContestacoes() {
    const requestBody = convertFormFieldsToJsonObj(fields);
    const cnpjConsulta = isVigenciaContestacaoPorEstabelecimento(fields.anoVigencia.value) && requestBody.estabelecimentos ? requestBody.estabelecimentos : requestBody.cnpjRaiz;

    requestBody.cnpj = cnpjConsulta;

    const res = await ContestacoesApi.obterContestacoes(requestBody);
    if (!res.erros) {
      setRows(res);
      setFormErrors([]);
    } else {
      setFormErrors(res.erros);
      setRows([]);
    }
  }

  const downloadAction = React.useMemo(
    () => ({
      title: "Baixar Relátorio Completo",
      icon: "text-info fa fa-file-pdf",
      action: (row) => _generateReportContestacao(row)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const sumarioAction = React.useMemo(
    () => ({
      title: "Visualizar Resumo da Contestação",
      icon: "text-info fa fa-file",
      action: (row) => obterSumario(row)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function _generateReportContestacao(contestacao) {
    _setLoading(true);
    const { cnpj, cnpjRaiz, id, anoVigencia } = contestacao;
    const cnpjConsulta = cnpj ? formataCNPJ14Digitos(cnpj) : formataCNPJ8Digitos(cnpjRaiz);
    const res = await ContestacoesApi.downloadRelatorioPdfContestacao(id, cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  async function obterSumario(row) {
    setLoading(true);
    let res = await ContestacoesApi.obterSumario(row.id);
    if (!res.erros) {
      res.protocolo = row.protocolo;
      setContestacaoSumario(res);
    } else {
      setFormErrors(res.erros);
    }
    setLoading(false);
  }

  async function confirmaReabrir() {
    _setLoading(true);
    const res = await ContestacoesApi.reabrirAdm(selectedRows);
    setAction(null);
    if (!res.erros) {
      await obterContestacoes();
      setFormErrors([]);
      setSuccessMessage("Operação realizada com sucesso!");
    } else {
      setFormErrors(res.erros);
      setSuccessMessage(null);
    }
    _setLoading(false);
  }

  async function confirmaDistribuir(cpfResponsavel) {
    _setLoading(true);
    setAction(null);
    const res = await ContestacoesApi.distribuir(selectedRows, cpfResponsavel);
    if (!res.erros) {
      await obterContestacoes();
      setFormErrors([]);
      setSuccessMessage("Operação realizada com sucesso!");
    } else {
      setSuccessMessage(null);
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {!!fields && (
            <Form onSubmit={_handleSubmit} errors={formErrors} submitTitle="Pesquisar">
              {successMessage && <BrMessage success>{successMessage}</BrMessage>}
              <Grid item sm={2}>
                <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.situacao} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={fields.estabelecimentos} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.cpfResponsavel} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={fields.instancia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={fields.ordenarMaiorMassaSalarial} onChange={_handleChange} />
              </Grid>
            </Form>
          )}
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid
            actions={[downloadAction, sumarioAction]}
            getRowId={(r) => r.id}
            rows={rows}
            columns={columns}
            density="compact"
            rowHeight={65}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={1} style={{ justifyContent: "right" }}>
            <Grid item>
              <BrButton
                primary
                onClick={() => setAction(actions.DISTRIBUIR)}
                disabled={!(selectedRows.length !== 0 && fields.situacao.value === TipoSituacaoGestaoProcessoContestacao.LIBERADA_PARA_ANALISE.value)}
              >
                Distribuir
              </BrButton>
            </Grid>
            <Grid item>
              <BrButton
                primary
                onClick={() => setAction(actions.REABRIR)}
                disabled={
                  !(
                    selectedRows.length !== 0 &&
                    (fields.situacao.value === TipoSituacaoGestaoProcessoContestacao.EM_ANALISE.value ||
                      fields.situacao.value === TipoSituacaoGestaoProcessoContestacao.FINALIZADA.value)
                  )
                }
              >
                Reabrir
              </BrButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {action === actions.DISTRIBUIR && <DistribuirModal show={true} onConfirm={confirmaDistribuir} onCancel={() => setAction(null)} selectedRows={selectedRows} />}
      {action === actions.REABRIR && <ModalConfirm show={true} message={MSG_MODAL_REABRIR} onConfirm={confirmaReabrir} cancel={() => setAction(null)} />}
      {contestacaoSumario && <ModalSumario show={true} contestacaoSumario={contestacaoSumario} onClose={() => setContestacaoSumario(null)} />}
    </React.Fragment>
  );
};
