import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrMessage from "@govbr/react-components/lib/BrMessage";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import { EFEITO_SUSPENSIVO_CONTESTACAO, TipoAcaoContestacao } from "Routes/ContestacaoEletronica/ContestacaoUtils";
import ContestacoesApi from "services/api/ContestacoesApi";

import { createDesisteAdmConstestacaoModel } from "./model";

export const DesisteAdmContestacao = ({ location, history }) => {
  const { contestacao: contestacaoParam, acao } = location.state;
  if (!contestacaoParam && TipoAcaoContestacao.DESISTIR !== acao) {
    history.replace("/404-requisicao-invalida");
  }

  const [fields, setFields] = React.useState(() => createDesisteAdmConstestacaoModel(contestacaoParam));
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [exibeSucesso, setExibeSucesso] = React.useState("");

  const _redirectBackToContestacoes = React.useCallback(() => {
    const rootPath = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
    history.replace(rootPath, { ...location.state });
  }, [history, location]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      const { justificativaDesistencia, dataEncerraEfeitoSupensivo, dataDesistencia } = requestBody;
      const res = await ContestacoesApi.desistirAdm([contestacaoParam.id], { justificativaDesistencia, dataEncerraEfeitoSupensivo, dataDesistencia });
      if (res.erros) {
        setFormErrors(res.erros);
      } else {
        setExibeSucesso("Desistência da Contestação registrada com sucesso!.");
        fields.justificativaDesistencia.readMode = true;
      }
    }

    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  async function _handleCancel(event) {
    setExibeSucesso(false);
    _redirectBackToContestacoes();
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <span className="text-up-02 text-bold">Desistência de Contestação</span>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              <BrMessage warning>{EFEITO_SUSPENSIVO_CONTESTACAO}</BrMessage>
            </Grid>

            <Form onSubmit={_handleSubmit} onCancel={_handleCancel} errors={formErrors} submitTitle="Confirmar" cancelTitle="Voltar">
              <Grid item sm={3}>
                <InputGeneric field={fields.cnpj} />
              </Grid>

              <Grid item sm={3} style={{ display: fields.protocolo.value ? "block" : "none" }}>
                <InputGeneric field={fields.protocolo} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={fields.situacao} />
              </Grid>

              <Grid item sm={3} style={{ display: fields.dataDOU.value ? "block" : "none" }}>
                <InputGeneric field={fields.dataDOU} />
              </Grid>

              <Grid item sm={3} style={{ display: fields.email.value ? "block" : "none" }}>
                <InputGeneric field={fields.email} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={fields.dataInicial} />
              </Grid>

              <Grid item sm={3} style={{ display: fields.dataTransmissao.value ? "block" : "none" }}>
                <InputGeneric field={fields.dataTransmissao} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={fields.dataDesistencia} onChange={_handleChange} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={fields.dataEncerraEfeitoSupensivo} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <InputGeneric field={fields.justificativaDesistencia} onChange={_handleChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Grid>
      <ModalMessage show={exibeSucesso} close={_handleCancel} />
    </React.Fragment>
  );
};
