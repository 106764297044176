import { CsvBuilder } from "filefy";

const toArray = (obj, fields) => Object.keys(obj).map((k) => fields[k] || "");

export function saveAsCsv({ filename, data = [], fields = null }) {
  if (!fields && data && data.length > 0) {
    fields = {};
    Object.keys(data[0]).forEach((k) => (fields[k] = k));
  }

  const colsArray = toArray(fields, fields);
  const dataArray = data.map((row) => toArray(fields, row));

  new CsvBuilder(`${filename}.csv`).setDelimeter(";").setColumns(colsArray).addRows(dataArray).exportFile();
}
