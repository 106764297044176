import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import CustomDataGrid from "components/CustomDataGrid";
import CustomCard from "components/CustomCard";
import useEscKey from "hooks/useEscKey";
import useLockBodyScroll from "hooks/useLockBodyScroll";

const DataGridModal = ({ onClose, rows = [], columns = [], title, iconComponent, getRowId, headerHeight }) => {
  useEscKey(() => onClose());
  useLockBodyScroll();

  return (
    <div className="modal-table">
      <div className="modal-table-content">
        <CustomCard title={title} iconComponent={iconComponent}>
          <Grid item sm={12}>
            <CustomDataGrid getRowId={getRowId} rows={rows} columns={columns} density="compact" toolbar="details" headerHeight={headerHeight} />
          </Grid>

          <Grid item sm={12} className="text-center" style={{ paddingTop: 10 }}>
            <BrButton small onClick={() => onClose()}>
              Fechar
            </BrButton>
          </Grid>
        </CustomCard>
      </div>
    </div>
  );
};

export default DataGridModal;
