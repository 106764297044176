import React from "react";
import { Grid } from "@material-ui/core";

import { LabelValue } from "components/LabelValue";
import CustomCard from "components/CustomCard";

import { formataIsoDateTimeBR, formataProtocoloContestacao } from "utils/StrUtils";
import { getInstanciaDescricao, getSituacaoDescricao } from "utils/ContestacaoUtils";

const DadosPrincipaisModel = {
  resultadoJulgamento: "Último Resultado de Julgamento Recalculado",
  dataUltimaAlteracao: "Data e Hora da Última Alteração",
  dataTransmissao: "Data e Hora da Transmissão",
  dataInicial: "Data Inicial",
  razaoSocial: "Razão Social",
  responsavel: "Analista Responsável",
  observacao: "Observação da Empresa",
  protocolo: "Número do Protocolo",
  instancia: "Instância",
  situacao: "Situação",
  cnpjRaiz: "CNPJ Raiz",
  versao: "Número Versão",
  cnpj: "CNPJ Completo"
};

const Secao1Card = ({ contestacao = {} }) => {
  return (
    <CustomCard title={"Dados Principais"}>
      <Grid container>
        <Grid item sm={5} style={{ marginLeft: 30 }}>
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.protocolo} value={formataProtocoloContestacao(contestacao.protocolo)} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.instancia} value={getInstanciaDescricao(contestacao)} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.situacao} value={getSituacaoDescricao(contestacao)} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.versao} value={contestacao.versao} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.dataInicial} value={formataIsoDateTimeBR(contestacao.dataInicial)} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.dataTransmissao} value={formataIsoDateTimeBR(contestacao.dataTransmissao)} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.dataUltimaAlteracao} value={formataIsoDateTimeBR(contestacao.dataAlteracao)} />
        </Grid>
        <Grid item sm={6}>
          <LabelValue orientation={"inlineLabel"} label={DadosPrincipaisModel.responsavel} value={contestacao.responsavel?.nome} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.cnpj} value={contestacao.cnpjFormatado} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.razaoSocial} value={contestacao.empresa.razaoSocial || ""} />
          <LabelValue showNull orientation={"inlineLabel"} label={DadosPrincipaisModel.resultadoJulgamento} value={contestacao.resultadoJulgamento.protocolo || ""} />
        </Grid>
        <Grid item sm={12} style={{ marginLeft: 30 }}>
          <LabelValue showNull orientation={"scrollTextLabel"} label={DadosPrincipaisModel.observacao} value={contestacao.observacao || ""} />
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default Secao1Card;
