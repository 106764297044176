import React from "react";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { withStyles } from "@material-ui/core";
import JulgamentoApi from "services/api/JulgamentoApi";

const columns = [
  {
    field: "tipoElemento",
    headerName: " ",
    description: "Tipo Elemento Revisado",
    valueGetter: ({ value }) => (value?.codigo !== "CAT" ? value?.descricao : value?.codigo),
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    align: "center",
    minWidth: 120,
    flex: 1
  },
  {
    field: "quantidadeExclusao",
    headerName: "Exclusão",
    headerAlign: "center",
    align: "center",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    flex: 1
  },
  {
    field: "quantidadeAlteracao",
    headerName: "Alteração",
    headerAlign: "center",
    align: "center",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    flex: 1
  },
  {
    field: "quantidadeTrajeto",
    headerName: "Trajeto",
    headerAlign: "center",
    align: "center",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    flex: 1
  },
  {
    field: "quantidadeTotal",
    headerName: "Total Revisados",
    headerAlign: "center",
    align: "center",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    flex: 1
  }
];

const StyledDataGrid = withStyles({
  root: {
    minHeight: "365px",

    "& .MuiDataGrid-columnHeaderTitle": {
      lineHeight: "normal",
      whiteSpace: "normal"
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#ededed",
      justifyContent: "center",
      maxHeight: "none !important"
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      textAlign: "center",
      justifyContent: "center"
    },

    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      minHeight: "auto!important",
      height: "auto",

      lineHeight: "1.2!important",
      overflowWrap: "anywhere",
      whiteSpace: "normal"
    }
  }
})(DataGrid);

const SumarioDataGrid = ({ resultadoJulgamento }) => {
  const [rows, setRows] = React.useState([]);
  const [noRowsLabel, setNoRowsLabel] = React.useState("");

  React.useEffect(() => {
    if (resultadoJulgamento) {
      (async () => {
        JulgamentoApi.obterSumarioPorId(resultadoJulgamento.id).then((res) => {
          if (res.erros) {
            setNoRowsLabel(res.erros[0].mensagem);
          } else {
            setRows(res);
          }
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="br-table">
      <StyledDataGrid
        localeText={{ ...ptBR.props.MuiDataGrid.localeText, noRowsLabel }}
        getRowId={(row) => row.tipoElemento?.codigo}
        rows={rows}
        columns={columns}
        density="compact"
        pageSize={9}
        rowsPerPageOptions={[9]}
        // rowHeight={70}
        components={{}}
        disableSelectionOnClick={true}
        disableColumnMenu
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
      />
    </div>
  );
};

export default SumarioDataGrid;
