import { convertToSelectOptions, createField, FieldType } from "../../components/Form";

export const createVigenciaModel = ({ vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    value: vigencias[0],
    required: true,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  })
});

export const createParametrosModel = (fields = {}) => ({
  anoVigencia: createField("anoVigencia", "Ano Vigência:", {
    value: fields.anoVigencia,
    readOnly: true
  }),
  particaoEstabelecimentos: createField(
    "particaoEstabelecimentos",
    "Partição Estabelecimentos:",
    {
      value: fields.particaoEstabelecimentos,
      required: false
    }
  ),
  arquivoEstabelecimentos: createField(
    "arquivoEstabelecimentos",
    "Arquivo Estabelecimentos:",
    {
      value: fields.arquivoEstabelecimentos,
      required: false
    }
  ),
  particaoAgrupBeneficios: createField(
    "particaoAgrupBeneficios",
    "Partição Agrupamento de Benefícios:",
    {
      value: fields.particaoAgrupBeneficios,
      required: false
    }
  ),
  particaoBeneficios: createField(
    "particaoBeneficios",
    "Partição Benefícios:",
    {
      value: fields.particaoBeneficios,
      required: false
    }
  ),
  arqBeneficios01: createField(
    "arqBeneficios01",
    "Arquivo Benefícios 1º ano:",
    {
      value: fields.arqBeneficios01,
      required: false
    }
  ),
  arqBeneficios02: createField(
    "arqBeneficios02",
    "Arquivo Benefícios 2º ano:",
    {
      value: fields.arqBeneficios02,
      required: false
    }
  ),
  particaoBeneficiosB93: createField("particaoBeneficiosB93", "Partição B93:", {
    value: fields.particaoBeneficiosB93,
    required: false
  }),
  arqBeneficiosB9301: createField("arqBeneficiosB9301", "Arquivo B93 1º Ano:", {
    value: fields.arqBeneficiosB9301,
    required: false
  }),
  arqBeneficiosB9302: createField("arqBeneficiosB9302", "Arquivo B93 2º Ano:", {
    value: fields.arqBeneficiosB9302,
    required: false
  }),
  particaoCats: createField("particaoCats", "Partição CATs:", {
    value: fields.particaoCats,
    required: false
  }),
  arqCats01: createField("arqCats01", "Arquivo CATs 1º Ano:", {
    value: fields.arqCats01,
    required: false
  }),
  arqCats02: createField("arqCats02", "Arquivo CATs 2º Ano:", {
    value: fields.arqCats02,
    required: false
  }),
  arqCats03: createField("arqCats03", "Arquivo CATs 3º Ano:", {
    value: fields.arqCats03,
    required: false
  }),
  arqCats04: createField("arqCats04", "Arquivo CATs 4º Ano:", {
    value: fields.arqCats04,
    required: false
  }),
  particaoAgrupVinculos: createField(
    "particaoAgrupVinculos",
    "Partição Agrupamento Vínculos:",
    {
      value: fields.particaoAgrupVinculos,
      required: false
    }
  ),
  particaoVinculos: createField("particaoVinculos", "Partição Vínculos GFIPWEB:", {
    value: fields.particaoVinculos,
    required: false
  }),
  arqVinculos01: createField("arqVinculos01", "Arquivo Vínculos GFIPWEB 1º Ano:", {
    value: fields.arqVinculos01,
    required: false
  }),
  arqVinculos02: createField("arqVinculos02", "Arquivo Vínculos GFIPWEB 2º Ano:", {
    value: fields.arqVinculos02,
    required: false
  }),
  particaoEsocial: createField("particaoEsocial", "Partição eSocial:", {
    value: fields.particaoEsocial,
    required: false
  }),
  arqEsocial01: createField("arqEsocial01", "Arquivo eSocial 1º Ano:", {
    value: fields.arqEsocial01,
    required: false
  }),
  arqEsocial02: createField("arqEsocial02", "Arquivo eSocial 2º Ano:", {
    value: fields.arqEsocial02,
    required: false
  }),
  arqObrigEntregaEsocial: createField(
    "arqObrigEntregaEsocial",
    "Arquivo Empresas eSocial Obrigatório:",
    {
      value: fields.arqObrigEntregaEsocial,
      required: false
    }
  ),
  particaoEsocialVinculos: createField(
    "particaoEsocialVinculos",
    "Partição Vínculos eSocial:",
    {
      value: fields.particaoEsocialVinculos,
      required: false
    }
  ),
  arqEsocialVinculos01: createField(
    "arqEsocialVinculos01",
    "Arquivo Vínculos do eSocial 1º Ano:",
    {
      value: fields.arqEsocialVinculos01,
      required: false
    }
  ),
  arqEsocialVinculos02: createField(
    "arqEsocialVinculos02",
    "Arquivo Vínculos do eSocial 2º Ano:",
    {
      value: fields.arqEsocialVinculos02,
      required: false
    }
  ),
  particaoGfipweb: createField("particaoGfipweb", "Partição GFIPWEB:", {
    value: fields.particaoGfipweb,
    required: false
  }),
  arqGfipweb01: createField("arqGfipweb01", "Arquivo GFIPWEB 1º Ano:", {
    value: fields.arqGfipweb01,
    required: false
  }),
  arqGfipweb02: createField("arqGfipweb02", "Arquivo GFIPWEB 2º Ano:", {
    value: fields.arqGfipweb02,
    required: false
  }),
  particaoCalculoFap: createField("particaoCalculoFap", "Partição Cálculo:", {
    value: fields.particaoCalculoFap,
    required: false
  }),
  particaoIndices: createField("particaoIndices", "Partição Índices:", {
    value: fields.particaoIndices,
    required: false
  }),
  particaoPercentis: createField("particaoPercentis", "Partição Percentis:", {
    value: fields.particaoPercentis,
    required: false
  }),
  cnaeUltVigencia: createField("cnaeUltVigencia", "CNAE da Última Vigência:", {
    value: Boolean(fields.cnaeUltVigencia),
    type: FieldType.SELECT_ONE_BUTTON,
    required: true
  }),
  arqCnaes: createField("arqCnaes", "Arquivo CNAE:", {
    value: fields.arqCnaes,
    required: !Boolean(fields.cnaeUltVigencia),
    disabled: Boolean(fields.cnaeUltVigencia)
  }),
  correspCnaeUltVigencia: createField(
    "correspCnaeUltVigencia",
    "Correspondência CNAE da Última Vigência:",
    {
      value: Boolean(fields.correspCnaeUltVigencia),
      type: FieldType.SELECT_ONE_BUTTON,
      required: true
    }
  ),
  arqCorrespondenciaCnae: createField(
    "arqCorrespondenciaCnae",
    "Arquivo Correspondência do CNAE:",
    {
      value: fields.arqCorrespondenciaCnae,
      required: !Boolean(fields.correspCnaeUltVigencia),
      disabled: Boolean(fields.correspCnaeUltVigencia)
    }
  ),
  pesoIndiceComposto: createField(
    "pesoIndiceComposto",
    "Peso Índice Composto:",
    {
      value: fields.pesoIndiceComposto,
      required: true,
      type: FieldType.DECIMAL,
      decimalScale: 2
    }
  ),
  pesoCusto: createField(
    "pesoCusto",
    "Peso Custo:",
    {
      value: fields.pesoCusto,
      required: true,
      type: FieldType.DECIMAL,
      decimalScale: 2
    }
  ),
  pesoFrequencia: createField(
    "pesoFrequencia",
    "Peso Frequência:",
    {
      value: fields.pesoFrequencia,
      required: true,
      type: FieldType.DECIMAL,
      decimalScale: 2
    }
  ),
  pesoGravidade: createField(
    "pesoGravidade",
    "Peso Gravidade:",
    {
      value: fields.pesoGravidade,
      required: true,
      type: FieldType.DECIMAL,
      decimalScale: 2
    }
  )
});
