import React from "react";

const Unauthorized = (props) => {
  return (
    <React.Fragment>
      <h1 className="no-match">401 - Acesso não autorizado!</h1>
    </React.Fragment>
  );
};

export default Unauthorized;
