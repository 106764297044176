import { convertToSelectOptions, createField, FieldType } from "components/Form";

export const createRelatorioAmostrasModel = ({ vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    value: vigencias[0],
    required: true,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  })
});
