import moment from "moment";

import { isUndefined } from "../components/Form";

const MESES_EXTENSO = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
  13: "13º"
};

const NUMBER_FORMAT = new Intl.NumberFormat("pt-BR", { currency: "BRL", roundingMode: "trunc", maximumFractionDigits: 2 });
const NUMBER_REAL_FORMAT = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });
const NUMBER_PORCENTO_FORMAT = new Intl.NumberFormat("pt-BR", { style: "unit", unit: "percent", minimumFractionDigits: 4, maximumFractionDigits: 4 });
const NUMBER_INDICADOR_FORMAT = new Intl.NumberFormat("pt-BR", { signDisplay: "never", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 });

const _replacerCep = (match, g1, g2, g3) => `${g1}.${g2}-${g3}`;
const _replacerCpf = (match, g1, g2, g3, g4) => `${g1}.${g2}.${g3}-${g4}`;
const _replacerCnae = (match, g1, g2, g3, g4) => `${g1}.${g2}-${g3}/${g4}`;
const _replacerCnpjRaiz = (match, g1, g2, g3) => `${g1}.${g2}.${g3}`;
const _replacerCnpj = (match, g1, g2, g3, g4, g5) => `${g1}.${g2}.${g3}/${g4}-${g5}`;
const _replacerCompetencia = (match, g1, g2) => `${g2}/${g1}`;

const _toNumber = (value = 0) => {
  if (typeof value === "string" && value.indexOf(",") >= 0) {
    value = value.replace(".", "").replace(",", ".");
  }
  return Number(value);
};

export const isNumber = (v) => !isUndefined(v) && typeof v === "number";

export const formataNumberEnBr = (valor) => NUMBER_FORMAT.format(_toNumber(valor));

export const formataReal = (valor) => NUMBER_REAL_FORMAT.format(_toNumber(valor));

export const formataPorcento = (valor) => NUMBER_PORCENTO_FORMAT.format(_toNumber(valor));

export const formataIndicador = (valor) => NUMBER_INDICADOR_FORMAT.format(_toNumber(valor));

export const formataDecimal = (valor, digits) =>
  new Intl.NumberFormat("pt-BR", { signDisplay: "never", useGrouping: true, minimumFractionDigits: digits ? digits : 1, maximumFractionDigits: digits ? digits : 1 }).format(
    _toNumber(valor)
  );

export const formataIndicadorCompleto = (valor) => {
  valor = valor ? valor.toString().trim().replace(".", ",") : "0,";
  const [vInt, vPrecision = ""] = valor.split(",");
  const vIntFormatado = formataIndicador(vInt).split(",")[0];
  return `${vIntFormatado},${vPrecision.padEnd("38", "0")}`;
};

const _removeNotNumberChars = (v) => (v || isNumber(v) ? v.toString().replace(/[^0-9]/g, "") : "");

export const formataCPF11Digitos = (cpf) => {
  cpf = _removeNotNumberChars(cpf);
  return cpf && cpf.length <= 11 ? cpf.padStart(11, "0") : "";
};

export const formataCPF = (cpf) => {
  return formataCPF11Digitos(cpf).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, _replacerCpf);
};

export const formataCnae = (cnae) => {
  cnae = _removeNotNumberChars(cnae);
  return cnae ? cnae.padStart(7, "0").replace(/(\d{2})(\d{2})(\d{1})(\d{2})/, _replacerCnae) : "";
};

export const formataCNPJ = (cnpj) => {
  cnpj = _removeNotNumberChars(cnpj);
  return cnpj && cnpj.length <= 14 ? cnpj.padStart(14, "0").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, _replacerCnpj) : "";
};

export const formataCNPJRaiz = (cnpj) => {
  cnpj = _removeNotNumberChars(cnpj);
  const raiz = cnpj.length > 8 ? cnpj.padStart(14, "0").substring(0, 8) : cnpj;
  return raiz ? raiz.padStart(8, "0").replace(/(\d{2})(\d{3})(\d{3})/, _replacerCnpjRaiz) : "";
};

export const formataCNPJ14Digitos = (cnpj) => {
  cnpj = _removeNotNumberChars(cnpj);
  return cnpj && cnpj.length <= 14 ? cnpj.padStart(14, "0") : "";
};

export const formataCNPJ8Digitos = (cnpj) => {
  cnpj = _removeNotNumberChars(cnpj);
  const raiz = cnpj.length > 8 ? cnpj.padStart(14, "0").substring(0, 8) : cnpj;
  return raiz ? raiz.padStart(8, "0") : "";
};

export const formataCEP = (cep) => {
  cep = _removeNotNumberChars(cep);
  return cep && cep.length <= 8 ? cep.padStart(8, "0").replace(/(\d{2})(\d{3})(\d{3})/, _replacerCep) : "";
};

export const formataCompetencia = (competencia) => {
  competencia = competencia ? competencia.toString().replace(/[^0-9]/g, "") : "";
  return competencia.length >= 6 ? competencia.replace(/(\d{4})(\d{2})\d*/, _replacerCompetencia) : "";
};

export const formataCompetenciaExtenso = (competencia) => {
  const [mes, ano] = formataCompetencia(competencia).split("/");
  return mes && ano ? `${MESES_EXTENSO[mes]}/${ano}` : "";
};

export const formataDataExtenso = (data) => {
  const [dia, mes, ano] = formataDateEnBr(data).split("/");
  return dia && mes && ano ? `${dia} de ${MESES_EXTENSO[mes]} de ${ano}` : "";
};

export function formataDateEnBr(string) {
  // TODO: moment nao esta usando strict iso mode moment(string, moment.ISO_8601, true).toDate()
  return string ? moment(string).format("DD/MM/YYYY") : "";
}

export function formataIsoDateTimeBR(string) {
  return string ? moment(string).format("DD/MM/YYYY HH:mm:ss") : "";
}

export function isDataAMaiorB(a, b = new Date()) {
  if (typeof a === "string") {
    a = moment(a, moment.ISO_8601, true).toDate();
  }
  if (typeof b === "string") {
    b = moment(b, moment.ISO_8601, true).toDate();
  }
  return moment(a).isAfter(b);
}

export const formataProtocoloContestacao = (protocolo) => {
  return protocolo
    ?.toString()
    ?.replace(/[^0-9]/g, "")
    ?.padStart(17, "0")
    ?.replace(/(\d{5})(\d{6})(\d{4})(\d{2})/, "$1.$2/$3-$4");
};

export const formataBoleano = (boleano) => (isUndefined(boleano) ? "" : boleano ? "Sim" : "Não");
