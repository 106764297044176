import React from "react";
import { Grid } from "@material-ui/core";

import ContestacoesApi from "services/api/ContestacoesApi";
import CustomCard from "components/CustomCard";
import Form from "components/Form";

import SumarioContestacaoDataGrid from "./SumarioContestacaoDataGrid";
import { formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";

const Secao4Card = ({ contestacao = {}, onSubmitContestacao = () => {}, setLoading = () => {} }) => {
  const [contestacaoSumario, setContestacaoSumario] = React.useState();
  const [contestacaoSumarioError, setContestacaoSumarioError] = React.useState();
  const [formErrors, setFormErrors] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await ContestacoesApi.obterSumario(contestacao.id);
      setContestacaoSumario(() => (!!res.erros ? [] : res));
      !!res.erros && setContestacaoSumarioError(res.erros[0].mensagem);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function _handleSubmitAnalise() {
    setLoading(true);
    const res = await ContestacoesApi.finalizar(contestacao.id);
    setLoading(false);
    if (res.erros) {
      setFormErrors(res.erros);
    } else {
      setFormErrors([]);
      onSubmitContestacao();
    }
  }

  async function _generateReportContestacao() {
    setLoading(true);
    const { cnpj, cnpjRaiz, id, anoVigencia } = contestacao;
    const cnpjConsulta = cnpj ? formataCNPJ14Digitos(cnpj) : formataCNPJ8Digitos(cnpjRaiz);
    const res = await ContestacoesApi.downloadRelatorioPdfContestacao(id, cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormErrors(res.erros);
    }
    setLoading(false);
  }

  return (
    <CustomCard title={"Sumário da Análise"}>
      <Grid container>
        <Grid item sm={12} style={{ marginLeft: 30 }}>
          <Form
            errors={formErrors}
            onSubmit={_handleSubmitAnalise}
            submitTitle="Finalizar análise da Contestação"
            onCancel={_generateReportContestacao}
            cancelTitle={"Relatório Completo"}
          >
            <Grid item sm={12}>
              <SumarioContestacaoDataGrid noRowsLabel={contestacaoSumarioError} getRowId={(row) => row.tipoElemento} rows={contestacaoSumario} />
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default Secao4Card;
