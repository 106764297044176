import { IconButton } from "@material-ui/core";
import AnexosApi from "services/api/AnexosApi";
import {
  formataCnae,
  formataCNPJ,
  formataCNPJRaiz,
  formataCompetencia,
  formataCPF,
  formataDateEnBr,
  formataIndicador,
  formataNumberEnBr,
  formataPorcento,
  formataReal,
  isNumber
} from "utils/StrUtils";

/** render numero ordem com destaque na celula do CNPJ consultado */
const renderNumeroOrdem = ({ value, row }) => {
  let cnpj = "";
  if (isNumber(row.cnpj) || isNumber(row.cnpjRaiz)) {
    cnpj = formataCNPJ(row.cnpj) || formataCNPJRaiz(row.cnpjRaiz);
  }
  return cnpj ? <b>{`${value} (${cnpj})`}</b> : value;
};

export const anexosRecalculoColumns = [
  { headerName: "Nome", field: "nome", align: "center", flex: 1 },
  { headerName: "CNPJ", field: "cnpj", align: "center", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
  { headerName: "Data Envio", field: "dataEnvio", valueGetter: ({ value }) => formataDateEnBr(value), flex: 1 },
  { headerName: "Tamanho (Bytes)", field: "quantidadeBytes", flex: 1 },
  {
    headerName: "Download",
    field: "id",
    align: "center",
    flex: 1,
    valueFormatter: () => "Download",
    renderCell: (obj) => (
      <IconButton
        title="Download"
        primary
        size="small"
        aria-label="download"
        onClick={() => {
          AnexosApi.downloadAnexo(obj.row.anoVigencia, obj.row.cnpj, obj.row.calculoFapId, obj.row.id);
        }}
      >
        <span className="fa fa-download"></span>
      </IconButton>
    )
  }
];

export const fapSimplificadoColumns = [
  { headerName: "Ano Vigência", field: "anoVigencia", flex: 1 },
  { headerName: "CNPJ Estabelecimento", field: "cnpj", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
  { headerName: "FAP", field: "valorFap", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  { headerName: "CNAE", field: "cnae", flex: 1, valueGetter: ({ value }) => formataCnae(value) },
  { headerName: "Está Bloqueado", field: "bloqueio", flex: 1, type: "boolean" },
  { headerName: "Efeito Suspensivo", field: "efeitoSuspensivo", flex: 1, type: "boolean" }
];

export const catColumns = [
  { headerName: "Estado do Insumo", field: "estado", minWidth: 150, flex: 1 },
  { headerName: "CNPJ do Empregador constante na CAT", field: "cnpjOriginal", minWidth: 180, valueGetter: ({ value }) => formataCNPJ(value), flex: 1 },
  { headerName: "CNPJ do Empregador atribuído", field: "cnpjAtribuido", minWidth: 180, valueGetter: ({ value }) => formataCNPJ(value), flex: 1 },
  { headerName: "NIT do Empregado", field: "nit", minWidth: 150, flex: 1 },
  { headerName: "CPF do Empregado", field: "cpf", minWidth: 150, flex: 1, valueGetter: ({ value }) => formataCPF(value) },
  { headerName: "Data de Nascimento do Empregado", field: "dataNascimento", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value), flex: 1 },
  { headerName: "Data do Acidente de Trabalho", field: "dataAcidente", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value), flex: 1 },
  { headerName: "Data de Óbito do Empregado", field: "dataObito", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value), flex: 1 },
  { headerName: "Data de Cadastramento da CAT", field: "dataCadastroCat", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value), flex: 1 },
  {
    headerName: "Número da CAT",
    field: "reciboEsocial",
    minWidth: 350,
    flex: 1,
    valueFormatter: (params) => {
      return params.value || params.row.numeroCat;
    }
  },
  { headerName: "Bloqueado", field: "bloqueio", flex: 1, minWidth: 130, type: "boolean" },
  { headerName: "Protocolo", field: "protocolo", minWidth: 150, flex: 1 }
];

export const massaSalarialColumns = [
  { headerName: "Competência GFIP/eSocial", field: "competencia", valueGetter: ({ value }) => formataCompetencia(value), flex: 1 },
  { headerName: "Estado do Insumo", field: "estado", flex: 1 },
  { headerName: "Valor", field: "valorMassaSalarial", renderCell: ({ value }) => formataReal(value), valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 },
  { headerName: "Protocolo", field: "protocolo", minWidth: 150, flex: 1 }
];

export const nexoTecnicoColumns = [
  { headerName: "Estado do Insumo", field: "estado", minWidth: 150, flex: 1 },
  { headerName: "CNPJ do Empregador", field: "cnpj", minWidth: 180, valueGetter: ({ value }) => formataCNPJ(value), flex: 1 },
  { headerName: "NIT do Empregado", field: "nit", minWidth: 150, flex: 1 },
  { headerName: "Data de Nascimento do Empregado", field: "dataNascimento", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Data de Óbito do Empregado", field: "dataObito", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Número do Benefício", field: "nb", minWidth: 150 },
  { headerName: "Espécie de Benefício", field: "especie", minWidth: 120 },
  { headerName: "Protocolo", field: "protocolo", minWidth: 150, flex: 1 }
];

export const vinculosColumns = [
  { headerName: "Competência GFIP/eSocial", field: "competencia", valueGetter: ({ value }) => formataCompetencia(value), flex: 1 },
  { headerName: "Estado do Insumo", field: "estado", flex: 1 },
  { headerName: "Vinculos", field: "vinculos", valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 },
  { headerName: "Protocolo", field: "protocolo", minWidth: 150, flex: 1 }
];

export const taxaRotatividadeColumns = [
  { headerName: "Estado do Insumo", field: "estado", flex: 1 },
  { headerName: "Ano", field: "ano" },
  {
    headerName: "Taxa de Rotatividade",
    field: "taxaRotatividade",
    minWidth: 150,
    flex: 1,
    renderCell: ({ value }) => formataPorcento(value),
    valueFormatter: ({ value }) => formataIndicador(value)
  },
  { headerName: "Rescisões", field: "rescisoes", minWidth: 150, flex: 1 },
  { headerName: "Admissões", field: "admissoes", minWidth: 150, flex: 1 },
  { headerName: "Número de Vínculos no Início do Ano", field: "numeroInicialVinculos", minWidth: 210, valueFormatter: ({ value }) => formataNumberEnBr(value) },
  { headerName: "Protocolo", field: "protocolo", minWidth: 150, flex: 1 }
];

export const beneficiosColumns = [
  { headerName: "Estado do Insumo", field: "estado", minWidth: 150, flex: 1 },
  { headerName: "Número do Benefício", field: "nb", minWidth: 150 },
  {
    headerName: "Número da CAT",
    field: "reciboEsocial",
    minWidth: 350,
    flex: 1,
    valueFormatter: (params) => {
      return params.value || params.row.cat;
    }
  },
  { headerName: "Espécie de Benefício", field: "especie", minWidth: 150, flex: 1 },
  {
    headerName: "Renda Mensal Inicial (RMI)",
    field: "valorMensal",
    minWidth: 150,
    renderCell: ({ value }) => formataReal(value),
    valueFormatter: ({ value }) => formataNumberEnBr(value),
    flex: 1
  },
  { headerName: "Data Despacho Benefício (DDB)", field: "dataDespacho", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Data Inicial Contabilização (fixada ou real)", field: "dataInicioContabilizacao", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Data Final Contabilização (fixada, real ou projetada)", field: "dataFimContabilizacao", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Duração/ Expectativa de Tempo (meses)", field: "mesesContabilizacao", minWidth: 150, flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  {
    headerName: "Total Pago/Projeção",
    field: "totalPago",
    minWidth: 150,
    renderCell: ({ value }) => formataReal(value),
    valueFormatter: ({ value }) => formataNumberEnBr(value),
    flex: 1
  },
  { headerName: "Data Nascimento Beneficiário", field: "dataNascimento", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },

  { headerName: "CNPJ do Empregador", field: "cnpj", minWidth: 180, flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
  { headerName: "NIT do Empregado", field: "nit", minWidth: 150, flex: 1 },
  { headerName: "CPF Instituidor", field: "cpfInstituidor", minWidth: 150, flex: 1, valueGetter: ({ value }) => formataCPF(value) },
  { headerName: "CPF do Beneficiário", field: "cpf", minWidth: 150, flex: 1, valueGetter: ({ value }) => formataCPF(value) },
  { headerName: "Data Início Benefício (DIB)", field: "dataInicio", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Data Cessação Benefício (DCB)", field: "dataCessacao", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { headerName: "Bloqueado", field: "bloqueio", flex: 1, minWidth: 130, type: "boolean" },

  { headerName: "Tipo do Dependente", field: "dependente", minWidth: 150, flex: 1 },
  { headerName: "Protocolo", field: "protocolo", minWidth: 150, flex: 1 }
];

export const percentilColumns = [
  { headerName: "Número de Ordem", field: "ordem", minWidth: 150, renderCell: renderNumeroOrdem, flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  { headerName: "Índice", field: "indice", minWidth: 150, flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  { headerName: "Quantidade de CNPJs Completos", field: "quantidadeEmpresas", minWidth: 150, flex: 1 }
];

export const declaracoesColumns = (versaoCnae) => [
  { headerName: "Competência", field: "competencia", minWidth: 150, valueGetter: ({ value }) => formataCompetencia(value), flex: 1 },
  { headerName: "Identif. GFIP/eSocial", field: "numeroDeclaracao", minWidth: 150, flex: 1 },
  { headerName: "Tipo", field: "tipo", minWidth: 50, flex: 1 },
  { headerName: "CNAE Estabelecimento", field: "cnae", minWidth: 150, valueGetter: ({ value }) => formataCnae(value), flex: 1 },
  { headerName: `CNAE Correspondente - versão ${versaoCnae}`, field: "cnaePreponderante", minWidth: 150, valueGetter: ({ value }) => formataCnae(value), flex: 1 },
  { headerName: "CNPJ Estabelecimento", field: "cnpjCompleto", minWidth: 180, valueGetter: ({ value }) => formataCNPJ(value), flex: 1 },
  { headerName: "CNPJ Raiz", field: "cnpjRaiz", minWidth: 150, valueGetter: ({ value }) => formataCNPJRaiz(value), flex: 1 }
];

export const TIPO_DETALHAMENTO = {
  ANEXOS_RECALCULO: "ANEXOS_RECALCULO",
  FAP_SIMPLIFICADO: "FAP_SIMPLIFICADO",
  CAT: "CAT",
  NEXO_TECNICO: "NEXO_TECNICO",
  MASSA_SALARIAL: "MASSA_SALARIAL",
  VINCULOS: "VINCULOS",
  BENEFICIOS: "BENEFICIOS",
  TAXA_ROTATIVIDADE: "TAXA_ROTATIVIDADE",
  PERCENTIL_CUSTO: "PERCENTIL_CUSTO",
  PERCENTIL_FREQUENCIA: "PERCENTIL_FREQUENCIA",
  PERCENTIL_GRAVIDADE: "PERCENTIL_GRAVIDADE",
  DECLARACOES: "DECLARACOES",
  BENEFICIOS_TOTAL: "BENEFICIOS_TOTAL"
};

export const getDetalhamentoDescricao = (tipo, especie, anoVigencia) => {
  switch (tipo) {
    case TIPO_DETALHAMENTO.CAT:
      return "Comunicação de Acidente de Trabalho (CAT)";
    case TIPO_DETALHAMENTO.MASSA_SALARIAL:
      return "Massa Salarial";
    case TIPO_DETALHAMENTO.NEXO_TECNICO:
      return "Nexo Técnico Previdenciário sem CAT vinculada";
    case TIPO_DETALHAMENTO.VINCULOS:
      return "Vínculos";
    case TIPO_DETALHAMENTO.TAXA_ROTATIVIDADE:
      return "Taxa Média de Rotatividade";
    case TIPO_DETALHAMENTO.BENEFICIOS:
      let title = "Auxílio-acidente por acidente de trabalho";
      "B93" === especie && (title = "Pensão por morte por acidente de trabalho");
      if ("B92" === especie) {
        title = anoVigencia > 2019 ? "Aposentadoria por incapacidade permanente por acidente de trabalho" : "Aposentadoria por invalidez por acidente de trabalho";
      }
      if ("B91" === especie) {
        title = anoVigencia > 2019 ? "Auxílio por incapacidade temporária por acidente de trabalho" : "Auxílio-doença por acidente de trabalho";
      }
      return title + ` (${especie})`;
    case TIPO_DETALHAMENTO.BENEFICIOS_TOTAL:
      return "Total de Benefícios Pagos";
    case TIPO_DETALHAMENTO.PERCENTIL_CUSTO:
      return "Rol dos Índices de Custo";
    case TIPO_DETALHAMENTO.PERCENTIL_FREQUENCIA:
      return "Rol dos Índices de Frequência";
    case TIPO_DETALHAMENTO.PERCENTIL_GRAVIDADE:
      return "Rol dos Índices de Gravidade";
    case TIPO_DETALHAMENTO.DECLARACOES:
      return `Subclasse CNAE do Estabelecimento - Ano Vigência: ${anoVigencia}`;
    case TIPO_DETALHAMENTO.FAP_SIMPLIFICADO:
      return "FAP Simplificado por Estabelecimento";
    case TIPO_DETALHAMENTO.ANEXOS_RECALCULO:
      return "Anexos";
    default:
      return "";
  }
};

export const getDetalhamento = (tipo, dados = [], anoVigencia) => {
  const especie = dados && dados[0] ? dados[0].especie : "";
  const versaoCnae = dados ? dados.versaoCnae : "";
  const titulo = getDetalhamentoDescricao(tipo, especie, anoVigencia);

  let colunas;

  switch (tipo) {
    case TIPO_DETALHAMENTO.CAT:
      colunas = catColumns;
      break;
    case TIPO_DETALHAMENTO.MASSA_SALARIAL:
      colunas = massaSalarialColumns;
      break;
    case TIPO_DETALHAMENTO.NEXO_TECNICO:
      colunas = nexoTecnicoColumns;
      break;
    case TIPO_DETALHAMENTO.VINCULOS:
      colunas = vinculosColumns;
      break;
    case TIPO_DETALHAMENTO.TAXA_ROTATIVIDADE:
      colunas = taxaRotatividadeColumns;
      break;
    case TIPO_DETALHAMENTO.BENEFICIOS:
      colunas = beneficiosColumns
        .filter((colDef) => colDef.field !== "especie")
        .filter((colDef) => !("B93" !== especie && (colDef.field === "dependente" || colDef.field === "cpfInstituidor")));
      break;
    case TIPO_DETALHAMENTO.BENEFICIOS_TOTAL:
      colunas = beneficiosColumns;
      break;
    case TIPO_DETALHAMENTO.PERCENTIL_CUSTO:
      colunas = percentilColumns;
      colunas.find((colDef) => colDef.field === "indice").headerName = "Índice de Custo";
      break;
    case TIPO_DETALHAMENTO.PERCENTIL_FREQUENCIA:
      colunas = percentilColumns;
      colunas.find((colDef) => colDef.field === "indice").headerName = "Índice de Frequência";
      break;
    case TIPO_DETALHAMENTO.PERCENTIL_GRAVIDADE:
      colunas = percentilColumns;
      colunas.find((colDef) => colDef.field === "indice").headerName = "Índice de Gravidade";
      break;
    case TIPO_DETALHAMENTO.DECLARACOES:
      colunas = declaracoesColumns(versaoCnae);
      break;
    case TIPO_DETALHAMENTO.FAP_SIMPLIFICADO:
      colunas = fapSimplificadoColumns;
      break;
    case TIPO_DETALHAMENTO.ANEXOS_RECALCULO:
      colunas = anexosRecalculoColumns;
      break;
    default:
      colunas = [];
      break;
  }
  return {
    titulo,
    colunas,
    dados
  };
};
