import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import CustomDataGrid from "components/CustomDataGrid";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import { useVigencias } from "hooks/useVigencias";
import PareceresApi from "services/api/PareceresApi";

import ParecerForm from "./components/ParecerForm";
import { columns } from "./model";
import { BoxMessage } from "components/BoxMessage";

export default function Pareceres() {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(true);

  const [row, setRow] = React.useState();
  const [rows, setRows] = React.useState();
  const [rowsError, setRowsError] = React.useState();

  const [exibeSucesso, setExibeSucesso] = React.useState();

  function _setLoading(show) {
    setLoading(show);
    show && setRowsError(null);
  }

  React.useEffect(() => {
    (async () => {
      const res = await PareceresApi.obter();
      !!res.error && setRowsError(res.erros[0].mensagem);
      !res.error && setRows(res);
      _setLoading(false);
    })();
  }, []);

  const _handleEditParecer = (row) => {
    setRow(row);
  };

  const _handleRemoveRow = async (parecerRow) => {
    _setLoading(true);
    const res = await PareceresApi.remover(parecerRow.idParecer);
    if (!res.erros) {
      setRows(() => rows.filter((r) => parecerRow.idParecer !== r.idParecer));
    } else {
      setRowsError(res);
    }
    _setLoading(false);
  };

  const _handleUpdateRows = (parecerRow) => {
    if (parecerRow) {
      const rowFound = rows.find((r) => parecerRow.idParecer === r.idParecer);
      let auxRows = [...rows];
      if (!!rowFound) {
        auxRows.forEach((r, i) => {
          if (parecerRow.idParecer === r.idParecer) {
            auxRows[i] = { ...parecerRow };
          }
        });
      } else {
        auxRows.unshift(parecerRow);
      }
      setRows(() => auxRows);
      setExibeSucesso(true);
    }
    setRow(null);
  };

  const _handleSaveParecer = async (idParecer, params) => {
    if (idParecer) {
      return await PareceresApi.alterar(idParecer, params);
    } else {
      return await PareceresApi.criar(params);
    }
  };

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container>
        {rowsError && (
          <Grid item sm={12}>
            <BoxMessage message={rowsError} />
          </Grid>
        )}

        <Grid item sm={10}>
          <h5>Relação de Pareceres</h5>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <BrButton secondary onClick={() => _handleEditParecer({})}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            Novo Parecer
          </BrButton>
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid
            rows={rows || []}
            columns={columns}
            noRowsLabel={rowsError}
            getRowId={(r) => r.idParecer}
            onEdit={_handleEditParecer}
            onDelete={_handleRemoveRow}
            onDeleteMessage="Deseja realmente remover o parecer?"
          />
        </Grid>

        <Grid item sm={12}>
          {!!row && <ParecerForm parecer={row} onSubmit={_handleSaveParecer} onClose={_handleUpdateRows} vigencias={vigencias} setLoading={setLoading} />}
        </Grid>
      </Grid>

      <ModalMessage show={!!exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
}
