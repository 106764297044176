import React from "react";
import { DataGrid, ptBR, GridToolbarContainer, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarExport } from "@mui/x-data-grid";
import { withStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { logAuditoriaStyle } from "./model";

function DetailsToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport csvOptions={{ delimiter: ";" }} />
    </GridToolbarContainer>
  );
}

const CustomRowHeightDataGrid = withStyles(logAuditoriaStyle)(DataGrid);

const LogAuditoriaDataGrid = ({ columns, rows = [], pageSize = 10, density = "compact", disableSelectionOnClick = false, ...rest }) => {
  const [pgSize, setPageSize] = React.useState(pageSize);

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item sm={12}>
          <Divider style={{ marginTop: 30 }} />
        </Grid>
        <Grid item sm={6}>
          <h5>Resultado</h5>
        </Grid>
        <Grid item sm={12} className="br-table">
          <CustomRowHeightDataGrid
            localeText={ptBR.props.MuiDataGrid.localeText}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            density={density}
            pageSize={pgSize}
            rowsPerPageOptions={[pageSize]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: DetailsToolBar }}
            disableSelectionOnClick={true}
            disableColumnMenu
            hideFooterSelectedRowCount={true}
            {...rest}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LogAuditoriaDataGrid;
