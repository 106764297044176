import { Divider, Grid } from "@material-ui/core";

import CustomCard from "components/CustomCard";

import { RevisaoContestacaoForm } from "./RevisaoContestacaoForm";

export const Secao3Card = ({ contestacaoParam, userData, _redirectBackToContestacoes }) => {
  return (
    <CustomCard title={"Revisar e Transmitir Contestação"}>
      <Grid container spacing={1}>
        <Grid item sm={12} style={{ marginLeft: 30 }}>
          <h6 style={{ marginTop: 0 }}>Quantidade de elementos contestados</h6>
          <Divider />
        </Grid>

        <Grid item sm={12} style={{ marginLeft: 30 }}>
          <RevisaoContestacaoForm contestacao={contestacaoParam} userData={userData} onCancel={_redirectBackToContestacoes} />
        </Grid>
      </Grid>
    </CustomCard>
  );
};
