import config from "react-global-configuration";

const CONFIG_FILE = "/config.json";

const MSG_ERR_CONFIG_NOT_LOAD = "Configuração de autenticação não carregada.";
const MSG_ERR_CONFIG_INVALID = "Configuração de autenticação inválida.";
const MSG_ERR_CONFIG_FAIL = "Falha ao carregar configurações. Tente novamente atualizando a página ou contate o administrador.";
const MSF_ERR_CONFIG_IDP_NOT_FOUND = "Configuração de login não encontrada.";

const P_BASE_AUTH_URL = "baseAuthUrl";
const P_BASE_API_URL = "baseApiUrl";
const P_API_URI = "apiUri";
const P_SCOPE = "scope";
const P_CLIENT_ID = "clientId";
const P_IDPS = "idps";

function validaConfiguracoes(arquivo = {}) {
  const cfg = {
    [P_BASE_AUTH_URL]: arquivo[P_BASE_AUTH_URL],
    [P_BASE_API_URL]: arquivo[P_BASE_API_URL],
    [P_API_URI]: arquivo[P_API_URI],
    [P_IDPS]: arquivo[P_IDPS],
    [P_SCOPE]: arquivo[P_SCOPE],
    [P_CLIENT_ID]: arquivo[P_CLIENT_ID]
  };

  Object.keys(cfg).forEach((i) => {
    if (!cfg[i]) {
      throw new Error(`${i}: ${MSG_ERR_CONFIG_NOT_LOAD}`);
    }
    if (i === P_BASE_AUTH_URL && !/^https.+/.test(cfg[i])) {
      throw new Error(MSG_ERR_CONFIG_INVALID);
    }
    if ((i === P_IDPS && !cfg[i]) || cfg[i].length < 1) {
      throw new Error(MSF_ERR_CONFIG_IDP_NOT_FOUND);
    }
    // TODO: validar chaves de cada IDP
  });
  return cfg;
}

function salvaConfiguracoes(cfg) {
  config.set(cfg);
}

class ConfigManager {
  constructor() {
    throw new Error("ConfigManager Abstract class.");
  }

  static carregaConfiguracoesExternas() {
    return fetch(CONFIG_FILE)
      .then((res) => res.json())
      .then((arquivo) => {
        const cfg = validaConfiguracoes(arquivo);
        salvaConfiguracoes(cfg);
        return Promise.resolve();
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject({
          message: MSG_ERR_CONFIG_FAIL,
          detail: err.message
        });
      });
  }

  static authUrl() {
    return config.get(P_BASE_AUTH_URL);
  }

  static apiBaseUrl() {
    return config.get(P_BASE_API_URL);
  }

  static apiUrl() {
    return config.get(P_BASE_API_URL) + config.get(P_API_URI);
  }

  static scope() {
    return config.get(P_SCOPE);
  }

  static clientId() {
    return config.get(P_CLIENT_ID);
  }

  static idp(chave = 0) {
    const idps = config.get(P_IDPS);
    if (!idps || !Array.isArray(idps) || idps.length === 0) {
      return null;
    }
    if (typeof chave === "string") {
      const lista = idps.filter((i) => chave === i.idp);
      return lista.length > 0 ? lista[0] : null;
    }
    return idps[chave];
  }
}

export default ConfigManager;
