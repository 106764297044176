import jsPDF from "jspdf";

export function generatePdf(htmlBody, fileName) {
  let jsPdf = new jsPDF("p", "pt", "a4");
  const opt = {
    callback: function (jsPdf) {
      jsPdf.save(fileName);
    },
    margin: [10, 10, 10, 10],
    autoPaging: "text",
    x: 0,
    y: 0,
    html2canvas: {
      allowTaint: false,
      dpi: 300,
      letterRendering: false,
      logging: false,
      scale: 0.7,
      removeContainer: false
    }
  };
  const html = "<div style='width:820px;'>" + htmlBody + "</div>";

  jsPdf.html(html, opt);
}

export function openHtmlReport(fileName, base64Content) {
  if (!base64Content || !fileName) return;

  const html = Buffer.from(base64Content, "base64");
  var win = window.open(fileName, "_blank", "width=820,height=700,scrollbars=1");
  if (win) {
    win.document.write("<html style='overflow:auto; height:100%;'><title>" + fileName + "</title>" + html + "</html>");
    win.focus();
    win.print();
  }
  return;
}
