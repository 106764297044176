import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  AnalisaContestacao,
  AutorizaRecalculo,
  CadastraPublicacaoDou,
  ConsultaAnalisaAdmContestacao,
  ConsultaDesisteAdmContestacao,
  DesisteAdmContestacao,
  GestaoContestacao,
  HomologaDesistenciaAdmContestacao,
  ImprimeDespacho
} from "./AnalisaContestacao";
import { ConsultaContestacao, Contestacao, CriaContestacao, DesisteContestacao } from "./ContestacaoEletronica";
import { ConsultaJulgamento, CriaJulgamento, EditarJulgamento } from "./ResultadoJulgamento";
import ExtratorInsumosEsocial from "./ConsultaExtracao/ExtratorInsumosEsocial";
import RelatorioProdutividade from "./RelatorioProdutividade";
import ConsultaProcessamento from "./ConsultaProcessamento";
import RelatoriosGerenciais from "./RelatoriosGerenciais";
import ExtratorInsumosGFip from "./ConsultaExtracao/ExtratorInsumosGFip";
import TaxaMortalidadeCnae from "./TaxaMortalidadeCnae";
import ResultadosAmostras from "./ResultadosAmostras";
import Reprocessamentos from "./Reprocessamentos";
import ExpectativaVida from "./ExpectativaVida";
import MonitorServicos from "./MonitorServicos";
import TaxaMortalidade from "./TaxaMortalidade";
import RelatoriosRat from "./RelatoriosRat";
import RegrasCalculo from "./RegrasCalculo";
import LogAuditoria from "./LogAuditoria";
import LogExecucao from "./LogExecucao";
import Unauthorized from "./Unauthorized";
import DatasPrazos from "./DatasPrazos";
import Procuracoes from "./Procuracoes";
import Parametros from "./Parametros";
import Mensagens from "./Mensagens";
import Pareceres from "./Pareceres";
import NoMatch from "./NoMatch";
import Home from "./Home";

const MenuNestedRoute = (props) => {
  if (!props.menu.isAuthorized()) {
    return <Unauthorized {...props} />;
  }

  switch (props.menu.id) {
    case "home":
      return <Home {...props} />;
    case "consultaFap":
      return <ConsultaProcessamento {...props} />;
    case "contestacaoEletronica":
      return (
        <Switch>
          <Route exact path={props.menu.url + "/criar"} render={(routeProps) => <CriaContestacao {...props} {...routeProps} />} />
          <Route exact path={props.menu.url + "/contestar"} render={(routeProps) => <Contestacao {...props} {...routeProps} />} />
          <Route exact path={props.menu.url + "/desistir"} render={(routeProps) => <DesisteContestacao {...props} {...routeProps} />} />
          <Route path={props.menu.url} render={(routeProps) => <ConsultaContestacao {...props} {...routeProps} />} />
        </Switch>
      );
    case "desisteContestacao":
      return (
        <Switch>
          <Route exact path={props.menu.url + "/desistir"} render={(routeProps) => <DesisteAdmContestacao {...props} {...routeProps} />} />
          <Route path={props.menu.url} render={(routeProps) => <ConsultaDesisteAdmContestacao {...props} {...routeProps} />} />
        </Switch>
      );
    case "homologaContestacao":
      return <HomologaDesistenciaAdmContestacao {...props} />;
    case "analisaContestacao":
      return (
        <Switch>
          <Route exact path={props.menu.url + "/analisar"} render={(routeProps) => <AnalisaContestacao {...props} {...routeProps} />} />
          <Route path={props.menu.url} render={(routeProps) => <ConsultaAnalisaAdmContestacao {...props} {...routeProps} />} />
        </Switch>
      );
    case "autorizaRecalculo":
      return <AutorizaRecalculo {...props} />;
    case "imprimeDespacho":
      return <ImprimeDespacho {...props} />;
    case "gestaoContestacao":
      return <GestaoContestacao {...props} />;
    case "cadastraPublicacaoDou":
      return <CadastraPublicacaoDou {...props} />;
    case "datasPrazos":
      return <DatasPrazos {...props} />;
    case "regrasCalculo":
      return <RegrasCalculo {...props} />;
    case "parametros":
      return <Parametros {...props} />;
    case "expectativa-vida":
      return <ExpectativaVida {...props} />;
    case "taxa-mortalidade-anual":
      return <TaxaMortalidade {...props} />;
    case "taxa-mortalidade-cnae":
      return <TaxaMortalidadeCnae {...props} />;
    case "mensagens":
      return <Mensagens {...props} />;
    case "procuracoes":
      return <Procuracoes {...props} />;
    case "pareceres":
      return <Pareceres {...props} />;
    case "reprocessamentos":
      return <Reprocessamentos {...props} />;
    case "relatoriosRat":
      return <RelatoriosRat {...props} />;
    case "relatoriosGerenciais":
      return <RelatoriosGerenciais {...props} />;
    case "relatorioProdutividade":
      return <RelatorioProdutividade {...props} />;
    case "logAuditoria":
      return <LogAuditoria {...props} />;
    case "logExecucao":
      return <LogExecucao {...props} />;
    case "extratorInsumosGfip":
      return <ExtratorInsumosGFip {...props} />;
    case "extratorInsumosEsocial":
      return <ExtratorInsumosEsocial {...props} />;
    case "resultadoJulgamento":
      return (
        <Switch>
          <Route exact path={props.menu.url + "/criar"} render={(routeProps) => <CriaJulgamento {...props} {...routeProps} />} />
          <Route exact path={props.menu.url + "/editar"} render={(routeProps) => <EditarJulgamento {...props} {...routeProps} />} />
          <Route path={props.menu.url} render={(routeProps) => <ConsultaJulgamento {...props} {...routeProps} />} />
        </Switch>
      );
    case "resultadosAmostras":
      return <ResultadosAmostras {...props} />;
    case "monitorServicos":
      return <MonitorServicos {...props} />;
    default:
      console.warn("MenuRoute not found.", { menu: props.menu });
      return <NoMatch {...props} />;
  }
};

const Routes = ({ menus = [], userData = {}, ...rest }) => {
  const renderMenuRoute = (menuRouteProps) => (routerProps) => {
    return <MenuNestedRoute {...routerProps} {...menuRouteProps} />;
  };

  return (
    <Switch>
      {menus
        .filter((m) => m.isRoute())
        .sort((a, b) => b.url.localeCompare(a.url)) //Não usamos 'exact' pra suportar rotas aninhadas. E por isso, é necessario manter a rota root '/' no final
        .map((menu, key) => (
          <Route key={`route-${key}`} path={menu.url} render={renderMenuRoute({ menu, userData })} />
        ))}
      <Route component={NoMatch} />;
    </Switch>
  );
};

export default Routes;
