import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, FormErrorPanel, InputGeneric, isUndefined } from "components/Form";
import AnexosDataGrid from "components/AnexosDataGrid";
import { LabelValue } from "components/LabelValue";

import { formataBoleano, formataCNPJ, formataCNPJRaiz, formataCompetencia, formataCPF, formataDateEnBr, formataNumberEnBr, formataPorcento, formataReal } from "utils/StrUtils";
import { isPrimeiraInstancia, isSegundaInstancia } from "utils/ContestacaoUtils";
import { isDeferimentoElementoAlteracao } from "Routes/ContestacaoEletronica/ContestacaoUtils";
import { TipoElementoContestacao } from "utils/TipoUtils";
import ContestacoesApi from "services/api/ContestacoesApi";
import AnexosApi from "services/api/AnexosApi";

import { anexoModel, createDeferimentoModel } from "../model";

const ULTIMO_PARECER_KEY = "ultimoParecer";

const DadosElementoModel = {
  CAT: {
    numeroCat: { label: "Número da CAT" },
    dataCadastroCat: { label: "Data de Cadastramento da CAT", format: formataDateEnBr },
    dataAcidente: { label: "Data do Acidente de Trabalho", format: formataDateEnBr },
    cnpjAtribuido: { label: "CNPJ do Empregador Atribuído", format: formataCNPJ },
    cnpjOriginal: { label: "CNPJ do Empregador constante na CAT", format: formataCNPJ },
    estado: { label: "Estado do Insumo" },
    nit: { label: "Nit do Empregado" },
    dataNascimento: { label: "Data de Nascimento do Empregado", format: formataDateEnBr },
    dataObito: { label: "Data de Óbito do Empregado", format: formataDateEnBr }
  },
  BENEFICIO: {
    nb: { label: "Número do Benefício" },
    especie: { label: "Espécie de Benefício" },
    bloqueio: { label: "Bloqueio", format: formataBoleano },
    cat: { label: "Número da CAT" },
    cnpjRaiz: { label: "CNPJ Raiz", format: formataCNPJRaiz },
    cnpj: { label: "CNPJ do Empregador", format: formataCNPJ },
    nit: { label: "Nit do Empregado" },
    cpf: { label: "CPF do Beneficiário", format: formataCPF },
    dataNascimento: { label: "Data de Nascimento", format: formataDateEnBr },
    valorMensal: { label: "Renda Mensal Inicial (R$)", format: formataReal },
    dependente: { label: "Dependente" },
    dataInicio: { label: "Data de Início", format: formataDateEnBr },
    dataCessacao: { label: "Data de Cessação", format: formataDateEnBr },
    dataDespacho: { label: "Data do Despacho do benefício-DDB", format: formataDateEnBr },
    dataInicioContabilizacao: { label: "Data Inicial de Contabilização (fixada ou real)", format: formataDateEnBr },
    dataFimContabilizacao: { label: "Data Final de Contabilização (fixada, real ou projetada)", format: formataDateEnBr },
    estado: { label: "Estado do Insumo" },
    mesesContabilizacao: { label: "Duração/Expectativa de Tempo (meses)", format: formataNumberEnBr },
    totalPago: { label: "Total Pago/Projeção (R$)", format: formataReal }
  },
  MASSA_SALARIAL: {
    competencia: { label: "Competência:", format: formataCompetencia },
    cnpjRaiz: { label: "CNPJ Raiz", format: formataCNPJRaiz },
    cnpj: { label: "CNPJ do Empregador", format: formataCNPJ },
    estado: { label: "Estado do Insumo" },
    valorMassaSalarial: { label: "Valor (R$)", format: formataReal }
  },
  NEXO_TECNICO: {
    cnpj: { label: "CNPJ do Empregador", format: formataCNPJ },
    cnpjRaiz: { label: "CNPJ Raiz", format: formataCNPJRaiz },
    dataNascimento: { label: "Data de Nascimento", format: formataDateEnBr },
    dataObito: { label: "Data de Óbito do Empregado", format: formataDateEnBr },
    especie: { label: "Espécie de Benefício" },
    estado: { label: "Estado do Insumo" },
    nb: { label: "Número do Benefício" },
    nit: { label: "Nit do Empregado" },
    valorMensal: { label: "Renda Mensal Inicial (R$)", format: formataReal }
  },
  ROTATIVIDADE: {
    ano: { label: "Ano" },
    taxaRotatividade: { label: "Taxa de Rotatividade (%)", format: formataPorcento },
    admissoes: { label: "Admissões", format: formataNumberEnBr },
    estado: { label: "Estado do Insumo" },
    rescisoes: { label: "Rescisões", format: formataNumberEnBr },
    numeroInicialVinculos: { label: "Número de Vínculos no Início do Ano", format: formataNumberEnBr }
  },
  NUMERO_MEDIO_VINCULOS: {
    competencia: { label: "Competência", format: formataCompetencia },
    vinculos: { label: "Quantidade", format: formataNumberEnBr },
    estado: { label: "Estado do Insumo" }
  }
};

const DadosElementoSolicitadoModel = {
  CAT: {
    justificativa: { label: "Justificativa" }
  },
  BENEFICIO: {
    justificativa: { label: "Justificativa" }
  },
  MASSA_SALARIAL: {
    valorMassaSalarialSolicitado: { label: "Valor Solicitado (R$)", format: formataReal },
    justificativa: { label: "Justificativa" }
  },
  NEXO_TECNICO: {
    justificativa: { label: "Justificativa" }
  },
  ROTATIVIDADE: {
    taxaRotatividadeSolicitado: { label: "Taxa de Rotatividade Solicitada (%)", format: formataPorcento },
    admissoesSolicitado: { label: "Admissões Solicitada", format: formataNumberEnBr },
    rescisoesSolicitado: { label: "Rescisões Solicitada", format: formataNumberEnBr },
    numeroInicialVinculosSolicitado: { label: "Número de Vínculos no Início do Ano Solicitado" },
    justificativa: { label: "Justificativa" }
  },
  NUMERO_MEDIO_VINCULOS: {
    vinculosSolicitado: { label: "Quantidade Solicitado", format: formataNumberEnBr },
    justificativa: { label: "Justificativa" }
  }
};

const DadosElementoAlteradoModel = {
  CAT: {
    deferimento: { label: "Decisão do Analista", format: (deferimento) => deferimento?.descricao },
    parecer: { label: "Parecer do Analista" }
  },
  BENEFICIO: {
    valorMensalAlterado: { label: "Renda Mensal Inicial Alterada (R$)", format: formataReal },
    dataInicioAlterado: { label: "Data de Início Alterada", format: formataDateEnBr },
    dataCessacaoAlterado: { label: "Data de Cessação Alterada", format: formataDateEnBr },
    acidenteTrajetoAlterado: { label: "Óbito por Acidente de Trajeto", format: formataBoleano },
    deferimento: { label: "Decisão do Analista", format: (deferimento) => deferimento?.descricao },
    parecer: { label: "Parecer do Analista" }
  },
  MASSA_SALARIAL: {
    valorMassaSalarialAlterado: { label: "Valor Alterado (R$)", format: formataReal },
    deferimento: { label: "Decisão do Analista", format: (deferimento) => deferimento?.descricao },
    parecer: { label: "Parecer do Analista" }
  },
  NEXO_TECNICO: {
    deferimento: { label: "Decisão do Analista", format: (deferimento) => deferimento?.descricao },
    parecer: { label: "Parecer do Analista" }
  },
  ROTATIVIDADE: {
    taxaRotatividadeAlterado: { label: "Taxa de Rotatividade Alterada (%)", format: formataPorcento },
    admissoesAlterado: { label: "Admissões Alterada", format: formataNumberEnBr },
    rescisoesAlterado: { label: "Rescisões Alterada", format: formataNumberEnBr },
    numeroInicialVinculosAlterado: { label: "Número de Vínculos no Início do Ano Alterado", format: formataNumberEnBr },
    deferimento: { label: "Decisão do Analista", format: (deferimento) => deferimento?.descricao },
    parecer: { label: "Parecer do Analista" }
  },
  NUMERO_MEDIO_VINCULOS: {
    vinculosAlterado: { label: "Quantidade Alterada", format: formataNumberEnBr },
    deferimento: { label: "Decisão do Analista", format: (deferimento) => deferimento?.descricao },
    parecer: { label: "Parecer do Analista" }
  }
};

const getDadosElemento = (tipo, elemento, campos = DadosElementoModel) => {
  if (!elemento || !campos[tipo]) {
    return [];
  }

  return Object.keys(campos[tipo]).map((key) => {
    const value = isUndefined(elemento[key]) ? "" : elemento[key];
    return {
      key,
      label: campos[tipo][key].label,
      value: !isUndefined(elemento[key]) && campos[tipo][key].format ? campos[tipo][key].format(value) : value
    };
  });
};

const getDadosSolicitados = (tipo, elemento) => getDadosElemento(tipo, elemento, DadosElementoSolicitadoModel);
const getDadosAlterados = (tipo, elemento) => getDadosElemento(tipo, elemento, DadosElementoAlteradoModel);
const shouldShowObitoAcidenteTrajeto = ({ especie, instancia, tipoElemento }) =>
  ["B92", "B93"].includes(especie) && isPrimeiraInstancia({ instancia }) && TipoElementoContestacao.BENEFICIO.id === tipoElemento;

const AnalisaElementoForm = ({ elementoContestado = {}, contestacao = {}, onSubmit = () => {}, onCancel = () => {}, setLoading = () => {} }) => {
  const { tipoElemento } = elementoContestado;
  const [fields, setFields] = React.useState();
  const [anexoField, setAnexoField] = React.useState(anexoModel());
  const [formErrors, setFormErrors] = React.useState([]);

  const [anexos, setAnexos] = React.useState([]);
  const [noAnexosError, setNoAnexosError] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [successMessage, setSuccessMessage] = React.useState();
  const [pareceres, setPareceres] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      let resDeferimentos = await ContestacoesApi.obterDeferimentosPorElemento(elementoContestado.tipoElemento);
      let resPareceres = await ContestacoesApi.obterPareceresPorElemento(elementoContestado.tipoElemento, elementoContestado.anoVigencia);

      !!resDeferimentos.erros && (resDeferimentos = []);
      !!resPareceres.erros && (resPareceres = []);
      setPareceres(resPareceres);
      const ultimoParecer = getUltimoParecer(tipoElemento);
      setFields(() =>
        createDeferimentoModel({
          fields: isSegundaInstancia(contestacao) ? elementoContestado.segundaInstancia : elementoContestado.primeiraInstancia,
          deferimentos: resDeferimentos.map((def) => ({
            value: def.codigo,
            label: def.descricao
          })),
          pareceres: resPareceres.map((par) => ({
            value: par.idParecer,
            label: par.titulo
          })),
          ultimoParecer: ultimoParecer ? { idParecer: ultimoParecer.idParecer, parecer: getTextoParecer(resPareceres, ultimoParecer.idParecer) } : null
        })
      );
    })();
    return () => setFields(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setLoading = React.useCallback((show) => {
    show && setFormErrors([]);
    setLoading(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _onChangeCatalogoParecer(field) {
    fields.parecer.value = getTextoParecer(pareceres, field.value);
    setFields(() => ({ ...fields }));
  }

  function _handleChange(field) {
    setFields(() => ({ ...fields }));
  }

  function _handleAnexoChange(field) {
    setAnexoField(() => ({ ...anexoField }));
  }

  React.useEffect(() => {
    (async () => {
      _setLoading(true);
      const { anoVigencia, cnpj } = contestacao;

      const elem = isSegundaInstancia(contestacao) ? elementoContestado.segundaInstancia : elementoContestado.primeiraInstancia;

      const params = {
        idContestacao: elem.idContestacao,
        idElemento: elem.id,
        tipoElemento: tipoElemento
      };

      let res = await AnexosApi.obterAnexosAdm(params);
      if (Array.isArray(res) && res.length > 0) {
        setAnexos(res.map((anexo) => ({ ...anexo, anoVigencia, cnpj })));
      } else {
        setNoAnexosError(!!res.erros ? res.erros[0]?.mensagem : "Não foram encontrados anexos para esse recálculo.");
      }
      _setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleConfirmRemoveAnexo = React.useCallback(
    async (idAnexo) => {
      _setLoading(true);
      const res = await AnexosApi.removerAnexos(idAnexo);
      if (res.erros) {
        setErrorMessage(res.erros.length > 0 ? res.erros[0]?.mensagem : res.mensagem);
      } else {
        setSuccessMessage("Arquivo removido com sucesso.");
        setAnexos(() => anexos.filter((r) => r.id !== idAnexo));
      }
      _setLoading(false);
    },
    [_setLoading, anexos]
  );

  function getTextoParecer(resPareceres, idParecer) {
    if (!resPareceres) return;
    const parecer = resPareceres.find((p) => p.idParecer === idParecer);
    return parecer?.parecer;
  }

  function getUltimoParecer(tipoElemento) {
    const pareceres = JSON.parse(localStorage.getItem(ULTIMO_PARECER_KEY));
    return pareceres ? pareceres.find((u) => u.tipoElemento === tipoElemento) : null;
  }

  function setUltimoParecer(tipoElemento, idParecer) {
    let ult = getUltimoParecer(tipoElemento);
    const pareceres = localStorage.getItem(ULTIMO_PARECER_KEY) ? JSON.parse(localStorage.getItem(ULTIMO_PARECER_KEY)) : [];
    if (ult) {
      const i = pareceres.findIndex((obj) => obj.tipoElemento === tipoElemento);
      pareceres[i].idParecer = idParecer;
    } else {
      pareceres.push({ tipoElemento, idParecer });
    }
    localStorage.setItem(ULTIMO_PARECER_KEY, JSON.stringify(pareceres));
  }

  async function _handleSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const { pareceres, ...requestBody } = convertFormFieldsToJsonObj(fields);
      const res = await ContestacoesApi.analisar(contestacao.id, requestBody.id, { ...requestBody, tipoElemento });
      if (res.erros) {
        setFormErrors(res.erros);
      } else {
        setUltimoParecer(tipoElemento, fields.pareceres.value);
        await onSubmit(requestBody);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  async function _handleAnexoSubmit(event) {
    _setLoading(true);
    event.preventDefault();
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(anexoField);
    if (isFormCheckValidity && isCheckValidity) {
      const elem = isSegundaInstancia(contestacao) ? elementoContestado.segundaInstancia : elementoContestado.primeiraInstancia;

      const params = {
        idContestacao: elem.idContestacao,
        idElemento: elem.id,
        tipoElemento: tipoElemento
      };

      let res = await AnexosApi.anexarArquivo(params, anexoField.anexo.value);

      if (!res.erros) {
        _setLoading(true);
        const { anoVigencia, cnpj } = contestacao;

        res = await AnexosApi.obterAnexosAdm(params);
        if (Array.isArray(res) && res.length > 0) {
          setAnexos(res.map((anexo) => ({ ...anexo, anoVigencia, cnpj })));
        } else {
          setNoAnexosError(!!res.erros ? res.erros[0]?.mensagem : "Não foram encontrados anexos para esse recálculo.");
        }
        _setLoading(false);
      } else {
        setFormErrors(res.erros);
      }
    }
    setAnexoField(() => anexoModel({}));
    _setLoading(false);
  }

  if (!fields) {
    return null;
  }

  const isShowDeferimentoForm = !!fields.deferimento.value && isDeferimentoElementoAlteracao(fields.deferimento.value);

  // TODO: fix form error message scrolls to top
  return (
    <>
      <Form errors={formErrors} onSubmit={_handleSubmit} submitTitle={"Salvar"} onCancel={onCancel} cancelTitle={"Voltar"}>
        <Grid item sm={12}>
          <p className="text-info text-up-01">Registro contestado</p>
        </Grid>

        <Grid item sm={12}>
          <Grid container>
            {getDadosElemento(tipoElemento, elementoContestado).map(({ key, label, value }) => (
              <LabelValue columns={6} key={`elemento-${key}`} label={label} value={value} orientation={key === "justificativa" ? "scrollTextLabel" : "inlineLabel"} />
            ))}
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Divider />
          <p className="text-info text-up-01">Contestação de primeira instância</p>
        </Grid>

        <Grid item sm={6}>
          {getDadosSolicitados(tipoElemento, elementoContestado.primeiraInstancia).map(({ key, label, value }) => (
            <LabelValue columns={12} key={`solicitado-${key}`} label={label} value={value} orientation={key === "justificativa" ? "scrollTextLabel" : "inlineLabel"} />
          ))}
        </Grid>

        {isSegundaInstancia(contestacao) && (
          <React.Fragment>
            <Grid item sm={6}>
              {getDadosAlterados(tipoElemento, elementoContestado.primeiraInstancia).map(({ key, label, value }) => (
                <LabelValue columns={12} key={`alterado-${key}`} label={label} value={value} orientation={key === "parecer" ? "scrollTextLabel" : "inlineLabel"} />
              ))}
            </Grid>
            <Grid item sm={12}>
              <Divider />
              <p className="text-info text-up-01">Contestação de segunda instância</p>
            </Grid>
            <Grid item sm={6}>
              {getDadosSolicitados(tipoElemento, elementoContestado.segundaInstancia).map(({ key, label, value }) => (
                <LabelValue key={`solicitado2-${key}`} label={label} value={value} orientation={key === "justificativa" ? "scrollTextLabel" : "inlineLabel"} />
              ))}
            </Grid>
          </React.Fragment>
        )}

        {!!fields && (
          <React.Fragment>
            <Grid item sm={12}>
              <Divider />
              <p className="text-info text-up-01">Análise Contestação</p>
              <InputGeneric field={fields.deferimento} onChange={_handleChange} />
            </Grid>

            {isShowDeferimentoForm && (
              <React.Fragment>
                {TipoElementoContestacao.BENEFICIO.id === tipoElemento && (
                  <React.Fragment>
                    <Grid item sm={3}>
                      <InputGeneric field={fields.valorMensalAlterado} onChange={_handleChange} />
                    </Grid>
                    <Grid item sm={3}>
                      <InputGeneric field={fields.dataInicioAlterado} onChange={_handleChange} />
                    </Grid>
                    <Grid item sm={3}>
                      <InputGeneric field={fields.dataCessacaoAlterado} onChange={_handleChange} />
                    </Grid>

                    {shouldShowObitoAcidenteTrajeto({ ...contestacao, ...elementoContestado }) && (
                      <Grid item sm={3}>
                        <InputGeneric field={fields.acidenteTrajetoAlterado} onChange={_handleChange} />
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {TipoElementoContestacao.MASSA_SALARIAL.id === tipoElemento && (
                  <Grid item sm={3}>
                    <InputGeneric field={fields.valorMassaSalarialAlterado} onChange={_handleChange} />
                  </Grid>
                )}
                {TipoElementoContestacao.ROTATIVIDADE.id === tipoElemento && (
                  <React.Fragment>
                    <Grid item sm={3}>
                      <InputGeneric field={fields.admissoesAlterado} onChange={_handleChange} />
                    </Grid>
                    <Grid item sm={3}>
                      <InputGeneric field={fields.rescisoesAlterado} onChange={_handleChange} />
                    </Grid>
                    <Grid item sm={3}>
                      <InputGeneric field={fields.numeroInicialVinculosAlterado} onChange={_handleChange} />
                    </Grid>
                  </React.Fragment>
                )}
                {TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id === tipoElemento && (
                  <Grid item sm={3}>
                    <InputGeneric field={fields.vinculosAlterado} onChange={_handleChange} />
                  </Grid>
                )}
              </React.Fragment>
            )}

            <Grid item sm={12}>
              <InputGeneric field={fields.pareceres} onChange={_onChangeCatalogoParecer} />
            </Grid>
            <Grid item sm={12}>
              <InputGeneric field={fields.parecer} onChange={_handleChange} />
            </Grid>

            <Grid item sm={12}>
              <Divider />
              <p className="text-info text-up-01">Relação de arquivos anexados</p>
            </Grid>

            <Grid item sm={12}>
              {anexoField.anexo && (
                <Grid container>
                  <FormErrorPanel messages={formErrors} />
                  <Grid item sm={4}>
                    <InputGeneric field={anexoField.anexo} onChange={_handleAnexoChange} />
                  </Grid>

                  <Grid item sm={2} style={{ paddingTop: 25, paddingLeft: 10 }}>
                    <BrButton secondary small onClick={(e) => _handleAnexoSubmit(e)}>
                      Anexar Arquivo
                    </BrButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm={12}>
              <AnexosDataGrid rows={anexos} noRowsLabel={noAnexosError} onDelete={_handleConfirmRemoveAnexo} successMessage={successMessage} errorMessage={errorMessage} />
            </Grid>
          </React.Fragment>
        )}
      </Form>
    </>
  );
};

export default AnalisaElementoForm;
