import { getApi, handleDownload } from "../ApiManager";

const contexto = "/relatorios-gerenciais";
const ctxResultadosAmostras = "/resultados-amostras";

const RelatoriosGerenciaisApi = {
  obterRelatorios: async () => getApi().get(`${contexto}`),
  downloadRelatorio: async (anoVigencia, tipoRelatorio, cnae) => getApi().get(`${contexto}/${anoVigencia}`, { params: { tipoRelatorio, cnae } }).then(handleDownload),
  obterResultadosAmostras: async (anoVigencia) => getApi().get(`${ctxResultadosAmostras}/${anoVigencia}`),
  downloadResultadosAmostras: async (anoVigencia, tipoFaseCalculo) =>
    getApi().get(`${ctxResultadosAmostras}/${anoVigencia}/download`, { params: { tipoFaseCalculo } }).then(handleDownload)
};

export default RelatoriosGerenciaisApi;
