import React from "react";
import { Grid, Link } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { checkFormValidity, InputGeneric } from "components/Form";
import { BoxMessage } from "components/BoxMessage";
import DataGridModal from "components/DataGridModal";
import Loading from "components/Loading";

import { useVigencias } from "hooks/useVigencias";
import RelatoriosApi from "services/api/RelatoriosApi";

import { createVigenciaModel, TipoRelatorioRat } from "./model";

const RelatoriosRat = () => {
  const { vigencias } = useVigencias();

  const [loading, setLoading] = React.useState(false);
  const [formError, setFormError] = React.useState([]);
  const [vigenciaField, setVigenciaField] = React.useState(null);

  const [exibeModal, setExibeModal] = React.useState(false);
  const [relatorio, setRelatorio] = React.useState({ dados: [], colunas: [], titulo: "" });

  React.useEffect(() => {
    setVigenciaField(() => createVigenciaModel({ vigencias }));
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormError([]);
  }

  function _handleChange() {
    _setLoading(true);
    setTimeout(() => {
      checkFormValidity(vigenciaField);
      setVigenciaField(() => ({ ...vigenciaField }));
      setFormError([]);
      return _setLoading(false);
    }, 700);
  }

  async function _obterRelatorio(tipoRelatorio, isPdf = false) {
    _setLoading(true);
    let { descricao, params, colunas, endpoint } = tipoRelatorio;
    const anoVigencia = vigenciaField.anoVigencia.value;

    params.publicar = isPdf;
    const res = await endpoint({ ...params, anoVigencia });
    if (!res.erros) {
      if (isPdf) {
        await RelatoriosApi.downloadRelatorioPercentisCnae({ ...params, anoVigencia });
      } else {
        setRelatorio(() => ({ dados: res, colunas, titulo: descricao }));
        setExibeModal(true);
      }
    } else {
      setFormError(res);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      {vigenciaField && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <BoxMessage message={formError} />
          </Grid>

          <Grid item sm={2}>
            <InputGeneric field={vigenciaField.anoVigencia} onChange={_handleChange} />
          </Grid>

          <Grid item sm={12}>
            <h5>Relatórios - {vigenciaField.anoVigencia.value}</h5>
          </Grid>

          {TipoRelatorioRat.map((tipoRelatorio) => (
            <Grid item sm={8}>
              <Grid container style={{ height: 30, fontSize: 15, paddingInline: 10, alignContent: "center" }}>
                <Grid item sm={9} key={tipoRelatorio.tipo}>
                  <Link component="button" onClick={() => _obterRelatorio(tipoRelatorio)}>
                    {tipoRelatorio.descricao}
                  </Link>
                </Grid>
                <Grid item>
                  {tipoRelatorio.tipo === "Percentis" && (
                    <BrButton small title="Download Relatório em PDF" onClick={() => _obterRelatorio(tipoRelatorio, true)}>
                      <span className={`text-info fa fa-file-pdf`}></span>
                      Download PDF
                    </BrButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {exibeModal && <DataGridModal rows={relatorio.dados} columns={relatorio.colunas} title={relatorio.titulo} headerHeight={110} onClose={() => setExibeModal(false)} />}
    </React.Fragment>
  );
};

export default RelatoriosRat;
