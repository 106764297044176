import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";
import BrModal from "@govbr/react-components/lib/BrModal";

import Form, { checkFormValidity, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import { BoxMessage } from "components/BoxMessage";
import Loading from "components/Loading";

import { handleDownload } from "services/ApiManager";
import TaxaMortalidadeApi from "services/api/TaxaMortalidadeApi";
import { formataDecimal } from "utils/StrUtils";

import { anexoModel, createAnoTaxaCnaeModel } from "./model";

export default function TaxaMortalidadeCnae() {
  const [anoTaxaCnaeField, setAnoTaxaCnaeField] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [message, setMessage] = React.useState([]);
  const [formEditError, setFormEditError] = React.useState([]);
  const [fields, setFields] = React.useState(anexoModel());
  const [taxasMortalidade, setTaxasMortalidade] = React.useState([]);

  const [exibeFormCsv, setExibeFormCsv] = React.useState(false);

  React.useEffect(() => {
    async function obterAnosTaxas() {
      _setLoading(true);
      let res = await TaxaMortalidadeApi.obterTaxasMortalidades();
      if (!res.erros) {
        setAnoTaxaCnaeField(createAnoTaxaCnaeModel(res.map((r) => r.ano).sort((a, b) => b - a)));
      } else {
        setMessage({ ...res });
        setAnoTaxaCnaeField([]);
      }
      _setLoading(false);
    }
    obterAnosTaxas();
  }, []);

  function _setLoading(show, overlay) {
    setLoading(show);
    show && setMessage();
    show && setTaxasMortalidade([]);
  }

  function _handleChangeArquivo(event) {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmitConsultar() {
    _setLoading(true);
    let res = await TaxaMortalidadeApi.obterTaxasMortalidadesCnae(anoTaxaCnaeField.anoTaxaCnae.value);
    if (!res.erros) {
      setTaxasMortalidade(res);
    } else {
      setMessage({ ...res });
    }
    _setLoading(false);
  }

  const columns = [
    { field: "ano", headerName: "Ano", flex: 1 },
    { field: "cnae", headerName: "Classe CNAE", flex: 1, valueGetter: ({ value }) => value },
    { field: "taxaMortalidade", headerName: "Taxa de Mortalidade", flex: 1, valueGetter: ({ value }) => formataDecimal(value, 2) }
  ];

  function _handleChangeVigencia(event) {
    setAnoTaxaCnaeField(() => ({ ...anoTaxaCnaeField }));
  }

  async function _handleSubmitUpload(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields) && checkFormValidity(anoTaxaCnaeField);
    if (isFormCheckValidity && isCheckValidity) {
      const res = await TaxaMortalidadeApi.carregarTaxasMortalidadesCnae(anoTaxaCnaeField.anoTaxaCnae.value, fields.anexo.value);
      if (!res.erros) {
        setMessage({ mensagem: "Operação realizada com sucesso!", type: "success" });
        setExibeFormCsv(false);
      } else {
        setFormEditError({ ...res });
      }
    }
    setFields(() => ({ ...fields }));
    setAnoTaxaCnaeField(() => ({ ...anoTaxaCnaeField }));
    _setLoading(false);
  }

  function _handleCancel(event) {
    setFields({});
    setMessage();
    setFormEditError([]);
    setExibeFormCsv(false);
  }

  async function importarCsv(row) {
    setFields(anexoModel(row));
    setExibeFormCsv(true);
    setMessage();
    setFormEditError([]);
  }

  function _handleClickDownloadModelo() {
    const ano = anoTaxaCnaeField?.anoTaxaCnae?.value || "";
    const MODELO_CSV = `"ano";"classeCnae";"taxaMortalidade";\r\n${ano};"0000";"0,0"`;

    const nome = `modelo_${ano}_mortalidade_cnae.csv`;
    const base64 = Buffer.from(MODELO_CSV).toString("base64");
    handleDownload({ nome, base64 });
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {anoTaxaCnaeField && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <BoxMessage message={message} />
          </Grid>
          <Grid item sm={3}>
            <Form onSubmit={_handleSubmitConsultar} submitTitle={"Consultar"} submitIcon={"fa-search"}>
              <Grid id="grid-anosTaxasMortalidadeCnae" item sm={6}>
                <InputGeneric field={anoTaxaCnaeField.anoTaxaCnae} onChange={_handleChangeVigencia} />
              </Grid>
            </Form>
          </Grid>

          <Grid item style={{ paddingTop: 30, marginLeft: "auto" }}>
            <BrButton onClick={() => importarCsv()}>
              <span className={`fa fa-file-upload fa-md`}></span>Carregar CSV
            </BrButton>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={12}>
              <h5>Taxas de Mortalidade Cnae</h5>
            </Grid>
            <Grid item sm={12}>
              <CustomDataGrid rows={taxasMortalidade} columns={columns} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {anoTaxaCnaeField && (
        <BrModal style={{ paddingTop: 0 }} id="modal2" visible={exibeFormCsv}>
          <Grid container>
            <Form onSubmit={_handleSubmitUpload} onCancel={_handleCancel} cancelTitle={"Cancelar"} submitTitle={"Carregar"} submitIcon="fa-file-upload">
              <Grid item sm={12}>
                <h6> Importar tabela de taxas de mortalidade Cnae para o ano: {anoTaxaCnaeField?.anoTaxaCnae?.value}</h6>

                <BoxMessage message={formEditError} />
                <BrMessage warning> Os dados do ano selecionado serão sobrescritos!</BrMessage>
              </Grid>
              <Grid item sm={12}>
                <InputGeneric field={fields.anexo} onChange={_handleChangeArquivo} />
              </Grid>
              <Grid item style={{ paddingTop: 30, marginLeft: 0 }}>
                <BrButton title="Utilize este arquivo .csv como modelo para preencher os dados de taxa de mortalidade." onClick={_handleClickDownloadModelo}>
                  <span className={`text-info fa fa-download`}></span>Baixar Modelo CSV
                </BrButton>
              </Grid>
            </Form>
          </Grid>
        </BrModal>
      )}
    </React.Fragment>
  );
}
