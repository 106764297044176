export class JWTError extends Error {
  constructor(message = "Token Inválido.") {
    super(message);
    this.name = "JWTError";
  }
}

const GOVBR = "GOVBR";
const GERID = "GERID";

function parseToJSON(payload) {
  const base64 = payload.replace(/-/g, "+").replace(/_/g, "/");
  const buff = Buffer.from(base64, "base64");
  return JSON.parse(buff);
}

function parseIdentityInfo(jwt) {
  const CLAIM_CPF = "http://wso2.org/claims/username";
  const CLAIM_NOME = "http://wso2.org/claims/fullname";
  const CLAIM_EMAIL = "http://wso2.org/claims/emailaddress";
  const CLAIM_FONE = "http://wso2.org/claims/telephone";
  const CLAIM_TIPO_GOVBR = "amr";
  const CLAIM_TIPO_GERID = "http://wso2.org/claims/gerid/mps/roles";

  const amr = jwt[CLAIM_TIPO_GOVBR];
  const isGovbr = !!amr && Array.isArray(amr) && amr.length > 0 && amr[0] === GOVBR;
  const isGerid = !!jwt[CLAIM_TIPO_GERID];

  if (!jwt[CLAIM_CPF] || isGerid === isGovbr) {
    return {};
  } else {
    return {
      tipo: isGerid ? GERID : GOVBR,
      cpf: jwt[CLAIM_CPF],
      nome: jwt[CLAIM_NOME],
      email: jwt[CLAIM_EMAIL],
      telefone: jwt[CLAIM_FONE],
      avatar: ""
    };
  }
}

function parseLoginInfo(jwt, identity) {
  const CLAIM_TIPO = "http://wso2.org/claims/amr";
  const senha = ["passwd", "LdapAuthenticationHandler"];
  const certificado = ["x509", "X509CredentialsAuthenticationHandler"];

  if (!!identity.tipo) {
    const tipoCert = certificado.includes(jwt[CLAIM_TIPO]) ? "certificado" : "outro";
    const tipo = senha.includes(jwt[CLAIM_TIPO]) ? "senha" : tipoCert;

    return {
      login_metodo: tipo
    };
  }
  return { login_metodo: "" };
}

function parseGeridInfo(jwt) {
  const CLAIM_PAPEIS = "http://wso2.org/claims/gerid/mps/roles";

  let geridRoles = jwt[CLAIM_PAPEIS] || [];
  geridRoles = Array.isArray(geridRoles) ? geridRoles : [geridRoles];
  return {
    papeis: geridRoles.length > 0 ? geridRoles : []
  };
}

function parseGovbrInfo(jwt) {
  const CLAIM_EMPRESAS = "http://wso2.org/claims/govbr/empresasVinculadas";

  let govbrEmpresas = jwt[CLAIM_EMPRESAS] || [];
  govbrEmpresas = Array.isArray(govbrEmpresas) ? govbrEmpresas : [govbrEmpresas];

  let cnpjsRaiz = [];
  const empresas = govbrEmpresas
    .filter((e) => {
      const isEmpresaVinculadaValid = /\d{8,14}\|.+/.test(e);
      if (!isEmpresaVinculadaValid) {
        console.error("Empresa inválida:", e);
      }
      return isEmpresaVinculadaValid;
    })
    .filter((e) => {
      const cnpj = e.substr(0, 8);
      if (cnpjsRaiz.includes(cnpj)) {
        return false;
      } else {
        cnpjsRaiz.push(cnpj);
        return true;
      }
    })
    .map((e) => ({ cnpj: e.substr(0, 8), nome: e.split("|")[1] }));

  return { empresas };
}

export function parseToUser(id_token) {
  if (!id_token || typeof id_token !== "string") {
    throw new JWTError();
  }

  const content = id_token.split(".")[1];
  if (!content) {
    throw new JWTError();
  }

  const jwt = parseToJSON(content);
  const date = jwt.exp ? jwt.exp * 1000 : Date.now();

  const identity = parseIdentityInfo(jwt);
  const gerid = parseGeridInfo(jwt);
  const govbr = parseGovbrInfo(jwt);
  const login = parseLoginInfo(jwt, identity);

  return {
    ...identity,
    ...login,
    ...gerid,
    ...govbr,
    date
  };
}

export function base64URLEncode(input) {
  if (typeof input === "undefined" || input === null || input === "") {
    return undefined;
  }

  if (!Buffer.isBuffer(input)) {
    if (typeof input === "object") {
      input = JSON.stringify(input);
    }
    input = Buffer.from(input.toString());
  }
  return input.toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}
