import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";
import BrCard from "@govbr/react-components/lib/BrCard";

import { TagMessage } from "components/TagMessages";
import { LabelValue } from "components/LabelValue";

import { formataCnae, formataProtocoloContestacao } from "utils/StrUtils";
import { TipoCalculo } from "utils/TipoUtils";

import { TIPO_DETALHAMENTO, getDetalhamentoDescricao } from "../DetalheModel";
import ModalDetalheIndicadores from "./ModalDetalheIndicadores";
import ModalAvisosImportantes from "./ModalAvisosImportantes";

const isRecalculo = ({ tipoProcessamento }) =>
  TipoCalculo.CALCULO_ORIGINAL !== TipoCalculo[tipoProcessamento] && TipoCalculo.DESBLOQUEIO_SINDICATO !== TipoCalculo[tipoProcessamento];
const isContestacao = ({ tipoProcessamento }) =>
  [TipoCalculo.RECALCULO_ELETRONICO_PRIMEIRA_INSTANCIA, TipoCalculo.RECALCULO_ELETRONICO_SEGUNDA_INSTANCIA].includes(TipoCalculo[tipoProcessamento]);

const DadosProcessoRecalculo = ({ calculoFap, onDetalhe, onRelatorio }) => {
  if (!isRecalculo(calculoFap)) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {isContestacao(calculoFap) && <LabelValue showNull columns={3} orientation={"inlineLabel"} label={"Protocolo"} value={calculoFap.protocolo} />}

        {!isContestacao(calculoFap) && <LabelValue showNull columns={3} orientation={"inlineLabel"} label={"Processo"} value={formataProtocoloContestacao(calculoFap.protocolo)} />}

        <Grid item sm={4}>
          {!!calculoFap.dataDou && <LabelValue orientation={"inlineLabel"} label="Data publicação no DOU" dataType={"date"} value={calculoFap.dataDou} />}
        </Grid>

        <LabelValue columns={3} orientation={"inlineLabel"} title={"Visualizar Relatório"} value={"Visualizar Relatório"} onClick={onRelatorio} className="text-info" />

        <LabelValue
          columns={2}
          orientation={"inlineLabel"}
          title={"Visualizar Anexos"}
          value={"Visualizar Anexos"}
          onClick={() => onDetalhe(TIPO_DETALHAMENTO.ANEXOS_RECALCULO)}
          className="text-info"
        />
      </Grid>
    </React.Fragment>
  );
};

const DadosCalculo = ({ calculoFap, vigencia, onDetalhe, avisosImportantes = [], mensagemIndicadores, relatorioPdfCnae, relatorioPdfRecalculo }) => {
  const [showDetalheIndicadores, setShowDetalheIndicadores] = React.useState(false);
  const { cnae = {}, ...dados } = calculoFap;
  const [exibeAvisosImportantes, setExibeAvisosImportantes] = React.useState(false);

  return (
    <BrCard>
      <Grid container>
        <Grid item sm={12}>
          <Grid container spacing={1} style={{ marginTop: 0 }} alignItems="baseline">
            <h5>Dados do Cálculo </h5>
            {avisosImportantes.length > 0 && (
              <BrButton small onClick={() => setExibeAvisosImportantes(true)}>
                <span className="text-danger"> Avisos </span>
                <span className="text-danger fa fa-exclamation-triangle fa-sm"></span>
              </BrButton>
            )}
          </Grid>

          {isRecalculo(calculoFap) && (
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <DadosProcessoRecalculo calculoFap={calculoFap} onDetalhe={onDetalhe} onRelatorio={relatorioPdfRecalculo} />
                <Divider />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={1} style={{ marginTop: 20 }}>
            <LabelValue
              showNull
              label={getDetalhamentoDescricao(TIPO_DETALHAMENTO.CAT)}
              value={dados.quantidadeCat}
              columns={4}
              orientation={"sideLabel"}
              dataType="number"
              onClick={dados.quantidadeCat > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.CAT) : null}
            />

            <LabelValue
              showNull
              label={getDetalhamentoDescricao(TIPO_DETALHAMENTO.BENEFICIOS, "B91", dados.anoVigencia)}
              value={dados.quantidadeB91}
              columns={4}
              orientation={"sideLabel"}
              dataType="number"
              onClick={dados.quantidadeB91 > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.BENEFICIOS, 91) : null}
            />

            <LabelValue
              showNull
              label={getDetalhamentoDescricao(TIPO_DETALHAMENTO.BENEFICIOS, "B92", dados.anoVigencia)}
              value={dados.quantidadeB92}
              columns={4}
              orientation={"sideLabel"}
              dataType="number"
              onClick={dados.quantidadeB92 > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.BENEFICIOS, 92) : null}
            />

            <LabelValue
              showNull
              label={getDetalhamentoDescricao(TIPO_DETALHAMENTO.BENEFICIOS, "B93", dados.anoVigencia)}
              value={dados.quantidadeB93}
              columns={4}
              orientation={"sideLabel"}
              dataType="number"
              onClick={dados.quantidadeB93 > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.BENEFICIOS, 93) : null}
            />

            <LabelValue
              showNull
              label={getDetalhamentoDescricao(TIPO_DETALHAMENTO.BENEFICIOS, "B94", dados.anoVigencia)}
              value={dados.quantidadeB94}
              columns={4}
              orientation={"sideLabel"}
              dataType="number"
              onClick={dados.quantidadeB94 > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.BENEFICIOS, 94) : null}
            />

            <LabelValue
              label={getDetalhamentoDescricao(TIPO_DETALHAMENTO.NEXO_TECNICO)}
              value={dados.quantidadeNexoTecnico}
              columns={4}
              orientation={"sideLabel"}
              dataType="number"
              onClick={dados.quantidadeNexoTecnico > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.NEXO_TECNICO) : null}
            />
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Divider style={{ marginTop: 20, marginBottom: 10 }} />
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={1}>
            <LabelValue
              showNull
              label="Massa Salarial"
              value={dados.valorTotalMassaSalarial}
              dataType="currency"
              columns={4}
              orientation={"upLabel"}
              onClick={dados.valorTotalMassaSalarial > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.MASSA_SALARIAL) : null}
            />
            <LabelValue
              showNull
              label="Número Médio de Vínculos"
              value={dados.mediaVinculos}
              dataType="index"
              columns={4}
              orientation={"upLabel"}
              onClick={dados.mediaVinculos > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.VINCULOS) : null}
            />
            <LabelValue
              showNull
              label="Total de Estabelecimentos na subclasse CNAE"
              value={dados.quantidadeEmpresasTotalCnae}
              columns={4}
              dataType="number"
              orientation={"upLabel"}
            />
            <LabelValue
              showNull
              label="Valor Total de Benefícios Pagos"
              value={dados.valorTotalBeneficiosPagos}
              dataType="currency"
              columns={4}
              orientation={"upLabel"}
              onClick={dados.valorTotalBeneficiosPagos > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.BENEFICIOS_TOTAL) : null}
            />
            <LabelValue
              showNull
              label="Total de Estabelecimentos na subclasse CNAE com todos os insumos necessários ao cálculo do FAP"
              columns={4}
              value={dados.quantidadeEmpresasValidasCnae}
              dataType="number"
              orientation={"upLabel"}
            />

            <Grid item sm={12} id="rel-cnae">
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <span className="text-base">CNAE - Classificação Nacional de Atividades Econômicas (Subclasse da CNAE - {vigencia.versaoCnae})</span>
                </Grid>

                <Grid item sm={11}>
                  <span
                    className="text-up-03 text-info"
                    style={{ cursor: calculoFap ? "pointer" : "default", textDecoration: calculoFap ? "underline" : "" }}
                    onClick={calculoFap ? () => onDetalhe(TIPO_DETALHAMENTO.DECLARACOES) : null}
                    title={calculoFap ? "Clique para ver detalhes" : ""}
                  >
                    {calculoFap && cnae.subClasse ? formataCnae(cnae.subClasse) : ""}
                  </span>
                  <span className="text-up-01 text-info">{calculoFap && cnae.descricao ? ` - ${cnae.descricao} ` : ""}</span>
                  <span style={{ cursor: "pointer" }} title="Relação de Declarações Válidas" onClick={() => relatorioPdfCnae()} className="text-info fa fa-file-pdf"></span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Divider style={{ marginTop: 20, marginBottom: 10 }} />
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={1} alignItems="baseline">
            <h5>Indicadores do Cálculo</h5>
            <div className="br-tag" title="Clique para ver detalhes" onClick={() => setShowDetalheIndicadores(true)}>
              <i className="fa fa-file-alt fa-sm" style={{ width: 9 }}></i>
            </div>
            <TagMessage messages={mensagemIndicadores} />
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={12}>
              <h6> Frequência </h6>
            </Grid>

            <LabelValue showNull label="Índice" value={dados.indiceFrequencia} columns={4} orientation={"upLabel"} dataType="index" />
            <LabelValue
              showNull
              label="Número de Ordem"
              value={dados.ordemFrequencia}
              columns={4}
              orientation={"upLabel"}
              dataType="index"
              onClick={dados.ordemFrequencia > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.PERCENTIL_FREQUENCIA) : null}
            />
            <LabelValue showNull label="Percentil" value={dados.percentilFrequencia} columns={4} orientation={"upLabel"} dataType="index" />

            <Grid item sm={12}>
              <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              <h6> Gravidade </h6>
            </Grid>

            <LabelValue showNull label="Índice" value={dados.indiceGravidade} columns={4} orientation={"upLabel"} dataType="index" />
            <LabelValue
              showNull
              label="Número de Ordem"
              value={dados.ordemGravidade}
              columns={4}
              orientation={"upLabel"}
              dataType="index"
              onClick={dados.ordemGravidade > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.PERCENTIL_GRAVIDADE) : null}
            />
            <LabelValue showNull label="Percentil" value={dados.percentilGravidade} columns={4} orientation={"upLabel"} dataType="index" />

            <Grid item sm={12}>
              <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              <h6> Custo </h6>
            </Grid>

            <LabelValue showNull label="Índice" value={dados.indiceCusto} columns={4} orientation={"upLabel"} dataType="index" />
            <LabelValue
              showNull
              label="Número de Ordem"
              value={dados.ordemCusto}
              columns={4}
              orientation={"upLabel"}
              dataType="index"
              onClick={dados.ordemCusto > 0 ? () => onDetalhe(TIPO_DETALHAMENTO.PERCENTIL_CUSTO) : null}
            />
            <LabelValue showNull label="Percentil" value={dados.percentilCusto} columns={4} orientation={"upLabel"} dataType="index" />

            <Grid item sm={12}>
              <Divider style={{ marginTop: 20, marginBottom: 10 }} />
            </Grid>
            <Grid container style={{ backgroundColor: "#c5d4eb", justifyContent: "space-between" }}>
              <LabelValue
                showNull
                label="Taxa Média de Rotatividade"
                value={dados.taxaMediaRotatividade}
                dataType="percent"
                columns={4}
                orientation={"upLabel"}
                onClick={dados.taxaMediaRotatividade >= 0 ? () => onDetalhe(TIPO_DETALHAMENTO.TAXA_ROTATIVIDADE) : null}
              />
              <LabelValue showNull label="Índice Composto" value={dados.indiceComposto} columns={4} orientation={"upLabel"} dataType="index" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalDetalheIndicadores show={showDetalheIndicadores} onClose={() => setShowDetalheIndicadores(false)} dados={{ ...dados }} />
      <ModalAvisosImportantes show={exibeAvisosImportantes} avisosImportantes={avisosImportantes} onClose={() => setExibeAvisosImportantes(false)} />
    </BrCard>
  );
};

export default DadosCalculo;
