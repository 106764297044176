import React from "react";
import { Grid } from "@material-ui/core";

import AnexosDataGrid from "components/AnexosDataGrid";
import CustomCard from "components/CustomCard";

import AnexosApi from "services/api/AnexosApi";

const Secao3Card = ({ contestacao, setLoading, ...props }) => {
  const [rows, setRows] = React.useState([]);
  const [rowsError, setRowsError] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [successMessage, setSuccessMessage] = React.useState();

  const _setLoading = React.useCallback((show) => {
    show && setErrorMessage(null);
    show && setSuccessMessage(null);
    setLoading(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => {
      _setLoading(true);
      const { anoVigencia, cnpj } = contestacao;
      const params = {
        idContestacao : contestacao.id
      }
      let res = await AnexosApi.obterAnexosAdm(params);
      if (Array.isArray(res) && res.length > 0) {
        setRows(res.map((anexo) => ({ ...anexo, anoVigencia, cnpj })));
      } else {
        setRowsError(!!res.erros ? res.erros[0]?.mensagem : "Não foram encontrados anexos para esse recálculo.");
      }
      _setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleConfirmRemove = React.useCallback(
    async (idAnexo) => {
      _setLoading(true);
      const res = await AnexosApi.removerAnexos(idAnexo);
      if (res.erros) {
        setErrorMessage(res.erros.length > 0 ? res.erros[0]?.mensagem : res.mensagem);
      } else {
        setSuccessMessage("Arquivo removido com sucesso.");
        setRows(() => rows.filter((r) => r.id !== idAnexo));
      }
      _setLoading(false);
    },
    [_setLoading, rows]
  );

  return (
    <CustomCard title={"Anexos da Contestação"}>
      <Grid container style={{ paddingLeft: 30 }}>
        <Grid item sm={12}>
          <AnexosDataGrid rows={rows} noRowsLabel={rowsError} onDelete={_handleConfirmRemove} successMessage={successMessage} errorMessage={errorMessage} />
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default Secao3Card;
