import React from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import { TipoSituacaoContestacao } from "utils/TipoUtils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";
import PublicacoesApi from "services/api/PublicacoesApi";

import { getContestacoesPublicacaoColumns, getPublicacaoColumns, isDataPrazoContestacaoRequired } from "./utils";
import { createConsultaPublicacoesModel, createCadastraPublicacaoModel } from "./model";
import ContestacoesPublicadasDataGridModal from "./components/ContestacoesPublicadasDataGridModal";

const actions = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST"
};

const contestacoesColumns = getContestacoesPublicacaoColumns();

const getSituacaoByPublicacao = (publicacao) => {
  if (!!publicacao.id) {
    return publicacao.situacaoContestacoes.codigo;
  } else {
    return !!publicacao.desistencia ? [TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_DOU.value] : [TipoSituacaoContestacao.FINALIZADA.value];
  }
};

async function obterContestacoes({ anoVigencia, instancia, id, situacao, situacaoContestacoes }) {
  return ContestacoesApi.obterContestacoes({
    anoVigencia: anoVigencia,
    idPublicacao: id,
    instancia: instancia?.codigo || instancia,
    situacao: situacaoContestacoes?.codigo || situacao
  });
}

const TITLE_FORM_CONTESTACOES_PUBLICACAO = "Selecione as contestações que serão inseridas no relatório de Publicação no DOU";
const TITLE_MODAL_CONTESTACOES = "Contestacões do relatório de publicação no DOU";

const ERROR_SAVE_NO_CONTESTACOES = "É necessário selecionar ao menos uma contestação no cadastro de relatório de Publicação no DOU.";
const ERROR_RELATORIO_PUBLICADO = "Relatório já publicado, suas informações não podem ser alteradas.";

export const CadastraPublicacaoDou = (props) => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState(actions.GET);

  const [consultaFormFields, setConsultaFormFields] = React.useState();
  const [consultaFormErrors, setConsultaFormErrors] = React.useState([]);

  const [publicacaoFormFields, setPublicacaoFormFields] = React.useState();
  const [publicacaoFormErrors, setPublicacaoFormErrors] = React.useState([]);

  const [publicacoesRows, setPublicacoesRows] = React.useState([]);
  const [contestacoesRows, setContestacoesRows] = React.useState([]);
  const [contestacoesRowsIdsSelected, setContestacoesRowsIdsSelected] = React.useState([]);
  const [efeitoSuspensivoErrors, setEfeitoSuspensivoErrors] = React.useState([]);
  const [efeitoSuspensivoSuccess, setEfeitoSuspensivoSuccess] = React.useState([]);

  const [detalhamento, setDetalhamento] = React.useState();
  const [exibeSucesso, setExibeSucesso] = React.useState();

  React.useEffect(() => {
    if (vigencias.length > 0) {
      setConsultaFormFields(() => createConsultaPublicacoesModel({ vigencias }));
    }
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);

    show && setConsultaFormErrors([]);
    show && setPublicacaoFormErrors([]);
    show && setEfeitoSuspensivoErrors([]);
    show && setEfeitoSuspensivoSuccess([]);
  }

  function _handleConsultaFormChange() {
    setConsultaFormFields(() => ({ ...consultaFormFields }));
    setPublicacoesRows([]);
  }

  function _handlePublicacaoFormChange() {
    setPublicacaoFormFields(() => ({ ...publicacaoFormFields }));
  }

  async function _handleContestacoesFormChange() {
    _setLoading(true);
    setContestacoesRowsIdsSelected([]);

    const { anoVigencia, instancia, desistencia } = convertFormFieldsToJsonObj(publicacaoFormFields);
    const res = await obterContestacoes({ anoVigencia, instancia, situacao: getSituacaoByPublicacao({ desistencia }) });
    if (!res.erros) {
      setContestacoesRows(() => res.filter((p) => !p.dataDOU));
    } else {
      setContestacoesRows([]);
      setPublicacaoFormErrors(res.erros);
    }

    setPublicacaoFormFields(() => ({ ...publicacaoFormFields }));
    _setLoading(false);
  }

  async function _handleConsultaSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(consultaFormFields);
    if (isFormCheckValidity && isCheckValidity) {
      const { anoVigencia, instancia, desistencia } = convertFormFieldsToJsonObj(consultaFormFields);
      const res = await PublicacoesApi.obterPublicacoes(anoVigencia, instancia, desistencia);
      if (!res.erros) {
        setPublicacoesRows(res);
      } else {
        setPublicacoesRows([]);
        setConsultaFormErrors(res.erros);
      }
    }
    setConsultaFormFields(() => ({ ...consultaFormFields }));
    _setLoading(false);
  }

  async function _handleNovaPublicacaoClick() {
    _setLoading(true);
    const { anoVigencia, instancia, desistencia } = convertFormFieldsToJsonObj(consultaFormFields);

    const res = await obterContestacoes({ anoVigencia, instancia, situacao: getSituacaoByPublicacao({ desistencia }) });
    setContestacoesRows(() => (!res.erros ? res.filter((p) => !p.dataDOU) : []));
    setPublicacaoFormFields(() => createCadastraPublicacaoModel({ anoVigencia, instancia, desistencia, vigencias }));
    setAction(actions.POST);
    _setLoading(false);
  }

  async function _handlePublicacaoSubmit(event) {
    _setLoading(true);

    publicacaoFormFields.dataPrazoContestacao.required = isDataPrazoContestacaoRequired(publicacaoFormFields.instancia.value, publicacaoFormFields.desistencia.value);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(publicacaoFormFields);

    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(publicacaoFormFields);

      const params = {
        anoVigencia: requestBody.anoVigencia,
        instancia: requestBody.instancia,
        desistencia: requestBody.desistencia,
        dataPublicacao: requestBody.dataPublicacao,
        dataPrazoContestacao: requestBody.dataPrazoContestacao,
        contestacoes: contestacoesRowsIdsSelected
      };

      let publicacoesAux = [...publicacoesRows];
      let res = null;
      if (action === actions.POST) {
        res = await PublicacoesApi.criarPublicacao(params);
        !res.erros && publicacoesAux.unshift(res);
      } else {
        res = await PublicacoesApi.alterarPublicacao(requestBody.id, params);
        const publicacaoIdxFound = publicacoesAux.findIndex((p) => res.id === p.id);
        if (!res.erros && publicacaoIdxFound >= 0) {
          publicacoesAux[publicacaoIdxFound] = { ...res };
        }
      }

      if (!res.erros) {
        setPublicacoesRows(() => [...publicacoesAux]);
        setPublicacaoFormFields(null);
        setExibeSucesso(true);
        setAction(actions.GET);
        _setLoading(false);
        return;
      } else if ("contestacoes" === res.erros[0]?.campo) {
        setPublicacaoFormErrors([{ mensagem: ERROR_SAVE_NO_CONTESTACOES }]);
      } else {
        setPublicacaoFormErrors(res.erros);
      }
    }
    setPublicacaoFormFields(() => ({ ...publicacaoFormFields }));
    _setLoading(false);
  }

  async function _handleReset(event) {
    setConsultaFormErrors([]);
    setPublicacaoFormErrors([]);
    setPublicacaoFormFields(null);
    setAction(actions.GET);
  }

  async function _handleClickEncerrarEfeitosSuspensivos(idsContestacoes, publicacao) {
    _setLoading(true);
    const res = await ContestacoesApi.encerrarEfeitosSuspensivos(idsContestacoes);
    if (res.erros) {
      setEfeitoSuspensivoErrors(res.erros);
    } else {
      const resContestacoes = await obterContestacoes(publicacao);
      if (resContestacoes.erros) {
        setEfeitoSuspensivoErrors(resContestacoes.erros);
      } else {
        setDetalhamento({ rows: resContestacoes, title: TITLE_MODAL_CONTESTACOES, columns: contestacoesColumns, publicacao });
      }
      const msgNumContestacoes = idsContestacoes.length > 1 ? "contestações" : "contestação";
      setEfeitoSuspensivoSuccess([{ mensagem: `Encerramento do efeito suspensivo de ${idsContestacoes.length} ${msgNumContestacoes} efetuado com sucesso.` }]);
    }
    _setLoading(false);
  }

  async function _handleClickReenviarEmails(idsContestacoes, publicacao) {
    _setLoading(true);
    const res = await PublicacoesApi.emailPublicacao(idsContestacoes);
    if (res.erros) {
      setEfeitoSuspensivoErrors(res.erros);
    } else {
      const resContestacoes = await obterContestacoes(publicacao);
      if (resContestacoes.erros) {
        setEfeitoSuspensivoErrors(resContestacoes.erros);
      } else {
        setDetalhamento({ rows: resContestacoes, title: TITLE_MODAL_CONTESTACOES, columns: contestacoesColumns, publicacao });
      }
      setEfeitoSuspensivoSuccess([{ mensagem: `${idsContestacoes.length} e-mails enviados.` }]);
    }
    _setLoading(false);
  }

  async function _handleViewContestacoesAction(row) {
    _setLoading(true);
    const res = await obterContestacoes(row);
    if (res.erros) {
      setConsultaFormErrors(res.erros);
    } else {
      setDetalhamento({ rows: res, title: TITLE_MODAL_CONTESTACOES, columns: contestacoesColumns, publicacao: row });
    }
    _setLoading(false);
  }

  async function _handleEditAction(row) {
    _setLoading(true);
    if (!!row.publicada) {
      setTimeout(() => {
        setConsultaFormErrors([{ mensagem: ERROR_RELATORIO_PUBLICADO }]);
        _setLoading(false);
      }, 700);
    } else {
      const { anoVigencia, instancia: instanciaRow, desistencia, id, situacaoContestacoes } = row;
      const notSelectedRes = await obterContestacoes({
        anoVigencia,
        instancia: instanciaRow.codigo,
        situacao: getSituacaoByPublicacao({ desistencia })
      });
      const selectedRes = await obterContestacoes({
        anoVigencia,
        instancia: instanciaRow.codigo,
        situacaoContestacoes,
        id
      });

      const contestacoesSelected = !!selectedRes.erros ? [] : selectedRes;
      const idsContestacoesSelected = contestacoesSelected.map((ctRow) => ctRow.id);
      const contestacoesNotSelected = !!notSelectedRes.erros ? [] : notSelectedRes.filter((c) => !c.dataDOU);

      setPublicacaoFormFields(() =>
        createCadastraPublicacaoModel({
          ...row,
          instancia: instanciaRow.codigo,
          vigencias
        })
      );
      setContestacoesRows([...contestacoesSelected, ...contestacoesNotSelected]);
      setContestacoesRowsIdsSelected(idsContestacoesSelected);
      setAction(actions.PUT);
      _setLoading(false);
    }
  }

  async function _handleRemoveAction(row) {
    _setLoading(true);
    if (!!row.publicada) {
      setTimeout(() => {
        setConsultaFormErrors([{ mensagem: ERROR_RELATORIO_PUBLICADO }]);
        _setLoading(false);
      }, 700);
    } else {
      let res = await PublicacoesApi.removerPublicacao(row.id);
      if (!res.erros) {
        setExibeSucesso(true);
        setPublicacoesRows(() => publicacoesRows.filter((p) => p.id !== row.id));
      } else {
        setConsultaFormErrors(res.erros);
      }
      _setLoading(false);
    }
  }

  async function _handleConfirmAction(row) {
    _setLoading(true);
    if (!!row.publicada) {
      setTimeout(() => {
        setConsultaFormErrors([{ mensagem: ERROR_RELATORIO_PUBLICADO }]);
        _setLoading(false);
      }, 700);
    } else {
      let publicacoesAux = [...publicacoesRows];
      const confirmRes = await PublicacoesApi.confirmarPublicacao(row.id);

      const publicacaoIdxFound = publicacoesAux.findIndex((p) => confirmRes.id === p.id);
      if (!confirmRes.erros && publicacaoIdxFound >= 0) {
        publicacoesAux[publicacaoIdxFound] = { ...confirmRes };
        setPublicacoesRows(() => [...publicacoesAux]);
        setExibeSucesso(true);
      }

      !!confirmRes.erros && setConsultaFormErrors(confirmRes.erros);
      _setLoading(false);
    }
  }

  const dataGridActions = [
    {
      title: "Visualizar Contestações da Publicação",
      icon: "text-info fa fa-search",
      action: _handleViewContestacoesAction
    },
    {
      title: "Modificar Relatório",
      icon: "text-info fa fa-edit",
      action: _handleEditAction
    },
    {
      title: "Remover Relatório",
      icon: "text-info fa fa-trash",
      action: _handleRemoveAction
    },
    {
      title: "Confirmar Publicação no DOU",
      icon: "text-info fa fa-check-circle",
      action: _handleConfirmAction
    }
  ];

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {action === actions.GET && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            {!!consultaFormFields && (
              <Form onSubmit={_handleConsultaSubmit} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={consultaFormErrors}>
                <Grid item sm={2}>
                  <InputGeneric field={consultaFormFields.anoVigencia} onChange={_handleConsultaFormChange} />
                </Grid>

                <Grid item sm={3}>
                  <InputGeneric field={consultaFormFields.instancia} onChange={_handleConsultaFormChange} />
                </Grid>

                <Grid item sm={2}>
                  <InputGeneric field={consultaFormFields.desistencia} onChange={_handleConsultaFormChange} />
                </Grid>
              </Form>
            )}
          </Grid>

          <Grid item sm={12}>
            <Grid container spacing={1} style={{ justifyContent: "right" }}>
              <Grid item>
                <BrButton small secondary onClick={_handleNovaPublicacaoClick}>
                  Novo Relatório
                </BrButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <CustomDataGrid columns={getPublicacaoColumns()} rows={publicacoesRows} actions={dataGridActions} density="compact" pageSize={5} />
          </Grid>
        </Grid>
      )}

      {action !== actions.GET && !!publicacaoFormFields && (
        <Grid container spacing={1}>
          {action === actions.POST && (
            <Grid item sm={12}>
              <h5>Novo Relatório</h5>
            </Grid>
          )}

          {action === actions.PUT && (
            <Grid item sm={12}>
              <h5>Modificar Relatório</h5>
            </Grid>
          )}

          <Form
            onSubmit={_handlePublicacaoSubmit}
            submitTitle={action === actions.POST ? "Salvar" : "Modificar"}
            errors={publicacaoFormErrors}
            onCancel={_handleReset}
            cancelTitle={"Cancelar"}
          >
            <Grid item sm={2}>
              <InputGeneric field={publicacaoFormFields.anoVigencia} onChange={_handleContestacoesFormChange} />
            </Grid>

            <Grid item sm={3}>
              <InputGeneric field={publicacaoFormFields.instancia} onChange={_handleContestacoesFormChange} />
            </Grid>

            <Grid item sm={1}>
              <InputGeneric field={publicacaoFormFields.desistencia} onChange={_handleContestacoesFormChange} />
            </Grid>

            <Grid item sm={3}>
              <InputGeneric field={publicacaoFormFields.dataPublicacao} onChange={_handlePublicacaoFormChange} />
            </Grid>

            {isDataPrazoContestacaoRequired(publicacaoFormFields.instancia.value, publicacaoFormFields.desistencia.value) && (
              <Grid item sm={3}>
                <InputGeneric field={publicacaoFormFields.dataPrazoContestacao} onChange={_handlePublicacaoFormChange} />
              </Grid>
            )}

            <Grid item sm={12}>
              <div className="br-input">
                <label>{TITLE_FORM_CONTESTACOES_PUBLICACAO}</label>
                <CustomDataGrid
                  columns={contestacoesColumns}
                  rows={contestacoesRows}
                  density="compact"
                  toolbar="details"
                  headerHeight={95}
                  header
                  checkboxSelection
                  disableSelectionOnClick
                  selectionModel={contestacoesRowsIdsSelected}
                  onSelectionModelChange={(ids) => setContestacoesRowsIdsSelected(ids)}
                />
              </div>
            </Grid>
          </Form>
        </Grid>
      )}

      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />

      {!!detalhamento && (
        <ContestacoesPublicadasDataGridModal
          data={detalhamento}
          onClose={() => setDetalhamento(null)}
          onClickEncerrarEfeitosSuspensivos={_handleClickEncerrarEfeitosSuspensivos}
          onClickReenviarEmails={_handleClickReenviarEmails}
          errorMessages={efeitoSuspensivoErrors}
          successMessages={efeitoSuspensivoSuccess}
        />
      )}
    </React.Fragment>
  );
};
