import { createField, FieldType } from "components/Form";

import { getCurrentMonthDate, getLastMonthDate } from "utils/DateUtils";
import { getInstanciaSelectOptions } from "utils/ContestacaoUtils";

export const createModel = (values = { analistas: { options: [], label: "", value: "" } }) => ({
  analistas: createField("analistas", values.analistas.label, {
    type: FieldType.SELECT,
    options: values.analistas.options,
    value: values.analistas.value,
    required: false,
    errorMessage: "Selecione Analista",
    placeholder: "Selecione Analista"
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    options: getInstanciaSelectOptions(),
    required: false,
    errorMessage: "Selecione a Instância",
    placeholder: "Selecione a Instância"
  }),
  dataInicio: createField("dataInicio", "Início:", {
    type: FieldType.DATE,
    value: getLastMonthDate(),
    required: true
  }),
  dataFim: createField("dataFim", "Fim:", {
    type: FieldType.DATE,
    value: getCurrentMonthDate(),
    required: true
  })
});
