import { getApi, handleDownload } from "../ApiManager";

const contextoVigencia = "/vigencias";
const contexto = "/relatorios";
const contextoProdutividade = "/relatorios-produtividade";

const RelatoriosApi = {
  ratCnae: async (params) => {
    let { anoVigencia = "" } = params;
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contexto}/rat-cnae`);
  },
  ratFinal: async (params) => {
    let { anoVigencia = "" } = params;
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contexto}/rat-final`);
  },
  percentisCnae: async ({ anoVigencia, ...params }) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/percentis-cnae`, { params }),
  downloadRelatorioPercentisCnae: async ({ anoVigencia, ...params }) =>
    getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/imprimir-percentis-cnae`, { params }).then(handleDownload),
  relatorioProdutividade: async (params) => {
    let config = { params: { ...params } };
    const Api = getApi();
    return Api.get(`${contextoProdutividade}/contestacoes`, config);
  },
  downloadRelatorioProdutividade: async (params, ids) => getApi().post(`${contextoProdutividade}/imprimir-produtividade`, ids, { params }).then(handleDownload)
};

export default RelatoriosApi;
