import React from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import { BoxMessage } from "components/BoxMessage";
import { LabelValue } from "components/LabelValue";
import CustomDataGrid from "components/CustomDataGrid";
import CustomCard from "components/CustomCard";

import LogExecucaoApi from "services/api/LogExecucaoApi";

import { columnsMensagem } from "./model";

export const DetalhaLogsExecucao = ({ setLoading, onClose, logExecucao }) => {
  const [rows, setRows] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState();

  React.useEffect(() => {
    (async () => {
      const res = await LogExecucaoApi.obterLogsMensagensExecucao(logExecucao?.id);
      if (res.erros) {
        setFormErrors(res);
      } else {
        setRows(res);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <BoxMessage message={formErrors} />
      </Grid>

      <Grid item sm={12}>
        <CustomCard title={"Dados do Log do Recálculo"}>
          <Grid container>
            <Grid item sm={5} style={{ paddingLeft: 30 }}>
              <LabelValue orientation={"inlineLabel"} label={"Fase"} value={logExecucao?.nomeFase} />
              <LabelValue orientation={"inlineLabel"} label={"Observações"} value={logExecucao?.observacoes} />
              <LabelValue orientation={"inlineLabel"} label={"Data Hora Início"} value={logExecucao?.dataHoraInicio} dataType="dateTime" />
              <LabelValue orientation={"inlineLabel"} label={"Data Hora Fim"} value={logExecucao?.dataHoraFim} dataType="dateTime" />
              <LabelValue orientation={"inlineLabel"} label={"Sucesso"} value={logExecucao?.sucesso} dataType="boolean" />
            </Grid>

            <Grid item style={{ display: "flex", alignItems: "end", marginLeft: "auto" }}>
              <BrButton secondary onClick={onClose}>
                Voltar
              </BrButton>
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>

      <Grid item sm={12}>
        <CustomDataGrid columns={columnsMensagem} rows={rows} toolbar={"details"} />
      </Grid>
    </Grid>
  );
};
