import React from "react";
import { Grid } from "@material-ui/core";

import Form, { InputGeneric, checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";
import { TipoMensagem } from "utils/TipoUtils";

import { createModelMensagem } from "./model";

const FormMensagem = ({ mensagem, onSubmit, onCancel, formError, loading }) => {
  const [fields, setFields] = React.useState();
  const [formTitle, setFormTitle] = React.useState();

  React.useEffect(() => {
    if (mensagem) {
      setFields(createModelMensagem(mensagem));
      setFormTitle(`Mensagem [${TipoMensagem[mensagem.tipo]} ${mensagem.anoVigencia}]`);
    } else {
      setFields(createModelMensagem());
      setFormTitle("Nova Mensagem");
    }
  }, [mensagem]);

  const _handleChange = () => {
    let auxFields = validaTipoMensagem(fields)
    setFields(() => ({ ...auxFields }));
  };

  function validaTipoMensagem(fields){
    if(TipoMensagem[fields.tipo.value] === TipoMensagem.MENSAGEM_INFORMATIVA){
      fields.cnae.disabled = false;
      fields.cnpj.disabled = false;
      fields.cnpjRaiz.disabled = false;
      fields.irrestrito.disabled = false;
    }else{
      fields.cnae.disabled = true;
      fields.cnae.value = "";
      fields.cnpj.disabled = true;
      fields.cnpj.value = "";
      fields.cnpjRaiz.disabled = true;
      fields.cnpjRaiz.value = "";
      fields.irrestrito.disabled = true;
      fields.irrestrito.value = false;
    }
    return fields;
  }

  function _handleSubmit(event) {
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      onSubmit(requestBody);
    }
    setFields(() => ({ ...fields }));
  }

  function _handleCancel(event) {
    onCancel();
  }

  return !!fields ? (
    <Form formTitle={formTitle} onSubmit={_handleSubmit} onCancel={_handleCancel} submitTitle={"Salvar"} cancelTitle={"Cancelar"} errors={formError} loading={loading}>
      <Grid item sm={3}>
        <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
      </Grid>
      <Grid item sm={3}>
        <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
      </Grid>
      <Grid item sm={3}>
        <InputGeneric field={fields.cnpj} onChange={_handleChange} />
      </Grid>
      <Grid item sm={3}>
        <InputGeneric field={fields.cnae} onChange={_handleChange} />
      </Grid>
      <Grid item sm={5}>
        <InputGeneric field={fields.tipo} onChange={_handleChange} />
      </Grid>
      <Grid item sm={3}>
        <InputGeneric field={fields.inicioValidade} onChange={_handleChange} />
      </Grid>
      <Grid item sm={3}>
        <InputGeneric field={fields.fimValidade} onChange={_handleChange} />
      </Grid>
      <Grid item sm={1}>
        <InputGeneric field={fields.irrestrito} onChange={_handleChange} />
      </Grid>
      <Grid item sm={12}>
        <InputGeneric field={fields.texto} onChange={_handleChange} />
      </Grid>
      <Grid item sm={9} />
    </Form>
  ) : null;
};

export default FormMensagem;
