import React from "react";
import { Grid } from "@material-ui/core";
import BrTabs from "@govbr/react-components/lib/BrTabs";

import Form, { FormErrorPanel, InputGeneric, convertFormFieldsToJsonObj } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import ModalConfirm from "components/ModalConfirm";
import ModalMessage from "components/ModalMessage";
import CustomCard from "components/CustomCard";
import Loading from "components/Loading";

import { TipoSituacaoContestacao } from "utils/TipoUtils";
import ContestacoesApi from "services/api/ContestacoesApi";

import { getContestacoesConcluidasColumns, getContestacoesPendentesColumns } from "./utils";
import { createRemessaContestacoesModel } from "./model";
import ContestacoesConcluidasDataGrid from "./components/ContestacoesConcluidasDataGrid";

const TipoStatusContestacao = {
  PENDENTE: "PENDENTE",
  CONCLUIDA: "CONCLUIDA"
};

export const ConsultaAnalisaAdmContestacao = ({ userData, history }) => {
  const [loading, setLoading] = React.useState();
  const [exibeSucesso, setExibeSucesso] = React.useState();
  const [exibeProximaContestacao, setExibeProximaContestacao] = React.useState(false);

  const [fields, setFields] = React.useState(() => createRemessaContestacoesModel());
  const [formErrors, setFormErrors] = React.useState();

  const [rowsPendentes, setRowsPendentes] = React.useState([]);
  const [rowsConcluidas, setRowsConcluidas] = React.useState([]);
  const [rowsError, setRowsError] = React.useState();

  const [selectedTab, setSelectedTab] = React.useState(TipoStatusContestacao.PENDENTE);

  const _setLoading = (show) => {
    setLoading(show);
    show && setFormErrors([]);
  };

  const consultarContestacoes = React.useCallback(
    async (params = {}) => {
      const status = params.status || TipoStatusContestacao.PENDENTE;

      let situacao = [TipoSituacaoContestacao.EM_ANALISE.value];
      TipoStatusContestacao.CONCLUIDA === status &&
        (situacao = [
          TipoSituacaoContestacao.LIBERADA_PARA_AUTORIDADE.value,
          TipoSituacaoContestacao.LIBERADA_PARA_RECALCULO.value,
          TipoSituacaoContestacao.AGUARDANDO_PUBLICACAO.value,
          TipoSituacaoContestacao.FINALIZADA.value,
          TipoSituacaoContestacao.PUBLICADA.value
        ]);
      const res = await ContestacoesApi.obterContestacoes({ situacao, cpfResponsavel: userData.document });

      if (!res.erros) {
        TipoStatusContestacao.PENDENTE === params.status && setRowsPendentes(() => res);
        TipoStatusContestacao.CONCLUIDA === params.status && setRowsConcluidas(() => res);
      } else {
        setRowsError(res.erros[0].mensagem);
        TipoStatusContestacao.PENDENTE === params.status && setRowsPendentes([]);
        TipoStatusContestacao.CONCLUIDA === params.status && setRowsConcluidas([]);
      }
    },
    [userData.document]
  );

  function _redirectToContestacao(contestacao) {
    const state = { contestacao, acao: "ANALISAR" };
    history.push("/contestacoes/analisar", state);
  }

  const analisarProximaContestacao = async () => {
    _setLoading(true);
    const requestBody = convertFormFieldsToJsonObj(fields);
    const res = await ContestacoesApi.iniciarProximaRemessaContestacoes(requestBody);
    if (res.erros) {
      setFormErrors(res.erros);
    } else {
      setRowsPendentes(() => res);
    }
    setExibeProximaContestacao(false);
    _setLoading(false);
  };

  const reiniciarContestacoes = React.useCallback(async (params, concluidas) => {
    _setLoading(true);
    const res = await ContestacoesApi.reiniciar(params.id);
    if (!res.erros) {
      setRowsConcluidas(() => concluidas.filter((r) => r.id !== params.id));
      setExibeSucesso(`Análise da contestação de protocolo ${params.protocolo} reiniciada com sucesso.`);
    } else {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }, []);

  const continuarAction = React.useMemo(
    () => ({
      title: "Continuar",
      icon: "text-info fa fa-edit",
      action: (row) => _redirectToContestacao(row)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    (async () => {
      _setLoading(true);
      await consultarContestacoes({ status: selectedTab });
      _setLoading(false);
    })();
  }, [selectedTab, consultarContestacoes]);

  return (
    <Grid container>
      <Loading overlay show={loading} />

      <Grid item sm={12}>
        <FormErrorPanel messages={formErrors} />
      </Grid>

      <Grid item sm={12}>
        <CustomCard title="Contestações">
          <BrTabs value={selectedTab} onChange={setSelectedTab} label="Tabs">
            <BrTabs.Tabs>
              <BrTabs.Tab id={TipoStatusContestacao.PENDENTE} label="Pendentes" />
              <BrTabs.Tab id={TipoStatusContestacao.CONCLUIDA} label="Concluídas" />

              {selectedTab === TipoStatusContestacao.PENDENTE && (
                <Grid item style={{ marginLeft: "auto" }}>
                  <Form onSubmit={() => setExibeProximaContestacao(true)} submitTitle={"Próxima Remessa para Julgamento"} disabled={rowsPendentes.length > 0}>
                    <div style={{ paddingTop: 14 }}>
                      <InputGeneric field={fields.instancia} onChange={() => setFields(() => ({ ...fields }))} />
                    </div>
                  </Form>
                </Grid>
              )}
            </BrTabs.Tabs>

            <BrTabs.Content>
              <BrTabs.Panel contentFor={TipoStatusContestacao.PENDENTE}>
                <Grid item sm={12}>
                  <CustomDataGrid
                    actions={[continuarAction]}
                    rows={rowsPendentes}
                    columns={getContestacoesPendentesColumns()}
                    noRowsLabel={rowsError || "Nenhuma contestação com análise pendente"}
                    pageSize={5}
                  />
                </Grid>
              </BrTabs.Panel>
              <BrTabs.Panel contentFor={TipoStatusContestacao.CONCLUIDA}>
                <Grid item sm={12}>
                  {selectedTab === TipoStatusContestacao.CONCLUIDA && (
                    <ContestacoesConcluidasDataGrid
                      setLoading={_setLoading}
                      setFormErrors={setFormErrors}
                      onClick={reiniciarContestacoes}
                      rows={rowsConcluidas}
                      columns={getContestacoesConcluidasColumns()}
                      noRowsLabel={rowsError || "Nenhuma contestação com análise finalizada"}
                      pageSize={5}
                    />
                  )}
                </Grid>
              </BrTabs.Panel>
            </BrTabs.Content>
          </BrTabs>
        </CustomCard>
      </Grid>

      <ModalConfirm
        show={exibeProximaContestacao}
        message={"Atenção! Confirma a solicitação de nova Remessa de 10 processos para Julgamento?"}
        onConfirm={analisarProximaContestacao}
        cancel={() => setExibeProximaContestacao(false)}
      />

      <ModalMessage show={!!exibeSucesso} close={() => setExibeSucesso(null)} message={exibeSucesso} />
    </Grid>
  );
};
