import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import BrModal from "@govbr/react-components/lib/BrModal";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";

import ReprocessamentosApi from "services/api/ReprocessamentosApi";

import { createReprocessamentoModel } from "../model";

const ReprocessamentoFormModal = ({ reprocessamento, vigencias, onClose, onConfirm }) => {
  const [fields, setFields] = useState(createReprocessamentoModel({ fields: reprocessamento, vigencias }));
  const [formErrors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const _requestRecalc = async ({ anoVigencia, cnpjRaiz, numeroProcesso }) => {
    if (!!reprocessamento) {
      return await ReprocessamentosApi.editar({ anoVigencia, cnpjRaiz, numeroProcesso });
    } else {
      return await ReprocessamentosApi.recalcular({ anoVigencia, cnpjRaiz, numeroProcesso });
    }
  };

  function _handleChange(event) {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmit(event) {
    setLoading(true);
    let res;
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      res = await _requestRecalc({ ...requestBody });
      if (!res.erros) {
        await onConfirm(res);
      } else {
        setFormErrors(res.erros);
      }
    }
    setFields(() => ({ ...fields }));
    setLoading(false);
  }

  React.useEffect(() => {
    return () => {
      setLoading(false);
      setFormErrors([]);
    };
  }, []);

  return (
    <BrModal id={"modal-reprocessamento-edit"} style={{ padding: 10, paddingBottom: 30 }} visible={true} closable={true} onClose={onClose}>
      <Grid container>
        <Grid item sm={12}>
          <Form
            formTitle={!!reprocessamento ? "Alterar Número Processo" : "Novo Reprocessamento"}
            submitTitle={loading ? "Aguarde..." : "Enviar"}
            onSubmit={_handleSubmit}
            cancelTitle={"Cancelar"}
            onCancel={onClose}
            errors={formErrors}
          >
            <Grid item sm={4}>
              <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.numeroProcesso} onChange={_handleChange} />
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </BrModal>
  );
};

export default ReprocessamentoFormModal;
