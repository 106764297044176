import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import BrHeader from "@govbr/react-components/lib/BrHeader";
import Menu from "../Menu";
import UserMenu from "./UserMenu";

/** Dados de Login do Usuário */
// loginWidget:
// PropTypes.shape({
//   show: PropTypes.bool,
//   label: PropTypes.string,
//   onClick: PropTypes.func,
//   user: PropTypes.shape({
//     firstName: PropTypes.string,
//     lastName: PropTypes.string,
//     document: PropTypes.string,
//     documentType: PropTypes.string,
//     avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//     totalNotifications: PropTypes.number,
//     avatarRef: PropTypes.oneOfType([
//       PropTypes.func,
//       PropTypes.shape({ current: PropTypes.instanceOf(Element) })
//     ])
//   }),
// }),

const Header = ({ menus, userData, noLogin, noMenu, doLogout }) => {
  const avatarRef = React.useRef();
  const [user, setUser] = useState({ ...userData, show: true, avatarRef });

  const [openMainMenu, setOpenMainMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = React.useState(false);

  const [activeItem, setActiveItem] = useState("");
  const history = useHistory();

  const handleLogin = () => setUser(user);
  const handleOpenMenu = () => setOpenMainMenu(!openMainMenu);
  const handleOpenUserMenu = () => setOpenNotifications(!openNotifications);
  const handleClickLogo = React.useCallback(() => {
    const menuHome = menus.find((m) => m.isHome) || { url: "/" };
    history.push(menuHome.url);
  }, [history, menus]);

  const userInfo = () => <UserMenu triggerRef={avatarRef} user={user} isOpenUserMenu={openNotifications} handleLogout={doLogout} />;

  const headerProps = {
    logo: "https://www.gov.br/++theme++padrao_govbr/img/govbr-colorido-b.png",
    sign: "MINISTÉRIO DA ECONOMIA",
    handleClickLogo,
    stick: true,
    compact: true,
    noLinks: false,
    noLogo: false,
    noSign: false,
    noSubtitle: true,
    noFunctions: true,
    noLogin: noLogin,
    noSearch: true,
    noMenu: noMenu,
    title: "FAP - Fator Acidentário de Prevenção",
    subTitle: "",
    links: [
      {
        label: "Documentos de apoio",
        url: "https://www.gov.br/previdencia/pt-br/assuntos/previdencia-social/saude-e-seguranca-do-trabalhador/fap/legislacao-e-atos-normativos-sobre-o-fap",
        target: "blank"
      }
    ],
    functions: [{ label: "Ajuda", onClick: () => {}, iconClass: "fas fa-question" }],
    handleOpenMenu,

    loginWidget: {
      show: true,
      label: "Entrar",
      user,
      onClick: handleLogin,
      avatarRef
    },
    handleOpenUserMenu,

    UserMenu: userInfo,

    searchWidget: {
      inputId: "header-search-input-id",
      onSearch: (searchTerm) => {
        console.debug("Valor pesquisado: " + searchTerm);
      }
    }
  };

  const handleNavigate = useCallback(
    (route) => {
      setActiveItem(route);
      history.push(route);
      if (openMainMenu) {
        setOpenMainMenu(false);
      }
    },
    [history, openMainMenu]
  );

  const menu = <Menu menuFolders={menus} openMainMenu={openMainMenu} setOpenMainMenu={setOpenMainMenu} activeItem={activeItem} handleNavigate={handleNavigate} />;

  return (
    <div>
      {/* <BrAccessibilityLinks /> */}
      <BrHeader {...headerProps} menu={menu} />
    </div>
  );
};

export default Header;
