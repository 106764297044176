import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { useVigencias } from "hooks/useVigencias";
import { formataReal } from "utils/StrUtils";

import { getResumoColumns, createModelGfip, toggleTipoCnpjField, updateParametrosExtracao, downloadRelatorioCsvDetalhado, obterRelatorioExtracao } from "./ExtratorUtils";

const ExtratorInsumosGFip = () => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(false);
  const [loadingOverlay, setLoadingOverlay] = React.useState(null);
  const [formFields, setFormFields] = React.useState(null);
  const [formError, setFormError] = React.useState([]);
  const [dados, setDados] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [totalizadorMassa, setTotalizadorMassa] = React.useState({ total: "", totalCompFinal: "", totalCompInicial: "", anoCompInicial: "", anoCompFinal: "" });
  const [totalizadorMassa13, setTotalizadorMassa13] = React.useState({ total: "", totalCompFinal: "", totalCompInicial: "", anoCompInicial: "", anoCompFinal: "" });

  React.useEffect(() => {
    function _initForm() {
      if (vigencias.length > 0) {
        _setLoading(true);
        const init = createModelGfip({ vigencias });
        setFormFields(init);
      }
      _setLoading(false);
    }
    _initForm();
  }, [vigencias]);

  function _setLoading(show, overlay = null) {
    setLoading(show);
    setLoadingOverlay(overlay);
    show && setFormError([]);
  }

  function _handleChange() {
    setFormFields(() => ({ ...formFields }));
  }

  function _handleTipoCnpj(v) {
    toggleTipoCnpjField(formFields);
    setFormFields(() => ({ ...formFields }));
  }

  async function _handleChangeVigencia(field) {
    _setLoading(true);
    await updateParametrosExtracao(formFields);
    setFormFields(() => ({ ...formFields }));
    _setLoading(false);
  }

  async function _handleSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(formFields);
    if (isFormCheckValidity && isCheckValidity) {
      let fieldsValues = convertFormFieldsToJsonObj(formFields);
      const res = await obterRelatorioExtracao("tipoGfip", fieldsValues);
      const resDados = res.erros ? [] : res;
      if (res.erros) {
        setFormError(res.erros);
      }

      setColumns(getResumoColumns(resDados));
      setDados(resDados);
      totalizadorGfip(resDados);
    }
    setFormFields(() => ({ ...formFields }));
    _setLoading(false);
  }

  async function _downloadRelatorioDetalhado() {
    _setLoading(true);
    let fieldsValues = convertFormFieldsToJsonObj(formFields);
    const erros = await downloadRelatorioCsvDetalhado("tipoGfip", fieldsValues);
    setFormError(erros);
    _setLoading(false);
  }

  if (!formFields) {
    return null;
  }

  function totalizadorGfip(resDados) {
    const anoCompInicial = String(formFields.competenciaInicial.value).substring(0, 4);
    const anoCompFinal = String(formFields.competenciaFinal.value).substring(0, 4);

    setTotalizadorMassa13({
      total: somaValorMassaSalarial13(resDados),
      totalCompFinal: somaValorMassaSalarial13(resDados.filter((f) => String(f.competencia).includes(anoCompFinal))),
      totalCompInicial: somaValorMassaSalarial13(resDados.filter((f) => String(f.competencia).includes(anoCompInicial))),
      anoCompInicial: anoCompInicial,
      anoCompFinal: anoCompFinal
    });

    setTotalizadorMassa({
      total: somaValorMassaSalarial(resDados),
      totalCompFinal: somaValorMassaSalarial(resDados.filter((f) => String(f.competencia).includes(anoCompFinal))),
      totalCompInicial: somaValorMassaSalarial(resDados.filter((f) => String(f.competencia).includes(anoCompInicial))),
      anoCompInicial: anoCompInicial,
      anoCompFinal: anoCompFinal
    });
  }
  function somaValorMassaSalarial13(d) {
    return formataReal(d.reduce((total, linha) => total + linha.valorMassaSalarial13, 0));
  }
  function somaValorMassaSalarial(d) {
    return formataReal(d.reduce((total, linha) => total + linha.valorMassaSalarial, 0));
  }

  return (
    <React.Fragment>
      <Loading overlay overlayTarget={loadingOverlay} show={loading} />
      <Grid container>
        <Grid item sm={12}>
          <Form onSubmit={_handleSubmit} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={formError}>
            <Grid item sm={4}>
              <InputGeneric field={formFields.tipoCnpjConsulta} onChange={_handleTipoCnpj} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={formFields.cnpj} onChange={_handleChange} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={formFields.anoVigencia} onChange={_handleChangeVigencia} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={formFields.competenciaInicial} onChange={_handleChange} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={formFields.competenciaFinal} onChange={_handleChange} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={formFields.fatosGeradores} onChange={_handleChange} />
            </Grid>

            <Grid item sm={10}>
              <InputGeneric field={formFields.rescisoes} onChange={_handleChange} />
            </Grid>
          </Form>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Divider style={{ marginTop: 30 }} />
        </Grid>

        <Grid item sm={6}>
          <h5>Resumo</h5>
        </Grid>

        <Grid item sm={6} style={{ textAlign: "right" }}>
          <br />
          <BrButton small secondary onClick={_downloadRelatorioDetalhado}>
            Baixar Relatório Completo (CSV)
          </BrButton>
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid getRowId={(row) => row.competencia} rows={dados} columns={columns} toolbar="details" density="compact" />
        </Grid>

        <Grid item sm={6} style={{ display: dados.length > 0 ? "" : "none" }}>
          <span className="text-up-01 text-bold">Massa Total</span>
          <BrMessage warning>
            <b>{totalizadorMassa.anoCompInicial}:</b> {totalizadorMassa.totalCompInicial} <br />
            <b>{totalizadorMassa.anoCompFinal}:</b> {totalizadorMassa.totalCompFinal} <br />
            <b>
              {totalizadorMassa.anoCompInicial} e {totalizadorMassa.anoCompFinal}:
            </b>{" "}
            {totalizadorMassa.total}
          </BrMessage>
        </Grid>

        <Grid item sm={6} style={{ display: dados.length > 0 ? "" : "none" }}>
          <span className="text-up-01 text-bold">Massa Total 13º</span>
          <BrMessage warning>
            <b>{totalizadorMassa13.anoCompInicial}:</b> {totalizadorMassa13.totalCompInicial} <br />
            <b>{totalizadorMassa13.anoCompFinal}:</b> {totalizadorMassa13.totalCompFinal} <br />
            <b>
              {totalizadorMassa13.anoCompInicial} e {totalizadorMassa13.anoCompFinal}:
            </b>{" "}
            {totalizadorMassa13.total}
          </BrMessage>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ExtratorInsumosGFip;
