import React from "react";
import { Grid } from "@material-ui/core";

import ilustraHome from "assets/images/ilustra.home.svg";
import { useMenu } from "contexts/AppContext";

import AcessoRapido from "./components/AcessoRapido";

const Home = () => {
  const { flatMenus } = useMenu();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={3}>
          <img src={ilustraHome} alt="FAP" />
        </Grid>

        <Grid item sm={9} style={{ display: "inherit" }}>
          <Grid container alignItems="center">
            <AcessoRapido menus={flatMenus} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;
