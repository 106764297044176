import React from "react";
import BrCard from "@govbr/react-components/lib/BrCard";

import { formataDateEnBr, formataIndicador } from "utils/StrUtils";

import { TipoCalculo, TipoEfeitoSuspensivo } from "utils/TipoUtils";

const ItemCalculo = ({ item, calculoId, onClickCalculo }) => (
  <li className={item.id === calculoId ? "timeline-selected" : "timeline-link"} style={{ cursor: "pointer" }} onClick={() => onClickCalculo(item.id)}>
    <i className={"timeline-icon fa fa-circle fa-2x"}></i>
    <div className={"text-up-01"}>{TipoCalculo[item.tipoProcessamento]}</div>
    <div className="text-up-01">{formataIndicador(item.fap)}</div>
    <div className={"text-base"}>{formataDateEnBr(item.dataProcessamento)}</div>
  </li>
);

const ItemEfeitoSuspensivo = ({ item }) => (
  <li>
    <i className={"timeline-icon fa fa-circle fa-2x"}></i>
    <div className={"text-danger text-up-01"}>{TipoEfeitoSuspensivo[item.tipoProcessamento]}</div>
    <div className={"text-danger text-base"}>{formataDateEnBr(item.dataProcessamento)}</div>
  </li>
);

const DadosHistorico = ({ eventos = [], calculoId, onClickCalculo }) => {
  return (
    <BrCard>
      <h5>Histórico</h5>
      <div className="timeline">
        <ul>
          {eventos.map((e, index) =>
            e.id ? (
              <ItemCalculo key={`evento-${index}`} item={e} calculoId={calculoId} onClickCalculo={onClickCalculo} />
            ) : (
              <ItemEfeitoSuspensivo key={`evento-${index}`} item={e} />
            )
          )}
        </ul>
      </div>
    </BrCard>
  );
};

export default DadosHistorico;
