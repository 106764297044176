import React from "react";
import { Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";

import { checkFormValidity, FormErrorPanel, InputGeneric } from "components/Form";
import Loading from "components/Loading";

import RelatoriosGerenciaisApi from "services/api/RelatoriosGerenciaisApi";
import { useVigencias } from "hooks/useVigencias";

import { createRelatorioAmostrasModel } from "./model";
import RelatoriosDataGrid from "components/RelatoriosDataGrid";

const ResultadosAmostras = () => {
  const { vigencias } = useVigencias();

  const [loading, setLoading] = React.useState(true);
  const [formError, setFormError] = React.useState([]);
  const [vigenciaField, setVigenciaField] = React.useState();
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (vigencias.length > 0) {
        setVigenciaField(() => createRelatorioAmostrasModel({ vigencias }));
        await obterRelatorios(vigencias[0]);
      }
      return setLoading(false);
    })();
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormError([]);
  }

  async function obterRelatorios(vigencia) {
    setLoading(true);
    const res = await RelatoriosGerenciaisApi.obterResultadosAmostras(vigencia);
    if (res.erros) {
      setFormError(res.erros);
      setRows([]);
    } else {
      setFormError([]);
      setRows(res);
    }
    setLoading(false);
  }

  async function _handleChangeVigencia() {
    _setLoading(true);
    setTimeout(async () => {
      const isCheckValidity = checkFormValidity(vigenciaField);
      setVigenciaField(() => ({ ...vigenciaField }));
      if (isCheckValidity) {
        await obterRelatorios(vigenciaField.anoVigencia.value);
      }
      return _setLoading(false);
    }, 700);
  }

  async function _handleClickDownload(row) {
    _setLoading(true);
    const res = await RelatoriosGerenciaisApi.downloadResultadosAmostras(row.anoVigencia, row.tipoFaseCalculo.codigo);
    if (res.erros) {
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  const columns = [
    {
      field: "anoVigencia",
      headerName: "Vigência",
      flex: 1
    },
    {
      field: "tipoFaseCalculo",
      headerName: "Tipo Fase Cálculo",
      flex: 1,
      valueGetter: ({ value }) => value.descricao
    },
    {
      field: "finalizado",
      headerName: "Finalizado",
      flex: 1,
      type: "boolean"
    },
    {
      field: "actions",
      headerName: "Baixar",
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      minWidth: 90,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (data) =>
        data.row.finalizado ? (
          <IconButton title="Baixar" color="inherit" aria-label="editar" onClick={(event) => _handleClickDownload(data.row)}>
            <span className="text-info fa fa-download"></span>
          </IconButton>
        ) : (
          ""
        )
    }
  ];

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {vigenciaField && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <FormErrorPanel messages={formError} />
          </Grid>

          <Grid item sm={2}>
            <InputGeneric field={vigenciaField.anoVigencia} onChange={_handleChangeVigencia} />
          </Grid>

          <Grid item sm={12}>
            <Grid container>
              <Grid item sm={12}>
                <h5>Resultados e Amostras - {vigenciaField.anoVigencia.value}</h5>
              </Grid>

              <Grid item sm={12}>
                <RelatoriosDataGrid rows={rows} columns={columns} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ResultadosAmostras;
