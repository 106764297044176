import { formataCNPJ, formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";
import { isPrimeiraInstancia, isSegundaInstancia } from "utils/ContestacaoUtils";
import { TipoSituacaoContestacao } from "utils/TipoUtils";
import { convertToSelectOptions } from "components/Form";
import ContestacoesApi from "services/api/ContestacoesApi";
import EmpresasApi from "services/api/EmpresasApi";

export const TERMO_CIENCIA_NOVA_CONTESTACAO = `Toda operação efetuada neste aplicativo, a partir deste passo, será gravada, porém somente após a confirmação do protocolo (clique
  do botão "Transmitir Contestação/Recurso", na página "Revisar e Transmitir Contestação/Recurso") é que as informações serão consolidadas em um processo de contestação
  devidamente formalizado.
    Somente um processo de contestação/recurso, devidamente formalizado, será analisado pelas instâncias competentes. De acordo com a Lei nº. 13.846 de 2019, as contestações
  e recursos administrativos ao FAP são de competência do Conselho de Recursos da Previdência Social (CRPS). Toda alteração relativa à contestação/recurso que não for transmitida
  (clique do botão "Transmitir Contestação/Recurso") não será conhecida pelas respectivas instâncias de julgamento.`;

export const EFEITO_SUSPENSIVO_CONTESTACAO = `A desistência da contestação/recurso será publicada no Diário Oficial da União - DOU, porém o efeito suspensivo cessará na data de
conclusão do pedido de desistência e pode ser visualizado pela empresa na tela de consulta do FAP.`;

const TipoDeferimentoElementoContestado = {
  DEFERIMENTO: "DEFERIMENTO",
  DEFERIMENTO_ALTERACAO_PARCIAL: "DEFERIMENTO_ALTERACAO_PARCIAL",
  INDEFERIMENTO: "INDEFERIMENTO",
  DEFERIMENTO_ALTERACAO_INTEGRAL: "DEFERIMENTO_ALTERACAO_INTEGRAL",
  DEFERIMENTO_ACIDENTE_TRAJETO: "DEFERIMENTO_ACIDENTE_TRAJETO",
  DEFERIMENTO_MAIOR: "DEFERIMENTO_MAIOR",
  INDEFERIMENTO_MENOR: "INDEFERIMENTO_MENOR"
};

export const isDeferimentoElementoAlteracao = (deferimento) => {
  return (
    !!TipoDeferimentoElementoContestado[deferimento] &&
    [
      TipoDeferimentoElementoContestado.DEFERIMENTO_ALTERACAO_PARCIAL,
      TipoDeferimentoElementoContestado.DEFERIMENTO_ALTERACAO_INTEGRAL,
      TipoDeferimentoElementoContestado.DEFERIMENTO_MAIOR,
      TipoDeferimentoElementoContestado.INDEFERIMENTO_MENOR
    ].includes(deferimento)
  );
};

export const TipoAcaoContestacao = {
  CRIAR: "CRIAR",
  EDITAR: "EDITAR",
  REABRIR: "REABRIR",
  DESISTIR: "DESISTIR",
  RECURSO: "RECURSO"
};

/** TipoSituacaoVirtual utilizado para controle/exibição na tabela */
export const TipoSituacaoVirtual = {
  EM_ANDAMENTO: "EM_ANDAMENTO",
  TRANSMITIDA: "TRANSMITIDA",
  PUBLICADA: "PUBLICADA",
  PRAZO_ENCERRADO: "PRAZO_ENCERRADO",
  REABRIR: "REABRIR",
  DESISTENCIA: "DESISTENCIA",
  CONTESTAR_2A: "CONTESTAR_2A"
};

const isEmAndamento = (contestacao) => TipoSituacaoContestacao.EM_ANDAMENTO.value === contestacao?.situacao.codigo;
const isTransmitida = (contestacao) => TipoSituacaoContestacao.EM_ANDAMENTO.value !== contestacao?.situacao.codigo;
const isPublicada = (contestacao) => TipoSituacaoContestacao.PUBLICADA.value === contestacao?.situacao.codigo;
export const isDesistencia = (contestacao) =>
  !![
    TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_AUTORIDADE.value,
    TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_DOU.value,
    TipoSituacaoContestacao.DESISTENCIA_PUBLICADA.value
  ].find((s) => s === contestacao?.situacao.codigo);

const isPrazo1InstanciaAberto = (contestacao) => !!contestacao?.prazo1InstanciaAberto;
const isPrazo2InstanciaAberto = (contestacao) => !!contestacao?.prazo2InstanciaAberto;

export const mapToEstabsContestacoes = (res) => {
  const estabs = [];
  res.forEach((c) => {
    const strIndex = `key-${c.cnpj}`;
    if (!estabs[strIndex]) {
      estabs[strIndex] = { anoVigencia: c.anoVigencia, cnpj: c.cnpj, contestacoes: [] };
    }
    estabs[strIndex].contestacoes.push(c);
  });

  const linhas = Object.keys(estabs).map((k) => {
    const { anoVigencia, cnpj, contestacoes } = estabs[k];
    let cAndamento1 = contestacoes.find((c) => isPrimeiraInstancia(c) && isEmAndamento(c));
    if (cAndamento1) {
      cAndamento1.situacaoVirtual = isPrazo1InstanciaAberto(cAndamento1) ? TipoSituacaoVirtual.EM_ANDAMENTO : TipoSituacaoVirtual.PRAZO_ENCERRADO;
    }

    let cTransmitida1 = contestacoes.find((c) => isPrimeiraInstancia(c) && isTransmitida(c));
    if (cTransmitida1) {
      cTransmitida1.situacaoVirtual = TipoSituacaoVirtual.TRANSMITIDA;
      !cAndamento1 && isPrazo1InstanciaAberto(cTransmitida1) && (cTransmitida1.situacaoVirtual = TipoSituacaoVirtual.REABRIR);
      isDesistencia(cTransmitida1) && (cTransmitida1.situacaoVirtual = TipoSituacaoVirtual.DESISTENCIA);
      isPublicada(cTransmitida1) && (cTransmitida1.situacaoVirtual = TipoSituacaoVirtual.PUBLICADA);
    }

    let cAndamento2 = contestacoes.find((c) => isSegundaInstancia(c) && isEmAndamento(c));
    if (cAndamento2) {
      cAndamento2.situacaoVirtual = isPrazo2InstanciaAberto(cAndamento2) ? TipoSituacaoVirtual.EM_ANDAMENTO : TipoSituacaoVirtual.PRAZO_ENCERRADO;
    }

    let cTransmitida2 = contestacoes.find((c) => isSegundaInstancia(c) && isTransmitida(c));
    if (cTransmitida2) {
      cTransmitida2.situacaoVirtual = TipoSituacaoVirtual.TRANSMITIDA;
      !cAndamento2 && isPrazo2InstanciaAberto(cTransmitida2) && (cTransmitida2.situacaoVirtual = TipoSituacaoVirtual.REABRIR);
      isDesistencia(cTransmitida2) && (cTransmitida2.situacaoVirtual = TipoSituacaoVirtual.DESISTENCIA);
      isPublicada(cTransmitida2) && (cTransmitida2.situacaoVirtual = TipoSituacaoVirtual.PUBLICADA);
    }

    if (!cTransmitida2 && !cAndamento2 && cTransmitida1 && isPrazo2InstanciaAberto(cTransmitida1)) {
      cTransmitida1.situacaoVirtual = TipoSituacaoVirtual.CONTESTAR_2A;
    }

    return {
      anoVigencia,
      cnpj: formataCNPJ14Digitos(cnpj),
      cAndamento1,
      cTransmitida1,
      cAndamento2,
      cTransmitida2
    };
  });

  return linhas.sort((a, b) => a.cnpj - b.cnpj);
};

export async function consultarEstabelecimentos(cnpjRaiz, anoVigencia) {
  const cnpjConsulta = formataCNPJ8Digitos(cnpjRaiz);
  if (!cnpjConsulta || !anoVigencia) {
    return [];
  }
  const resEstab = await EmpresasApi.obterEstabelecimentos(cnpjConsulta, anoVigencia, { contestados: false });
  if (resEstab.erros) {
    return resEstab;
  }
  return convertToSelectOptions(resEstab, formataCNPJ, formataCNPJ14Digitos);
}

export async function consultarContestacoes(params = {}) {
  const cnpjConsulta = formataCNPJ8Digitos(params.cnpjRaiz);
  return await EmpresasApi.obterContestacoes(cnpjConsulta, params.anoVigencia);
}

export async function consultarContestacao(params = {}) {
  const cnpjConsulta = params.cnpj ? formataCNPJ14Digitos(params.cnpj) : formataCNPJ8Digitos(params.cnpjRaiz);
  return await ContestacoesApi.obterPorId(cnpjConsulta, params.anoVigencia, params.id);
}

export async function novaContestacao(params = {}) {
  const cnpjConsulta = params.estabelecimentos ? formataCNPJ14Digitos(params.estabelecimentos) : formataCNPJ8Digitos(params.cnpjRaiz);
  return await ContestacoesApi.criarContestacao(cnpjConsulta, params.anoVigencia);
}

export async function novoRecurso(params = {}) {
  const cnpjConsulta = params.cnpj ? formataCNPJ14Digitos(params.cnpj) : formataCNPJ8Digitos(params.cnpjRaiz);
  return await ContestacoesApi.criarRecurso(cnpjConsulta, params.anoVigencia, params.id);
}

export async function transmitirContestacao(params = {}) {
  const cnpjConsulta = params.cnpj ? formataCNPJ14Digitos(params.cnpj) : formataCNPJ8Digitos(params.cnpjRaiz);
  return await ContestacoesApi.transmitir(cnpjConsulta, params.anoVigencia, params.id, params);
}

export async function reabrirContestacao(params = {}) {
  const cnpjConsulta = params.cnpj ? formataCNPJ14Digitos(params.cnpj) : formataCNPJ8Digitos(params.cnpjRaiz);
  return await ContestacoesApi.reabrir(cnpjConsulta, params.anoVigencia, params.id);
}

export async function desistirContestacao(params = {}) {
  const cnpjConsulta = params.cnpj ? formataCNPJ14Digitos(params.cnpj) : formataCNPJ8Digitos(params.cnpjRaiz);
  return await ContestacoesApi.desistir(cnpjConsulta, params.anoVigencia, params.id, params);
}
