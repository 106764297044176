import React from "react";
import { Grid, Link } from "@material-ui/core";

import { getSituacaoDescricao, isPrimeiraInstancia } from "utils/ContestacaoUtils";
import { formataDateEnBr, formataProtocoloContestacao } from "utils/StrUtils";

import { TipoSituacaoVirtual } from "../ContestacaoUtils";

function getDadosSituacaoVirtual(situacaoVirtual) {
  switch (situacaoVirtual) {
    case TipoSituacaoVirtual.EM_ANDAMENTO:
      return {
        title: "Continuar a contestação",
        icon: "fa-edit",
        label: "Continuar",
        className: "text-info"
      };
    case TipoSituacaoVirtual.PRAZO_ENCERRADO:
      return {
        title: "Prazo encerrado para continuar a contestação",
        icon: "fa-exclamation",
        label: "Prazo Encerrado",
        className: "text-danger"
      };
    case TipoSituacaoVirtual.CONTESTAR_2A:
    case TipoSituacaoVirtual.TRANSMITIDA:
    case TipoSituacaoVirtual.DESISTENCIA:
    case TipoSituacaoVirtual.PUBLICADA:
    case TipoSituacaoVirtual.REABRIR:
    default:
      return {
        title: "Detalhar a contestação",
        icon: "fa-eye",
        label: "Detalhar",
        className: "text-info"
      };
  }
}

const _showPrazo = (situacaoVirtual) =>
  [TipoSituacaoVirtual.EM_ANDAMENTO, TipoSituacaoVirtual.PRAZO_ENCERRADO, TipoSituacaoVirtual.REABRIR, TipoSituacaoVirtual.CONTESTAR_2A].includes(situacaoVirtual);

const _showSituacao = (situacaoVirtual) =>
  [TipoSituacaoVirtual.TRANSMITIDA, TipoSituacaoVirtual.DESISTENCIA, TipoSituacaoVirtual.PUBLICADA, TipoSituacaoVirtual.CONTESTAR_2A].includes(situacaoVirtual);

const _isPrazoEncerrado = (situacaoVirtual) => TipoSituacaoVirtual.PRAZO_ENCERRADO === situacaoVirtual;

const SituacaoVirtualButton = ({ contestacao = {}, onClick }) => {
  const { situacaoVirtual, dataFimPrazo1Inst, dataFimPrazo2Inst, protocolo } = contestacao;
  const dados = getDadosSituacaoVirtual(situacaoVirtual);

  if (protocolo) {
    dados.label = formataProtocoloContestacao(protocolo);
    dados.titleLabel = "Protocolo da Contestação";
  }

  let prazo = isPrimeiraInstancia(contestacao) ? formataDateEnBr(dataFimPrazo1Inst) : formataDateEnBr(dataFimPrazo2Inst);

  TipoSituacaoVirtual.PRAZO_ENCERRADO === situacaoVirtual && (prazo = `em ${prazo}`);
  TipoSituacaoVirtual.EM_ANDAMENTO === situacaoVirtual && (prazo = `até ${prazo}`);
  TipoSituacaoVirtual.REABRIR === situacaoVirtual && (prazo = `Reabrir até ${prazo}`);
  TipoSituacaoVirtual.CONTESTAR_2A === situacaoVirtual && (prazo = "Abrir Recurso até " + formataDateEnBr(dataFimPrazo2Inst));

  return (
    <Grid container spacing={0} style={{ lineHeight: "15px", justifyContent: "center" }}>
      <Grid item sm={12} className={dados.className} style={{ fontWeight: "bold", fontSize: 11, display: "flex", justifyContent: "center" }}>
        <span title={dados.titleLabel || dados.title} className={`${dados.className}`}>
          {dados.label}
        </span>
        {_isPrazoEncerrado(situacaoVirtual) && <i title={dados.title} className={`${dados.className} fa ${dados.icon}`}></i>}
        {!_isPrazoEncerrado(situacaoVirtual) && (
          <Link title={dados.title} component="button" onClick={() => onClick(contestacao)} aria-hidden="true" aria-label={dados.title}>
            <i className={`${dados.className} fa ${dados.icon}`}></i>
          </Link>
        )}
      </Grid>
      <Grid item sm={12} style={{ fontSize: 10, whiteSpace: "normal" }}>
        {_showSituacao(situacaoVirtual) && getSituacaoDescricao(contestacao)}
      </Grid>
      <Grid item sm={12} style={{ fontSize: 10, whiteSpace: "normal" }}>
        {_showPrazo(situacaoVirtual) && prazo}
      </Grid>
    </Grid>
  );
};

export default SituacaoVirtualButton;
