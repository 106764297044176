import React from "react";
import { DataGrid, ptBR, GridToolbarContainer, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarExport } from "@mui/x-data-grid";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import PreferenceManager from "services/PreferenceManager";

import RowActionsCell from "./components/RowActionsCell";
import ModalConfirm from "../ModalConfirm";

function basicToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

function detailsToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport csvOptions={{ delimiter: ";", allColumns: true, utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

function getActionsColumn(onEditRow, onDeleteRow, actions) {
  return {
    field: "actions",
    headerName: "Ações",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    minWidth: 110,
    disableClickEventBubbling: true,
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    disableReorder: true,
    renderCell: (params) => <RowActionsCell {...params} actions={actions} onEditRow={onEditRow} onDeleteRow={onDeleteRow} />
  };
}

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-columnHeaderTitle": {
      lineHeight: "normal",
      whiteSpace: "normal"
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#ededed",
      maxHeight: "none !important"
    },
    "& .MuiDataGrid-columnHeader--alignRight": {
      textAlign: "end"
    },
    "& .MuiDataGrid-columnHeader--alignCenter": {
      textAlign: "center"
    }
  }
})(DataGrid);

const CustomDataGrid = ({
  columns,
  rows,
  getRowId,
  onEdit,
  onDelete,
  onDeleteMessage = "Deseja Remover?",
  pageSize = 10,
  pageSizePreference = null,
  rowsPerPageOptions = [5, 10, 20, 50, 100],
  toolbar = "basic",
  density = "standard",
  disableSelectionOnClick = true,
  noRowsLabel = "Nenhuma linha",
  actions,
  ...rest
}) => {
  const [pgSize, setPageSize] = React.useState(pageSize);
  const [exibeConfirmacao, setExibeConfirmacao] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();

  function _handlePageSizeChange(newPgSize) {
    if (!!pageSizePreference) {
      PreferenceManager.set(pageSizePreference, newPgSize);
    }
    setPageSize(newPgSize);
  }

  React.useEffect(() => {
    if (!!pageSizePreference && PreferenceManager.get(pageSizePreference)) {
      const prefPageSize = PreferenceManager.get(pageSizePreference);
      _handlePageSizeChange(Number(prefPageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _handleDelete(row) {
    setExibeConfirmacao(true);
    setSelectedRow(row);
  }

  async function _handleConfirmDelete() {
    setExibeConfirmacao(false);
    await onDelete(selectedRow);
  }

  if (!getRowId && rows.length > 0 && !rows[0].id) {
    rows.forEach((row, index) => (row.id = index + 1));
  }

  if (!!onEdit || !!onDelete) {
    columns.push(getActionsColumn(onEdit, !!onDelete ? _handleDelete : null, undefined));
  }

  if (!!actions) {
    columns.push(getActionsColumn(undefined, undefined, actions));
  }

  let components;
  if (toolbar === "basic") {
    components = { Toolbar: basicToolBar };
  } else if (toolbar === "details") {
    components = { Toolbar: detailsToolBar };
  } else {
    components = {};
  }

  const getKey = () => {
    return "grid-key-" + rows.timestamp;
  };

  return (
    <React.Fragment>
      <div className="br-table">
        <StyledDataGrid
          key={getKey()}
          localeText={{ ...ptBR.props.MuiDataGrid.localeText, noRowsLabel }} //https://github.com/mui/mui-x/blob/docs-v4/packages/grid/_modules_/grid/constants/localeTextConstants.ts
          autoHeight
          getRowId={getRowId}
          rows={rows}
          columns={columns}
          pageSize={pgSize}
          components={components}
          disableSelectionOnClick={disableSelectionOnClick}
          disableColumnMenu
          density={density}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={_handlePageSizeChange}
          {...rest}
        />
      </div>
      {!!onDelete && <ModalConfirm show={exibeConfirmacao} cancel={() => setExibeConfirmacao(false)} onConfirm={() => _handleConfirmDelete()} message={onDeleteMessage} />}
    </React.Fragment>
  );
};

export default CustomDataGrid;

CustomDataGrid.propTypes = {
  getRowId: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteMessage: PropTypes.string,
  pageSize: PropTypes.number,
  toolbar: PropTypes.string,
  density: PropTypes.string,
  disableSelectionOnClick: PropTypes.bool,
  pageSizePreference: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func.isRequired
    })
  )
};
