import {
    createField,
    FieldType
} from "../../components/Form";

export const createModel = (fields = {}, action = "") => ({

    ano: createField("ano", "Ano:", {
        type: FieldType.NUM_MASK,
        mask: "####",
        value: fields.ano,
        required: true,
        disabled: action === "PUT"?true:false       
    }),

    taxaMortalidade: createField(
        "taxaMortalidade",
        "Taxa de Mortalidade:",
        {
          value: fields.taxaMortalidade,
          required: true,
          type: FieldType.DECIMAL,
          decimalScale: 2
        }
      )
});