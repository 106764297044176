import { getApi } from "../ApiManager";

const contextoAuditoria = "/auditoria";

const LogAuditoriaApi = {
  obter: async (params) => {
    const config = { params: { ...params } };

    const Api = getApi();
    return await Api.get(contextoAuditoria, config);
  },
  registrarLogin: async () => {
    const Api = getApi();
    return await Api.post(`${contextoAuditoria}/login`).catch((err) => console.error("Erro ao auditar login", err));
  },
  registrarLogout: async () => {
    const Api = getApi();
    return await Api.post(`${contextoAuditoria}/logout`).catch((err) => console.error("Erro ao auditar logout", err));
  }
};

export default LogAuditoriaApi;
