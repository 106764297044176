import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import BrMessage from "@govbr/react-components/lib/BrMessage";

export const TagMessage = ({ messages, show = false, title = "Clique para exibir as mensagens" }) => {
  const [showInfo, setShowInfo] = React.useState(show);

  if (!messages || messages.length === 0) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <IconButton size="small" title={title} aria-label={title} onClick={() => setShowInfo(!showInfo)}>
        <span className="br-tag bg-warning fa fa-exclamation fa-sm"></span>
      </IconButton>
      <Grid item sm={12} style={{ display: showInfo ? "block" : "none" }}>
        <BrMessage warning>
          <ul className="m-1">
            {messages.map((m, i) => {
              return <li key={`m-${i}`}>{m.texto} </li>;
            })}
          </ul>
        </BrMessage>
      </Grid>
    </React.Fragment>
  );
};
