export const TipoCalculo = {
  CALCULO_ORIGINAL: "Cálculo Original",
  RECALCULO_ELETRONICO_PRIMEIRA_INSTANCIA: "Recálculo 1ª Instância Eletrônica",
  RECALCULO_ELETRONICO_SEGUNDA_INSTANCIA: "Recálculo 2ª Instância Eletrônica",
  RECALCULO_MANUAL_PRIMEIRA_INSTANCIA: "Recálculo 1ª Instância Manual",
  RECALCULO_MANUAL_SEGUNDA_INSTANCIA: "Recálculo 2ª Instância Manual",
  RECALCULO_JUDICIAL: "Recálculo Judicial",
  REVISAO_OFICIO: "Revisão de Ofício",
  DESBLOQUEIO_SINDICATO: "Desbloqueio pelo Sindicato"
};

export function isResultadoJulgamento(tipoCalculo) {
  const desc = TipoCalculo[tipoCalculo] || "";
  return (
    !!desc &&
    [TipoCalculo.RECALCULO_MANUAL_PRIMEIRA_INSTANCIA, TipoCalculo.RECALCULO_MANUAL_SEGUNDA_INSTANCIA, TipoCalculo.RECALCULO_JUDICIAL, TipoCalculo.REVISAO_OFICIO].includes(desc)
  );
}

export const TipoEfeitoSuspensivo = {
  INICIO_EFEITO_SUSPENSIVO_PRIMEIRA_INSTANCIA: "Início Efeito Suspensivo 1ª Instância",
  FIM_EFEITO_SUSPENSIVO_PRIMEIRA_INSTANCIA: "Fim Efeito Suspensivo 1ª Instância",
  INICIO_EFEITO_SUSPENSIVO_SEGUNDA_INSTANCIA: "Início Efeito Suspensivo 2ª Instância",
  FIM_EFEITO_SUSPENSIVO_SEGUNDA_INSTANCIA: "Fim Efeito Suspensivo 2ª Instância",
  INICIO_EFEITO_SUSPENSIVO_JUDICIAL: "Início Efeito Suspensivo Judicial",
  FIM_EFEITO_SUSPENSIVO_JUDICIAL: "Fim Efeito Suspensivo Judicial"
};

export const TipoMensagem = {
  MENSAGEM_NAO_CALCULADO: "Não Calculado",
  MENSAGEM_EFEITO_SUSPENSIVO: "Efeito Suspensivo",
  MENSAGEM_MOTIVO_NEUTRO_PERIODO_BASE: "Motivo Neutro Período Base",
  MENSAGEM_MOTIVO_NEUTRO_OPTANTE_SIMPLES: "Motivo Neutro Optante Simples",
  MENSAGEM_MOTIVO_NEUTRO_FILANTROPICA: "Motivo Neutro Filantrópica",
  MENSAGEM_MOTIVO_NEUTRO_GRUPO_CNAE_MENOR_5: "Motivo Neutro Grupo CNAE Menor que 5",
  MENSAGEM_MOTIVO_NEUTRO_SEM_VINCULOS_VALIDOS: "Motivo Neutro sem Vínculos Válidos",
  MENSAGEM_MOTIVO_NEUTRO_SEM_DECLARACOES_VALIDAS: "Motivo Neutro sem Declarações Válidas",
  MENSAGEM_MOTIVO_NEUTRO_SEM_GFIPS_VALIDAS_2017: "Motivo Neutro sem GFIPs Válidas 2017",
  MENSAGEM_MOTIVO_NEUTRO_CNAE_INVALIDA: "Motivo Neutro CNAE Inválida",
  MENSAGEM_MOTIVO_NEUTRO_MEDIA_VINCULOS_MENOR_5: "Motivo Neutro Média Vínculos Menos que 5",
  MENSAGEM_INFORMATIVA: "Mensagem Informativa",
  MENSAGEM_DETALHAMENTO_CAT: "Detalhamento CAT",
  MENSAGEM_DETALHAMENTO_B91: "Detalhamento B91",
  MENSAGEM_DETALHAMENTO_B92: "Detalhamento B92",
  MENSAGEM_DETALHAMENTO_B93: "Detalhamento B93",
  MENSAGEM_DETALHAMENTO_B94: "Detalhamento B94",
  MENSAGEM_DETALHAMENTO_NEXO_TECNICO: "Detalhamento Nexo Técnico",
  MENSAGEM_DETALHAMENTO_MASSA_SALARIAL: "Detalhamento Massa Salarial",
  MENSAGEM_DETALHAMENTO_MEDIA_VINCULOS: "Detalhamento Média Vínculos",
  MENSAGEM_DETALHAMENTO_ROTATIVIDADE: "Detalhamento Rotatividade",
  MENSAGEM_DETALHAMENTO_NUMERO_ORDEM: "Detalhamento Número Ordem",
  MENSAGEM_DADOS_CALCULO: "Dados do Cálculo",
  MENSAGEM_INDICADORES_CALCULO: "Indicadores do Cálculo",
  MENSAGEM_REPROCESSAMENTO_ESTABELECIMENTO: "Reprocessamento por Estabelecimento",
  MENSAGEM_BLOQUEIO_BONIFICACAO_B92: "Bloqueio Bonif. B92",
  MENSAGEM_BLOQUEIO_BONIFICACAO_B93: "Bloqueio Bonif. B93",
  MENSAGEM_BLOQUEIO_BONIFICACAO_CAT: "Bloqueio Bonif. CAT",
  MENSAGEM_BLOQUEIO_BONIFICACAO_ROTATIVIDADE: "Bloqueio Bonif. Rotatividade",
  MENSAGEM_BLOQUEIO_REDUCAO_MALUS_B92: "Bloqueio Red. Malus B92",
  MENSAGEM_BLOQUEIO_REDUCAO_MALUS_B93: "Bloqueio Red. Malus B93",
  MENSAGEM_BLOQUEIO_REDUCAO_MALUS_CAT: "Bloqueio Red. Malus CAT",
  MENSAGEM_BLOQUEIO_REDUCAO_MALUS_ROTATIVIDADE: "Bloqueio Red. Malus Rotatividade",
  MENSAGEM_DESISTENCIA_CONTESTACAO: "Desistência de Contestação",
  MENSAGEM_AJUSTE_R1316: "Ajuste R1316",
  MENSAGEM_CONTESTACAO_CATS_NAO_CONTESTADAS: "CATs: Seleção de CATs à Contestar",
  MENSAGEM_CONTESTACAO_CATS_CONTESTADAS: "CATs: Lista de CATs contestadas",
  MENSAGEM_CONTESTACAO_CAT_CONTESTACAO: "CATs: Formulário de contestação de CAT",
  MENSAGEM_CONTESTACAO_BENEFICIOS_NAO_CONTESTADOS: "Beneficios: Seleção de Benefícios à Contestar",
  MENSAGEM_CONTESTACAO_BENEFICIOS_CONTESTADOS: "Beneficios: Lista de Benefícios contestados",
  MENSAGEM_CONTESTACAO_BENEFICIO_CONTESTACAO: "Beneficios: Formulário de contestação de Benefício",
  MENSAGEM_CONTESTACAO_TAXA_ROTATIVIDADE_NAO_CONTESTADAS: "Taxa de Rotatividade: Seleção de Rotatividade à Contestar",
  MENSAGEM_CONTESTACAO_TAXAS_ROTATIVIDADE_CONTESTADAS: "Taxa de Rotatividade: Lista de Rotatividades contestadas",
  MENSAGEM_CONTESTACAO_TAXA_ROTATIVIDADE_CONTESTACAO: "Taxa de Rotatividade: Formulário de contestacao de Rotatividade",
  MENSAGEM_CONTESTACAO_NEXO_TECNICO_NAO_CONTESTADOS: "Nexo Técnico: Seleção de Nexo Técnico à Contestar",
  MENSAGEM_CONTESTACAO_NEXO_TECNICO_CONTESTADOS: "Nexo Técnico: Lista de Nexos Técnicos contestados",
  MENSAGEM_CONTESTACAO_NEXO_TECNICO_CONTESTACAO: "Nexo Técnico: Formulário de contestacao de Nexo Técnico",
  MENSAGEM_CONTESTACAO_MASSAS_SALARIAIS_NAO_CONTESTADAS: "Massa Salarial: Seleção de Massa Salarial à Contestar",
  MENSAGEM_CONTESTACAO_MASSAS_SALARIAIS_CONTESTADAS: "Massa Salarial: Lista de Massas Salariais contestados",
  MENSAGEM_CONTESTACAO_MASSA_SALARIAL_CONTESTACAO: "Massa Salarial: Formulário de contestacao de Massa Salarial",
  MENSAGEM_CONTESTACAO_VINCULOS_NAO_CONTESTADOS: "Vínculo: Seleção de Vínculo à Contestar",
  MENSAGEM_CONTESTACAO_VINCULOS_CONTESTADOS: "Vínculo: Lista de Vínculos contestados",
  MENSAGEM_CONTESTACAO_VINCULO_CONTESTACAO: "Vínculo: Formulário de contestacao de Vínculo",
  MENSAGEM_CONFIRMACAO_PROTOCOLO: "Transmitir Contestação: Confirmação de Protocolo"
};

export const TipoFaseCalculo = {
  ESTABELECIMENTOS: { value: "6.0", label: "Carga de Estabelecimentos" },
  GFIPWEB_ESOCIAL: { value: "7.0", label: "Carga da GFIP e eSocial" },
  CNAE: { value: "7.1", label: "Carga da tabela de CNAEs" },
  CORRESPONDENCIA_CNAE: { value: "7.2", label: "Carga da Correspondência de CNAEs" },
  OBRIGACAO_ESOCIAL: { value: "7.3", label: "Carga das empresas obrigadas a declarar eSocial" },
  GFIP: { value: "7.4", label: "Carga da GFIP" },
  ESOCIAL: { value: "7.5", label: "Carga do eSocial" },
  VINCULOS: { value: "8.0", label: "Carga de Vínculos" },
  VINCULOS_GFIP: { value: "8.1", label: "Carga de Vínculos da GFIP" },
  VINCULOS_ESOCIAL: { value: "8.2", label: "Carga de Vínculos do eSocial" },
  BENEFICIOS_DEPENDENTES: { value: "10.0", label: "Carga de Beneficios e dependentes" },
  DEPENDENTES: { value: "10.1", label: "Carga de dependentes" },
  BENEFICIOS: { value: "10.2", label: "Carga de beneficios" },
  CATS: { value: "11.0", label: "Carga de CATs" },
  AGRUPAMENTO_VINCULOS: { value: "12.0", label: "Agrupamento de Vínculos" },
  AGRUPAMENTO_BENEFICIOS: { value: "13.0", label: "Agrupamento de Benefícios" },
  INDICES: { value: "14.0", label: "Cálculo de Índices" },
  PERCENTIS: { value: "15.0", label: "Cálculo de Percentis" },
  CALCULO_FAP: { value: "16.0", label: "Cálculo do FAP" },
  RAT: { value: "17.0", label: "Fases do Cálculo do RAT" },
  AGRUPAMENTO_VINCULOS_RAT: { value: "17.1", label: "Agrupamento de Vínculos do RAT" },
  AGRUPAMENTO_BENEFICIOS_RAT: { value: "17.2", label: "Agrupamento de Benefícios do RAT" },
  INDICES_RAT: { value: "17.3", label: "Cálculo de índices do RAT" },
  PERCENTIS_RAT: { value: "17.4", label: "Cálculo de percentis do RAT" },
  CALCULO_RAT: { value: "17.5", label: "Cálculo do RAT" }
};

export const TipoRegra = {
  HARDCODED: "Hardcoded",
  VALIDACAO: "Regra Validação",
  ATRIBUICAO: "Atribuição de Valores"
};

export const TipoReprocessamento = {
  REPROCESSADO: "REPROCESSADO",
  PUBLICADO: "PUBLICADO",
  REPROCESSANDO: "REPROCESSANDO",
  ERRO_REPROCESSAMENTO: "ERRO_REPROCESSAMENTO",
  CANCELADO: "CANCELADO",
  EXCLUIDO: "EXCLUIDO"
};

export const TipoInstancia = {
  primeira: { value: "ADMINISTRATIVO_PRIMEIRA_INSTANCIA", label: "Administrativa em 1ª Instância" },
  segunda: { value: "ADMINISTRATIVO_SEGUNDA_INSTANCIA", label: "Administrativa em 2ª Instância" }
};

export const TipoSituacaoContestacao = {
  EM_ANDAMENTO: { value: "EM_ANDAMENTO", label: "Iniciada" },
  LIBERADA_PARA_ANALISE: { value: "LIBERADA_PARA_ANALISE", label: "Aguardando análise", empresa: "Transmitida" },
  EM_ANALISE: { value: "EM_ANALISE", label: "Processo em análise" },
  LIBERADA_PARA_AUTORIDADE: { value: "LIBERADA_PARA_AUTORIDADE", label: "Aguardando análise da autoridade" },
  LIBERADA_PARA_RECALCULO: { value: "LIBERADA_PARA_RECALCULO", label: "Processo liberado para Recálculo do FAP" },
  HISTORICO: { value: "HISTORICO", label: "Informações para histórico" },
  FINALIZADA: { value: "FINALIZADA", label: "Processo finalizado" },
  AGUARDANDO_PUBLICACAO: { value: "AGUARDANDO_PUBLICACAO", label: "Aguardando publicação no DOU" },
  PUBLICADA: { value: "PUBLICADA", label: "Resultado divulgado no DOU" },
  DESISTENCIA_AGUARDANDO_AUTORIDADE: { value: "DESISTENCIA_AGUARDANDO_AUTORIDADE", label: "Desistência aguardando Homologação da Autoridade" },
  DESISTENCIA_AGUARDANDO_DOU: { value: "DESISTENCIA_AGUARDANDO_DOU", label: "Desistência aguardando publicação no DOU" },
  DESISTENCIA_PUBLICADA: { value: "DESISTENCIA_PUBLICADA", label: "Desistência publicada no DOU" }
};

export const TipoSituacaoGestaoProcessoContestacao = {
  LIBERADA_PARA_ANALISE: { value: "LIBERADA_PARA_ANALISE", label: "Aguardando análise" },
  EM_ANALISE: { value: "EM_ANALISE", label: "Processo em análise" },
  LIBERADA_PARA_AUTORIDADE: { value: "LIBERADA_PARA_AUTORIDADE", label: "Aguardando análise da autoridade" },
  LIBERADA_PARA_RECALCULO: { value: "LIBERADA_PARA_RECALCULO", label: "Processo liberado para Recálculo do FAP" },
  FINALIZADA: { value: "FINALIZADA", label: "Processo finalizado" },
  AGUARDANDO_PUBLICACAO: { value: "AGUARDANDO_PUBLICACAO", label: "Aguardando publicação no DOU" },
  PUBLICADA: { value: "PUBLICADA", label: "Resultado divulgado no DOU" }
};

export const TipoElementoContestacao = {
  CAT: {
    id: "CAT",
    label: "CATs",
    descricao: "Comunicação de Acidente de Trabalho",
    url: "cats"
  },
  NEXO_TECNICO: {
    id: "NEXO_TECNICO",
    label: "Nexo Técnico Previdenciário",
    descricao: "Nexo Técnico Previdênciario sem CAT Vinculada",
    url: "nexos-tecnicos"
  },
  BENEFICIO: {
    id: "BENEFICIO",
    label: "Benefícios",
    descricao: "Benefício",
    url: "beneficios"
  },
  MASSA_SALARIAL: {
    id: "MASSA_SALARIAL",
    label: "Massa Salarial",
    descricao: "Massa Salarial",
    url: "massa-salarial"
  },
  NUMERO_MEDIO_VINCULOS: {
    id: "NUMERO_MEDIO_VINCULOS",
    label: "Número Médio de Vínculos",
    descricao: "Número Médio de Vínculos",
    url: "vinculos"
  },
  ROTATIVIDADE: {
    id: "ROTATIVIDADE",
    label: "Taxa Média de Rotatividade",
    descricao: "Taxa Média de Rotatividade",
    url: "rotatividade"
  }
};

export function getTipoElementoUrl(tipoElemento) {
  return TipoElementoContestacao[tipoElemento]?.url || "";
}

export const TipoPapel = {
  CONSELHEIRO_CRPS: "FAP.CONSELHEIRO_CRPS",
  CONSULTA_CGBRP: "FAP.CONSULTA_CGBRP",
  GESTOR_CGBRP: "FAP.GESTOR_CGBRP",
  GESTOR_DATAPREV: "FAP.GESTOR_DATAPREV",
  GESTOR_CRPS: "FAP.GESTOR_CRPS",
  PRESIDENTE_JUNTA: "FAP.PRESIDENTE_JUNTA",
  PRESIDENTE_CAMARA: "FAP.PRESIDENTE_CAMARA",
  ANALISTA_CGBRP: "FAP.ANALISTA_CGBRP",
  EMPRESA: "FAP_EMPRESA",
  PROCURACAO: "FAP_EMPRESA_PROCURACAO"
};

export const todosPapeis = Object.values(TipoPapel);
export const geridPapeis = todosPapeis.filter((v) => v !== TipoPapel.EMPRESA && v !== TipoPapel.PROCURACAO);

export const existeGeridPapel = (papeis = []) => {
  if (!papeis) {
    return false;
  }
  const param = Array.isArray(papeis) ? papeis : [papeis];
  const total = geridPapeis.reduce((acc, el) => (acc += param.includes(el) ? el : ""), "");
  return !!total;
};

export const TipoPapelDescricao = {
  ANALISTA_CGBRP: "Analista CGBRP",
  CONSELHEIRO_CRPS: "Conselheiro CRPS",
  CONSULTA_CGBRP: "Consulta FAP",
  GESTOR_CGBRP: "Gestor CGBRP",
  GESTOR_CRPS: "Gestor CRPS",
  GESTOR_DATAPREV: "Gestor Dataprev",
  PRESIDENTE_CAMARA: "Presidente Camara",
  PRESIDENTE_JUNTA: "Presidente Junta"
};

export const TipoContexto = {
  CALCULOS: "Consulta FAP",
  CONTESTACOES: "Contestação Eletrônica",
  PAINEL_CONTROLE: "Painel de Controle",
  RESULTADOS_JULGAMENTO: "Resultado de Julgamento",
  SEGURANCA: "Controle de Acesso",
  PROCURACAO: "Procuração Eletrônica"
};

export const TipoSituacaoProcuracao = {
  PENDENTE: "Aguardando Parecer",
  DEFERIDA: "Deferida",
  INDEFERIDA: "Indeferida",
  EXCLUIDA: "Excluída",
  EXPIRADA: "Expirada"
};
export const TipoDeferimentoProcuracao = {
  DEFERIDO: "Deferido",
  INDEFERIDO: "Indeferido"
};

export const TipoProcuracao = {
  CONCESSAO_ACESSO: "Concessão de Acesso",
  INCORPORACAO: "Incorporacao de Empresa"
};

export function filterByTipo(tipo, values = [], list = []) {
  if (!Array.isArray(values)) {
    values = [values];
  }
  const tipos = Object.keys(tipo).filter((key) => values.includes(tipo[key]));
  return list.filter((m) => tipos.includes(m.tipo));
}
