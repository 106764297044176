import React from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, convertToSelectOptions, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { TipoSituacaoContestacao } from "utils/TipoUtils";
import { isVigenciaContestacaoPorEstabelecimento } from "utils/VigenciaUtils";
import { formataCNPJ, formataCNPJ14Digitos } from "utils/StrUtils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";
import EmpresasApi from "services/api/EmpresasApi";

import { getImpressaoDespachoColumns, getInstanciaPresidenteLogado } from "./utils";
import { createConsultaImpressaoDespachoAdmConstestacaoModel } from "./model";

const columns = getImpressaoDespachoColumns();

export const ImprimeDespacho = ({ userData }) => {
  const { vigencias } = useVigencias();

  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);

  const [fields, setFields] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    async function _initForm() {
      setLoading(true);
      if (vigencias.length > 0) {
        const init = createConsultaImpressaoDespachoAdmConstestacaoModel({ fields: {}, vigencias: vigencias });
        setFields(init);
        setRows([]);
      }
      setLoading(false);
    }
    _initForm();
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  //TODO: refatorar para dois _handleChange separados
  async function _handleChange(field) {
    if (fields.estabelecimentos.id !== field.id && fields.situacao.id !== field.id) {
      _setLoading(true);
      const ativaEstabs = isVigenciaContestacaoPorEstabelecimento(fields.anoVigencia.value);
      toggleEstabelecimentos(fields.estabelecimentos, ativaEstabs);
      const resEstab = await obterEstabelecimentos(fields.cnpjRaiz.value, fields.anoVigencia.value, ativaEstabs);
      if (resEstab.erros) {
        setFormErrors(resEstab.erros);
      } else {
        fields.estabelecimentos.options = resEstab;
      }
      setRows([]);
      _setLoading(false);
    }
    setFields(() => ({ ...fields }));
  }

  const toggleEstabelecimentos = (estabelecimentos = {}, ativaEstabs = false) => {
    if (ativaEstabs) {
      estabelecimentos.required = false;
      estabelecimentos.disabled = false;
    } else {
      estabelecimentos.required = false;
      estabelecimentos.disabled = true;
    }
    estabelecimentos.options = [];
    estabelecimentos.value = "";
  };

  async function obterEstabelecimentos(cnpjConsulta, anoVigencia, ativaEstabs = false) {
    const isCnpjRaiz = cnpjConsulta.length === 8;
    if (isCnpjRaiz && ativaEstabs) {
      const resEstab = await EmpresasApi.obterEstabelecimentos(cnpjConsulta, anoVigencia);
      if (resEstab.erros) {
        return resEstab;
      }
      return convertToSelectOptions(resEstab, formataCNPJ, formataCNPJ14Digitos);
    }
    return [];
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      await obterContestacoes();
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  async function obterContestacoes() {
    const requestBody = convertFormFieldsToJsonObj(fields);
    const params = {
      situacao: requestBody.situacao
        ? requestBody.situacao
        : [
            TipoSituacaoContestacao.LIBERADA_PARA_RECALCULO.value,
            TipoSituacaoContestacao.FINALIZADA.value,
            TipoSituacaoContestacao.AGUARDANDO_PUBLICACAO.value,
            TipoSituacaoContestacao.PUBLICADA.value
          ],
      anoVigencia: fields.anoVigencia.value,
      cnpj: isVigenciaContestacaoPorEstabelecimento(fields.anoVigencia.value) && requestBody.estabelecimentos ? requestBody.estabelecimentos : requestBody.cnpjRaiz
    };

    params.instancia = getInstanciaPresidenteLogado(userData.papeis);

    const res = await ContestacoesApi.obterContestacoes(params);
    if (!res.erros) {
      setRows(res);
      setFormErrors([]);
    } else {
      setFormErrors(res.erros);
      setRows([]);
    }
  }

  async function imprimirDespacho() {
    _setLoading(true);
    const res = await ContestacoesApi.downloadRelatorioPdfDespacho(selectedRows);
    if (!!res.erros) {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {!!fields && (
            <Form onSubmit={_handleSubmit} errors={formErrors} submitTitle="Pesquisar">
              <Grid item sm={2}>
                <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.estabelecimentos} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.situacao} onChange={_handleChange} />
              </Grid>
            </Form>
          )}
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid
            getRowId={(r) => r.id}
            rows={rows}
            columns={columns}
            density="compact"
            rowHeight={65}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Grid container spacing={1} style={{ justifyContent: "right" }}>
          <Grid item>
            <BrButton primary onClick={imprimirDespacho} disabled={!selectedRows.length > 0}>
              Imprimir Despacho
            </BrButton>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
