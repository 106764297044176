import { getApi } from "../ApiManager";

const contexto = "/pareceres";

const PareceresApi = {
  obter: (params = {}) => getApi().get(`${contexto}`, { params }),
  alterar: (id, body = {}) => getApi().put(`${contexto}/${id}`, body),
  criar: (body = {}) => getApi().post(`${contexto}`, body),
  remover: (id) => getApi().delete(`${contexto}/${id}`)
};
export default PareceresApi;
