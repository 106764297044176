import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { FormErrorPanel, InputGeneric } from "components/Form";
import { LabelValue } from "components/LabelValue";

const isAtivaFapSimplificado = ({ estabelecimentos }) => {
  const isEstabelecimentosEnabled = !estabelecimentos.disabled;
  const hasEstabelecimentos = estabelecimentos.options && estabelecimentos.options.length > 0;
  return isEstabelecimentosEnabled && hasEstabelecimentos;
};

const ConsultaFapForm = ({ errors, formFields, loading, onSubmit, onChange, onSubmitFapSimplificado }) => {
  const [formIsLoading, setFormIsLoading] = React.useState(loading);

  function onFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    setFormIsLoading(true);
    onSubmit(event);
    setFormIsLoading((f) => false);
  }

  return formFields ? (
    <form autoComplete="off" noValidate onSubmit={onFormSubmit}>
      <FormErrorPanel messages={errors} />
      <Grid container spacing={1}>
        <Grid item sm={8}>
          <Grid container spacing={2}>
            <Grid id="grid-vigencias" item sm={2}>
              <InputGeneric field={formFields.anoVigencia} onChange={onChange} />
            </Grid>

            {!formFields.empresas.disabled && (
              <Grid item sm={5}>
                <InputGeneric field={formFields.empresas} onChange={onChange} />
              </Grid>
            )}

            {formFields.empresas.disabled && (
              <Grid item sm={4}>
                <InputGeneric small field={formFields.cnpjRaiz} onChange={onChange} />
              </Grid>
            )}

            <Grid item sm={4} id={"grid-estabelecimentos"}>
              {!formFields.estabelecimentos.disabled && <InputGeneric field={formFields.estabelecimentos} onChange={onChange} />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={4} style={{ paddingTop: 30 }}>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item>
              {/* <BrButton small secondary onClick={onSubmitFapSimplificado}>FAP Simplificado</BrButton> */}
              {isAtivaFapSimplificado(formFields) && (
                <LabelValue
                  title={"Consultar o FAP simplificado de todos os estabelecimentos."}
                  className="text-info"
                  value={"FAP Simplificado"}
                  onClick={onSubmitFapSimplificado}
                />
              )}
            </Grid>
            <Grid item>
              <BrButton submit loading={loading || formIsLoading} primary>
                <span className={`fa fa-search fa-md`}></span>Consultar
              </BrButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  ) : null;
};

export default ConsultaFapForm;
