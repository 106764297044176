import React from "react";
import VigenciasApi from "services/api/VigenciasApi";

export function useVigencias() {
  const [vigencias, setVigencias] = React.useState([]);

  const atualizaVigencias = React.useCallback(async () => {
    let res = await VigenciasApi.obterVigenciasAnos();
    if (res && !res.erros) {
      res.forEach((v, i) => {
        res[i] = String(res[i]);
      });

      setVigencias(res);
    } else {
      setVigencias([]);
    }
  }, []);

  React.useEffect(() => {
    atualizaVigencias();
  }, [atualizaVigencias]);

  return { vigencias, atualizaVigencias };
}
