import React from "react";
import { Grid } from "@material-ui/core";

import Form, { InputGeneric } from "components/Form";

export const ConsultaLogsExecucaoForm = ({ fields, formRef, formErrors, onChange, onSumit }) => {
  return (
    <Grid container>
      <Grid item sm={12}>
        {fields && (
          <Form ref={formRef} onSubmit={onSumit} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={formErrors}>
            <Grid item sm={2}>
              <InputGeneric field={fields.dataInicio} onChange={onChange} />
            </Grid>
            <Grid item sm={2}>
              <InputGeneric field={fields.dataFim} onChange={onChange} />
            </Grid>
          </Form>
        )}
      </Grid>
    </Grid>
  );
};
