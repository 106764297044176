import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";
import { formataCnae, formataCNPJ, formataCompetenciaExtenso, formataNumberEnBr, formataReal } from "utils/StrUtils";
import { saveAsCsv } from "utils/CsvUtils";

import ExtracoesApi from "services/api/ExtracoesApi";

const createModel = ({ vigencias = [] }) => ({
  tipoCnpjConsulta: createField("tipoCnpjConsulta", "Consultar por:", {
    type: FieldType.RADIO,
    options: convertToSelectOptions({ cnpj: "CNPJ Completo", raiz: "CNPJ Raiz" }),
    value: "cnpj",
    errorMessage: "Informe o CNPJ completo",
    placeholder: "Informe o CNPJ completo"
  }),
  cnpj: createField("cnpj", "CNPJ:", {
    mask: MaskType.CNPJ,
    errorMessage: "Informe o CNPJ completo",
    placeholder: "Informe o CNPJ completo"
  }),
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  competenciaInicial: createField("competenciaInicial", "Início Competência:", {
    type: FieldType.SELECT,
    errorMessage: "Selecione o Início Competência",
    placeholder: "Selecione o Início Competência"
  }),
  competenciaFinal: createField("competenciaFinal", "Término Competência:", {
    type: FieldType.SELECT,
    errorMessage: "Selecione o Término",
    placeholder: "Selecione o Término"
  }),
  vinculos: createField("vinculos", "Vínculos:", {
    type: FieldType.SELECT,
    required: false,
    errorMessage: "Selecione uma opção",
    placeholder: "Selecione os Tipos de Vínculos",
    multiple: true
  }),
  fatosGeradores: createField("fatosGeradores", "Fato Gerador:", {
    type: FieldType.SELECT,
    required: false,
    errorMessage: "Selecione uma opção",
    placeholder: "Selecione os Fatos Geradores",
    multiple: true
  }),
  rescisoes: createField("rescisoes", "Rescisão:", {
    type: FieldType.SELECT,
    required: false,
    errorMessage: "Selecione uma opção",
    placeholder: "Selecione os Tipos de Rescisão",
    multiple: true
  })
});

const resumoColumns = [
  { field: "competencia", headerName: "Competência", valueGetter: ({ value }) => formataCompetenciaExtenso(value), flex: 1 },
  { field: "quantidadeDeclaracoes", headerName: "Declarações", valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 },
  { field: "valorMassaSalarial", headerName: "Massa Salarial", renderCell: ({ value }) => formataReal(value), valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 },
  {
    field: "valorMassaSalarial13",
    headerName: "Massa Salarial 13º",
    renderCell: ({ value }) => formataReal(value),
    valueFormatter: ({ value }) => formataNumberEnBr(value),
    flex: 1
  },
  { field: "quantidadeVinculos", headerName: "Vínculos", valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 },
  { field: "quantidadeAdmissoes", headerName: "Admissões", valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 },
  { field: "quantidadeRescisoes", headerName: "Rescisões", valueFormatter: ({ value }) => formataNumberEnBr(value), flex: 1 }
];

const TipoRelatorioExtrator = {
  tipoEsocial: {
    get: ExtracoesApi.obterRelatorioEsocial,
    params: { detalhado: true },
    fileName: "RelatorioDetalhadoEsocial"
  },
  tipoGfip: {
    get: ExtracoesApi.obterRelatorioGfipweb,
    params: { detalhado: true },
    fileName: "RelatórioDetalhadoGfipWeb"
  }
};

const CsvColumnFormatter = {
  cnae: formataCnae,
  nuCnae2: formataCnae,
  cnpj: formataCNPJ,
  cnpjTomador: formataCNPJ,
  valorMassaSalarial: formataNumberEnBr,
  valorMassaSalarial13: formataNumberEnBr
};

function formatCsvRows(rows = []) {
  rows.forEach((row) => {
    Object.keys(row).forEach((k) => {
      const formatter = CsvColumnFormatter[k];
      if (formatter) {
        row[k] = formatter(row[k]);
      }
    });
  });
}

function getCompetenciasPeriodoBasePorVigencia(anoVigencia) {
  const a3AnosAntes = (anoVigencia - 3) * 100;
  const a2AnosAntes = (anoVigencia - 2) * 100;
  const meses = [];
  [a3AnosAntes, a2AnosAntes].forEach((ano) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].forEach((mes) => meses.push(ano + mes)));
  return meses;
}

export async function obterRelatorioExtracao(tipo, fieldsValues, csvParams = {}) {
  const relatorio = TipoRelatorioExtrator[tipo];
  const { anoVigencia, cnpj, tipoCnpjConsulta, ...params } = fieldsValues;
  return await relatorio.get(anoVigencia, cnpj, { ...params, ...csvParams });
}

export async function downloadRelatorioCsvDetalhado(tipo, fieldsValues) {
  const relatorio = TipoRelatorioExtrator[tipo];
  const { anoVigencia, cnpj } = fieldsValues;

  const res = await obterRelatorioExtracao(tipo, fieldsValues, relatorio.params);
  if (res.erros) {
    return res.erros;
  }
  formatCsvRows(res);

  res.sort(function (a, b) {
    return a.competencia - b.competencia;
  });

  saveAsCsv({ filename: `${relatorio.fileName}-${anoVigencia}-${cnpj}`, data: res });
  return [];
}

export const getResumoColumns = (dados = []) => {
  const [firstRow] = dados;
  return dados.length > 0 ? resumoColumns.filter((colDef) => firstRow.hasOwnProperty(colDef.field)) : [];
};

export const createModelEsocial = (params) => {
  const { fatosGeradores, ...model } = createModel(params);
  return model;
};

export const createModelGfip = (params) => {
  const { vinculos, ...model } = createModel(params);
  return model;
};

export function toggleTipoCnpjField({ cnpj = {} }) {
  if (cnpj.label !== "CNPJ:") {
    cnpj.mask = MaskType.CNPJ;
    cnpj.errorMessage = "Informe o CNPJ completo";
    cnpj.placeholder = "Informe o CNPJ completo";
    cnpj.label = "CNPJ:";
  } else {
    cnpj.mask = MaskType.CNPJ_RAIZ;
    cnpj.errorMessage = "Informe os 8 primeiros dígitos do CNPJ";
    cnpj.placeholder = "Informe os 8 primeiros dígitos do CNPJ";
    cnpj.label = "CNPJ Raiz:";
  }
}

export async function updateParametrosExtracao({ anoVigencia, competenciaInicial, competenciaFinal, rescisoes, vinculos, fatosGeradores }) {
  const ano = anoVigencia.value;
  const isGfip = !!fatosGeradores;

  const resRescisoes = isGfip ? await ExtracoesApi.obterRecisoesGfipweb(ano) : await ExtracoesApi.obterRecisoesEsocial(ano);
  let resVinculos = !isGfip ? await ExtracoesApi.obterVinculosEsocial(ano) : [];
  let restFatosGeradores = isGfip ? await ExtracoesApi.obterFatosGeradoresGfipweb(ano) : [];

  const formataCodigo = (e) => e.codigo;
  const formataDescricao = (e) => e.descricao;

  rescisoes.options = !resRescisoes.erros ? convertToSelectOptions(resRescisoes, formataDescricao, formataCodigo) : [];
  const recisoesPre = isGfip ? ["I1", "I3"] : ["2", "3", "6"];
  rescisoes.value = rescisoes.options.filter((f) => recisoesPre.includes(f.value));

  restFatosGeradores = !restFatosGeradores.erros ? convertToSelectOptions(restFatosGeradores, formataDescricao, formataCodigo) : [];
  if (fatosGeradores) {
    fatosGeradores.options = restFatosGeradores;
    fatosGeradores.value = restFatosGeradores.filter((f) => f.value === "0");
  }

  resVinculos = !resVinculos.erros ? convertToSelectOptions(resVinculos, formataDescricao, formataCodigo) : [];
  if (vinculos) {
    vinculos.options = resVinculos;
    vinculos.value = resVinculos;
  }

  const competencias = getCompetenciasPeriodoBasePorVigencia(ano);
  const competenciasOptions = convertToSelectOptions(competencias, formataCompetenciaExtenso);
  competenciaInicial.options = competenciasOptions;
  competenciaInicial.value = competencias.at(0);
  competenciaFinal.options = competenciasOptions;
  competenciaFinal.value = competencias.at(-1);
}
