import { TipoElementoContestacao, TipoInstancia, TipoPapel, TipoSituacaoContestacao } from "utils/TipoUtils";
import { getInstanciaDescricao, getSituacaoDescricao, isPrimeiraInstancia } from "utils/ContestacaoUtils";
import {
  formataCNPJ,
  formataCNPJRaiz,
  formataCompetencia,
  formataDateEnBr,
  formataIsoDateTimeBR,
  formataNumberEnBr,
  formataPorcento,
  formataProtocoloContestacao
} from "utils/StrUtils";

const anoCol = { field: "ano", headerName: "Ano", description: "Ano", headerAlign: "center", align: "center", flex: 1 };
const catCol = { field: "numeroCat", headerName: "Número da CAT", description: "Número da CAT", headerAlign: "center", align: "center", flex: 1 };
const nbCol = { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", headerAlign: "center", align: "center", flex: 1 };
const especieCol = { field: "especie", headerName: "Espécie", description: "Espécie", headerAlign: "center", align: "center", flex: 1 };
const processoCol = { field: "protocolo", headerName: "Protocolo", description: "Protocolo", headerAlign: "center", align: "center", flex: 1 };
const deferimentoCol = {
  field: "deferimento",
  headerName: "Decisão do Analista",
  description: "Decisão do Analista",
  headerAlign: "left",
  align: "left",
  renderCell: ({ value }) => (!value ? "Não Analisado" : value?.descricao),
  valueFormatter: ({ value }) => (!value ? "Não Analisado" : value?.descricao),
  flex: 1
};
const rotatividadeCol = {
  field: "taxaRotatividade",
  headerName: "Taxa de Rotatividade Contestada (%)",
  description: "Total de Remunerações Contestado (%)",
  headerAlign: "right",
  align: "right",
  flex: 1,
  valueGetter: ({ value }) => formataPorcento(value)
};
const competenciaCol = {
  field: "competencia",
  headerName: "Competência",
  description: "Competência",
  headerAlign: "center",
  align: "center",
  flex: 1,
  type: "string",
  renderCell: ({ value }) => formataCompetencia(value)
};
const dataHoraEnvioCol = {
  field: "dataTransmissao",
  headerName: "Data/Hora Envio",
  description: "Data/Hora da Transmissão",
  minWidth: 165,
  type: "string",
  valueFormatter: ({ value }) => formataIsoDateTimeBR(value)
};
const protocoloCol = { headerName: "Protocolo", field: "protocolo", minWidth: 182, type: "string", valueGetter: ({ value }) => formataProtocoloContestacao(value) };
const anoVigenciaCol = { field: "anoVigencia", hideSortIcons: true, width: 90, headerName: "Vigência", description: "Ano Vigência" };
const cnpjRaizCol = {
  headerName: "CNPJ Raiz",
  field: "cnpjRaiz",
  description: "CNPJ da Empresa",
  minWidth: 105,
  type: "string",
  valueFormatter: ({ value }) => formataCNPJRaiz(value)
};
const cnpjCol = {
  headerName: "CNPJ",
  field: "cnpj",
  description: "CNPJ do Estabelecimento",
  minWidth: 172,
  type: "string",
  valueFormatter: ({ value }) => formataCNPJ(value)
};
const instanciaCol = { headerName: "Instância", field: "instancia", minWidth: 220, flex: 1, valueGetter: ({ value }) => getInstanciaDescricao({ instancia: value }) };
const situacaoCol = { headerName: "Situação", field: "situacao", minWidth: 260, flex: 1, valueGetter: ({ value }) => getSituacaoDescricao({ situacao: value }) };
const responsavelCol = {
  field: "responsavel",
  hideSortIcons: true,
  filterable: false,
  headerName: "Responsável",
  description: "Responsável",
  flex: 1,
  minWidth: 170,
  valueGetter: ({ value }) => value?.nome || value?.cpf
};

const getCatContestadasColumns = () => [catCol, processoCol, deferimentoCol];
const getNexosTecnicosContestadosColumns = () => [nbCol, especieCol, processoCol, deferimentoCol];
const getBeneficiosContestadosColumns = () => [nbCol, especieCol, processoCol, deferimentoCol];
const getMassasSalariaisContestadasColumns = () => [competenciaCol, processoCol, deferimentoCol];
const getVinculosContestadosColumns = () => [competenciaCol, processoCol, deferimentoCol];
const getTaxaRotatividadeContestadasColumns = () => [anoCol, rotatividadeCol, processoCol, deferimentoCol];

export function getElementosContestadosColumns(tipoElemento) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return getCatContestadasColumns();
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return getNexosTecnicosContestadosColumns();
    case TipoElementoContestacao.BENEFICIO.id:
      return getBeneficiosContestadosColumns();
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return getMassasSalariaisContestadasColumns();
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return getVinculosContestadosColumns();
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return getTaxaRotatividadeContestadasColumns();
    default:
      return [];
  }
}

export const getContestacoesPendentesColumns = () => [dataHoraEnvioCol, protocoloCol, anoVigenciaCol, cnpjRaizCol, cnpjCol, instanciaCol];
export const getContestacoesConcluidasColumns = () => [dataHoraEnvioCol, protocoloCol, anoVigenciaCol, cnpjRaizCol, cnpjCol, instanciaCol, situacaoCol];

export function getSumarioColumns() {
  return [
    {
      field: "tipoElemento",
      headerName: " ",
      description: "Elemento",
      valueGetter: ({ value }) => value?.descricao,
      sortable: false,
      align: "center",
      minWidth: 120
    },
    { field: "deferidos", headerName: "Deferidos", description: "Deferidos", headerAlign: "center", align: "center", sortable: false, flex: 1 },
    {
      field: "deferidosAcidenteTrajeto",
      headerName: "Deferidos Acidente de Trajeto",
      description: "Deferidos por acidente de trajeto",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "deferidosAlteracaoParcial",
      headerName: "Deferidos Alteração Parcial",
      description: "Deferidos com alteração parcial",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "deferidosAlteracaoIntegral",
      headerName: "Deferidos Alteração Integral",
      description: "Deferidos com alteração integral",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    { field: "indeferidos", headerName: "Indeferidos", description: "Indeferidos", sortable: false, headerAlign: "center", align: "center", minWidth: 103, flex: 1 },
    {
      field: "indeferidosMenor",
      headerName: "Indeferidos Menor",
      description: "Indeferidos Menor",
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 103,
      flex: 1
    },
    {
      field: "deferidosMaior",
      headerName: "Deferidos Maior",
      description: "Deferidos Maior",
      valueGetter: ({ value }) => formataNumberEnBr(value),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "indeferidosMaior",
      headerName: "Indeferidos Maior",
      description: "Indeferidos Maior",
      valueGetter: ({ value }) => formataNumberEnBr(value),
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 103,
      flex: 1
    },
    { field: "deferidosMenor", headerName: "Deferidos Menor", description: "Deferidos Menor", sortable: false, headerAlign: "center", align: "center", flex: 1 },
    { field: "naoAnalisados", headerName: "Não Analisados", description: "Não Analisados", sortable: false, headerAlign: "center", align: "center", flex: 1 }
  ];
}

export const getDesistenciaColumns = () => [
  dataHoraEnvioCol,
  anoVigenciaCol,
  protocoloCol,
  cnpjRaizCol,
  cnpjCol,
  { ...responsavelCol, minWidth: 220 },
  instanciaCol,
  { ...situacaoCol, hide: true, flex: 1, minWidth: 360 }
];

export const getImpressaoDespachoColumns = () => [
  {
    field: "dataAlteracao",
    headerName: "Data/Hora última alteração",
    description: "Data/Hora última alteração",
    minWidth: 170,
    type: "string",
    valueFormatter: ({ value }) => formataIsoDateTimeBR(value)
  },
  anoVigenciaCol,
  { ...protocoloCol, hideSortIcons: true, filterable: false },
  cnpjRaizCol,
  cnpjCol,
  responsavelCol,
  { ...situacaoCol, hideSortIcons: true, filterable: false, flex: 1 }
];

export const getAutorizaRecalculoColumns = () => [
  {
    field: "dataAlteracao",
    align: "center",
    headerName: "Data/Hora última alteração",
    description: "Data/Hora última alteração",
    minWidth: 170,
    type: "string",
    valueFormatter: ({ value }) => formataIsoDateTimeBR(value)
  },
  anoVigenciaCol,
  { ...protocoloCol, hideSortIcons: true, filterable: false },
  cnpjRaizCol,
  cnpjCol,
  responsavelCol,
  { field: "versao", hideSortIcons: true, filterable: false, headerName: "Nº. Versão", description: "Nº. Versão", minWidth: 90 }
];

export const getGestaoContestacaoColumns = () => [dataHoraEnvioCol, anoVigenciaCol, protocoloCol, cnpjRaizCol, cnpjCol, { ...instanciaCol, filterable: false }, responsavelCol];

export const getPublicacaoColumns = () => [
  // columns precisa ser funcao pra atualizar DataGrid a cada setRows
  { field: "dataPublicacao", headerName: "Data da publicação", minWidth: 130, type: "string", valueGetter: ({ value }) => formataDateEnBr(value) },
  anoVigenciaCol,
  { field: "instancia", headerName: "Instância", minWidth: 150, filterable: false, flex: 1, renderCell: ({ value }) => getInstanciaDescricao({ instancia: value }) },
  { field: "dataPrazoContestacao", headerName: "Data Limite para Recurso", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value) },
  { field: "quantidadeContestacao", headerName: "Número de Contestações", minWidth: 150 },
  {
    field: "situacaoContestacoes",
    headerName: "Situação das Contestações",
    minWidth: 170,
    hide: true,
    hideSortIcons: true,
    filterable: false,
    valueGetter: ({ value }) => getSituacaoDescricao({ situacao: value })
  },
  { field: "publicada", headerName: "Publicada", minWidth: 125, type: "boolean" }
];

export const getContestacoesPublicacaoColumns = () => [
  { ...anoVigenciaCol, hideSortIcons: true, filterable: false, minWidth: 90, width: 90 },
  { ...instanciaCol, hide: true, filterable: false },
  cnpjRaizCol,
  cnpjCol,
  protocoloCol,
  { field: "homologador", headerName: "Autoridade", minWidth: 101, hide: true, hideSortIcons: true, filterable: false },
  responsavelCol,
  { field: "dataFimEfeitoSuspensivo", headerName: "Efeito Suspensivo Encerrado", valueGetter: ({ value }) => !!value, minWidth: 130, type: "boolean", filterable: false },
  { ...deferimentoCol, minWidth: 150 },
  { ...situacaoCol, filterable: false, minWidth: 150 },
  { field: "erroEmailPublicacao", headerName: "E-mail enviado", valueGetter: ({ value }) => !value, minWidth: 130, type: "boolean", filterable: false }
];

export const TipoSituacaoConsultaDesistencias = {
  NAO_DESISTIDAS: {
    value: [
      TipoSituacaoContestacao.LIBERADA_PARA_ANALISE.value,
      TipoSituacaoContestacao.EM_ANALISE.value,
      TipoSituacaoContestacao.LIBERADA_PARA_AUTORIDADE.value,
      TipoSituacaoContestacao.LIBERADA_PARA_RECALCULO.value
    ],
    label: "Passíveis de Desistência"
  },
  LIBERADA_PARA_ANALISE: {
    value: TipoSituacaoContestacao.LIBERADA_PARA_ANALISE.value,
    label: " >>>" + TipoSituacaoContestacao.LIBERADA_PARA_ANALISE.label
  },
  EM_ANALISE: {
    value: TipoSituacaoContestacao.EM_ANALISE.value,
    label: " >>>" + TipoSituacaoContestacao.EM_ANALISE.label
  },
  LIBERADA_PARA_AUTORIDADE: {
    value: TipoSituacaoContestacao.LIBERADA_PARA_AUTORIDADE.value,
    label: " >>>" + TipoSituacaoContestacao.LIBERADA_PARA_AUTORIDADE.label
  },
  LIBERADA_PARA_RECALCULO: {
    value: TipoSituacaoContestacao.LIBERADA_PARA_RECALCULO.value,
    label: " >>>" + TipoSituacaoContestacao.LIBERADA_PARA_RECALCULO.label
  },
  DESISTIDAS: {
    value: [
      TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_AUTORIDADE.value,
      TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_DOU.value,
      TipoSituacaoContestacao.DESISTENCIA_PUBLICADA.value
    ],
    label: "Desistidas"
  },
  DESISTENCIA_AGUARDANDO_AUTORIDADE: {
    value: TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_AUTORIDADE.value,
    label: " >>>" + TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_AUTORIDADE.label
  },
  DESISTENCIA_AGUARDANDO_DOU: {
    value: TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_DOU.value,
    label: " >>>" + TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_DOU.label
  },
  DESISTENCIA_PUBLICADA: {
    value: TipoSituacaoContestacao.DESISTENCIA_PUBLICADA.value,
    label: " >>>" + TipoSituacaoContestacao.DESISTENCIA_PUBLICADA.label
  }
};

export const TipoSituacaoDespachoContestacao = {
  LIBERADA_PARA_RECALCULO: "Processo liberado para Recálculo do FAP",
  FINALIZADA: "Processo finalizado",
  AGUARDANDO_PUBLICACAO: "Aguardando publicação no DOU",
  PUBLICADA: "Resultado divulgado no DOU"
};

export const getInstanciaPresidenteLogado = (papeis = []) => {
  const isPrimeira = papeis.includes(TipoPapel.PRESIDENTE_JUNTA);
  const isSegunda = papeis.includes(TipoPapel.PRESIDENTE_CAMARA);
  if (isPrimeira && isSegunda) {
    return undefined;
  } else if (isPrimeira) {
    return TipoInstancia.primeira.value;
  } else {
    return TipoInstancia.segunda.value;
  }
};

export const isSituacaoDesistida = (situacaoValue) => ["DESISTIDAS", ...TipoSituacaoConsultaDesistencias.DESISTIDAS.value].includes(situacaoValue);
export const isDataPrazoContestacaoRequired = (instancia, isDesistencia) => isPrimeiraInstancia({ instancia: { codigo: instancia } }) && !isDesistencia;
