import { convertToSelectOptions, createField, FieldType } from "components/Form";

export const createModel = (fields = { vigencias: [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(fields.vigencias),
    value: fields.vigencias[0],
    required: false,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  })
});

export const createVigenciaModel = (fields = {}) => ({
  anoDecretoRAT: createField("anoVigencia", "Ano Decreto RAT:", {
    type: FieldType.NUM_MASK,
    mask: "####",
    value: fields.anoDecretoRAT,
    required: false
  }),

  anoReferenciaExpectativaVida: createField("anoReferenciaExpectativaVida", "Ano expectativa de vida:", {
    type: FieldType.NUM_MASK,
    mask: "####",
    value: fields.anoReferenciaExpectativaVida,
    required: false
  }),
  anoTaxaMortalidade: createField("anoTaxaMortalidade", "Ano Taxa Mortalidade:", {
    type: FieldType.NUM_MASK,
    mask: "####",
    value: fields.anoTaxaMortalidade,
    required: false
  }),
  anoVigencia: createField("anoVigencia", "Ano vigência:", {
    readMode: true,
    value: fields.anoVigencia,
    required: false
  }),
  consultaCompetencia: createField("consultaCompetencia", "Início consulta FAP:", {
    type: FieldType.DATE,
    value: fields.consultaCompetencia,
    required: false
  }),
  dataFimContestacao: createField("dataFimContestacao", "Fim contestação 1ª instância:", {
    type: FieldType.DATE,
    value: fields.dataFimContestacao,
    required: false
  }),
  dataInicioContestacao: createField("dataInicioContestacao", "Início contestação 1ª instância:", {
    type: FieldType.DATE,
    value: fields.dataInicioContestacao,
    required: false
  }),
  decretoCnae: createField("decretoCnae", "Decreto CNAE:", {
    type: FieldType.NUMERIC,
    value: fields.decretoCnae,
    required: false
  }),
  extracaoExpectativaVida: createField("extracaoExpectativaVida", "Extração expectativa de vida:", {
    type: FieldType.DATE,
    value: fields.extracaoExpectativaVida,
    required: false
  }),
  inicioPeriodoBase: createField("inicioPeriodoBase", "Início período-base:", {
    type: FieldType.DATE,
    value: fields.inicioPeriodoBase,
    required: true
  }),
  fimPeriodoBase: createField("fimPeriodoBase", "Fim período-base:", {
    type: FieldType.DATE,
    value: fields.fimPeriodoBase,
    required: true
  }),
  inicioExtracaoBeneficios: createField("inicioExtracaoBeneficios", "Extração benefícios:", {
    type: FieldType.DATE,
    value: fields.inicioExtracaoBeneficios,
    required: false
  }),
  inicioExtracaoCat: createField("inicioExtracaoCat", "Extração CAT:", {
    type: FieldType.DATE,
    value: fields.inicioExtracaoCat,
    required: false
  }),
  inicioExtracaoCnis: createField("inicioExtracaoCnis", "Extração CNIS:", {
    type: FieldType.DATE,
    value: fields.inicioExtracaoCnis,
    required: false
  }),
  inicioExtracaoEsocial: createField("inicioExtracaoEsocial", "Extração eSocial:", {
    type: FieldType.DATE,
    value: fields.inicioExtracaoEsocial,
    required: false
  }),
  inicioExtracaoEstabelecimentos: createField("inicioExtracaoEstabelecimentos", "Extração estabelecimentos:", {
    type: FieldType.DATE,
    value: fields.inicioExtracaoEstabelecimentos,
    required: false
  }),
  inicioExtracaoGfip: createField("inicioExtracaoGfip", "Extração GFIP:", {
    type: FieldType.DATE,
    value: fields.inicioExtracaoGfip,
    required: false
  }),
  portariaFap: createField("portariaFap", "Portaria FAP:", {
    type: FieldType.TEXT,
    value: fields.portariaFap,
    required: false
  }),
  situacao: createField("situacao", "Situação:", {
    type: FieldType.TEXT,
    value: fields.situacao,
    required: false
  }),
  versaoCnae: createField("versaoCnae", "Versão CNAE:", {
    type: FieldType.TEXT,
    value: fields.versaoCnae,
    required: false
  })
});
