import { getApi, handleDownload } from "../ApiManager";

const contextoVigencia = "/vigencias";
const contexto = "/empresa";

const EmpresasApi = {
  obterEmpresas: (cnpj, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpj}`),
  downloadRelatorioPdfGfips: (cnpj, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpj}/imprimir-declaracoes`).then(handleDownload),
  obterEstabelecimentos: (cnpjRaiz, anoVigencia, params = {}) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpjRaiz}/estabelecimentos`, { params }),
  obterCalculos: (cnpj, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpj}/calculos`),
  obterHistorico: (cnpj, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpj}/historico`),
  obterGfips: (cnpj, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpj}/declaracoes`),
  obterContestacoes: (cnpjRaiz, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpjRaiz}/contestacoes`),
  obterMensagens: async (cnpj, anoVigencia) => getApi().get(`${contextoVigencia}/${anoVigencia}${contexto}/${cnpj}/mensagens`)
};

export default EmpresasApi;
