import React from "react";
import { Grid } from "@material-ui/core";

import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import BrButton from "@govbr/react-components/lib/BrButton";



const ModalMessage = ({ show, close, message="Operação realizada com sucesso!" }) => {


  return (
    <BrModal style={{padding:1}} id="modal2" visible={show} closable={true} onClose={() => close(false)}>

      <div style={{ height: 120, padding:20, textAlign: "center", color:"white" }} 
        className="bg-success" >
        <span className="fa fa-check-circle fa-5x"></span>
      </div>
      
      <div style={{ padding:50, textAlign: "center" }} 
        className="text-up-01" >
        {message}
      </div>

      <BrModalFooter>

        <Grid container style={{padding:10}}>
          <Grid item sm={12} className="text-center"  >
            <BrButton onClick={() => close()}>Fechar</BrButton>
          </Grid>
        </Grid>

      </BrModalFooter>
    </BrModal>
  )
};

export default ModalMessage;

