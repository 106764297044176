import { getApi, handleDownload } from "../ApiManager";

const contexto = "/resultados-julgamentos";
const ctxVigencia = "/vigencias";
const ctxEmpresa = "/empresa";

const JulgamentoApi = {
  obterPorId: (cnpj, anoVigencia, id) => getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${id}`),
  obterElementos: (cnpjRaiz, anoVigencia, id, elemento) => getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${id}/${elemento}`),
  downloadRelatorioPdfResultadoJulgamento: (id, cnpj, anoVigencia) =>
    getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${id}/imprimir-julgamentos`).then(handleDownload),
  obter: (cnpjRaiz, anoVigencia) => getApi().get(`${contexto}`, { params: { anoVigencia, cnpjRaiz } }),
  criar: (body) => getApi().post(`${contexto}`, body),
  obterPorIdAdm: (id) => getApi().get(`${contexto}/${id}`),
  obterSumarioPorId: (id) => getApi().get(`${contexto}/${id}/sumario`),
  alterar: (id, body) => getApi().put(`${contexto}/${id}`, body),
  remover: (id) => getApi().delete(`${contexto}/${id}`),
  liberarRecalculo: (id) => getApi().put(`${contexto}/${id}/liberar-recalculo`),
  reabrir: (id) => getApi().put(`${contexto}/${id}/reabrir`),

  obterElementosNaoRevisados: (id, elemento) => getApi().get(`${contexto}/${id}/${elemento}?contestados=false`),
  obterElementosRevisados: (id, elemento) => getApi().get(`${contexto}/${id}/${elemento}`),
  obterElementoRevisadoPorId: (id, elemento, idElemento) => getApi().get(`${contexto}/${id}/${elemento}/${idElemento}`),
  criarElementoRevisado: (id, elemento, body) => getApi().post(`${contexto}/${id}/${elemento}`, body),
  removerElementoRevisado: (id, elemento, idElemento) => getApi().delete(`${contexto}/${id}/${elemento}/${idElemento}`)
};

export default JulgamentoApi;
