import React from "react";
import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { LabelValue } from "components/LabelValue";

import useEscKey from "hooks/useEscKey";

const labels = {
  mediaVinculos: "Número Médio de Vínculos",
  valorTotalBeneficiosPagos: "Valor Total de Benefícios Pagos",

  indiceFrequencia: "Índice de Frequência",
  indiceGravidade: "Índice de Gravidade",
  indiceCusto: "Índice de Custo",
  taxaMediaRotatividade: "Taxa Média de Rotatividade",

  ordemFrequencia: "Número de Ordem de Frequência",
  ordemGravidade: "Número de Ordem de Gravidade",
  ordemCusto: "Número de Ordem de Custo",

  percentilCusto: "Percentil de Ordem de Custo",
  percentilFrequencia: "Percentil de Ordem de Frequência",
  percentilGravidade: "Percentil de Ordem de Gravidade",

  indiceCompostoDetalhamento: "Índice Composto",
  fapOriginalDetalhamento: "FAP Original"
};

const ModalDetalheIndicadores = ({ show, onClose, dados = [] }) => {
  useEscKey(() => onClose());

  return show ? (
    <BrModal id="modal-detalhe-indicadores" visible={true} closable={true} onClose={onClose}>
      <span className="text-up-02 text-bold">Detalhamento dos valores</span>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <span className={"text-base"}>
            Os cálculos são realizados com a utilização de até 38 casas decimais. Para fins de apresentação dos valores na tela de consulta, os mesmos são arredondados na quarta
            casa decimal, excetuado o IC e o FAP que têm o seu valor truncado.
          </span>
        </Grid>
        {Object.keys(labels).map((l) => {
          return (
            <LabelValue
              showNull
              key={`label-${l}`}
              label={labels[l]}
              value={dados[l]}
              dataType={l === "valorTotalBeneficiosPagos" ? "currency" : "indexFull"}
              orientation={"inlineLabel"}
            />
          );
        })}
      </Grid>
      <BrModalFooter>
        <BrButton onClick={onClose}>Fechar</BrButton>
      </BrModalFooter>
    </BrModal>
  ) : null;
};

export default ModalDetalheIndicadores;
