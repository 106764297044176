import React from "react";
import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

const FullScreenError = ({ title = "Ocorreu um erro inesperado!", children, onCloseTitle = "Voltar para o Início", onClose }) => {
  const _onClose = (event) => {
    if (!!onClose) {
      onClose(event);
    } else {
      window.location.href = window.location.origin;
    }
  };

  return (
    <BrModal medium id="full-error-modal" title={title} visible={true} closable={true} onClose={_onClose} style={{ fontSize: "inherit", backgroundColor: "#ffcd07" }}>
      <Grid container>
        <Grid item sm={12}>
          <Divider />
        </Grid>

        <Grid item sm={12} className="text-up-03">
          {children}
        </Grid>
      </Grid>
      <BrModalFooter>
        <BrButton onClick={_onClose}>{onCloseTitle}</BrButton>
      </BrModalFooter>
    </BrModal>
  );
};

export default FullScreenError;
