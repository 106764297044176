import { formataBoleano, formataCNPJ, formataCompetencia, formataCPF, formataDateEnBr, formataIndicador, formataPorcento, formataReal } from "utils/StrUtils";
import { createField, FieldType, isUndefined } from "components/Form";
import { LabelValue } from "components/LabelValue";
import { TipoElementoContestacao } from "utils/TipoUtils";

export const Actions = {
  EDITAR_ELEMENTO: "EDITAR_ELEMENTO",
  NOVO_ELEMENTO: "NOVO_ELEMENTO",
  JUSTIFICAR_ELEMENTO: "JUSTIFICAR_ELEMENTO",
  LISTAR_CONTESTADOS: "LISTAR_CONTESTADOS"
};

export function contestacaoModel(fields = {}, tipoElemento) {
  let model = {
    id: createField("id", "id:", {
      type: FieldType.NUMERIC,
      value: fields.id,
      required: false
    }),
    justificativa: createField("justificativa", "Justificativa:", {
      type: FieldType.TEXTAREA,
      maxLength: 5000,
      value: fields.justificativa,
      required: true
    })
  };

  if (tipoElemento === TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id) {
    model.vinculosSolicitado = createField("vinculosSolicitado", "Quantidade Vínculos considerado correto pelo Estabelecimento:", {
      type: FieldType.NUMERIC,
      value: fields.vinculosSolicitado,
      required: true
    });
  }
  if (tipoElemento === TipoElementoContestacao.MASSA_SALARIAL.id) {
    model.valorMassaSalarialSolicitado = createField("valorMassaSalarialSolicitado", "Total de Remunerações considerado correto pelo Estabelecimento (R$):", {
      type: FieldType.CURRENCY,
      value: fields.valorMassaSalarialSolicitado,
      required: true
    });
  }

  if (tipoElemento === TipoElementoContestacao.ROTATIVIDADE.id) {
    model = {
      ...model,
      numeroInicialVinculosSolicitado: createField("numeroInicialVinculosSolicitado", "Número Inicial de Vínculos considerado correto pelo Estabelecimento:", {
        type: FieldType.NUMERIC,
        value: fields.numeroInicialVinculosSolicitado,
        required: true
      }),
      admissoesSolicitado: createField("admissoesSolicitado", "Número de Admissões considerado correto pelo Estabelecimento:", {
        type: FieldType.NUMERIC,
        value: fields.admissoesSolicitado,
        required: true
      }),
      rescisoesSolicitado: createField("rescisoesSolicitado", "Número de Rescisões considerado correto pelo Estabelecimento:", {
        type: FieldType.NUMERIC,
        value: fields.rescisoesSolicitado,
        required: true
      })
    };
  }
  return model;
}

export function getElementoTaxaRotarividade(obj) {
  return obj
    ? {
      id: obj.id,
        ano: { label: "Ano", value: obj.ano },
        taxaRotatividade: { label: "Taxa de Rotatividade (%)", value: obj.taxaRotatividade ? formataPorcento(obj.taxaRotatividade) : null },
        taxaRotatividadeSolicitado: {
          label: "Taxa de Rotatividade Solicitado (%)",
          value: obj.taxaRotatividadeSolicitado ? formataPorcento(obj.taxaRotatividadeSolicitado) : null
        },
        taxaRotatividadeAlterado: { label: "Taxa de Rotatividade Alterado (%)", value: obj.taxaRotatividadeAlterado ? formataPorcento(obj.taxaRotatividadeAlterado) : null },
        numeroInicialVinculosSolicitado: { label: "Número de Vínculos no Início do Ano Solicitado", value: obj.numeroInicialVinculosSolicitado },
        numeroInicialVinculosAlterado: { label: "Número de Vínculos no Início do Ano Alterado", value: obj.numeroInicialVinculosAlterado },
        rescisoes: { label: "Rescisões", value: obj.rescisoes },
        rescisoesSolicitado: { label: "Rescisões Solicitado", value: obj.rescisoesSolicitado },
        rescisoesAlterado: { label: "Rescisões Alterado", value: obj.rescisoesAlterado },
        admissoes: { label: "Admissões", value: obj.admissoes },
        admissoesSolicitado: { label: "Admissões Solicitado", value: obj.admissoesSolicitado },
        admissoesAlterado: { label: "Admissões Alterado", value: obj.admissoesAlterado },
        numeroInicialVinculos: { label: "Número de Vínculos no Início do Ano", value: obj.numeroInicialVinculos },
        estado: { label: "Estado do Insumo", value: obj.estado },
        justificativa: { label: "Justificativa", value: obj.justificativa },
        deferimento: { label: "Status", value: obj?.deferimento?.descricao },
        parecer: { label: "Parecer", value: obj.parecer }
      }
    : null;
}

export function getTaxaRotatividadeNaoContestadasColumns(action) {
  const columns = [
    { field: "ano", headerName: "Ano", description: "Ano", flex: 1 },
    { field: "taxaRotatividade", headerName: "Taxa de Rotatividade (%)", description: "Taxa de Rotatividade Contestada (%)", flex: 1, valueGetter: ({ value }) => formataPorcento(value)  },
    { field: "rescisoes", headerName: "Rescisões", description: "Rescisões", flex: 1 },
    { field: "admissoes", headerName: "Admissões", description: "Admissões", flex: 1 },
    { field: "numeroInicialVinculos", headerName: "Número de Vínculos no Início do Ano", description: "Número de Vínculos no Início do Ano", flex: 1 }
  ];
  // columns.push(getContestarAction(action));
  return columns;
}

export function getTaxaRotatividadeContestadasColumns() {
  return [
    { field: "ano", headerName: "Ano", description: "Ano", flex: 1 },
    {
      field: "taxaRotatividadeSolicitado",
      headerName: "Taxa de Rotatividade considerada correta pelo Estabelecimento (%)",
      description: "Taxa de Rotatividade considerado correto pelo Estabelecimento (%)",
      flex: 1,
      valueGetter: ({ value }) => formataPorcento(value)
    },
    { field: "justificativa", headerName: "Justificativa", description: "Justificativa", flex: 1 }
  ];
}

export function getElementoVinculo(obj) {
  return obj ? {
    id: obj.id,
    competencia: { label: "Competência", value: obj.competencia ? formataCompetencia(obj.competencia) : null },
    estado: { label: "Estado do Insumo", value: obj.estado },
    vinculos: { label: "Quantidade de Vinculos", value: obj.vinculos },
    justificativa:{ label: "Justificativa", value: obj.justificativa },
    parecer:{ label: "Parecer", value: obj.parecer },
    deferimento: { label: "Status", value: obj?.deferimento?.descricao },
    vinculosSolicitado: { label: "Quantidade de Vinculos Solicitado", value: obj.vinculosSolicitado },
    vinculosAlterado: { label: "Quantidade de Vinculos Alterado", value: obj.vinculosAlterado },
  } : null;
}

export function getVinculosNaoContestadosColumns(action) {
  const columns = [
    { field: "competencia", headerName: "Competência", description: "Competência", flex: 1, valueGetter: ({ value }) => formataCompetencia(value) },
    { field: "vinculos", headerName: "Quantidade de Vínculos", description: "Quantidade de Vínculos", flex: 1 }
  ];
  // columns.push(getContestarAction(action));
  return columns;
}

export function getVinculosContestadosColumns() {
  return [
    { field: "competencia", headerName: "Competência", description: "Competência", flex: 1, valueGetter: ({ value }) => formataCompetencia(value) },
    {
      field: "vinculosSolicitado",
      headerName: "Quantidade de Vínculos considerada correta pelo Estabelecimento",
      description: "Quantidade de Vínculos considerada correta pelo Estabelecimento",
      flex: 1
    },
    { field: "justificativa", headerName: "Justificativa", description: "Justificativa", flex: 1 }
  ];
}

export function getElementoNexoTecnico(obj) {
  return obj ? {
    id: obj.id,
    cnpj: { value: obj.cnpj ? formataCNPJ(obj.cnpj) : null , label: "CNPJ do Empregador" },
    nit: { value: obj.nit, label: "NIT do Empregado" },
    dataNascimento: { value: obj.dataNascimento ? formataDateEnBr(obj.dataNascimento) : null, label: "Data de Nascimento do Empregado" },
    nb: { value: obj.nb, label: "Número do Benefício" },
    especie: { value: obj.especie, label: "Espécie de Benefício" },
    estado: { value: obj.estado, label: "Estado do Insumo" },
    justificativa:{ label: "Justificativa", value: obj.justificativa },
    parecer:{ label: "Parecer", value: obj.parecer },
    deferimento: { label: "Status", value: obj?.deferimento?.descricao },
  } : null;
}

export function getNexosTecnicosContestadosColumns() {
  return [
    { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", flex: 1 },
    { field: "especie", headerName: "Espécie", description: "Espécie", flex: 1 },
    { field: "justificativa", headerName: "Justificativa", description: "Justificativa", flex: 1 }
  ];
}

export function getNexosTecnicosNaoContestadosColumns(action) {
  const columns = [
    { field: "especie", headerName: "Espécie", description: "Espécie", flex: 1 },
    { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", flex: 1 },
    { field: "cnpj", headerName: " CNPJ do Empregador", description: "CNPJ do Empregador", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "nit", headerName: "NIT do Empregado", description: "NIT do Empregado", flex: 1 },
    { field: "dataNascimento", headerName: "Data de Nascimento do Empregado", description: "Data de Nascimento do Empregado", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) }
  ];
  // columns.push(getContestarAction(action));
  return columns;
}

export function getBeneficiosContestadosColumns() {
  return [
    { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", flex: 1 },
    { field: "justificativa", headerName: "Justificativa", description: "Justificativa", flex: 1 }
  ];
}

export function getBeneficiosNaoContestadosColumns(action) {
  const columns = [
    { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", hideSortIcons: true, flex: 1 },
    { field: "especie", headerName: "Espécie de Benefício", description: "Espécie de Benefício", hideSortIcons: true, flex: 1 },
    { field: "valorMensal", headerName: "Renda Mensal Inicial(RMI)", description: "Renda Mensal Inicial (RMI)", hideSortIcons: true, flex: 1, valueGetter: ({ value }) => formataReal(value) },
    { field: "dataDespacho", headerName: "Data de Despacho Benefício (DDB)", description: "Data de Despacho do Benefício (DDB)", hideSortIcons: true, flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
    { field: "cnpj", headerName: " CNPJ do Empregador", description: "CNPJ do Empregador", hideSortIcons: true, flex: 1, valueGetter: ({ value }) => formataCNPJ(value)},
    { field: "nit", headerName: "NIT do Empregado", description: "NIT do Empregado", hideSortIcons: true, flex: 1 },
    { field: "dataInicio", headerName: "Data de Início Benefício (DIB)", description: "Data de Início do Benefício (DIB)", hideSortIcons: true, flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
    { field: "dataCessacao", headerName: "Data de Cessação Benefício (DCB)", description: "Data de Cessação do Benefício (DCB)", hideSortIcons: true, flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
  ];
  // columns.push(getContestarAction(action));
  return columns;
}

export function getElementoBeneficio(obj) {
  
  return obj ? {
    id: obj.id,
    estado: { value: obj.estado, label: "Estado do Insumo" },
    nb: { value: obj.nb, label: "Número do Benefício" },
    especie: { value: obj.especie, label: "Espécie de Benefício" },
    valorMensal: { value: obj.valorMensal ? formataReal(obj.valorMensal) : null, label: "Renda Mensal Inicial (RMI)" },
    dataDespacho: { value: obj.dataDespacho ? formataDateEnBr(obj.dataDespacho) : null, label: "Data de Despacho do Benefício (DDB)" },
    dataInicioContabilizacao: { value: obj.dataInicioContabilizacao ? formataDateEnBr(obj.dataInicioContabilizacao) : null, label: "Data Inicial de Contabilização (fixada ou real)" },
    dataFimContabilizacao: { value: obj.dataFimContabilizacao ? formataDateEnBr(obj.dataFimContabilizacao) : null, label: "Data Final de Contabilização (fixada, real ou projetada)" },
    mesesContabilizacao: { value: obj.mesesContabilizacao ? formataIndicador(obj.mesesContabilizacao) : null, label: "Duração/ Expectativa de Tempo (meses)" },
    totalPago: { value: obj.totalPago ? formataReal(obj.totalPago) : null, label: "Total Pago/Projeção" },
    dataNascimento: { value: obj.dataNascimento ? formataDateEnBr(obj.dataNascimento) : null, label: "Data Nascimento Beneficiário" },
    cnpj: { value: obj.cnpj ? formataCNPJ(obj.cnpj) : null , label: "CNPJ do Empregador" },
    nit: { value: obj.nit, label: "NIT do Empregado" },
    cpf: { value: obj.cpf ? formataCPF(obj.cpf) : null, label: "CPF do Beneficiário" },
    dataInicio: { value: obj.dataInicio ? formataDateEnBr(obj.dataInicio) : null, label: "Data de Início do Benefício (DIB)" },
    dataCessacao: { value: obj.dataCessacao ? formataDateEnBr(obj.dataCessacao) : null , label: "Data de Cessação do Benefício (DCB)" },
    justificativa:{ label: "Justificativa", value: obj.justificativa },
    parecer:{ label: "Parecer", value: obj.parecer },
    deferimento: { label: "Status", value: obj?.deferimento?.descricao },
    valorMensalAlterado: { value: obj.valorMensalAlterado ? formataReal(obj.valorMensalAlterado) : null, label: "Renda Mensal Inicial Alterada(RMI)(R$)" },
    dataInicioAlterado: { value: obj.dataInicioAlterado ? formataDateEnBr(obj.dataInicioAlterado) : null , label: "Data de Início do Benefício Alterada(DIB)" },
    dataCessacaoAlterado: { value: obj.dataCessacaoAlterado ? formataDateEnBr(obj.dataCessacaoAlterado) : null, label: "Data de Cessação do Benefício Alterada(DCB)" },
    acidenteTrajetoAlterado: { value: !isUndefined(obj.acidenteTrajetoAlterado) ? formataBoleano(obj.acidenteTrajetoAlterado) : null, label: "Óbito por Acidente de Trajeto" },
  } : null;
}

export function getCatContestadasColumns() {
  return [
    { field: "numeroCat", headerName: "Número da CAT", description: "Número da CAT", flex: 1 },
    { field: "justificativa", headerName: "Justificativa", description: "Justificativa", flex: 1 }
  ];
}

export function getCatNaoContestadasColumns(action) {
  const columns = [
    { field: "cnpjOriginal", headerName: "CNPJ do Empregador constante na CAT", description: "CNPJ do Empregador constante na CAT", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "nit", headerName: "NIT do Empregado", description: "NIT do Empregado", flex: 1 },
    { field: "dataNascimento", headerName: "Data de Nascimento do Empregado", description: "Data de Nascimento do Empregado", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
    { field: "dataAcidente", headerName: " Data do Acidente de Trabalho", description: " Data do Acidente de Trabalho", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
    { field: "dataCadastroCat", headerName: "Data de Cadastramento da CAT", description: "Data de Cadastramento da CAT", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
    { field: "numeroCat", headerName: "Número da CAT", description: "Número da CAT", flex: 1 },
  ];
  // columns.push(getContestarAction(action));
  return columns;
}

export function getElementoCat(obj) {
  return obj ? {
    id: obj.id,
    estado: { label: "Estado do Insumo", value: obj.estado },
    cnpjOriginal: { label: "CNPJ do Empregador constante na CAT", value: obj.cnpjOriginal ? formataCNPJ(obj.cnpjOriginal) : null },
    dataNascimento: { label: " Data de Nascimento do Empregado", value: obj.dataNascimento ? formataDateEnBr(obj.dataNascimento) : null },
    dataAcidente: { label: "Data do Acidente de Trabalho", value: obj.dataAcidente ? formataDateEnBr(obj.dataAcidente) : null },
    dataCadastroCat: { label: "Data de Cadastramento da CAT", value: obj.dataCadastroCat ? formataDateEnBr(obj.dataCadastroCat) : null },
    numeroCat: { label: "Número da CAT", value: obj.numeroCat },
    justificativa:{ label: "Justificativa", value: obj.justificativa },
    parecer:{ label: "Parecer", value: obj.parecer },
    deferimento: { label: "Status", value: obj?.deferimento?.descricao },
  }: null;
}

export function getMassasSalariaisContestadasColumns() {
  return [
    { field: "competencia", headerName: "Competência", description: "Competência", flex: 1, type: 'string',  valueFormatter:({ value }) => formataCompetencia(value),  valueGetter: ({ value }) => (value) },
    { field: "valorMassaSalarialSolicitado", 
      headerName: "Total de Remunerações Contestado (R$)", 
      description: "Total de Remunerações Contestado (R$)", 
      flex: 1, valueGetter: ({ value }) => formataReal(value) },
    {
      field: "valorMassaSalarialSolicitado",
      headerName: "Total de Remunerações considerado correto pelo Estabelecimento (R$)",
      description: "Total de Remunerações considerado correto pelo Estabelecimento (R$)",
      flex: 1, valueGetter: ({ value }) => formataReal(value)
    },
    { field: "justificativa", headerName: "Justificativa", description: "Justificativa", flex: 1 }
  ];
}

export function getMassasSalariaisNaoContestadasColumns(action) {
  const columns = [
    { field: "competencia", headerName: "Competência", description: "Competência", flex: 1, type: 'string', valueFormatter:({ value }) => formataCompetencia(value),  valueGetter: ({ value }) => (value)},
    { field: "valorMassaSalarial", headerName: "Total de Remunerações Contestado (R$)", description: "Total de Remunerações Contestado (R$)", flex: 1, valueGetter: ({ value }) => formataReal(value) }
  ];
  // columns.push(getContestarAction(action));
  return columns;
}

export function getElementoMassa(obj) {
  return obj ?{
    id: obj.id,
    competencia: { label: "Competência", value: obj.competencia ? formataCompetencia(obj.competencia) : null },
    estado: { label: "Estado do Insumo", value: obj.estado },
    valorMassaSalarial: { label: "Total de Remunerações (R$)", value: obj.valorMassaSalarial ? formataReal(obj.valorMassaSalarial) : null },
    justificativa:{ label: "Justificativa", value: obj.justificativa },
    parecer:{ label: "Parecer", value: obj.parecer },
    deferimento: { label: "Status", value: obj?.deferimento?.descricao },
    valorMassaSalarialSolicitado: { label: "Total de Remunerações Solicitado (R$)", value: obj.valorMassaSalarialSolicitado ? formataReal(obj.valorMassaSalarialSolicitado) : null },
    valorMassaSalarialAlterado: { label: "Total de Remunerações Alterado (R$)", value: obj.valorMassaSalarialAlterado ? formataReal(obj.valorMassaSalarialAlterado) : null }    
  }:null;
}

export function getElementoComponent(e, key) {
  return (
    (e[key]?.value || 0 === e[key]?.value) && (
      <LabelValue
        key={`elemento-${key}`}
        orientation={key === "justificativa" || key === "parecer" ? "scrollTextLabel" : "inlineLabel"}
        label={e[key].label}
        value={e[key].value}
      />
    )
  );
}

export const mapRowId = (row, id) => ({ id, ...row });