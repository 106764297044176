import React from "react";
import { Grid } from "@material-ui/core";
import BrModal from "@govbr/react-components/lib/BrModal";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";

import useLockBodyScroll from "hooks/useLockBodyScroll";
import useEscKey from "hooks/useEscKey";

import { createParecerModel } from "../model";

const ParecerForm = ({ parecer, vigencias, setLoading, onClose, onSubmit }) => {
  useEscKey(() => onClose());
  useLockBodyScroll();

  const [fields, setFields] = React.useState(createParecerModel(parecer, vigencias));
  const [formError, setFormError] = React.useState();

  function _setLoading(show) {
    setLoading(show);
    show && setFormError([]);
  }

  function _handleChange(field) {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      const { idParecer, ...params } = requestBody;
      const res = await onSubmit(idParecer, params);
      if (!!res.erros) {
        setFormError(res.erros);
      } else {
        onClose(res);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  return (
    <BrModal style={{ paddingTop: 0 }} visible={true}>
      {!!fields.idParecer.value && <h6>Alterar Parecer</h6>}
      {!fields.idParecer.value && <h6>Novo Parecer</h6>}
      <Grid container>
        <Form onSubmit={_handleSubmit} onCancel={() => onClose()} cancelTitle={"Cancelar"} submitTitle={"Salvar"} errors={formError}>
          <Grid item sm={8}>
            <InputGeneric field={fields.titulo} onChange={_handleChange} />
          </Grid>
          <Grid item sm={5}>
            <InputGeneric field={fields.tipoElemento} onChange={_handleChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
          </Grid>
          <Grid item sm={12}>
            <InputGeneric field={fields.parecer} onChange={_handleChange} />
          </Grid>
        </Form>
      </Grid>
    </BrModal>
  );
};

export default ParecerForm;
