import React from "react";

import CustomDataGrid from "components/CustomDataGrid";
import ModalConfirm from "components/ModalConfirm";
import ContestacoesApi from "services/api/ContestacoesApi";
import { formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";

const ContestacoesConcluidasDataGrid = ({ columns, rows, noRowsLabel, onClick, pageSize, setLoading, setFormErrors, ...props }) => {
  const [row, setRow] = React.useState();

  const reiniciarAction = React.useMemo(
    () => ({
      title: "Reiniciar Análise",
      icon: "text-info fa fa-redo",
      action: (selectedRow) => setRow(selectedRow)
    }),
    []
  );

  const downloadAction = React.useMemo(
    () => ({
      title: "Baixar Relátorio",
      icon: "text-info fa fa-file-pdf",
      action: (selectedRow) => _generateReportContestacao(selectedRow)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function _generateReportContestacao(contestacao) {
    setLoading(true);
    const { cnpj, cnpjRaiz, id, anoVigencia } = contestacao;
    const cnpjConsulta = cnpj ? formataCNPJ14Digitos(cnpj) : formataCNPJ8Digitos(cnpjRaiz);
    const res = await ContestacoesApi.downloadRelatorioPdfContestacao(id, cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormErrors(res.erros);
    }
    setLoading(false);
  }

  const _handleConfirmModal = async () => {
    await onClick(row, rows);
    setRow(null);
  };

  return (
    <React.Fragment>
      <CustomDataGrid actions={[reiniciarAction, downloadAction]} rows={rows} columns={columns} noRowsLabel={noRowsLabel} pageSize={pageSize} />

      {!!row && (
        <ModalConfirm show={true} message={"Realmente deseja reiniciar a situação da análise da contestação?"} onConfirm={_handleConfirmModal} cancel={() => setRow(null)} />
      )}
    </React.Fragment>
  );
};

export default ContestacoesConcluidasDataGrid;
