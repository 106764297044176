import React from "react";
import { Grid, Link } from "@material-ui/core";
import classnames from "classnames";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";

import { isUndefined } from "components/Form";
import CustomCard from "components/CustomCard";
import Loading from "components/Loading";

import { formataCNPJ, formataCNPJRaiz } from "utils/StrUtils";

import { TipoAcaoContestacao } from "./ContestacaoUtils";

import { Secao1Card } from "./components/Secao1Card";
import { Secao2Card } from "./components/Secao2Card";
import { Secao3Card } from "./components/Secao3Card";

const Cards = {
  INICIO: "INICIO",
  ELEMENTOS: "ELEMENTOS",
  REVISAO: "REVISAO"
};

export const Contestacao = ({userData, ...props}) => {
  if (!props?.location?.state?.contestacao || !TipoAcaoContestacao[props?.location?.state?.acao]) {
    props.history.replace("/404-requisicao-invalida");
  }

  const contestacaoParam = props?.location?.state?.contestacao || {};
  const vigenciaParam = props?.location?.state?.vigencia || {};
  
  const [loading, setLoading] = React.useState(false);
  const [card, setCard] = React.useState(Cards.INICIO);
  
  const cnpjFormatado = isUndefined(contestacaoParam.cnpj) ? formataCNPJRaiz(contestacaoParam.cnpjRaiz) : formataCNPJ(contestacaoParam.cnpj);

  const _redirectBackToContestacoes = React.useCallback(() => {
    props?.history.replace("/contestacoes-eletronicas", { ...props?.location?.state });
  }, [props.history, props.location]);

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
      <Grid item sm={12}>
          <CustomCard title={`Contestação dos elementos do cálculo do FAP  ${vigenciaParam.anoVigencia} do estabelecimento ${cnpjFormatado}`}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <ol className={"text-up-01 text-info"}>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.INICIO)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.INICIO })}>Introdução</span>
                    </Link>
                  </li>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.ELEMENTOS)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.ELEMENTOS })}>Elementos a Contestar</span>
                    </Link>
                  </li>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.REVISAO)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.REVISAO })}>Revisar e Transmitir Contestação</span>
                    </Link>
                  </li>
                </ol>
            </Grid>
              <Grid item sm={8}>
                <BrMessage warning>
                  Atenção! Após contestar os elementos do cálculo que julgar necessários, presentes no item 2 do menu, realize a transmissão da contestação no item 3, para então
                  concluir o processo de contestação. Realizada a transmissão, para a realização de eventuais ajustes, o processo poderá ser reaberto clicando no botão "Reabrir".
                </BrMessage>
            </Grid>
              <Grid item sm={4} style={{ marginLeft: 30 }}>
                <BrButton small secondary onClick={_redirectBackToContestacoes}>
                  Voltar para consulta de contestações
                    </BrButton>
                  </Grid>
                        </Grid>
          </CustomCard>
                        </Grid>
                        
                        <Grid item sm={12}>
          {card === Cards.INICIO && <Secao1Card contestacaoParam={contestacaoParam} vigenciaParam={vigenciaParam} />}
                        
          {card === Cards.ELEMENTOS && <Secao2Card contestacaoParam={contestacaoParam} setLoading={setLoading} />}

          {card === Cards.REVISAO && <Secao3Card contestacaoParam={contestacaoParam} userData={userData} _redirectBackToContestacoes={_redirectBackToContestacoes} />}
                        </Grid>
                      </Grid>
    </React.Fragment>
  );
};
