import { formataCnae, formataCNPJ, formataCNPJRaiz, formataCompetencia, formataDateEnBr, formataIndicador, formataPorcento, formataReal } from "utils/StrUtils";

export const TipoElementos = {
  CATS: { value: "cats", label: "Comunicação de Acidente de Trabalho - CAT com Óbito" },
  BENEFICIOS: { value: "beneficios", label: "Benefícios" },
  NEXOS_TECNICOS: { value: "nexos-tecnicos", label: "Nexo Técnico Previdenciário s/ CAT vinculada" },
  MASSA_SALARIAL: { value: "massa-salarial", label: "Massa Salarial" },
  VINCULOS: { value: "vinculos", label: "Número Médio de Vínculos" },
  PERCENTIS: { value: "percentis", label: "Percentis" },
  ROTATIVIDADE: { value: "rotatividade", label: "Taxa Média de Rotatividade" },
  DESBLOQUEIO: { value: "desbloqueios", label: "Desbloqueio de Bônus/Malus" },
  TROCA_CNAE: { value: "trocas-cnae", label: "Troca de CNAE" }
};

export const Actions = {
  EDITAR_ELEMENTO: "EDITAR_ELEMENTO",
  NOVO_ELEMENTO: "NOVO_ELEMENTO",
  CRIAR_ELEMENTO: "JUSTIFICAR_ELEMENTO",
  LISTAR_REVISADOS: "LISTAR_REVISADOS"
};

export function getResultadosJulgamentosColumns() {
  return [
    { field: "anoVigencia", hideSortIcons: true, filterable: false, headerName: "Vigência", description: "Ano Vigência", minWidth: 90 },
    {
      field: "cnae",
      headerName: "CNAE",
      description: "CNAE",
      flex: 1,
      type: "string",
      valueFormatter: ({ value }) => formataCnae(value)
    },
    {
      field: "numeroDecisao",
      headerName: "N° Decisão",
      description: "N° Decisão",
      flex: 1,
      type: "string"
    },
    {
      field: "numeroProcesso",
      headerName: "N° do Processo",
      description: "N° do Processo",
      flex: 1,
      type: "string"
    },
    {
      field: "tipoProcesso",
      headerName: "Tipo do Processo",
      description: "Tipo do Processo",
      valueGetter: ({ value }) => value?.descricao,
      flex: 1,
      type: "string"
    },
    {
      field: "status",
      headerName: "Situação do Processo",
      description: "Situação do Processo",
      valueGetter: ({ value }) => value.descricao,
      flex: 1.5,
      type: "string"
    }
  ];
}

export function getTaxaRotatividadeRevisadasColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "ano", headerName: "Ano", description: "Ano", flex: 1 },
    {
      field: "taxaRotatividade",
      headerName: "Taxa de Rotatividade (%)",
      description: "Total de Rotatividade (%)",
      flex: 1,
      valueGetter: ({ value }) => formataPorcento(value)
    }
  ];
}

export function getVinculosRevisadosColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "competencia", headerName: "Competência", description: "Competência", flex: 1, valueGetter: ({ value }) => formataCompetencia(value) },
    {
      field: "vinculos",
      headerName: "Quantidade de Vínculos",
      description: "Quantidade de Vínculos",
      flex: 1
    }
  ];
}

function formataCnaeDescricao(cnae) {
  return cnae ? formataCnae(cnae.subClasse) + (cnae.descricao ? " - " + cnae.descricao : "") : "";
}

export function getTrocaCnaeColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "caneOriginal", headerName: "Subclasse Original", description: "Subclasse original", flex: 1, valueGetter: (obj) => formataCnaeDescricao(obj.row.cnaeOriginal) },
    { field: "cane", headerName: "Nova Subclasse", description: "Subclasse original", flex: 1, valueGetter: (obj) => formataCnaeDescricao(obj.row.cnae) }
  ];
}

export function getDesbloqueioColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "fapOriginal", headerName: "FAP Original", description: "Fap original", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
    { field: "bloqueio", headerName: "Tipo de Trava", description: "Tipo de Trava", flex: 1, valueGetter: ({ value }) => value?.descricao }
  ];
}

export function getNexosTecnicosRevisadosColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", flex: 1 }
  ];
}

export function getBeneficiosRevisadosColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "nb", headerName: "Número do Benefício", description: "Número do Benefício", flex: 1 },
    { field: "deferimento", headerName: "Deferimento", description: "Deferimento", flex: 1, renderCell: ({ value }) => value?.descricao }
  ];
}

export function getCatRevisadasColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "numeroCat", headerName: "Número da CAT", description: "Número da CAT", flex: 1 },
    { field: "deferimento", headerName: "Deferimento", description: "Deferimento", flex: 1, renderCell: ({ value }) => value?.descricao }
  ];
}

export function getPercentisRevisadasColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ Desconsiderar", description: "CNPJ Desconsiderar", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    { field: "cnpjRaiz", headerName: "CNPJ Raiz Desconsiderar", description: "CNPJ Raiz Desconsiderar", flex: 1, valueGetter: ({ value }) => formataCNPJRaiz(value) },
    { field: "cane", headerName: "SubClasse Cnae", description: "Subclasse Cnae", flex: 1, valueGetter: (obj) => formataCnaeDescricao(obj.row.cnae) }
  ];
}

export function getMassasSalariaisRevisadasColumns() {
  return [
    { field: "cnpj", headerName: "CNPJ", description: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
    {
      field: "competencia",
      headerName: "Competência",
      description: "Competência",
      flex: 1,
      type: "string",
      valueFormatter: ({ value }) => formataCompetencia(value),
      valueGetter: ({ value }) => value
    },
    {
      field: "valorMassaSalarial",
      headerName: "Total de Remunerações (R$)",
      description: "Total de Remunerações (R$)",
      flex: 1,
      valueGetter: ({ value }) => formataReal(value)
    }
  ];
}

export function getAnexosColumns() {
  return [
    { field: "nome", headerName: "Nome", description: "Nome", flex: 1 },
    { field: "dataEnvio", headerName: "Data de Envio", description: "Data de Envio", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value) },
    { field: "quantidadeBytes", headerName: "Tamanho", description: "Tamanho", flex: 1 }
  ];
}

export const TipoAcaoResultadoJulgamento = {
  CRIAR: "CRIAR",
  EDITAR: "EDITAR"
};

export const TipoProcesso = {
  ADMINISTRATIVO_PRIMEIRA_INSTANCIA: "Administrativo 1ª Instância",
  ADMINISTRATIVO_SEGUNDA_INSTANCIA: "Administrativo 2ª Instância",
  JUDICIAL: "Judicial",
  OFICIO: "Revisão de Ofício"
};
