import React from "react";
import { Divider, Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric, isUndefined } from "components/Form";

import { getTipoElementoUrl, TipoElementoContestacao } from "utils/TipoUtils";
import { formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";
import { isPrimeiraInstancia, isSegundaInstancia } from "utils/ContestacaoUtils";
import ContestacoesApi from "services/api/ContestacoesApi";

import {
  Actions,
  contestacaoModel,
  getElementoBeneficio,
  getElementoCat,
  getElementoComponent,
  getElementoMassa,
  getElementoNexoTecnico,
  getElementoTaxaRotarividade,
  getElementoVinculo,
  mapRowId
} from "../NovaContestacaoUtils";

function getElementoFormByTipo(tipoElemento, elementoParam) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return getElementoCat(elementoParam);
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return getElementoNexoTecnico(elementoParam);
    case TipoElementoContestacao.BENEFICIO.id:
      return getElementoBeneficio(elementoParam);
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return getElementoMassa(elementoParam);
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return getElementoVinculo(elementoParam);
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return getElementoTaxaRotarividade(elementoParam);
    default:
      return null;
  }
}

function getElemento(elementoParam = {}) {
  const { tipoElemento } = elementoParam;
  const elementoCalculo = getElementoFormByTipo(tipoElemento, elementoParam);
  const primeiraInstancia = getElementoFormByTipo(tipoElemento, elementoParam.primeiraInstancia);
  return [elementoCalculo, primeiraInstancia];
}

function iteraElemento(e) {
  return Object.keys(e).map((key) => getElementoComponent(e, key));
}

export const ContestaElementoForm = ({ action, contestacaoParam, elementoParam, onConfirm, onCancel, setLoading }) => {
  const { tipoElemento } = elementoParam;
  const cnpjConsulta = isUndefined(contestacaoParam.cnpj) ? formataCNPJ8Digitos(contestacaoParam.cnpjRaiz) : formataCNPJ14Digitos(contestacaoParam.cnpj);
  const cnpjEstabelecimento = elementoParam.cnpj;

  const [formErrors, setFormErrors] = React.useState([]);
  const [fields, setFields] = React.useState();
  const [elemento, setElemento] = React.useState();
  const [elementoPrimeira, setElementoPrimeira] = React.useState();

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  React.useEffect(() => {
    const elementoFields = isPrimeiraInstancia(contestacaoParam) ? elementoParam?.primeiraInstancia : elementoParam?.segundaInstancia;
    setFields(() => contestacaoModel(elementoFields, tipoElemento));
    const [elementoAux, elementoPrimeiraAux] = getElemento(elementoParam);
    setElemento(elementoAux);
    setElementoPrimeira(elementoPrimeiraAux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
  }

  async function cofirmarContestarElemento(event) {
    _setLoading(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      switch (tipoElemento) {
        case TipoElementoContestacao.CAT.id:
          requestBody.numeroCat = elementoParam.numeroCat;
          break;
        case TipoElementoContestacao.BENEFICIO.id:
          requestBody.nb = elementoParam.nb;
          break;
        case TipoElementoContestacao.MASSA_SALARIAL.id:
          requestBody.cnpj = cnpjEstabelecimento;
          requestBody.competencia = elementoParam.competencia;
          break;
        case TipoElementoContestacao.NEXO_TECNICO.id:
          requestBody.nb = elementoParam.nb;
          break;
        case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
          requestBody.cnpj = cnpjEstabelecimento;
          requestBody.competencia = elementoParam.competencia;
          break;
        case TipoElementoContestacao.ROTATIVIDADE.id:
          requestBody.cnpj = cnpjEstabelecimento;
          requestBody.ano = elementoParam.ano;
          break;
        default:
          break;
      }
      // requestBody.competencia = requestBody.competencia ? requestBody.competencia.replace(/([0-9]{2}).([0-9]{4})/, "$2-$1") : undefined;

      let res = {};
      const elementoUrl = getTipoElementoUrl(tipoElemento);
      if (action === Actions.JUSTIFICAR_ELEMENTO) {
        res = await ContestacoesApi.criarElementoContestado(cnpjConsulta, contestacaoParam.anoVigencia, contestacaoParam.id, elementoUrl, requestBody);
      } else if (action === Actions.EDITAR_ELEMENTO) {
        res = await ContestacoesApi.alterarElementoContestado(cnpjConsulta, contestacaoParam.anoVigencia, contestacaoParam.id, elementoUrl, requestBody.id, requestBody);
      }

      if (res.erros) {
        setFormErrors(res.erros);
      } else {
        onConfirm(mapRowId(res, elementoParam.id));
        return;
      }
    } else {
      setFields(() => ({ ...fields }));
    }
    _setLoading(false);
  }

  function cancelarContestarElemento() {
    _setLoading(true);
    setFields(null);
    onCancel();
  }

  if (!fields || !elemento) {
    return null;
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => cofirmarContestarElemento(e)}
        loading={false}
        errors={formErrors}
        submitTitle={action === Actions.JUSTIFICAR_ELEMENTO ? "Contestar" : "Alterar"}
        cancelTitle={"Cancelar"}
        onCancel={() => cancelarContestarElemento()}
      >
        <Grid item sm={12}>
          <Grid container spacing={1}>
            <Grid item sm={5}>
              <h6 style={{ marginTop: 0 }}>Registro original</h6>
              <Divider />
              {iteraElemento(elemento)}
            </Grid>

            {elementoPrimeira && isSegundaInstancia(contestacaoParam) && (
              <Grid item sm={7}>
                <h6 style={{ marginTop: 0 }}>Contestação de primeira instância</h6>
                <Divider />
                {iteraElemento(elementoPrimeira)}
              </Grid>
            )}
          </Grid>

          <Grid item sm={12}>
            <Divider />
          </Grid>

          {fields.vinculosSolicitado && (
            <Grid item sm={12}>
              <InputGeneric field={fields.vinculosSolicitado} onChange={_handleChange} />
            </Grid>
          )}
          {fields.valorMassaSalarialSolicitado && (
            <Grid item sm={12}>
              <InputGeneric field={fields.valorMassaSalarialSolicitado} onChange={_handleChange} />
            </Grid>
          )}
          {fields.numeroInicialVinculosSolicitado && (
            <Grid item sm={12}>
              <InputGeneric field={fields.numeroInicialVinculosSolicitado} onChange={_handleChange} />
            </Grid>
          )}
          {fields.admissoesSolicitado && (
            <Grid item sm={12}>
              <InputGeneric field={fields.admissoesSolicitado} onChange={_handleChange} />
            </Grid>
          )}
          {fields.rescisoesSolicitado && (
            <Grid item sm={12}>
              <InputGeneric field={fields.rescisoesSolicitado} onChange={_handleChange} />
            </Grid>
          )}

          <Grid item sm={12}>
            <InputGeneric field={fields.justificativa} onChange={_handleChange} />
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  );
};
