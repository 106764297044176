import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { LabelValue } from "components/LabelValue";

const CamposProcuracao = ({ procuracao = {}, onDownload = () => {} }) => {
  return (
    <Grid container>
      <LabelValue columns={4} label="Tipo Procuracao" value={procuracao.tipoProcuracao?.descricao} orientation={"inlineLabel"} />
      <LabelValue columns={8} label="Cnpj Raiz Outorgante" value={procuracao.cnpjRaizOutorgante} orientation={"inlineLabel"} dataType={"cnpjRaiz"} />
      <LabelValue columns={4} label="Cnpj Raiz Outorgado" value={procuracao.cnpjRaizOutorgado} orientation={"inlineLabel"} dataType={"cnpjRaiz"} />
      <LabelValue columns={4} label="Cpf Outorgado" value={procuracao.cpfOutorgado} orientation={"inlineLabel"} dataType={"cpf"} />
      <LabelValue columns={4} label="Data Cadastro" value={procuracao.dataCadastro} orientation={"inlineLabel"} dataType={"date"} />
      <LabelValue columns={4} label="Data Início" value={procuracao.dataInicio} orientation={"inlineLabel"} dataType={"date"} />
      <LabelValue columns={4} label="Data Fim" value={procuracao.dataFim} orientation={"inlineLabel"} dataType={"date"} />
      <LabelValue columns={4} label="Data Exclusão" value={procuracao.dataExclusao} orientation={"inlineLabel"} dataType={"date"} />
      <LabelValue columns={4} label="Situação" value={procuracao.situacao?.descricao} orientation={"inlineLabel"} />
      <LabelValue columns={12} label="Justificativa" value={procuracao.justificativa} orientation={"scrollTextLabelFormatted"} maxHeight="300px" />
      <LabelValue columns={12} label="Parecer" value={procuracao.parecer} orientation={"scrollTextLabelFormatted"} maxHeight="300px" />
      <Grid item sm={12} style={{ paddingTop: 23 }}>
        {procuracao.arquivo && (
          <BrButton small onClick={() => onDownload(procuracao)}>
            <i className="fa fa-download" aria-hidden="true"></i>Baixar Anexo
          </BrButton>
        )}
      </Grid>
    </Grid>
  );
};

export default CamposProcuracao;
