import React from "react";
import { Grid } from "@material-ui/core";

function getSituacao(val) {
  let situacao = { icon: "", label: "" };
  switch (val) {
    case "CRIADA":
      situacao = {
        icon: "fa-check-circle",
        label: "Criada",
        className: "text-success"
      };
      break;
    case "EM_PROCESSAMENTO":
      situacao = {
        icon: "fa-exclamation-triangle",
        label: "Em processamento",
        className: "text-warning"
      };
      break;
    case "PROCESSADA":
      situacao = {
        icon: "fa-double-check",
        label: "Processada",
        className: "text-success"
      };
      break;
    case "NOVA":
      situacao = {
        icon: "fa-calendar-check",
        label: "Nova",
        className: "text-info"
      };
      break;
    default:
      break;
  }
  return situacao;
}

const SituacaoVigencia = ({ value }) => {
  const situacao = getSituacao(value);
  return (
    <Grid item sm={6} style={{ paddingTop: 20 }}>
      <Grid container justifyContent="center" alignItems="center">
        <span className={`fa ${situacao.icon} ${situacao.className} fa-2x`}></span>
        <span style={{ paddingLeft: 5, paddingRight: 5 }} className="text-base text-up-03">
          Situação:
        </span>
        <span className="text-base text-up-03 text-bold">{situacao.label}</span>
      </Grid>
    </Grid>
  );
};

export default SituacaoVigencia;
