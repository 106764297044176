import { createField, FieldType } from "../../components/Form";
import { convertToSelectOptions } from "../../components/Form/formUtils";
import { TipoFaseCalculo, TipoRegra } from "../../utils/TipoUtils";

export const createModel = (values = { anoVigencia: {} }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: values.anoVigencia.options,
    value: values.anoVigencia.value,
    required: true,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  }),
  faseCalculo: createField("faseCalculo", "Fase do Cálculo:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(
      Object.keys(TipoFaseCalculo),
      (k) => TipoFaseCalculo[k].label,
      (k) => k
    ),
    required: true,
    errorMessage: "Selecione fase do cálculo",
    placeholder: "Selecione fase do cálculo"
  })
});

export const createRegraModel = (fields = {}, errosCriticasOptions = [], action = "") => ({
  algoritmo: createField("algoritmo", "Algoritmo:", {
    type: FieldType.TEXTAREA,
    value: fields.algoritmo,
    required: false
  }),

  ativa: createField("ativa", "Regra Ativa:", {
    type: FieldType.SELECT_ONE_BUTTON,
    value: fields.ativa,
    required: true
  }),

  anoVigencia: createField("anoVigencia", "Ano Vigência:", {
    type: FieldType.NUM_MASK,
    mask: "####",
    value: fields.anoVigencia,
    required: false,
    disabled: true
  }),
  faseCalculo: createField("faseCalculo", "Fase do Cálculo:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(
      Object.keys(TipoFaseCalculo),
      (k) => TipoFaseCalculo[k].label,
      (k) => k
    ),
    value: action === "POST" ? fields.faseCalculo : fields.faseCalculo.codigo,
    required: true,
    disabled: true
  }),
  numeroRegra: createField("numeroRegra", "Número da Regra:", {
    type: FieldType.NUMERIC,
    value: fields.numeroRegra,
    required: true,
    disabled: action === "PUT" ? true : false
  }),
  campoAtribuicao: createField("campoAtribuicao", "Campo Atribuição:", {
    type: FieldType.TEXT,
    value: fields.campoAtribuicao,
    required: false
  }),

  erroCritica: createField("erroCritica", "Erro Crítica:", {
    type: FieldType.SELECT,
    value: fields.erroCritica ? fields.erroCritica.codigo : null,
    options: errosCriticasOptions,
    required: false
  }),
  nome: createField("nome", "Nome da Regra:", {
    type: FieldType.TEXT,
    value: fields.nome,
    required: true
  }),
  tipoRegra: createField("tipoRegra", "Tipo da Regra:", {
    type: FieldType.SELECT,
    value: fields.tipoRegra,
    options: convertToSelectOptions(TipoRegra),
    required: true
  }),
  variaveis: createField("variaveis", "Variáveis:", {
    type: FieldType.TEXT,
    value: fields.variaveis,
    required: false
  })
});
