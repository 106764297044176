import { formataIsoDateTimeBR } from "utils/StrUtils";
import { getCurrentMonthDate, getLastMonthDate } from "utils/DateUtils";
import { createField, FieldType } from "components/Form";

export const createExecucaoConsultaModel = (fields) => ({
  dataInicio: createField("dataInicio", "Data Início:", {
    type: FieldType.DATE,
    value: fields?.dataInicio || getLastMonthDate(),
    required: true,
    errorMessage: "Informe a Data Início"
  }),
  dataFim: createField("dataFim", "Data Fim:", {
    type: FieldType.DATE,
    value: fields?.dataFim || getCurrentMonthDate(),
    required: true,
    errorMessage: "Informe a Data Fim"
  })
});

export const columns = [
  { headerName: "Identificador", field: "id", headerAlign: "center", align: "center", flex: 0.5, hide: true },
  { headerName: "Data Hora Início", field: "dataHoraInicio", headerAlign: "center", align: "center", flex: 0.5, valueGetter: ({ value }) => formataIsoDateTimeBR(value, 1) },
  { headerName: "Data Hora Fim", field: "dataHoraFim", headerAlign: "center", align: "center", flex: 0.5, valueGetter: ({ value }) => formataIsoDateTimeBR(value, 1) },
  { headerName: "Fase", field: "nomeFase", headerAlign: "left", align: "left", flex: 1 },
  { headerName: "Observações", field: "observacoes", headerAlign: "left", align: "left", flex: 1, hide: true },
  { headerName: "Sucesso", field: "sucesso", headerAlign: "center", align: "center", flex: 0.5, type: "boolean", hide: true }
];

export const columnsMensagem = [
  { headerName: "Identificador", field: "id", headerAlign: "center", align: "center", flex: 0.5, hide: true },
  { headerName: "Data Hora", field: "dataHora", headerAlign: "center", align: "center", flex: 0.5, valueGetter: ({ value }) => formataIsoDateTimeBR(value, 1) },
  { headerName: "Mensagem", field: "mensagem", headerAlign: "left", align: "left", flex: 1 }
];
