import axios from "axios";
import qs from "qs";

import { openHtmlReport } from "utils/PdfUtils";
import SessionManager from "./SessionManager";
import ConfigManager from "./ConfigManager";
import AuthManager from "./AuthManager";

const MSG_ERROR_UNAUTHORIZED_EXPIRED_SESSION = "Sessão expirada. Redirecionando para o login...";
const MSG_ERROR_UNAUTHORIZED = "Usuário sem autorização. Redirecionando para o login...";
const MSG_ERROR_INTERNAL_SERVER = "Erro interno no sistema. Tente novamente mais tarde ou contate o administrador.";
const MSG_ERROR_FORBIDDEN = "Usuário sem permissão.";
const MSG_ERROR_REQUEST = "Falha na rede: Verifique a conexão com a internet ou tente novamente mais tarde.";
const MSG_ERROR_UNKNOWN = "Erro geral: Contate o administrador.";

const MSG_ERROR_FILE_INVALID_CONTENT = "Conteúdo para download do arquivo inválido.";
const MSG_ERROR_FILE_INVALID_NAME = "Nome do arquivo inválido ou sem extensão.";
const MSG_ERROR_FILE_MAX_SIZE = "Tamanho de arquivo maior que o máximo de 5MB permitido.";

const isAPIGateway = () => /https:\/\/(api-dev.prevnet|hapi.dataprev.gov.br|api.dataprev.gov.br)$/.test(ConfigManager.apiBaseUrl());

const _getAuthorizationToken = () => (isAPIGateway() ? SessionManager.getAccessToken() : SessionManager.getToken());

const FILE_UPLOAD_MAX_SIZE_BYTES = 20971520;
const MEDIA_TYPE = "application/json;charset=utf-8";
const CONTENT_TYPE = "Content-Type";
const ACCEPT = "Accept";
const AUTHORIZATION = "Authorization";
const X_JWT_ASSERTION = "X-JWT-Assertion";

const _client = axios.create();
_client.defaults.headers.post[CONTENT_TYPE] = MEDIA_TYPE;
_client.defaults.headers.common[ACCEPT] = MEDIA_TYPE;
_client.defaults.headers.common[CONTENT_TYPE] = MEDIA_TYPE;

export const getApi = () => {
  _client.defaults.baseURL = ConfigManager.apiUrl();
  if (isAPIGateway()) {
    _client.defaults.headers.common[AUTHORIZATION] = "Bearer " + _getAuthorizationToken();
  } else {
    _client.defaults.headers.common[X_JWT_ASSERTION] = _getAuthorizationToken();
  }
  return _client;
};

const _isUnauthorized = (status) => status === 401;
const _isForbidden = (status) => status === 403;
const _isInternalServerError = (status) => status >= 500;

const _isSessionExpiredError = (response = {}) => {
  const { status, data = "" } = response;
  return _isUnauthorized(status) && typeof data === "string" && data.indexOf("Invalid Credentials") >= 0;
};

function _onRequestSuccess(config) {
  return config;
}

const _onResponseSuccess = (res) => {
  if (res.config.method !== "get" && res.status >= 200 && res.status <= 299) {
    console.debug("Operação realizada com sucesso!");
  }
  if (res.data) {
    res.data.timestamp = Date.now();
  }
  return res.data;
};

const _handleErrorResponse = ({ mensagem = "", status, erros, detalhe }) => {
  if (!erros || erros.length === 0) {
    erros = [{ mensagem }];
  }
  return { status, mensagem, erros, detalhe };
};

const _onError = (err) => {
  const { message, response, request } = err;

  if (response) {
    const { status = err.status, config = {} } = response;
    console.debug(err.message, status, err.response);

    if (_isSessionExpiredError(response)) {
      return AuthManager.doRefreshSignIn()
        .then(() => {
          let { method, baseURL, url, data, params, paramsSerializer: pSerializer } = config;
          if (data && typeof data === "string") {
            data = JSON.parse(data);
          }
          return getApi()
            .request({ method, baseURL, url, data, params, paramsSerializer: pSerializer })
            .then((retryRes) => Promise.resolve(retryRes))
            .catch(_onError);
        })
        .catch((refreshErr) => {
          AuthManager.doKickOut();
          return Promise.resolve(_handleErrorResponse({ status: 401, mensagem: MSG_ERROR_UNAUTHORIZED_EXPIRED_SESSION, detalhe: refreshErr }));
        });
    }

    const resData = response.data || {};
    if (_isUnauthorized(status)) {
      AuthManager.doKickOut();
      return Promise.resolve(_handleErrorResponse({ status, mensagem: MSG_ERROR_UNAUTHORIZED, detalhe: resData }));
    }
    if (_isForbidden(status)) {
      return Promise.resolve(_handleErrorResponse({ status, mensagem: MSG_ERROR_FORBIDDEN, detalhe: resData }));
    }
    if (_isInternalServerError(status)) {
      return Promise.resolve(_handleErrorResponse({ status, mensagem: MSG_ERROR_INTERNAL_SERVER, detalhe: resData }));
    }
    return Promise.resolve(_handleErrorResponse({ status, mensagem: message, ...resData }));
  }

  if (request) {
    console.error(err.message, err.request);
    return Promise.resolve(_handleErrorResponse({ mensagem: MSG_ERROR_REQUEST, detalhe: err }));
  }

  console.debug(err.message, err);
  return Promise.resolve(_handleErrorResponse({ mensagem: MSG_ERROR_UNKNOWN, detalhe: err }));
};

_client.interceptors.request.use(_onRequestSuccess, _onError);
_client.interceptors.response.use(_onResponseSuccess, _onError);

async function upload(path, params = {}, file = null) {
  const formData = new FormData();
  Object.keys(params)
    .filter((k) => typeof params[k] !== "undefined")
    .forEach((k) => {
      formData.append(k, params[k]);
    });

  if (file) {
    const { name, size } = file;
    if (FILE_UPLOAD_MAX_SIZE_BYTES < size) {
      return Promise.resolve(_handleErrorResponse({ status: 400, mensagem: MSG_ERROR_FILE_MAX_SIZE }));
    }
    formData.append("nome", name);
    formData.append("arquivo", file);
  }

  return this.post(path, formData);
}

_client.upload = upload;
_client.bind(upload, _client);

const CONTENT_TYPE_PDF_BASE64 = "data:application/pdf;base64,";
const CONTENT_TYPE_CSV_BASE64 = "data:text/csv;base64,";
const CONTENT_TYPE_TXT_BASE64 = "data:text/plain;base64,";
const CONTENT_TYPE_XLS_BASE64 = "data:application/vnd.ms-excel;base64,";
const CONTENT_TYPE_GENERIC = "data:application/octet-stream;base64,";

const getContentTypeByFileName = (fileName) => {
  if (fileName && fileName.toLowerCase().endsWith(".pdf")) {
    return CONTENT_TYPE_PDF_BASE64;
  } else if (fileName && fileName.toLowerCase().endsWith(".txt")) {
    return CONTENT_TYPE_TXT_BASE64;
  } else if (fileName && fileName.toLowerCase().endsWith(".xls")) {
    return CONTENT_TYPE_XLS_BASE64;
  } else if (fileName && fileName.toLowerCase().endsWith(".csv")) {
    return CONTENT_TYPE_CSV_BASE64;
  } else {
    return CONTENT_TYPE_GENERIC;
  }
};

const handleDownloadBase64File = (fileName, base64Content) => {
  if (fileName && fileName.toLowerCase().endsWith(".html")) {
    openHtmlReport(fileName, base64Content);
  } else {
    const downloadLink = document.createElement("a");
    downloadLink.href = getContentTypeByFileName(fileName) + base64Content;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  return {};
};

export const handleDownload = (res) => {
  const { nome, base64, erros } = res;
  if (erros) {
    return res;
  }
  if (!base64) {
    throw new Error(MSG_ERROR_FILE_INVALID_CONTENT);
  }
  if (!nome || !/.+\.[\w]{3,4}$/.test(nome)) {
    throw new Error(`${MSG_ERROR_FILE_INVALID_NAME} '${nome}'.`);
  }
  return handleDownloadBase64File(nome, base64);
};

export const paramsSerializer = (parameters) => qs.stringify(parameters, { arrayFormat: "repeat" });
