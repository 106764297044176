import { getInstanciaSelectOptions, getSituacaoDescricao, getSituacaoEmpresaSelectOptions } from "utils/ContestacaoUtils";
import { formataCNPJ, formataCNPJRaiz, formataProtocoloContestacao } from "utils/StrUtils";
import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";

const formataEmpresaLabel = (e) => `${formataCNPJRaiz(e.cnpj)} - ${e.nome}`;
const formataEmpresaValue = (e) => e.cnpj;

export const createConsultaModel = ({ fields = {}, vigencias = [], empresas = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value || "",
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.SELECT,
    value: fields?.cnpjRaiz?.value || "",
    options: fields?.cnpjRaiz?.options || convertToSelectOptions(empresas, formataEmpresaLabel, formataEmpresaValue),
    placeholder: "Selecione o CNPJ raiz",
    errorMessage: "Selecione o CNPJ raiz"
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    value: fields?.instancia?.value || "",
    options: getInstanciaSelectOptions(),
    filterOptions: true,
    required: false,
    errorMessage: "Instância inválida"
  }),
  situacao: createField("situacao", "Situação:", {
    type: FieldType.SELECT,
    value: fields?.situacao?.value || "",
    options: getSituacaoEmpresaSelectOptions(),
    filterOptions: true,
    required: false,
    errorMessage: "Situação inválida"
  }),
  prazo2InstanciaAberto: createField("prazo2InstanciaAberto", "Prazo Aberto 2ª Instância:", {
    type: FieldType.CHECKBOX,
    options: convertToSelectOptions([true]),
    value: fields?.prazo2InstanciaAberto?.value || [],
    required: false
  }),
  protocolo: createField("protocolo", "Protocolo:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.PROTOCOLO,
    value: fields?.protocolo?.value || "",
    required: false,
    errorMessage: "Número de protocolo inválido"
  })
});

export const createNovaContestacaoModel = ({ fields = {}, vigencias = [], empresas = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value || "",
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.SELECT,
    value: fields?.cnpjRaiz?.value || "",
    options: fields?.cnpjRaiz?.options || convertToSelectOptions(empresas, formataEmpresaLabel, formataEmpresaValue),
    placeholder: "Selecione o CNPJ raiz",
    errorMessage: "Selecione o CNPJ raiz"
  }),
  estabelecimentos: createField("estabelecimentos", "Estabelecimentos:", {
    type: FieldType.SELECT,
    value: fields?.estabelecimentos?.value || "",
    filterOptions: true,
    placeholder: "Selecione o estabelecimento",
    errorMessage: "Selecione o estabelecimento"
  })
});

export const createDesistenciaConstestacaoModel = (contestacao = {}) => ({
  cnpj: createField("cnpj", "CNPJ:", {
    required: false,
    readMode: true,
    value: !!contestacao.cnpj ? formataCNPJ(contestacao.cnpj) : formataCNPJRaiz(contestacao.cnpjRaiz)
  }),
  protocolo: createField("protocolo", "Protocolo:", {
    required: false,
    readMode: true,
    value: formataProtocoloContestacao(contestacao.protocolo)
  }),
  situacao: createField("situacao", "Situação:", {
    required: false,
    readMode: true,
    value: getSituacaoDescricao(contestacao)
  }),
  dataInicial: createField("dataInicial", "Data de Início:", {
    required: false,
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataInicial
  }),
  dataTransmissao: createField("dataTransmissao", "Data da Transmissão:", {
    required: false,
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataTransmissao
  }),
  dataDOU: createField("dataDOU", "Data Publicação no DOU:", {
    required: false,
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataDOU
  }),
  deferimento: createField("deferimento", "Deferimento:", {
    required: false,
    readMode: true,
    value: contestacao.deferimento ? contestacao.deferimento.descricao : ""
  }),
  dataDesistencia: createField("dataDesistencia", "Data Desistência:", {
    required: false,
    readMode: true,
    // disabled: true,
    type: FieldType.DATE,
    value: new Date()
  }),
  email: createField("email", "E-mail do Responsável:", {
    required: false,
    readMode: true,
    value: contestacao.email
  }),
  justificativaDesistencia: createField("justificativaDesistencia", "Informações Desistência:", {
    type: FieldType.TEXTAREA,
    maxLength: 10000,
    value: contestacao?.justificativaDesistencia || ""
  })
});

export const createResumoConstestacaoModel = (contestacao = {}) => ({
  cnpj: createField("cnpj", "CNPJ:", {
    readMode: true,
    value: !!contestacao.cnpj ? formataCNPJ(contestacao.cnpj) : formataCNPJRaiz(contestacao.cnpjRaiz)
  }),
  protocolo: createField("protocolo", "Protocolo:", {
    readMode: true,
    value: formataProtocoloContestacao(contestacao.protocolo)
  }),
  situacao: createField("situacao", "Situação:", {
    readMode: true,
    value: getSituacaoDescricao(contestacao)
  }),
  dataInicial: createField("dataInicial", "Data de Início:", {
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataInicial
  }),
  dataTransmissao: createField("dataTransmissao", "Data da Transmissão:", {
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataTransmissao
  }),
  dataDOU: createField("dataDOU", "Data Publicação no DOU:", {
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataDOU
  }),
  deferimento: createField("deferimento", "Deferimento:", {
    readMode: true,
    value: contestacao.deferimento ? contestacao.deferimento.descricao : ""
  }),
  email: createField("email", "E-mail do Responsável:", {
    readMode: true,
    value: contestacao.email
  }),
  dataDesistencia: createField("dataDesistencia", "Data da Desistência:", {
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataDesistencia
  }),
  justificativaDesistencia: createField("justificativaDesistencia", "Informações Desistência:", {
    readMode: true,
    value: contestacao?.justificativaDesistencia,
    type: FieldType.TEXTAREA
  })
});

export const createRevisaoContestacaoModel = ({ email, contestacao }) => ({
  observacao: createField("observacao", "", {
    type: FieldType.TEXTAREA,
    value: contestacao?.observacao,
    maxLength: 5000,
    required: false
  }),
  email: createField("email", "Insira o endereço de e-mail", {
    value: contestacao?.email || email,
    required: true
  }),
  quantidadeBeneficios: createField("quantidadeBeneficios", "Benefício:", {
    readMode: true,
    required: false,
    type: FieldType.NUMERIC,
    value: contestacao?.quantidadeBeneficios
  }),
  quantidadeCat: createField("quantidadeCat", "Comunicação de Acidente de Trabalho:", {
    readMode: true,
    required: false,
    type: FieldType.NUMERIC,
    value: contestacao?.quantidadeCat
  }),
  quantidadeNexoTecnico: createField("quantidadeNexoTecnico", "Nexo Técnico Previdenciário sem CAT vinculada:", {
    readMode: true,
    required: false,
    type: FieldType.NUMERIC,
    value: contestacao?.quantidadeNexoTecnico
  }),
  quantidadeMassaSalarial: createField("quantidadeMassaSalarial", "Massa Salarial:", {
    readMode: true,
    required: false,
    type: FieldType.NUMERIC,
    value: contestacao?.quantidadeMassaSalarial
  }),
  quantidadeVinculos: createField("quantidadeVinculos", "Número Médio de Vínculos:", {
    readMode: true,
    required: false,
    type: FieldType.NUMERIC,
    value: contestacao?.quantidadeVinculos
  }),
  quantidadeRotatividade: createField("quantidadeRotatividade", "Taxa Média de Rotatividade:", {
    readMode: true,
    required: false,
    type: FieldType.NUMERIC,
    value: contestacao?.quantidadeRotatividade
  })
});
