import React from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import { checkFormValidity, convertFormFieldsToJsonObj, convertToSelectOptions } from "components/Form/formUtils";
import Form, { InputGeneric, isUndefined } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { formataCNPJ, formataCNPJRaiz, formataCPF11Digitos, formataDateEnBr } from "utils/StrUtils";
import ContestacoesApi from "services/api/ContestacoesApi";
import RelatoriosApi from "services/api/RelatoriosApi";
import { TipoPapel } from "utils/TipoUtils";
import { createModel } from "./model";

const columns = [
  { field: "responsavel", headerName: "Responsável", valueGetter: ({ value }) => value?.nome || value?.cpf, hide: true, flex: 1 },
  { field: "protocolo", headerName: "Nº Protocolo", flex: 1 },
  { field: "anoVigencia", headerName: "Vigência", flex: 0.5 },
  { field: "instancia", headerName: "Instância", flex: 1, valueGetter: ({ value }) => value.descricao },
  { field: "cnpj", headerName: "CNPJ", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
  { field: "cnpjRaiz", headerName: "CNPJ Raiz", flex: 0.5, valueGetter: ({ value }) => formataCNPJRaiz(value) },
  { field: "nomeEmpresa", headerName: "Razão Social", flex: 1 },
  { field: "dataInicioAnalise", headerName: "Data de Distribuição", flex: 0.5, valueGetter: ({ value }) => formataDateEnBr(value) },
  { field: "dataLiberacaoAnalise", headerName: "Data de Conclusão", flex: 0.5, valueGetter: ({ value }) => formataDateEnBr(value) },
  { field: "dataRecalculo", headerName: "Houve Recálculo", flex: 0.5, valueGetter: ({ value }) => (isUndefined(value) ? "Não" : "Sim") }
];

export default function RelatorioProdutividade({ userData }) {
  const [loading, setLoading] = React.useState(false);

  const [formFields, setFormFields] = React.useState(createModel());
  const [formError, setFormError] = React.useState([]);

  const [rows, setRows] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    async function _initForm() {
      _setLoading(true);
      let init = null;

      if (userData.papeis.includes(TipoPapel.CONSELHEIRO_CRPS)) {
        const cpfAnalista = String(userData.document);
        init = createModel({
          analistas: { options: [{ value: cpfAnalista, label: String(userData.firstName) }], value: cpfAnalista, label: "Analista" }
        });
      }

      if (userData.papeis.includes(TipoPapel.GESTOR_CRPS) || userData.papeis.includes(TipoPapel.GESTOR_DATAPREV)) {
        let analistaOptions = [];
        const todosOption = { label: "Todos", value: "" };
        const todasOption = { label: "Todas", value: "" };
        const res = await ContestacoesApi.obterAnalistas();
        if (res.erros) {
          setFormError(res.erros);
        } else {
          analistaOptions = convertToSelectOptions(
            res,
            (a) => a.nome,
            (a) => formataCPF11Digitos(a.cpf)
          );
        }

        init = createModel({
          analistas: { options: analistaOptions, value: null, label: "Analistas" }
        });
        init.analistas.options.unshift(todosOption);
        init.instancia.options.unshift(todasOption);
      }

      setFormFields(init);
      _setLoading(false);
    }
    _initForm();
  }, [userData]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormError([]);
  }

  function _handleChange(event) {
    setFormFields(() => ({ ...formFields }));
  }

  async function handleSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(formFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(formFields);
      const params = {
        cpfResponsavel: requestBody.analistas,
        instancia: requestBody.instancia,
        dataInicio: requestBody.dataInicio,
        dataFim: requestBody.dataFim
      };
      const res = await RelatoriosApi.relatorioProdutividade(params);
      if (!res.erros) {
        setRows(res);
      } else {
        setFormError(res.erros);
        setRows([]);
      }
    }
    setFormFields({ ...formFields });
    _setLoading(false);
  }

  async function _handleClickDownloadRelatorio(tipoRelatorio) {
    _setLoading(true);
    const requestBody = convertFormFieldsToJsonObj(formFields);
    const params = {
      cpfResponsavel: requestBody.analistas,
      instancia: requestBody.instancia,
      dataInicio: requestBody.dataInicio,
      dataFim: requestBody.dataFim,
      tipoRelatorio
    };

    const res = await RelatoriosApi.downloadRelatorioProdutividade(params, selectedRows);
    if (res.erros) {
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {formFields && (
        <Grid container>
          <Grid item sm={12}>
            <Form onSubmit={handleSubmit} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={formError}>
              <Grid item sm={3}>
                <InputGeneric field={formFields.analistas} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={formFields.instancia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={formFields.dataInicio} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={formFields.dataFim} onChange={_handleChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}

      {rows.length > 0 && (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <h5>Relatório de Produtividade</h5>
            <CustomDataGrid rows={rows} columns={columns} toolbar="details" checkboxSelection disableSelectionOnClick onSelectionModelChange={setSelectedRows} />
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <BrButton secondary disabled={!(selectedRows.length !== 0)} onClick={() => _handleClickDownloadRelatorio("XLS")}>
              <span className="fa fa-file-invoice"></span>
              Baixar Relatório XLS
            </BrButton>
            <span> </span>
            <BrButton secondary disabled={!(selectedRows.length !== 0)} onClick={() => _handleClickDownloadRelatorio("PDF")}>
              <span className="fa fa-file-pdf"></span>
              Baixar Relatório PDF
            </BrButton>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
