import React, { useState } from "react";
import { Divider, Grid } from "@material-ui/core";

import { checkFormValidity, convertToSelectOptions } from "components/Form";
import { TagMessage } from "components/TagMessages";
import DataGridModal from "components/DataGridModal";
import Loading from "components/Loading";

import { formataCNPJ, formataCNPJ14Digitos } from "utils/StrUtils";
import { isVigenciaFapPorEstabelecimento } from "utils/VigenciaUtils";
import { TipoMensagem } from "utils/TipoUtils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";
import JulgamentoApi from "services/api/JulgamentoApi";
import VigenciasApi from "services/api/VigenciasApi";
import EmpresasApi from "services/api/EmpresasApi";
import CalculosApi from "services/api/CalculosApi";
import AnexosApi from "services/api/AnexosApi";

import DadosFapResumido from "./components/DadosFapResumido";
import ConsultaFapForm from "./components/ConsultaFapForm";
import DadosHistorico from "./components/DadosHistorico";
import DadosEmpresa from "./components/DadosEmpresa";
import DadosCalculo from "./components/DadosCalculo";

import { getDetalhamento, TIPO_DETALHAMENTO } from "./DetalheModel";
import { createModel } from "./model";

const toggleEstabelecimentos = (estabelecimentos = {}, ativaEstabs = false, isAcessoEmpresa = true) => {
  if (ativaEstabs) {
    estabelecimentos.required = isAcessoEmpresa;
    estabelecimentos.disabled = false;
  } else {
    estabelecimentos.required = false;
    estabelecimentos.disabled = true;
  }
  estabelecimentos.options = [];
  estabelecimentos.value = "";
};

function filterMensagens(values = [], mensagens = []) {
  if (!Array.isArray(values)) {
    values = [values];
  }
  const tipos = Object.keys(TipoMensagem).filter((key) => values.includes(TipoMensagem[key]));
  return mensagens.filter((m) => tipos.includes(m.tipo));
}

function getCnpjConsulta({ estabelecimentos, empresas, cnpjRaiz }) {
  return estabelecimentos.value || (empresas.disabled ? cnpjRaiz.value : empresas.value);
}

async function obterEstabelecimentos(cnpjConsulta, anoVigencia, ativaEstabs = false) {
  const isCnpjRaiz = cnpjConsulta.length === 8;
  if (isCnpjRaiz && ativaEstabs) {
    const resEstab = await EmpresasApi.obterEstabelecimentos(cnpjConsulta, anoVigencia);
    if (resEstab.erros) {
      return resEstab;
    }
    return convertToSelectOptions(resEstab, formataCNPJ, formataCNPJ14Digitos);
  }
  return [];
}

const obterCalculos = (historico = []) => {
  const calculos = historico.filter((e) => e.tipoProcessamento.indexOf("EFEITO_SUSPENSIVO") < 0);
  return calculos.length > 0 ? calculos : { erros: [{ mensagem: "Não foi encontrado FAP para a Empresa nessa vigência." }] };
};

async function obterDetalhementos(tipo, anoVigencia, cnpj, calculoFapId, especie, versaoCnae) {
  let res = {};
  let mensagens = [];
  switch (tipo) {
    case TIPO_DETALHAMENTO.NEXO_TECNICO:
      res = await CalculosApi.obterNexosTecnicos(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_NEXO_TECNICO];
      break;
    case TIPO_DETALHAMENTO.MASSA_SALARIAL:
      res = await CalculosApi.obterMassasSalariais(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_MASSA_SALARIAL];
      break;
    case TIPO_DETALHAMENTO.TAXA_ROTATIVIDADE:
      res = await CalculosApi.obterTaxasRotatividades(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_ROTATIVIDADE];
      break;
    case TIPO_DETALHAMENTO.VINCULOS:
      res = await CalculosApi.obterVinculos(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_MEDIA_VINCULOS];
      break;
    case TIPO_DETALHAMENTO.CAT:
      res = await CalculosApi.obterCats(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_CAT];
      break;
    case TIPO_DETALHAMENTO.PERCENTIL_CUSTO:
      res = await CalculosApi.obterPercentilCusto(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_NUMERO_ORDEM];
      break;
    case TIPO_DETALHAMENTO.PERCENTIL_FREQUENCIA:
      res = await CalculosApi.obterPercentilFrequencia(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_NUMERO_ORDEM];
      break;
    case TIPO_DETALHAMENTO.PERCENTIL_GRAVIDADE:
      res = await CalculosApi.obterPercentilGravidade(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_NUMERO_ORDEM];
      break;
    case TIPO_DETALHAMENTO.BENEFICIOS_TOTAL:
      res = await CalculosApi.obterBeneficios(calculoFapId, anoVigencia, cnpj);
      mensagens = [TipoMensagem.MENSAGEM_DETALHAMENTO_B93];
      break;
    case TIPO_DETALHAMENTO.BENEFICIOS:
      res = await CalculosApi.obterBeneficios(calculoFapId, anoVigencia, cnpj, "B" + especie);
      mensagens = [TipoMensagem["MENSAGEM_DETALHAMENTO_B" + especie]];
      break;
    case TIPO_DETALHAMENTO.DECLARACOES:
      res = await EmpresasApi.obterGfips(cnpj, anoVigencia);
      res["versaoCnae"] = versaoCnae;
      if (!res.erros && res.length === 0) {
        res = { erros: [{ mensagem: "Não foram encontradas GFIPs declaradas." }] };
      }
      break;
    case TIPO_DETALHAMENTO.FAP_SIMPLIFICADO:
      const cnpjRaiz = cnpj.substring(0, 8);
      res = await CalculosApi.obterFapSimplificado(anoVigencia, cnpjRaiz);
      if (!res.erros && res.length === 0) {
        res = { erros: [{ mensagem: "Fap Simplificado não encontrado para este CNPJ Raiz." }] };
      }
      break;
    case TIPO_DETALHAMENTO.ANEXOS_RECALCULO:
      res = await AnexosApi.obterAnexos(anoVigencia, cnpj, calculoFapId);
      if (Array.isArray(res)) {
        res.forEach(function (e) {
          e.anoVigencia = anoVigencia;
          e.cnpj = cnpj;
          e.calculoFapId = calculoFapId;
        });
      }
      if (!res.erros && res.length === 0) {
        res = { erros: [{ mensagem: "Não foram encontrados anexos para esse recálculo." }] };
      }
      break;
    default:
      res = { erros: [{ mensagem: "Tipo detalhamento não encontrado." }] };
  }
  return { res, mensagens };
}

const ConsultaProcessamento = ({ userData = {} }) => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(null);
  const [formFields, setFormFields] = React.useState(null);
  const [formError, setFormError] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const [historico, setHistorico] = useState([]);
  const [calculoFap, setCalculoFap] = useState({});
  const [fap, setFap] = useState({});
  const [extracao, setExtracao] = useState({});
  const [vigencia, setVigencia] = useState({});
  const [detalhamento, setDetalhamento] = React.useState({});
  const [mensagensInformativas, setMensagensInformativas] = useState([]);
  const [avisosImportantes, setAvisosImportantes] = useState([]);
  const empresas = React.useMemo(() => userData.empresas || [], [userData]);
  const isAcessoEmpresa = React.useMemo(() => userData.isAcessoEmpresa, [userData]);

  React.useEffect(() => {
    async function _initForm() {
      _setLoading(true, "grid-form");
      if (vigencias.length > 0) {
        const init = createModel({ vigencias, empresas }, isAcessoEmpresa);
        setFormFields(init);
      }
      _setLoading(false);
    }
    _initForm();
  }, [vigencias, empresas, isAcessoEmpresa]);

  function _setLoading(show, overlay) {
    setLoading(show);
    setLoadingOverlay(overlay);
    if (show) {
      setFormError([]);
    }
  }

  async function _handleChange(field) {
    if (formFields.estabelecimentos.id !== field.id) {
      _setLoading(true, "grid-form");
      const ativaEstabs = isVigenciaFapPorEstabelecimento(formFields.anoVigencia.value);
      toggleEstabelecimentos(formFields.estabelecimentos, ativaEstabs, isAcessoEmpresa);
      const resEstab = await obterEstabelecimentos(getCnpjConsulta(formFields), formFields.anoVigencia.value, ativaEstabs);
      if (resEstab.erros) {
        setFormError(resEstab.erros);
      } else {
        formFields.estabelecimentos.options = resEstab;
      }
      _setLoading(false);
    }
    setFormFields(() => ({ ...formFields }));
    setEmpresa(null);
  }

  async function handleSubmit(event) {
    _setLoading(true);
    setEmpresa(null);
    setHistorico([]);
    setCalculoFap({});
    setFap({});
    let resExtracao, resMensagens, resVigencia;

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(formFields);
    if (isFormCheckValidity && isCheckValidity) {
      const cnpjConsulta = getCnpjConsulta(formFields);
      const anoVigencia = formFields.anoVigencia.value;

      let { resEmpresa = {}, resHistorico = {}, resCalculos = {}, resEstab = null } = {};
      let res = await EmpresasApi.obterEmpresas(cnpjConsulta, anoVigencia);
      resEmpresa = res;
      if (!res.erros) {
        res = await EmpresasApi.obterCalculos(cnpjConsulta, anoVigencia);
        resHistorico = res;
      }
      if (!res.erros) {
        res = obterCalculos(resHistorico);
        resCalculos = res;
      }
      const isCnpjRaiz = cnpjConsulta.length === 8;
      if (!res.erros && isCnpjRaiz && resEmpresa.reprocessadoPorEstabelecimento) {
        toggleEstabelecimentos(formFields.estabelecimentos, true, isAcessoEmpresa);
        res = await obterEstabelecimentos(cnpjConsulta, anoVigencia, true);
        resEstab = res;
      }
      if (resEstab && !resEstab.erros) {
        formFields.estabelecimentos.options = resEstab;
      }
      if (!res.erros) {
        const ultimoFap = resCalculos[0];
        setEmpresa(resEmpresa);
        setHistorico(resHistorico);
        setFap(ultimoFap);
        setCalculoFap(ultimoFap);
        obterAvisosImportantes(ultimoFap.id);
        resExtracao = await VigenciasApi.obterExtracao(anoVigencia);
        resVigencia = await VigenciasApi.obterVigencia(anoVigencia);
        resMensagens = await EmpresasApi.obterMensagens(cnpjConsulta, anoVigencia);
      } else {
        setFormError(res.erros);
      }
    }

    setExtracao(() => (resExtracao && !resExtracao.erros ? resExtracao : []));
    setVigencia(() => (resVigencia && !resVigencia.erros ? resVigencia : []));
    setMensagensInformativas(() => (resMensagens && !resMensagens.erros ? resMensagens : []));
    setFormFields(() => ({ ...formFields }));
    _setLoading(false);
  }

  async function _handleClickHistorico(idCalculo) {
    if (idCalculo) {
      _setLoading(true, "grid-dados");
      setCalculoFap(() => historico.find((e) => idCalculo === e.id));
      obterAvisosImportantes(idCalculo);
      setTimeout(() => _setLoading(false), 400);
    }
  }

  async function obterAvisosImportantes(idCalculo) {
    const cnpjConsulta = getCnpjConsulta(formFields);
    const anoVigencia = formFields.anoVigencia.value;

    const res = await CalculosApi.obterAvisosImportantes({ cnpj: cnpjConsulta, anoVigencia, idCalculo });
    if (!res.erros) {
      setAvisosImportantes(res);
    } else {
      setAvisosImportantes([]);
    }
  }

  async function _handleClickRelatorioPdfCalculoFap() {
    setLoading(true);
    const cnpjConsulta = getCnpjConsulta(formFields);
    const anoVigencia = formFields.anoVigencia.value;
    const res = await CalculosApi.downloadRelatorioPdfCalculoFap(fap.id, cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormError(res.erros);
    }
    setLoading(false);
  }

  function _toggleModalDetalhe(tipo = null, dados = [], tipoMensagemValues = []) {
    const { titulo, colunas } = getDetalhamento(tipo, dados, formFields.anoVigencia.value);
    setDetalhamento(() => ({
      tipo,
      titulo,
      colunas,
      dados,
      mensagens: filterMensagens(tipoMensagemValues, mensagensInformativas)
    }));
  }

  async function _detalhar(tipo, especie) {
    _setLoading(true);
    const anoVigencia = formFields.anoVigencia.value;
    const cnpj = getCnpjConsulta(formFields);
    const { res, mensagens } = await obterDetalhementos(tipo, anoVigencia, cnpj, calculoFap.id, especie, vigencia.versaoCnae);
    res.erros ? setFormError(res.erros) : _toggleModalDetalhe(tipo, res, mensagens);
    _setLoading(false);
  }

  async function _handleClickRelatorioPdfGfips() {
    _setLoading(true, "rel-cnae");
    const cnpjConsulta = getCnpjConsulta(formFields);
    const anoVigencia = formFields.anoVigencia.value;
    const res = await EmpresasApi.downloadRelatorioPdfGfips(cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  async function _handleClickRelatorioPdfRecalculo() {
    _setLoading(true);
    if (!!calculoFap.idContestacao) {
      const cnpjConsulta = getCnpjConsulta(formFields);
      const res = await ContestacoesApi.downloadRelatorioPdfContestacao(calculoFap.idContestacao, cnpjConsulta, vigencia.anoVigencia);
      if (res.erros) {
        setFormError(res.erros);
      }
    } else if (!!calculoFap.idResultadoJulgamento) {
      const cnpjConsulta = getCnpjConsulta(formFields);
      const res = await JulgamentoApi.downloadRelatorioPdfResultadoJulgamento(calculoFap.idResultadoJulgamento, cnpjConsulta, calculoFap.anoVigencia);
      if (res.erros) {
        setFormError(res.erros);
      }
    } else {
      setFormError([{ mensagem: "Não há dados para emitir o relatório." }]);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay overlayTarget={loadingOverlay} show={loading} />
      <Grid container spacing={1} id={"grid-form"}>
        <Grid item sm={12}>
          <ConsultaFapForm
            formFields={formFields}
            onChange={_handleChange}
            onSubmit={handleSubmit}
            onSubmitFapSimplificado={() => _detalhar(TIPO_DETALHAMENTO.FAP_SIMPLIFICADO)}
            errors={formError}
            loading={loading}
          />
        </Grid>
        <Grid item sm={12}>
          <Divider />
        </Grid>
      </Grid>
      {empresa && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item sm={3} id={"grid-fap"}>
                <DadosFapResumido fap={fap} empresa={empresa} extracao={extracao} onClickPdf={_handleClickRelatorioPdfCalculoFap} />
              </Grid>
              <Grid item sm={9} id={"grid-empresa"}>
                <DadosEmpresa empresa={empresa} messages={filterMensagens(TipoMensagem.MENSAGEM_INFORMATIVA, mensagensInformativas)} />
              </Grid>
            </Grid>
          </Grid>
          {historico.length > 0 && (
            <Grid item sm={12}>
              <Grid container spacing={1}>
                <Grid item sm={3} id={"grid-historico"}>
                  <DadosHistorico calculoId={calculoFap.id} eventos={historico} onClickCalculo={_handleClickHistorico} />
                </Grid>
                <Grid item sm={9} id={"grid-dados"}>
                  <DadosCalculo
                    mensagemCalculo={filterMensagens(TipoMensagem.MENSAGEM_DADOS_CALCULO, mensagensInformativas)}
                    mensagemIndicadores={filterMensagens(TipoMensagem.MENSAGEM_INDICADORES_CALCULO, mensagensInformativas)}
                    vigencia={vigencia}
                    calculoFap={calculoFap}
                    onDetalhe={_detalhar}
                    relatorioPdfCnae={_handleClickRelatorioPdfGfips}
                    relatorioPdfRecalculo={_handleClickRelatorioPdfRecalculo}
                    avisosImportantes={avisosImportantes}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {detalhamento.tipo && (
        <DataGridModal
          title={detalhamento.titulo}
          rows={detalhamento.dados}
          columns={detalhamento.colunas}
          headerHeight={100}
          iconComponent={<TagMessage messages={detalhamento.mensagens} />}
          onClose={() => setDetalhamento({})}
        />
      )}
    </React.Fragment>
  );
};

export default ConsultaProcessamento;
