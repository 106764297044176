import React from "react";
import { Divider, Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import Loading from "components/Loading";

import JulgamentoApi from "services/api/JulgamentoApi";

import { TipoAcaoResultadoJulgamento } from "./utils";
import { criaResultadoJulgamentoModel } from "./model";

const isAguardaLiberacao = (status) => "AGUARDANDO_LIBERACAO" === status;

export const CriaJulgamento = ({ location, history }) => {
  const vigencias = location.state?.vigencias || [];
  const fieldsLocation = location.state?.resultadoJulgamento || location.state?.fields;
  const editar = !!location.state?.resultadoJulgamento;

  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);

  const [fields, setFields] = React.useState(() => criaResultadoJulgamentoModel({ fields: fieldsLocation, vigencias, editar }));

  const _redirectBackToResultados = React.useCallback(() => {
    history.replace("/resultado-julgamento", { ...location?.state });
  }, [history, location]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  React.useEffect(() => {
    (async () => await _handleChange())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);

      let res = {};

      if (!editar) {
        res = await JulgamentoApi.criar(requestBody);
      } else {
        res = await JulgamentoApi.alterar(fieldsLocation.id, requestBody);
      }

      if (!res.erros) {
        if (!editar) {
          const state = { resultadoJulgamento: res, fields, acao: TipoAcaoResultadoJulgamento.EDITAR, vigencia: requestBody.vigencia };
          return await history.replace("/resultado-julgamento", state);
        }
      } else {
        setFormErrors(res.erros);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        {!editar && (
          <Grid item sm={12}>
            <span className="text-up-02 text-bold">{"Novo Resultado de Julgamento"}</span>
            <Divider />
          </Grid>
        )}

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item sm={12} id="form-novo">
            <Form
              disabled={editar ? !isAguardaLiberacao(fieldsLocation.status?.codigo) : false}
              onSubmit={_handleSubmit}
              cancelTitle={editar ? null : "Cancelar"}
              onCancel={editar ? null : _redirectBackToResultados}
              errors={formErrors}
              submitTitle={editar ? "Salvar Alterações" : "Criar Novo Resultado de Julgamento"}
            >
              <Grid item sm={2}>
                <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={fields.tipoProcesso} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={fields.geraReprocessamento} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <InputGeneric field={fields.numeroDecisao} onChange={_handleChange} />
                  </Grid>
                  <Grid item sm={12}>
                    <InputGeneric field={fields.numeroProcesso} onChange={_handleChange} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={8}>
                <InputGeneric field={fields.consideracoes} onChange={_handleChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
