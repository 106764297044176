import moment from "moment";
import { FieldType, MaskType } from "./types";
import Field from "./fieldModel";

export const isUndefined = (v) => {
  return typeof v === "undefined" || v === null;
};

export const isValidByMaskLength = (value, mask) => {
  if (isUndefined(mask)) {
    return true;
  }
  const sizeMaskValue = mask.toString().replace(/[^#]/g, "").length;
  if (0 === sizeMaskValue) {
    return true;
  }
  if (isUndefined(value)) {
    return false;
  }
  const sizeValue = value.toString().replace(/[^0-9]/g, "").length;
  return sizeMaskValue === sizeValue;
};

export const convertToPositiveNumber = (text) => {
  if (isUndefined(text)) {
    return undefined;
  }
  let num = text.toString().trim();
  if (typeof text === "string" && text.indexOf(",") >= 0) {
    num = text.replace(/[^0-9,]/g, "").replace(",", ".");
  }
  if (num === "") {
    return undefined;
  }
  num = Number(num);
  return num >= 0 ? num : undefined;
};

export const convertFieldToJsonValue = ({ value, multiple, mask, type }) => {
  if (isUndefined(value) || value.toString().trim() === "") {
    return undefined;
  }
  if (mask) {
    value = value
      .toString()
      .replace(/[^0-9,]/g, "")
      .replace(",", ".");

    if (!isValidByMaskLength(value, mask)) {
      return undefined;
    }

    switch (mask) {
      case MaskType.COMPETENCIA:
        value = value.replace(/([0-9]{2})([0-9]{4})/, "$2-$1");
        break;
      case MaskType.CNPJ_RAIZ:
      case MaskType.CNPJ:
      case MaskType.CPF:
        const length = mask.replace(/[^#]/g, "").length;
        value = convertToPositiveNumber(value);
        if (typeof value !== "undefined") {
          value = String(value).padStart(length, "0");
        }
        break;
      default:
        value = convertToPositiveNumber(value);
    }
  } else {
    switch (type) {
      case FieldType.SELECT_ONE_BUTTON:
        value = typeof value === "boolean" ? value : Boolean(value);
        break;
      case FieldType.SELECT:
        if (multiple) {
          value = value.map((e) => e.value);
        }
        break;
      case FieldType.DATE:
        value = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
        break;
      case FieldType.CURRENCY:
      case FieldType.NUMERIC:
        value = convertToPositiveNumber(value);
        break;
      default:
        value = value.toString().trim();
        break;
    }
  }

  return value;
};

export function convertFormFieldsToJsonObj(formFields) {
  let object = {};
  Object.keys(formFields).forEach((key) => {
    const field = formFields[key];
    object[key] = convertFieldToJsonValue(field);
  });
  return object;
}

export const checkFormValidity = (fields) => {
  if (isUndefined(fields)) {
    return false;
  }

  let isValidForm = true;
  Object.keys(fields).forEach((key) => {
    fields[key].validated = true;
    isValidForm = isValidForm && !isUndefined(fields[key].isValid) && fields[key].isValid();
  });
  return isValidForm;
};

export const validationStateField = (field) => {
  if (!isUndefined(field) && field.validated) {
    const message = field.errorMessage ? field.errorMessage : "Campo obrigatório";
    const value = isUndefined(field.value) ? "" : field.value.toString().trim();
    if (field.required && !value) {
      return message;
    }

    if (field.type === FieldType.NUM_MASK && !!value && !isValidByMaskLength(value, field.mask)) {
      return message;
    }
  }
  return undefined;
};

export const convertToSelectOptions = (options, formataLabel = (e) => e, formataValue = (e) => e) => {
  if (Array.isArray(options)) {
    return options.map((e) => ({ value: formataValue(e), label: formataLabel(e) }));
  }

  return Object.keys(options).map((key) => ({
    value: isNaN(key) ? key : Number(key),
    label: options[key]
  }));
};

export function createField(id, label, props = {}) {
  return new Field(id, label, props);
}

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        base64: reader.result.replace(/^data[^;]+;base64,/, ""),
        file: file
      };
      resolve(fileInfo);
    };

    reader.onerror = (e) => {
      reader.abort();
      reject({ error: e });
    };
  });
};