import { convertToSelectOptions, createField, FieldType } from "../../components/Form";

export const createAnoTaxaCnaeModel = (anos = []) => ({
  anoTaxaCnae: createField("anoTaxaCnae", "Ano:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(
      anos,
      (e) => String(e),
      (e) => e
    ),
    required: true,
    errorMessage: "Selecione o ano",
    placeholder: "Selecione o ano"
  })
});

export function anexoModel() {
  const id = Math.random().toString(36);
  return {
    anexo: createField(id, "Arquivo CSV:", {
      type: FieldType.FILE,
      required: true,
      multiple: false,
      value: "",
      accept: ".csv"
    })
  };
}
