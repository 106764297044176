import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import Header from "components/Header";
import ilustraLanding from "assets/images/ilustra.landing.svg";

const LandingPage = ({ redirectToGerid, redirectToGovbr }) => {
  return (
    <React.Fragment>
      <Header noLogin={true} noMenu={true} />

      <div className="container-lg fap-container">
        <Grid container style={{ paddingTop: "15px" }}>
          <Grid item sm={12}>
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <img src={ilustraLanding} alt="FAP" />
              </Grid>

              <Grid item sm={8}>
                <h1>Seja bem-vindo ao FAP</h1>
                <p className="text-up-01">
                  Este é o canal para as empresas consultarem o valor do Fator Acidentário de Prevenção (FAP), bem como apresentarem contestação e recurso ao FAP atribuído a cada
                  estabelecimento empresarial.
                </p>
                <p>
                  Aplicado desde 2010, o FAP é um sistema de bonificação ou majoração do Seguro contra Acidentes de Trabalho (SAT), individualizado para cada estabelecimento da
                  empresa, de acordo com seu desempenho na frequência, gravidade e custo previdenciários dos acidentes e doenças do trabalho sofridos por seus trabalhadores, por
                  meio de comparação desses indicadores entre as empresas da mesma atividade econômica, conforme determina o art. 10 da Lei nº. 10.666, de 2003.
                </p>
                <div className="text-center">
                  <h5>Sou representante de uma empresa</h5>
                  <BrButton primary onClick={redirectToGovbr}>
                    Entrar com gov.br
                  </BrButton>
                  <br />
                  <h5>Sou servidor do Ministério da Previdência Social</h5>
                  <BrButton secondary onClick={redirectToGerid}>
                    Entrar com Gerid
                  </BrButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
