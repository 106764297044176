import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

import { isUndefined } from "components/Form";
import {
  formataBoleano,
  formataCnae,
  formataCNPJ,
  formataCNPJRaiz,
  formataCPF,
  formataDateEnBr,
  formataIsoDateTimeBR,
  formataIndicador,
  formataIndicadorCompleto,
  formataNumberEnBr,
  formataPorcento,
  formataReal
} from "utils/StrUtils";

const DATA_TYPES = {
  BOOLEAN: "boolean",
  NUMBER: "number",
  DATE: "date",
  DATETIME: "dateTime",
  INDEX: "index",
  INDEX_FULL: "indexFull",
  CURRENCY: "currency",
  CNPJ: "cnpj",
  CNPJ_RAIZ: "cnpjRaiz",
  CPF: "cpf",
  CNAE: "cnae",
  PERCENT: "percent"
};

function formataDataType(value, dataType) {
  value = String(value);
  switch (dataType) {
    case DATA_TYPES.BOOLEAN:
      return formataBoleano(value);
    case DATA_TYPES.NUMBER:
      return formataNumberEnBr(value);
    case DATA_TYPES.DATE:
      return formataDateEnBr(value);
    case DATA_TYPES.DATETIME:
      return formataIsoDateTimeBR(value);
    case DATA_TYPES.INDEX:
      return formataIndicador(value);
    case DATA_TYPES.INDEX_FULL:
      return formataIndicadorCompleto(value);
    case DATA_TYPES.CURRENCY:
      return formataReal(value);
    case DATA_TYPES.CNPJ:
      return formataCNPJ(value);
    case DATA_TYPES.CNPJ_RAIZ:
      return formataCNPJRaiz(value);
    case DATA_TYPES.CPF:
      return formataCPF(value);
    case DATA_TYPES.CNAE:
      return formataCnae(value);
    case DATA_TYPES.PERCENT:
      return formataPorcento(value);
    default:
      return value;
  }
}

const LabelValue = ({ showNull, label, value, orientation, dataType, columns = 12, onClick, title = "Clique para ver detalhes", className = "label", maxHeight }) => {
  if (isUndefined(value)) {
    if (showNull) {
      value = 0;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
  value = formataDataType(value, dataType);

  if (!label) {
    // FIXME: refatorar LavelValue para que orientation nao dependa de label
    orientation = "fullValue";
  }

  return (
    <Grid item sm={columns}>
      {orientation === "fullValue" && (
        <span className={className} style={{ cursor: onClick ? "pointer" : "default", textDecoration: onClick ? "underline" : "" }} title={onClick ? title : ""} onClick={onClick}>
          {value}
        </span>
      )}
      {orientation === "sideLabel" && (
        <Grid container spacing={1}>
          <Grid item sm={5} style={{ display: "flex", justifyContent: "right" }}>
            <span
              className="text-up-03 text-info"
              style={{ cursor: onClick ? "pointer" : "default", textDecoration: onClick ? "underline" : "" }}
              title={onClick ? "Clique para ver detalhes" : ""}
              onClick={onClick}
            >
              {value}
            </span>
          </Grid>
          <Grid item sm={7}>
            <span className="text-base">{label}</span>
          </Grid>
        </Grid>
      )}
      {orientation === "inlineLabel" && (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <span className={className}>{label}:</span>
          </Grid>
          <Grid item>
            <span className="text-info">{value}</span>
          </Grid>
        </Grid>
      )}
      {orientation === "scrollTextLabel" && (
        <Grid container spacing={1}>
          <Grid item>
            <span className={className}>{label}:</span>
          </Grid>
          <Grid item style={{ overflowY: "auto", maxHeight: maxHeight ? maxHeight : "150px" }}>
            <span className="text-info">{value}</span>
          </Grid>
        </Grid>
      )}
      {orientation === "scrollTextLabelFormatted" && (
        <Grid container spacing={1}>
          <Grid item>
            <span className={className}>{label}:</span>
          </Grid>
          <Grid item style={{ overflowY: "auto", maxHeight: maxHeight ? maxHeight : "150px", whiteSpace: "pre-line" }}>
            <span className="text-info">{value}</span>
          </Grid>
        </Grid>
      )}
      {orientation === "upLabel" && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <span className="text-base">{label}</span>
          </Grid>
          <Grid item sm={12}>
            <span
              className="text-up-03 text-info"
              style={{ cursor: onClick ? "pointer" : "default", textDecoration: onClick ? "underline" : "" }}
              title={onClick ? "Clique para ver detalhes" : ""}
              onClick={onClick}
            >
              {value}
            </span>
          </Grid>
        </Grid>
      )}
      {!orientation && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <span className="text-base">{label}</span>
          </Grid>
          <Grid item sm={12}>
            <span className={className}>{value}</span>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

LabelValue.propTypes = {
  showNull: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  orientation: PropTypes.oneOf(["fullValue", "sideLabel", "inlineLabel", "scrollTextLabel", "scrollTextLabelFormatted", "upLabel"]),
  dataType: PropTypes.oneOf(Object.values(DATA_TYPES)),
  columns: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string
};

LabelValue.defaultProps = {
  showNull: undefined,
  label: undefined,
  value: undefined,
  orientation: undefined,
  dataType: undefined,
  columns: 12,
  onClick: undefined,
  title: "Clique para ver detalhes",
  className: "label"
};

export { LabelValue };
