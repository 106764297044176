import { convertToSelectOptions } from "components/Form";
import { TipoInstancia, TipoSituacaoContestacao } from "./TipoUtils";

export function isPrimeiraInstancia(contestacao = {}) {
  const codigo = contestacao?.instancia?.codigo;
  return TipoInstancia.primeira.value === codigo;
}

export function isSegundaInstancia(contestacao = {}) {
  const codigo = contestacao?.instancia?.codigo;
  return TipoInstancia.segunda.value === codigo;
}

export function getInstanciaSelectOptions() {
  return convertToSelectOptions(
    Object.keys(TipoInstancia),
    (k) => TipoInstancia[k].label,
    (k) => TipoInstancia[k].value
  );
}

export function getInstanciaDescricao({ instancia = {} }) {
  return instancia?.descricao || "";
}

export function getSituacaoDescricao({ situacao = {} }) {
  const descricao = situacao?.descricao;
  if (!descricao) {
    throw new Error("getSituacaoDescricao invalida");
  }
  return descricao;
}

const situacoesEmpresa = [
  TipoSituacaoContestacao.EM_ANDAMENTO,
  TipoSituacaoContestacao.LIBERADA_PARA_ANALISE,
  TipoSituacaoContestacao.PUBLICADA,
  TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_DOU,
  TipoSituacaoContestacao.DESISTENCIA_PUBLICADA
];

export function getSituacaoEmpresaSelectOptions() {
  return convertToSelectOptions(
    situacoesEmpresa,
    (s) => s.empresa || s.label,
    (s) => s.value
  );
}
