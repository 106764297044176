import React from "react";
import { Link } from "@material-ui/core";

import FullScreenError from "components/FullScreenError";

import AuthManager from "services/AuthManager";

const MSG_ERRO_GOVBR_LINK_CNPJ = "https://acesso.gov.br/faq/_perguntasdafaq/comocadastrarCNPJnologinunico.html";

class ErrorBoundary extends React.Component {
  static MSG_RETRY = "Tente novamente ou contate o administrador.";

  state = { message: "" };

  /** Render a fallback UI after an error */
  static getDerivedStateFromError(error) {
    // TODO: criar um enum com tipos de erros e refatorar render ErrorBoundary
    return { message: error.message };
  }

  /** Log errors */
  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });
  }

  render() {
    if ("MSG_ERR_GOVBR_NOT_ALLOWED" === this.state.message) {
      return (
        <FullScreenError onClose={() => AuthManager.doKickOut(1000)}>
          <p>Usuário sem CNPJ de empresa vinculado no GovBR.</p>
          <p>Para acesso ao FAP é necessário solicitar uma das opções:</p>
          <p>
            - Vinculação de CNPJ conforme procedimento descrito em
            <Link style={{ paddingLeft: 5 }} underline="always" target={"_blank"} href={MSG_ERRO_GOVBR_LINK_CNPJ}>
              acesso.gov.br
            </Link>
            .
          </p>
          <p>- Concessão de acesso via procuração eletrônica.</p>
        </FullScreenError>
      );
    }

    if (!!this.state.message) {
      return (
        <FullScreenError onClose={() => AuthManager.doKickOut(1000)}>
          <p>{this.state.message}</p>
          <p>{ErrorBoundary.MSG_RETRY}</p>
        </FullScreenError>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
