import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { AppProvider, useMenu, useUser } from "contexts/AppContext";
import { ErrorBoundary } from "contexts/ErrorBoundary";
import Breadcrumb from "components/Breadcrumb";
import Header from "components/Header";
import Routes from "Routes";

function App({ doLogout }) {
  const { user } = useUser();
  const { menus, flatMenus } = useMenu();

  return (
    <BrowserRouter>
      <React.Fragment>
        <Header userData={user} menus={menus} doLogout={doLogout} />

        <div className="container-lg fap-container">
          <Grid container>
            <Grid item sm={12}>
              <Breadcrumb menus={flatMenus} />
            </Grid>
            <Grid item sm={12}>
              <Routes userData={user} menus={flatMenus} />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    </BrowserRouter>
  );
}

export default function WrapApp(props) {
  return (
    <ErrorBoundary>
      <AppProvider>
        <App {...props} />
      </AppProvider>
    </ErrorBoundary>
  );
}
