import React from "react";
import BrTooltip from "@govbr/react-components/lib/BrTooltip";

// status: PropTypes.oneOf(["success", "warning", "info", "error"]),

const TooltipMouseOver = ({
  icon,
  title = "",
  message = "",
  status = "warning"
}) => {
  const iconRef = React.useRef();
  const [show, setShow] = React.useState(false);

  const _showTip = () => setShow(true);
  const _hideTip = () => setShow(false);

  return (
    <div
      style={{ margin: 0, paddingRight: "10px", cursor: "pointer" }}
      onMouseOver={_showTip}
      onMouseLeave={_hideTip}
    >
      <span className="text-danger text-bold">{title}</span>

      <BrTooltip
        popover
        id="tooltip-popover"
        open={show}
        anchorEl={iconRef.current}
        onClose={_hideTip}
        status={status}
      >
        <BrTooltip.PopoverHeader />
        <BrTooltip.PopoverBody>
          <span style={{ margin: 0, padding: 0 }}>{message}</span>
        </BrTooltip.PopoverBody>
        <BrTooltip.PopoverFooter />
      </BrTooltip>

      <span
        ref={iconRef}
        style={{ paddingLeft: "10px" }}
        className={`text-danger fa ${icon}`}
      ></span>
    </div>
  );
};

export default TooltipMouseOver;
