import { formataDecimal } from "utils/StrUtils";
import { convertToSelectOptions, createField, FieldType } from "../../components/Form";

export const createVigenciaModel = ({ vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    required: true,
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  })
});

export function anexoModel() {
  const id = Math.random().toString(36);
  return {
    anexo: createField(id, "Arquivo CSV:", {
      type: FieldType.FILE,
      required: true,
      multiple: false,
      value: "",
      accept: ".csv"
    })
  };
}

export const columns = [
  { field: "idade", headerName: "Idade", flex: 1 },
  { field: "expectativa", headerName: "Expectativa de Vida", flex: 1, valueGetter: ({ value }) => formataDecimal(value, 1) },
];
