import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { TipoSituacaoContestacao } from "utils/TipoUtils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";

import { createConsultaHomologaDesistenciaAdmConstestacaoModel } from "./model";
import { getDesistenciaColumns, getInstanciaPresidenteLogado } from "./utils";

const MSG_SUCCESS = "As desistências das contestações selecionadas foram homologadas com sucesso!";

export const HomologaDesistenciaAdmContestacao = ({ userData }) => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(false);

  const [formErrors, setFormErrors] = React.useState([]);
  const formElement = React.useRef();
  const [fields, setFields] = React.useState();

  const [successMessage, setSuccessMessage] = React.useState();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      _setLoading(true);
      if (!!vigencias && vigencias.length > 0) {
        const res = await ContestacoesApi.obterAnalistas();
        const analistas = !!res.erros ? [] : res;
        setFields(() => createConsultaHomologaDesistenciaAdmConstestacaoModel({ vigencias, analistas }));
      }
      _setLoading(false);
    })();
  }, [vigencias]);

  function _setLoading(show) {
    show && setFormErrors([]);
    show && setSuccessMessage(null);
    setLoading(show);
  }

  function _handleChange() {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      requestBody.situacao = [TipoSituacaoContestacao.DESISTENCIA_AGUARDANDO_AUTORIDADE.value];
      requestBody.instancia = getInstanciaPresidenteLogado(userData.papeis);
      const res = await ContestacoesApi.obterContestacoes(requestBody);
      if (res.erros) {
        setFormErrors(res.erros);
        setRows([]);
      } else {
        setSelectedRows([]);
        setRows(res);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  const _handleSelectedRows = (ids) => {
    setSelectedRows(ids);
  };

  async function _homologarDesistencia(idsContestacoes = []) {
    const idsArray = Array.isArray(idsContestacoes) ? idsContestacoes : [idsContestacoes];
    _setLoading(true);
    const res = await ContestacoesApi.homologarDesistencias(idsArray);
    if (res && res.erros) {
      setFormErrors(res.erros);
    } else {
      setSuccessMessage(MSG_SUCCESS);
      setRows(() => rows.filter((r) => !idsArray.includes(r.id)));
    }
    _setLoading(false);
  }

  async function _downloadRelatorioDesistenciaContestacao(id) {
    _setLoading(true);
    const res = await ContestacoesApi.downloadRelatorioPdfDesistenciaContestacao(id);
    if (res && res.erros) {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  // const homologarAction = {
  //   title: "Homologar Desistência",
  //   icon: "text-info fa fa-check-square",
  //   action: (rowContestacao) => _homologarDesistencia(rowContestacao.id)
  // };

  const relatorioAction = {
    title: "Relatório da Desistência",
    icon: "text-info fa fa-file-pdf",
    action: (rowContestacao) => _downloadRelatorioDesistenciaContestacao(rowContestacao.id)
  };

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {!!successMessage && <BrMessage success>{successMessage}</BrMessage>}
        </Grid>

        <Grid item sm={12}>
          {!!fields && (
            <Form ref={formElement} submitTitle="Pesquisar" onSubmit={_handleSubmit} errors={formErrors}>
              <Grid item sm={2}>
                <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.cnpj} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.cpfResponsavel} onChange={_handleChange} />
              </Grid>
            </Form>
          )}
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid
            actions={[relatorioAction]}
            rows={rows}
            columns={getDesistenciaColumns()}
            density="compact"
            toolbar="details"
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={_handleSelectedRows}
          />
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={1} style={{ justifyContent: "right" }}>
            <Grid item>
              <BrButton primary onClick={() => _homologarDesistencia(selectedRows)} disabled={!selectedRows.length > 0}>
                Homologar Desistências
              </BrButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
