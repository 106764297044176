class InvalidParamError extends Error {
  constructor(message = "Invalid Parameter.") {
    super(message);
    this.name = "InvalidParamError";
  }
}

const getItem = (key) => {
  if (typeof key !== "string") {
    throw new InvalidParamError();
  }
  return localStorage.getItem(key) || "";
};

const setItem = (key, value = "") => {
  if (typeof key !== "string") {
    throw new InvalidParamError();
  }
  const auxValue = typeof value !== "undefined" && value !== null ? `${value}` : "";
  localStorage.setItem(key, auxValue);
};

const clear = () => {
  localStorage.clear();
};

export const PREFS = {
  CONTESTACAO_PAGE_SIZE: "CONTESTACAO_PAGE_SIZE",
  CONTESTACAO_ELEMENTO_PAGE_SIZE: "CONTESTACAO_ELEMENTO_PAGE_SIZE"
};

class PreferenceManager {
  constructor() {
    throw new Error("PreferenceManager Abstract class.");
  }

  static get(key) {
    return getItem(PREFS[key]);
  }

  static set(key, value) {
    if (!PREFS[key]) {
      throw new InvalidParamError(`Propriedade '${key}' inválida.`);
    }
    setItem(key, value);
  }

  static clean() {
    clear();
  }
}
export default PreferenceManager;
