import React from "react";

import { Divider, Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import { consultarContestacao, transmitirContestacao } from "../ContestacaoUtils";
import { createRevisaoContestacaoModel } from "../model";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";
import { isVigenciaNexoTecnico } from "utils/VigenciaUtils";

export const RevisaoContestacaoForm = ({ userData = {}, contestacao, onCancel = () => {} }) => {
  const { anoVigencia } = contestacao;
  const [revisaoFields, setRevisaoFields] = React.useState(createRevisaoContestacaoModel({ contestacao }));
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [exibeSucesso, setExibeSucesso] = React.useState(false);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    setRevisaoFields(() => ({ ...revisaoFields }));
  }

  async function _transmitirContestacao(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(revisaoFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(revisaoFields);
      const { cnpjRaiz, cnpj, id } = contestacao;
      const { email, observacao } = requestBody;
      const res = await transmitirContestacao({ cnpjRaiz, cnpj, id, anoVigencia, email, observacao });

      if (res.erros) {
        setFormErrors(res.erros);
      } else {
        setExibeSucesso(true);
      }
    }
    _setLoading(false);
    setRevisaoFields(() => ({ ...revisaoFields }));
  }

  const loadContestacao = React.useCallback(async () => {
    _setLoading(true);
    const res = await consultarContestacao(contestacao);
    if (res.erros) {
      setFormErrors(res.erros);
    } else {
      setRevisaoFields(() => createRevisaoContestacaoModel({ email: userData.email, contestacao: res }));
    }
    _setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadContestacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Form onSubmit={_transmitirContestacao} errors={formErrors} submitTitle="Transmitir Contestação">
        <Grid item sm={12}>
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <InputGeneric field={revisaoFields.quantidadeCat} />
            </Grid>
            {isVigenciaNexoTecnico(anoVigencia) && (
              <Grid item sm={4}>
                <InputGeneric field={revisaoFields.quantidadeNexoTecnico} />
              </Grid>
            )}
            <Grid item sm={4}>
              <InputGeneric field={revisaoFields.quantidadeBeneficios} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={revisaoFields.quantidadeMassaSalarial} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={revisaoFields.quantidadeVinculos} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={revisaoFields.quantidadeRotatividade} />
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <h6>Observações Gerais</h6>
            <Divider />
            <InputGeneric field={revisaoFields.observacao} onChange={_handleChange} />

            <h6>E-mail Responsável</h6>
            <Divider />
            <p>
              A informação deste endereço eletrônico tem como objetivo alertar a empresa acerca da existência de publicação de resultado de julgamento do FAP no Diário Oficial da
              União. Ressalta-se que qualquer problema que impossibilite o envio, recebimento, leitura, entre outros, da mensagem eletrônica em referência não implica invalidação
              da publicação no DOU.
            </p>
            <InputGeneric field={revisaoFields.email} onChange={_handleChange} />
          </Grid>
        </Grid>
      </Form>
      <ModalMessage show={exibeSucesso} close={onCancel} />
    </React.Fragment>
  );
};
