import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";
import { formataCompetencia } from "utils/StrUtils";
import { isVigenciaCatPorTrajeto } from "utils/VigenciaUtils";
import { TipoElementos, TipoProcesso } from "./utils";

export const TipoDeferimentoResultadoJulgamento = {
  EXCLUSAO: "Exclusão",
  ALTERACAO: "Alteração de Valores",
  TRAJETO: "Trajeto"
};

function optionsDeferimento(anoVigencia, tab) {
  let opt = convertToSelectOptions(TipoDeferimentoResultadoJulgamento);
  if (tab === TipoElementos.CATS) {
    opt.splice(1, 1);
  }

  if (isVigenciaCatPorTrajeto(anoVigencia)) {
    return opt;
  } else {
    opt.pop();
    return opt;
  }
}

export const createConsultaResultadosJulgamentosModel = (fields = {}, vigencias = []) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia ? String(fields?.anoVigencia.value) : "",
    options: convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: true,
    value: fields?.cnpjRaiz?.value.toString().padStart(8, "0"),
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  })
});

export const criaResultadoJulgamentoModel = ({ fields = {}, vigencias = [], editar }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: editar ? FieldType.TEXT : FieldType.SELECT,
    value: editar ? fields.anoVigencia : String(fields?.anoVigencia.value),
    options: convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência",
    disabled: editar
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: true,
    value: editar ? fields?.cnpjRaiz?.toString().padStart(8, "0") : fields?.cnpjRaiz?.value?.toString().padStart(8, "0"),
    placeholder: "Informe os 8 primeiros dígitos do CNPJ",
    disabled: editar
  }),
  geraReprocessamento: createField("geraReprocessamento", "Gera Reprocessamento:", {
    type: FieldType.SELECT_ONE_BUTTON,
    required: true,
    value: fields?.geraReprocessamento
  }),

  numeroDecisao: createField("numeroDecisao", "Número da Decisao:", {
    type: FieldType.TEXT,
    required: true,
    value: fields?.numeroDecisao
  }),
  numeroProcesso: createField("numeroProcesso", "Número do Processo:", {
    type: FieldType.TEXT,
    required: true,
    value: fields?.numeroProcesso
  }),
  tipoProcesso: createField("tipoProcesso", "Tipo do Processo:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoProcesso),
    required: true,
    value: fields?.tipoProcesso?.codigo
  }),
  consideracoes: createField("consideracoes", "Considerações:", {
    type: FieldType.TEXTAREA,
    required: false,
    maxLength: 10000,
    lines: 10,
    value: fields?.consideracoes
  })
});

export function anexoModel() {
  const id = Math.random().toString(36);
  return {
    anexo: createField(id, "Anexo:", {
      type: FieldType.FILE,
      required: true,
      multiple: false,
      value: ""
    })
  };
}

export function elementoModel(julgamento, fields = {}, tab) {
  let model = {
    id: createField("id", "id:", {
      type: FieldType.NUMERIC,
      value: fields.resultadoJulgamento ? fields.resultadoJulgamento.id : "",
      required: false
    })
  };

  let nb = createField("nb", "Número do Benefício:", {
    type: FieldType.NUMERIC,
    value: fields.resultadoJulgamento ? fields.resultadoJulgamento.nb : "",
    required: true
  });

  let cnpj = createField("cnpj", "Cnpj:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ,
    value: fields.cnpj,
    required: true
  });

  let competencia = createField("competencia", "Competencia:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.COMPETENCIA,
    value: formataCompetencia(fields.competencia).replace(/[^0-9]/g, ""),
    required: true
  });

  let deferimento = createField("deferimento", "Deferimento:", {
    type: FieldType.SELECT,
    options: optionsDeferimento(julgamento.anoVigencia, tab),
    required: true,
    value: fields.resultadoJulgamento ? fields.resultadoJulgamento.deferimento : ""
  });

  if (tab === TipoElementos.CATS) {
    model.deferimento = deferimento;
    model.numeroCat = createField("numeroCat", "Número da Cat:", {
      type: FieldType.NUMERIC,
      value: fields.numeroCat,
      required: true
    });
  }

  if (tab === TipoElementos.BENEFICIOS) {
    model.nb = nb;
    model.deferimento = deferimento;
    model.valorMensal = createField("valorMensal", "Valor Mensal (R$):", {
      type: FieldType.CURRENCY,
      value: fields.resultadoJulgamento ? fields.resultadoJulgamento.valorMensal : "",
      required: false
    });

    model.dataCessacao = createField("dataCessacao", "Data de Cessação:", {
      type: FieldType.DATE,
      value: fields.resultadoJulgamento ? fields.resultadoJulgamento.dataCessacao : "",
      required: false
    });

    model.dataInicio = createField("dataInicio", "Data de Inicio:", {
      type: FieldType.DATE,
      value: fields.resultadoJulgamento ? fields.resultadoJulgamento.dataInicio : "",
      required: false
    });
  }

  if (tab === TipoElementos.MASSA_SALARIAL) {
    model.cnpj = cnpj;
    model.cnpj.required = false;
    model.competencia = competencia;
    model.valorMassaSalarial = createField("valorMassaSalarial", "Valor Massa Salarial (R$):", {
      type: FieldType.CURRENCY,
      value: fields.valorMassaSalarial,
      required: true
    });
  }

  if (tab === TipoElementos.VINCULOS) {
    model.cnpj = cnpj;
    model.cnpj.required = false;
    model.competencia = competencia;
    model.vinculos = createField("vinculos", "Vínculos:", {
      type: FieldType.NUMERIC,
      value: fields.vinculos,
      required: true
    });
  }

  if (tab === TipoElementos.ROTATIVIDADE) {
    model.cnpj = cnpj;
    model.cnpj.required = false;
    model.ano = createField("ano", "Ano:", {
      type: FieldType.NUMERIC,
      value: fields.ano,
      required: true
    });

    model.admissoes = createField("admissoes", "Número de Admissões:", {
      type: FieldType.NUMERIC,
      value: fields.admissoes,
      required: true
    });

    model.rescisoes = createField("rescisoes", "Número de Rescisões:", {
      type: FieldType.NUMERIC,
      value: fields.rescisoes,
      required: true
    });

    model.numeroInicialVinculos = createField("numeroInicialVinculos", "Número Inicial de Vinculos:", {
      type: FieldType.NUMERIC,
      value: fields.numeroInicialVinculos,
      required: true
    });
  }

  if (tab === TipoElementos.NEXOS_TECNICOS) {
    model.nb = nb;
  }

  if (tab === TipoElementos.DESBLOQUEIO) {
    model.cnpj = cnpj;
    model.cnpj.required = false;
  }

  if (tab === TipoElementos.PERCENTIS) {
    model.cnpj = cnpj;
    model.cnpj.disabled = fields?.cnpjRaiz?.value > 0;

    model.cnpjRaiz = createField("cnpjRaiz", "Cnpj Raiz:", {
      type: FieldType.NUM_MASK,
      mask: MaskType.CNPJ_RAIZ,
      value: fields.cnpjRaiz,
      required: true
    });
  }

  if (tab === TipoElementos.TROCA_CNAE) {
    model.cnpj = cnpj;
    model.cnpj.required = false;
    model.cnae = createField("cnae", "Cnae:", {
      type: FieldType.NUM_MASK,
      mask: MaskType.CNAE,
      value: fields.cnae,
      required: true
    });
  }

  return model;
}
