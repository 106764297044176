import React from "react";

const useEscKey = (onEscape) => {
  React.useEffect(() => {
    const onEscPress = (event) => {
      if (event.keyCode === 27 && !!onEscape) {
        onEscape();
      }
    };
    window.addEventListener("keydown", onEscPress);

    return () => {
      window.removeEventListener("keydown", onEscPress);
    };
  }, [onEscape]);
};

export default useEscKey;
