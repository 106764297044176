import { convertToSelectOptions, createField, FieldType } from "../../components/Form";
import { formataDateEnBr } from "utils/StrUtils";

const TipoElementoContestado = {
  CAT: "Comunicado de Acidente de trabalho",
  NEXO_TECNICO: "Nexo Técnico",
  BENEFICIO: "Benefício",
  MASSA_SALARIAL: "Massa Salarial",
  NUMERO_MEDIO_VINCULOS: "Número Médio de Vínculos",
  ROTATIVIDADE: "Taxa de Rotatividade"
};

export const createParecerModel = (parecerObj = {}, vigencias = []) => ({
  idParecer: createField("idParecer", "", {
    required: false,
    type: FieldType.NUMERIC,
    value: parecerObj?.idParecer,
    readOnly: true
  }),
  anoVigencia: createField("anoVigencia", "Vigência onde se aplica:", {
    required: false,
    type: FieldType.SELECT,
    value: parecerObj?.anoVigencia ? String(parecerObj.anoVigencia) : "",
    options: convertToSelectOptions(vigencias),
    placeholder: "Selecione a vigência"
  }),
  tipoElemento: createField("tipoElemento", "Tipo de Elemento onde pode ser usado:", {
    required: false,
    type: FieldType.SELECT,
    value: parecerObj?.tipoElemento?.codigo,
    options: convertToSelectOptions(TipoElementoContestado),
    placeholder: "Todos"
  }),
  titulo: createField("titulo", "Título:", {
    value: parecerObj.titulo
  }),
  parecer: createField("parecer", "Parecer:", {
    type: FieldType.TEXTAREA,
    maxLength: 5000,
    value: parecerObj?.parecer
  })
});

export const columns = [
  { field: "titulo", headerName: "Título", flex: 1 },
  { field: "anoVigencia", headerName: "Vigência", minWidth: 120 },
  { field: "tipoElemento", headerName: "Tipo Elemento", flex: 1, valueGetter: ({ value }) => value?.descricao },
  { field: "dataUltimaAlteracao", headerName: "Data Reprocessado", minWidth: 150, valueGetter: ({ value }) => formataDateEnBr(value, 1) }
];
