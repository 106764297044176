import { getSumarioColumns } from "../utils";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { withStyles } from "@material-ui/core";

const SumarioContestacaoDataGrid = ({ rows = [], noRowsLabel = "" }) => {
  const columns = getSumarioColumns();

  const StyledDataGrid = withStyles({
    root: {
      minHeight: "488px",

      "& .MuiDataGrid-columnHeaderTitle": {
        lineHeight: "normal",
        whiteSpace: "normal"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: "#ededed",
        justifyContent: "center",
        maxHeight: "none !important"
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        textAlign: "center",
        justifyContent: "center"
      },

      "& .MuiDataGrid-cell": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        minHeight: "auto!important",
        height: "auto",

        lineHeight: "1.2!important",
        overflowWrap: "anywhere",
        whiteSpace: "normal"
      }
    }
  })(DataGrid);

  return (
    <div className="br-table">
      <StyledDataGrid
        localeText={{ ...ptBR.props.MuiDataGrid.localeText, noRowsLabel }}
        getRowId={(row) => row.tipoElemento?.codigo}
        rows={rows}
        columns={columns}
        density="compact"
        pageSize={6}
        rowsPerPageOptions={[6]}
        headerHeight={95}
        rowHeight={95}
        components={{}}
        disableSelectionOnClick={true}
        disableColumnMenu
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
      />
    </div>
  );
};

export default SumarioContestacaoDataGrid;
