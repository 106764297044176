import React from "react";
import { Grid } from "@material-ui/core";

import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import BrButton from "@govbr/react-components/lib/BrButton";


const ModalConfirm = ({ show, cancel, onConfirm, message = {} }) => {


  return message ? (
    <BrModal style={{padding:1}} id="modal2" visible={show} closable={true} onClose={() => cancel(false)}>

      <div style={{ height: 120, padding:20, textAlign: "center", color:"white" }} 
        className="bg-warning" >
        <span className="fa fa-question fa-5x"></span>
      </div>
      
      <div style={{ padding:50, textAlign: "center" }} 
        className="text-up-01" >
        {message}
      </div>

      <BrModalFooter>

        <Grid container  style={{padding:10}}>
          <Grid item sm={6} className="text-center"  >
            <BrButton onClick={() => cancel()}>Cancelar</BrButton>
          </Grid>

          <Grid item sm={6} className="text-center" >
            <BrButton primary onClick={() => onConfirm()}>Confirmar</BrButton>
          </Grid>
        </Grid>

      </BrModalFooter>
    </BrModal>
  ) : "";
};

export default ModalConfirm;

