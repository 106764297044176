import React from "react";
import BrLoading from "@govbr/react-components/lib/BrLoading";

const Loading = ({ show = true, overlayTarget = null, overlay = false }) => {
  return (
    <BrLoading
      size={"md"}
      overlay={overlay}
      overlayTarget={overlayTarget}
      show={show}
      label="Aguarde, carregando..."
    />
  );
};

export default Loading;
