import React from "react";
import { withStyles } from "@material-ui/core";
import { DataGrid, ptBR } from "@mui/x-data-grid";

const StyledDataGrid = withStyles({
  root: {
    minHeight: "auto",

    "& .MuiDataGrid-columnHeaderTitle": {
      lineHeight: "normal",
      whiteSpace: "normal"
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#ededed",
      justifyContent: "center"
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      textAlign: "center"
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1.2!important",
      overflowWrap: "anywhere",
      whiteSpace: "normal"
    }
  }
})(DataGrid);

const RelatoriosDataGrid = ({ rows, columns = [], rowHeight = 70, ...rest }) => {
  rows.forEach((row, index) => (row.id = index + 1));

  return (
    <div className="br-table">
      <StyledDataGrid
        autoHeight={true}
        localeText={ptBR.props.MuiDataGrid.localeText}
        rows={rows}
        columns={columns}
        density="compact"
        rowHeight={rowHeight}
        components={{}}
        disableSelectionOnClick={true}
        disableColumnMenu
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        {...rest}
      />
    </div>
  );
};

export default RelatoriosDataGrid;
