import React, { useEffect, useState } from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";
import BrModal from "@govbr/react-components/lib/BrModal";

import Form, { checkFormValidity, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import { BoxMessage } from "components/BoxMessage";
import ModalConfirm from "components/ModalConfirm";
import Loading from "components/Loading";

import { handleDownload } from "services/ApiManager";
import { useVigencias } from "hooks/useVigencias";
import VigenciasApi from "services/api/VigenciasApi";

import { anexoModel, columns, createVigenciaModel } from "./model";

export default function ExpectativaVida() {
  const { vigencias } = useVigencias();

  const [message, setMessage] = useState([]);
  const [formUploadError, setFormUploadError] = useState([]);

  const [loading, setLoading] = useState(false);

  const [vigenciaField, setVigenciaField] = useState(null);
  const [expectativaFields, setExpectativaFields] = useState(anexoModel());
  const [result, setResult] = useState([]);
  const [exibeFormExpectativa, setExibeFormExpectativa] = useState(false);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    async function _initFormAnoVigencia() {
      _setLoading(true);

      if (vigencias.length > 0) {
        const init = createVigenciaModel({ vigencias });
        setVigenciaField(init);
      }
      _setLoading(false);
    }

    _initFormAnoVigencia();
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    if (show) {
      setFormUploadError([]);
      setResult([]);
    }
  }

  function _handleChangeVigencia(event) {
    setVigenciaField(() => ({ ...vigenciaField }));
  }

  async function _handleSubmitConsulta(event) {
    _setLoading(true);

    const res = await VigenciasApi.obterExpectativaVida(vigenciaField.anoVigencia.value);
    if (!res.erros) {
      setResult(res);
      setMessage();
    } else {
      setMessage({ ...res });
    }
    _setLoading(false);
  }

  async function _handleSubmitUpload(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(expectativaFields);
    if (isFormCheckValidity && isCheckValidity) {
      const res = await VigenciasApi.carregarExpectativaVida(vigenciaField.anoVigencia.value, expectativaFields.anexo.value);
      if (!res.erros) {
        setMessage({ mensagem: "Dados importados com sucesso!", type: "success" });
        setExibeFormExpectativa(false);
      } else {
        setFormUploadError({ ...res, exibeCampo: true });
      }
    }
    _setLoading(false);
  }

  function _handleCancel(event) {
    setExpectativaFields({});
    setMessage();
    setFormUploadError([]);
    setExibeFormExpectativa(false);
  }
  async function importarCsv(row) {
    setExpectativaFields(anexoModel(row));
    setExibeFormExpectativa(true);
    setMessage();
    setFormUploadError([]);
  }

  async function _handleChangeArquivo(field) {
    setExpectativaFields(() => ({ ...expectativaFields }));
    _setLoading(false);
  }

  async function remover(event) {
    _setLoading(true);
    const res = await VigenciasApi.removerExpectativaVida(vigenciaField.anoVigencia.value);
    if (!res.erros) {
      setMessage({ mensagem: "Operação realizada com sucesso!", type: "success" });
    } else {
      setMessage({ ...res });
    }
    setConfirm(false);
    _setLoading(false);
  }

  function _handleClickDownloadModelo() {
    const MODELO_CSV = '"Idade";"ExpectativaVida";\r\n"0";"0,0"';
    const base64 = Buffer.from(MODELO_CSV).toString("base64");

    const ano = vigenciaField?.anoVigencia?.value || "";
    const nome = `modelo_${ano}_expectativa_vida.csv`;
    handleDownload({ nome, base64 });
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {null != vigenciaField && (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <BoxMessage message={message} />
          </Grid>
          <Grid item sm={3}>
            <Form onSubmit={_handleSubmitConsulta} submitTitle={"Consultar"} submitIcon={"fa-search"}>
              <Grid id="grid-vigencias" item sm={6}>
                <InputGeneric field={vigenciaField.anoVigencia} onChange={_handleChangeVigencia} />
              </Grid>
            </Form>
          </Grid>

          <Grid item style={{ paddingTop: 30, marginLeft: "auto" }}>
            <BrButton onClick={() => setConfirm(true)}>
              <span className={`fa fa-trash fa-md`}></span>
              Remover
            </BrButton>
            <BrButton onClick={() => importarCsv()}>
              <span className={`fa fa-file-upload fa-md`}></span>Carregar CSV
            </BrButton>
          </Grid>

          <Grid item sm={12}>
            <Grid container>
              <Grid item sm={12}>
                <h5>Expectativa de Vida</h5>
              </Grid>
              <Grid item sm={12}>
                <CustomDataGrid density={"compact"} getRowId={(row) => row.idade} rows={result} columns={columns} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <BrModal style={{ paddingTop: 0 }} id="modal2" visible={exibeFormExpectativa}>
        <Grid container>
          <Form onSubmit={_handleSubmitUpload} onCancel={_handleCancel} cancelTitle={"Cancelar"} submitTitle={"Carregar"} submitIcon={"fa-file-upload"}>
            <Grid item sm={12}>
              <h6> Importar tabela de expectativa de vida para vigência: {vigenciaField && vigenciaField.anoVigencia.value}</h6>
              <BrMessage warning> Os dados do ano selecionado serão sobrescritos!</BrMessage>
              <BoxMessage message={formUploadError} />
            </Grid>
            <Grid item sm={12}>
              <InputGeneric field={expectativaFields.anexo} onChange={_handleChangeArquivo} />
            </Grid>
            <Grid item style={{ paddingTop: 30, marginLeft: 0 }}>
              <BrButton title="Utilize este arquivo .csv como modelo para preencher os dados de expectativa de vida." onClick={_handleClickDownloadModelo}>
                <span className={`text-info fa fa-download`}></span>Baixar Modelo CSV
              </BrButton>
            </Grid>
          </Form>
        </Grid>
      </BrModal>

      {vigenciaField && (
        <ModalConfirm
          show={confirm}
          message={"Deseja remover dos dados de expectativa de vida da vigência " + vigenciaField.anoVigencia.value + " ?"}
          onConfirm={() => remover()}
          cancel={() => setConfirm(false)}
        />
      )}
    </React.Fragment>
  );
}
