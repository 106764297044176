import { getApi } from "../ApiManager";

const contextoVigencia = "/vigencias";
const contexto = "/mensagens";

function filtrarMensagens(params, mensagens) {
  const { anoVigencia = "", cnpjRaiz = "", cnpj = "", cnae = "" } = params;
  if (!mensagens.erros) {
    mensagens = mensagens.filter((m) => {
      if (anoVigencia && m.anoVigencia !== Number(anoVigencia)) {
        return false;
      } else if (cnpjRaiz.toString() && m.cnpjRaiz !== Number(cnpjRaiz.toString().replace(/[^0-9]/g, ""))) {
        return false;
      } else if (cnpj.toString() && m.cnpj !== Number(cnpj.toString().replace(/[^0-9]/g, ""))) {
        return false;
      } else if (cnae.toString() && m.cnae !== Number(cnae.toString().replace(/[^0-9]/g, ""))) {
        return false;
      } else {
        return true;
      }
    });
  }
  return mensagens;
}

const MensagensApi = {
  obterTodas: async (params) => {
    let { anoVigencia = "" } = params;
    const Api = getApi();
    let mensagens = await Api.get(`${contextoVigencia}/${anoVigencia}${contexto}`);
    return filtrarMensagens(params, mensagens);
  },
  alterar: async (params) => {
    let { anoVigencia = "", id = "" } = params;
    const Api = getApi();
    return Api.put(`${contextoVigencia}/${anoVigencia}${contexto}/${id}`, params);
  },
  criar: async (params) => {
    let { anoVigencia = "" } = params;
    const Api = getApi();
    return Api.post(`${contextoVigencia}/${anoVigencia}${contexto}`, params);
  },
  remover: async (params) => {
    let { anoVigencia = "", id = "" } = params;
    const Api = getApi();
    return Api.delete(`${contextoVigencia}/${anoVigencia}${contexto}/${id}`);
  }
};

export default MensagensApi;
