import React from "react";
import BrModal from "@govbr/react-components/lib/BrModal";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, InputGeneric } from "components/Form";

import { createDistribuirModel } from "../model";

const DistribuirModal = ({ onConfirm, onCancel, selectedRows = [] }) => {
  const [fields, setFields] = React.useState(() => createDistribuirModel());

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
  }
  async function _handleSubmit(event) {
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      await onConfirm(fields.cpfResponsavel.value);
    }
    setFields(() => ({ ...fields }));
  }

  return (
    <BrModal style={{ padding: 0 }} visible={true} closable={true} onClose={() => onCancel(false)}>
      <div style={{ height: 120, padding: 20, textAlign: "center", color: "white" }} className="bg-info">
        <span className="fa fa-arrow-right fa-5x"></span>
      </div>
      <Grid container style={{ padding: 10 }}>
        <Form onSubmit={_handleSubmit} submitTitle={"Distribuir"} onCancel={() => onCancel(false)} cancelTitle={"Cancelar"}>
          <Grid item sm={12}>
            <h5> Distribuir contestação </h5>
            <p> {selectedRows.length === 1 ? selectedRows.length + " contestação selecionada." : selectedRows.length + " contestações selecionadas."} </p>
          </Grid>

          <Grid item sm={12}>
            <InputGeneric field={fields.cpfResponsavel} onChange={_handleChange} />
          </Grid>
        </Form>
      </Grid>
    </BrModal>
  );
};

export default DistribuirModal;
