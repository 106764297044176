import React from "react";
import {Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";
import useEscKey from "hooks/useEscKey";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import CustomCard from "components/CustomCard";

const ModalAvisosImportantes = ({ show = false, avisosImportantes = [], onClose }) => {
  useEscKey(() => onClose());

  return show ? (
    <div className="modal-table">
      <div className="modal-table-content">
        <CustomCard title={"Avisos Importantes"}>
        <Grid item sm={12}>
          {avisosImportantes.map((m, i) => {
              return <BrMessage key={"m_"+i} warning>{m.texto}</BrMessage>;
            })}
          </Grid>

          <Grid item sm={12} className="text-center" style={{ paddingTop: 10 }}>
            <BrButton small onClick={() => onClose()}>
              Fechar
            </BrButton>
          </Grid>
        </CustomCard>
      </div>
    </div>

  ) : null;
};

export default ModalAvisosImportantes;