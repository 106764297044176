import React, { useState, useEffect } from "react";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import { checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import { useVigencias } from "hooks/useVigencias";
import VigenciasApi from "services/api/VigenciasApi";

import { createModel, createVigenciaModel } from "./model";
import FormAnoVigencia from "./components/FormAnoVigencia";
import FormVigencia from "./components/FormVigencia";

export default function DatasPrazos() {
  const { vigencias, atualizaVigencias } = useVigencias();

  const [formError, setFormError] = useState([]);

  const [loading, setLoad] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(null);

  const [anoVigenciaFields, setAnoVigenciaFields] = useState(createModel());
  const [vigenciaFields, setVigenciaFields] = useState(null);

  const [proximaVigencia, setProximaVigencia] = useState(null);
  const [exibeSucesso, setExibeSucesso] = useState(false);

  useEffect(() => {
    async function _initForm() {
      setLoading(true, "grid-vigencias");

      handleReset();
      if (vigencias.length > 0) {
        setAnoVigenciaFields(() => createModel({ vigencias }));
      }
      setLoading(false);
    }
    _initForm();
  }, [vigencias]);

  function setLoading(show, overlay) {
    setLoad(show);
    setLoadingOverlay(overlay ? overlay : null);
    if (show) {
      setFormError([]);
    }
  }

  async function handleReset(event) {
    setVigenciaFields(null);
    setProximaVigencia(null);
    setFormError([]);
    }

  function _handleChangeAnoVigenciaFields(event) {
    setAnoVigenciaFields(() => ({ ...anoVigenciaFields }));
  }

  function _handleChangeVigenciaFields(event) {
    setVigenciaFields(() => ({ ...vigenciaFields }));
  }

  async function _carregarDadosVigencia(event) {
    setLoading(true);

    const res = await VigenciasApi.obterVigencia(anoVigenciaFields.anoVigencia.value);
    if (!res.erros) {
      setVigenciaFields(createVigenciaModel(res));
    } else {
      setFormError(res.erros);
      setVigenciaFields(null);
    }
    setLoading(false);
  }

  async function salvarDadosVigencia(event) {
    setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(vigenciaFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(vigenciaFields);
      let res = {};
      if (proximaVigencia) {
        requestBody.anoVigencia = proximaVigencia;
        res = await VigenciasApi.criarVigencia(requestBody.anoVigencia, requestBody);
      } else {
        res = await VigenciasApi.alterarVigencia(requestBody.anoVigencia, requestBody);
      }
      if (!res.erros) {
        setExibeSucesso(true);
        atualizaVigencias();
      } else {
        setFormError(res.erros);
      }
    } else {
      setVigenciaFields(() => ({ ...vigenciaFields }));
    }
    setLoading(false);
  }

  async function criarVigencia(event) {
    setLoading(true);
    handleReset();
    setVigenciaFields(createVigenciaModel());
    setProximaVigencia(Number(vigencias[0]) + 1);
    setLoading(false);
  }

  async function removerVigencia(event) {
    setLoading(true);
    if (anoVigenciaFields.anoVigencia.value) {
      let res = await VigenciasApi.removerVigencia(anoVigenciaFields.anoVigencia.value);
      if (!res.erros) {
        atualizaVigencias();
        setExibeSucesso(true);
      } else {
        if (res.erros.length > 0) {
          setFormError(res.erros);
        } else {
          setFormError([{ mensagem: res.mensagem }]);
        }
      }
    }
    setLoading(false);
  }

  async function reprocessaVigencia(event) {
    setLoading(true);
    if (anoVigenciaFields.anoVigencia.value) {
      let res = await VigenciasApi.reiniciarProcessamento(anoVigenciaFields.anoVigencia.value);
      if (!res.erros) {
        atualizaVigencias();
        setExibeSucesso(true);
      } else {
        if (res.erros.length > 0) {
          setFormError(res.erros);
        } else {
          setFormError([{ mensagem: res.mensagem }]);
        }
      }
    }
    setLoading(false);
  }
  return (
    <React.Fragment>
      <Loading overlay overlayTarget={loadingOverlay} show={loading} />
      <Grid container>
        {!vigenciaFields && (
          <Grid container>
            <Grid item sm={4}>
              <FormAnoVigencia formError={formError} onSubmit={_carregarDadosVigencia} onChange={_handleChangeAnoVigenciaFields} fields={anoVigenciaFields} />
            </Grid>
            <Grid item sm={8} style={{ paddingTop: 20, textAlign: "right" }}>
              <BrButton secondary onClick={() => criarVigencia()}>
                Criar próxima vigência
              </BrButton>
            </Grid>
          </Grid>
        )}

        {!proximaVigencia && vigenciaFields && (
          <FormVigencia
            onSubmit={salvarDadosVigencia}
            onCancel={handleReset}
            onChange={_handleChangeVigenciaFields}
            formError={formError}
            onDelete={removerVigencia}
            onReprocessa={vigenciaFields.anoVigencia.value === Number(vigencias[0])? reprocessaVigencia: false}
            submitTitle={"Salvar"}
            fields={vigenciaFields}
          />
        )}

        {proximaVigencia && vigenciaFields && (
          <FormVigencia
            onSubmit={salvarDadosVigencia}
            onCancel={handleReset}
            onChange={_handleChangeVigenciaFields}
            formError={formError}
            submitTitle={"Criar Vigência"}
            proximaVigencia={proximaVigencia}
            fields={vigenciaFields}
          />
        )}
      </Grid>
      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
}
