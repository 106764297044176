const USER_PAPEIS = [];

function hydrateUserPapeis(papeis = []) {
  Object.keys(USER_PAPEIS).forEach((k) => (USER_PAPEIS[k] = null));
  USER_PAPEIS.length = 0;

  papeis.forEach((p) => {
    USER_PAPEIS.push(p);
  });
}

function Menu({ id, label, roles = [], url, items, homeIcon, isHome = false, breadcrumbs, description }) {
  this.id = id;
  this.label = label;
  this.roles = roles;
  this.url = url;
  this.homeIcon = homeIcon;
  this.isHome = isHome;
  this.breadcrumbs = breadcrumbs;
  this.description = description;

  if (!!items && items.length > 0) {
    this.items = [];
    items.forEach((i) => {
      this.items.push(new Menu(i));
    });
  }
}

Menu.prototype.isRoute = function () {
  return !!this.url;
};

Menu.prototype.isAuthorized = function () {
  return USER_PAPEIS.length > 0 && this.roles.length > 0 && USER_PAPEIS.some((p) => this.roles.includes(p));
};

export function createMenus(arrayMenus = [], userPapeis = []) {
  const menus = [];
  const flatMenus = [];

  hydrateUserPapeis(userPapeis);

  for (let i = 0; i < arrayMenus.length; i++) {
    const m = arrayMenus[i];

    const obj = new Menu(m);
    menus.push(new Menu(m));
    flatMenus.push(obj);
    !!obj.items && obj.items.length > 0 && obj.items.forEach((i) => flatMenus.push(i));
  }

  return { menus, flatMenus };
}
