import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";

import { TipoDeferimentoProcuracao, TipoProcuracao, TipoSituacaoProcuracao } from "utils/TipoUtils";
import { formataCNPJRaiz, formataCPF, formataDateEnBr } from "utils/StrUtils";

const [CONCESSAO_ACESSO, INCORPORACAO] = Object.keys(TipoProcuracao);

function isIncorporacao(tipo) {
  return INCORPORACAO === tipo;
}

function isConcessao(tipo) {
  return CONCESSAO_ACESSO === tipo;
}

function getOptions(empresas) {
  return convertToSelectOptions(
    empresas,
    (e) => `${formataCNPJRaiz(e.cnpj)} - ${e.nome}`,
    (e) => String(e.cnpj)
  );
}

export const filtroModel = () => ({
  dataInicio: createField("dataInicio", "Início:", {
    type: FieldType.DATE,
    required: false
  }),
  dataFim: createField("dataFim", "Fim:", {
    type: FieldType.DATE,
    required: false
  }),
  situacao: createField("situacao", "Situação:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoSituacaoProcuracao),
    required: false
  }),
  tipoProcuracao: createField("tipoProcuracao", "Tipo Procuração:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoProcuracao),
    required: false
  }),
  cnpjRaizOutorgante: createField("cnpjRaizOutorgante", "CNPJ Raiz do Outorgante:", {
    mask: MaskType.CNPJ_RAIZ,
    required: false
  }),
  cnpjRaizOutorgado: createField("cnpjRaizOutorgado", "CNPJ Raiz do Outorgado:", {
    mask: MaskType.CNPJ_RAIZ,
    required: false
  }),
  cpfOutorgado: createField("cpfOutorgado", "CPF do Outorgado:", {
    mask: MaskType.CPF,
    required: false
  })
});

export const createModel = (empresasVinculadas = [], todasEmpresas = [], fields = {}) => ({
  dataInicio: createField("dataInicio", "Início:", {
    type: FieldType.DATE,
    value: fields?.dataInicio?.value
  }),
  dataFim: createField("dataFim", "Fim:", {
    type: FieldType.DATE,
    required: false,
    value: fields?.dataFim?.value
  }),
  tipoProcuracao: createField("tipoProcuracao", "Tipo Procuração:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoProcuracao),
    value: fields?.tipoProcuracao?.value
  }),
  cnpjRaizOutorgado: createField("cnpjRaizOutorgado", "CNPJ Raiz do Outorgado:", {
    mask: isIncorporacao(fields?.tipoProcuracao?.value) ? null : MaskType.CNPJ_RAIZ,
    type: isIncorporacao(fields?.tipoProcuracao?.value) ? FieldType.SELECT : FieldType.TEXT,
    options: isIncorporacao(fields?.tipoProcuracao?.value) ? getOptions(empresasVinculadas) : [],
    value: fields?.cnpjRaizOutorgado?.value,
    required: !fields?.cpfOutorgado?.value
  }),
  cnpjRaizOutorgante: createField("cnpjRaizOutorgante", "CNPJ Raiz do Outorgante:", {
    mask: isConcessao(fields?.tipoProcuracao?.value) ? null : MaskType.CNPJ_RAIZ,
    type: isConcessao(fields?.tipoProcuracao?.value) ? FieldType.SELECT : FieldType.TEXT,
    options: isConcessao(fields?.tipoProcuracao?.value) ? getOptions(todasEmpresas) : [],
    value: fields?.cnpjRaizOutorgante?.value
  }),
  cpfOutorgado: createField("cpfOutorgado", "CPF do Outorgado:", {
    mask: MaskType.CPF,
    required: isIncorporacao(fields?.tipoProcuracao?.value) ? false : !fields?.cnpjRaizOutorgado?.value,
    value: isIncorporacao(fields?.tipoProcuracao?.value) ? "" : fields?.cpfOutorgado?.value,
    disabled: isIncorporacao(fields?.tipoProcuracao?.value)
  }),
  arquivo: createField("arquivo", "Arquivo:", {
    type: FieldType.FILE,
    accept: ".pdf",
    required: isIncorporacao(fields?.tipoProcuracao?.value),
    value: isConcessao(fields?.tipoProcuracao?.value) ? "" : fields?.arquivo?.value,
    disabled: isConcessao(fields?.tipoProcuracao?.value)
  }),
  justificativa: createField("justificativa", "Justificativa:", {
    type: FieldType.TEXTAREA,
    required: isIncorporacao(fields?.tipoProcuracao?.value),
    value: isConcessao(fields?.tipoProcuracao?.value) ? "" : fields?.justificativa?.value,
    disabled: isConcessao(fields?.tipoProcuracao?.value)
  })
});

export const parecerModel = () => ({
  tipoDeferimento: createField("tipoDeferimento", "Tipo Deferimento:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoDeferimentoProcuracao)
  }),
  parecer: createField("parecer", "Parecer:", {
    type: FieldType.TEXTAREA
  })
});

export const createResumoProcuracaoModel = (procuracao = {}) => ({
  tipoProcuracao: createField("tipoProcuracao", "Tipo da Procuração:", {
    readMode: true,
    value: procuracao?.tipoProcuracao?.descricao
  }),
  cnpjRaizOutorgante: createField("cnpjRaizOutorgante", "CNPJ Raiz do Outorgante:", {
    readMode: true,
    value: formataCNPJRaiz(procuracao.cnpjRaizOutorgante)
  }),
  cnpjRaizOutorgado: createField("cnpjRaizOutorgado", "CNPJ Raiz do Outorgado:", {
    readMode: true,
    value: formataCNPJRaiz(procuracao.cnpjRaizOutorgado)
  }),
  cpfOutorgado: createField("cpfOutorgado", "CPF do Outorgado:", {
    readMode: true,
    value: formataCPF(procuracao.cpfOutorgado)
  }),
  dataCadastro: createField("dataCadastro", "Data Cadastro:", {
    readMode: true,
    value: formataDateEnBr(procuracao.dataCadastro)
  }),
  dataInicio: createField("dataInicio", "Data Início da Validade:", {
    readMode: true,
    value: formataDateEnBr(procuracao.dataInicio)
  }),
  dataFim: createField("dataFim", "Data Fim da Validade:", {
    readMode: true,
    value: formataDateEnBr(procuracao.dataFim)
  }),
  dataExclusao: createField("dataExclusao", "Data Exclusão:", {
    readMode: true,
    value: formataDateEnBr(procuracao.dataExclusao)
  }),
  situacao: createField("situacao", "Situação:", {
    readMode: true,
    value: procuracao?.situacao?.descricao
  }),
  justificativa: createField("justificativa", "Justificativa:", {
    readMode: true,
    value: procuracao?.justificativa,
    type: FieldType.TEXTAREA
  }),
  tipoDeferimento: createField("tipoDeferimento", "Tipo Deferimento:", {
    readMode: true,
    value: procuracao?.tipoDeferimento?.descricao
  }),
  parecer: createField("parecer", "Parecer:", {
    readMode: true,
    value: procuracao.parecer,
    type: FieldType.TEXTAREA
  })
});
