import React from "react";
import Loading from "../../components/Loading";
import FormLogAuditoria from "./components/FormLogAuditoria";
import LogAuditoriaDataGrid from "./components/LogAuditoriaDataGrid";
import { logAuditoriaDataGridColumns } from "./components/model";
import LogAuditoriaApi from "../../services/api/LogAuditoriaApi";

const LogAuditoria = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [loadingOverlay, setLoadingOverlay] = React.useState(null);

  const [formError, setFormError] = React.useState([]);
  const [logs, setLogs] = React.useState([]);

  function _setLoading(show, overlay = null) {
    setLoading(show);
    setLoadingOverlay(overlay);
    if (show) {
      setFormError([]);
    }
  }

  async function _consultar(fieldsJson) {
    _setLoading(true);
    const res = await LogAuditoriaApi.obter({ ...fieldsJson });
    if (!res.erros) {
      setLogs(res);
    } else {
      setLogs([]);
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay overlayTarget={loadingOverlay} show={loading} />
      <FormLogAuditoria onSubmit={_consultar} loading={loading} formError={formError} />
      <LogAuditoriaDataGrid rows={logs} columns={logAuditoriaDataGridColumns} />
    </React.Fragment>
  );
};

export default LogAuditoria;
