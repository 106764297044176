import React, { useState, useEffect } from "react";
import Form, { InputGeneric } from "../../components/Form";
import TaxaMortalidadeApi from "../../services/api/TaxaMortalidadeApi";
import { Grid } from "@material-ui/core";
import BrLoading from "@govbr/react-components/lib/BrLoading";
import BrButton from "@govbr/react-components/lib/BrButton";

import { checkFormValidity, convertFormFieldsToJsonObj } from "../../components/Form/formUtils";
import { createModel } from "./model";
import { Divider } from "@material-ui/core";

import CustomDataGrid from "../../components/CustomDataGrid";
import { formataDecimal } from "utils/StrUtils";
import { BoxMessage } from "components/BoxMessage";

const actions = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST"
};

export default function TaxaMortalidade() {
  const [loading, setLoad] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(null);
  const [formMessage, setFormMessage] = useState([]);
  const [fields, setFields] = useState(createModel());
  const [taxasMortalidade, setTaxasMortalidade] = useState([]);
  const [action, setAction] = useState(actions.GET);

  useEffect(() => {
    async function _initForm() {
      obterTaxas();
      setFormMessage();
    }
    _initForm();
  }, []);

  function setLoading(show, overlay) {
    setLoad(show);
    setLoadingOverlay(overlay ? overlay : null);
  }

  function _handleChange(event) {
    setFields(() => ({ ...fields }));
  }

  async function obterTaxas() {
    let res = await TaxaMortalidadeApi.obterTaxasMortalidades();
    if (!res.erros) {
      setTaxasMortalidade(res);
      setAction(actions.GET);
    } else {
      setFormMessage({ ...res });
      setTaxasMortalidade([]);
    }
  }

  async function handleReset(event) {
    setAction(actions.GET);
  }

  async function editar(obj) {
    setFields(createModel(obj, actions.PUT));
    setAction(actions.PUT);
    setFormMessage();
  }

  async function novaTaxa() {
    setFields(createModel({}, actions.POST));
    setAction(actions.POST);
    setFormMessage();
  }

  async function salvar(event) {
    setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      let res = null;

      if (action === actions.PUT) {
        let params = {
          ano: requestBody.ano
        };
        delete requestBody.ano;
        res = await TaxaMortalidadeApi.alterarTaxaMortalidade(params, requestBody);
      } else {
        res = await TaxaMortalidadeApi.criarTaxaMortalidade(requestBody);
      }
      if (!res.erros) {
        setFormMessage({ mensagem: "Operação realizada com sucesso!", type: "success" });
        handleReset();
        obterTaxas();
      } else {
        setFormMessage({ ...res });
      }
    }
    setFields(() => ({ ...fields }));
    setLoading(false);
  }

  async function remover(obj) {
    setLoading(true);
    let res = await TaxaMortalidadeApi.removerTaxaMortalidade(obj.ano);
    if (!res.erros) {
      setFormMessage({ mensagem: "Operação realizada com sucesso!", type: "success" });
      handleReset();
      obterTaxas();
    } else {
      setFormMessage({ ...res });
    }
    setLoading(false);
  }

  const columns = [
    { field: "ano", headerName: "Ano", flex: 1 },
    { field: "taxaMortalidade", headerName: "Taxa de Mortalidade", flex: 1, valueGetter: ({ value }) => formataDecimal(value, 2) }
  ];

  return (
    <React.Fragment>
      <BrLoading size={"md"} overlay overlayTarget={loadingOverlay} show={loading} label="Aguarde, carregando..." />
      <Grid container>
        <BoxMessage message={formMessage} />
        {action === actions.GET && (
          <Grid container>
            <Grid item sm={6}>
              <h5>Taxas de Mortalidade Anuais</h5>
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 23, textAlign: "right" }}>
              <BrButton small secondary onClick={() => novaTaxa()}>
                Incluir Taxa de Mortalidade Anual
              </BrButton>
            </Grid>
            <Grid item sm={12}>
              <CustomDataGrid
                onEdit={editar}
                onDelete={remover}
                onDeleteMessage="Deseja remover a Taxa de Mortalidade?"
                getRowId={(row) => row.ano}
                rows={taxasMortalidade}
                columns={columns}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {action !== actions.GET && fields && (
        <Grid container>
          <Grid item sm={12}>
            <h5>{action === actions.POST ? "Nova Taxa de Mortalidade Anual" : "Atualizar Taxa de Mortalidade Anual"}</h5>
            <Divider style={{ marginTop: 15, marginBottom: 30 }} />
          </Grid>
          <Grid item sm={12}>
            <Form onSubmit={salvar} submitTitle={action === actions.POST ? "Salvar" : "Atualizar"} onCancel={handleReset} cancelTitle={"Cancelar"}>
              <Grid item sm={2}>
                <InputGeneric field={fields.ano} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={fields.taxaMortalidade} onChange={_handleChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
