import { getApi, handleDownload } from "../ApiManager";

const contexto = "/anexos";
const contextoVigencia = "vigencias";
const contextoEmpresa = "empresa";
const contextoCalculos = "calculos";
const contextoAnexos = "anexos";

const AnexosApi = {
  anexarArquivo: (params, file) => {
    return getApi().upload(contexto, params, file);
  },
  obterAnexos: (anoVigencia, cnpj, id) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}/${contextoEmpresa}/${cnpj}/${contextoCalculos}/${id}/${contextoAnexos}`);
  },
  obterAnexosAdm: (params) => getApi().get(`${contexto}`, { params }),
  removerAnexos: (id) => getApi().delete(`${contexto}/${id}`),
  downloadAnexo: (anoVigencia, cnpj, id, idAnexo) =>
    getApi().get(`${contextoVigencia}/${anoVigencia}/${contextoEmpresa}/${cnpj}/${contextoCalculos}/${id}/${contextoAnexos}/${idAnexo}/download`).then(handleDownload),
  downloadAnexoAdm: (id) => {
    return getApi().get(`${contexto}/${id}/download`).then(handleDownload);
  }
};

export default AnexosApi;
