import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@material-ui/core";

import Form, { InputGeneric, checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import { createParametrosModel, createVigenciaModel } from "./model";
import { useVigencias } from "hooks/useVigencias";
import VigenciasApi from "services/api/VigenciasApi";

export default function Parametros() {
  const { vigencias } = useVigencias();

  const [formError, setFormError] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(null);

  const [vigenciaField, setVigenciaField] = useState(null);
  const [parametrosFields, setParametrosFields] = useState(null);
  const [exibeSucesso, setExibeSucesso] = useState(false);

  useEffect(() => {
    async function _initFormAnoVigencia() {
      _setLoading(true, "grid-vigencias");

      if (vigencias.length > 0) {
        const init = createVigenciaModel({ vigencias });
        setVigenciaField(init);
      }
      _setLoading(false);
    }

    _initFormAnoVigencia();
  }, [vigencias]);

  function _setLoading(show, overlay) {
    setLoading(show);
    setLoadingOverlay(overlay ? overlay : null);
    if (show) {
      setFormError([]);
    }
  }

  function _handleChangeVigencia(event) {
    setVigenciaField(() => ({ ...vigenciaField }));
  }

  async function _loadParametros(event) {
    _setLoading(true);

    const res = await VigenciasApi.obterParametros(vigenciaField.anoVigencia.value);
    if (!res.erros) {
      setParametrosFields(createParametrosModel(res));
    } else {
      setParametrosFields(null);
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  function _handleChange(field) {
    const { cnaeUltVigencia, arqCnaes, correspCnaeUltVigencia, arqCorrespondenciaCnae } = parametrosFields;

    if (field.id === cnaeUltVigencia.id) {
      _handleSwitchCnae(cnaeUltVigencia.value, arqCnaes);
    }
    if (field.id === correspCnaeUltVigencia.id) {
      _handleSwitchCnae(correspCnaeUltVigencia.value, arqCorrespondenciaCnae);
    }
    setParametrosFields(() => ({ ...parametrosFields }));
  }

  function _handleSwitchCnae(switchValue, field) {
    if (switchValue) {
      field.value = "";
      field.required = false;
      field.disabled = true;
    } else {
      field.value = "";
      field.required = true;
      field.disabled = false;
    }
  }

  async function _handleSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(parametrosFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(parametrosFields);
      const res = await VigenciasApi.alterarParametros(requestBody.anoVigencia, requestBody);
      if (!res.erros) {
        setParametrosFields(createParametrosModel(res));
        setExibeSucesso(true);
      } else {
        setFormError(res.erros);
      }
    } else {
      setParametrosFields(() => ({ ...parametrosFields }));
    }
    _setLoading(false);
  }

  function _handleCancel(event) {
    setParametrosFields(null);
    setFormError([]);
  }

  return (
    <React.Fragment>
      <Loading overlay overlayTarget={loadingOverlay} show={loading} />
      <Grid container>
        {null != vigenciaField && (
          <Grid container>
            <Grid item sm={4}>
              <Form onSubmit={_loadParametros} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={formError}>
                <Grid id="grid-vigencias" item sm={6}>
                  <InputGeneric field={vigenciaField.anoVigencia} onChange={_handleChangeVigencia} />
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}

        {null != parametrosFields && (
          <Grid item sm={12} className={"form-parametros-vigencia"}>
            <Form onSubmit={_handleSubmit} onCancel={_handleCancel} submitTitle={"Salvar"} cancelTitle={"Cancelar"}>
              <Grid item sm={2} style={{ display: "none" }}>
                <InputGeneric field={parametrosFields.anoVigencia} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>Estabelecimentos</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={parametrosFields.particaoEstabelecimentos} onChange={_handleChange} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={parametrosFields.arquivoEstabelecimentos} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>CNAE</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={parametrosFields.cnaeUltVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqCnaes} onChange={_handleChange} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={parametrosFields.correspCnaeUltVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqCorrespondenciaCnae} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>GFIPWEB</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoVinculos} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqVinculos01} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqVinculos02} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoGfipweb} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqGfipweb01} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqGfipweb02} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>eSocial</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoEsocialVinculos} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqEsocialVinculos01} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqEsocialVinculos02} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoEsocial} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqEsocial01} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqEsocial02} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqObrigEntregaEsocial} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>Benefícios</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoBeneficios} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqBeneficios01} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqBeneficios02} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoBeneficiosB93} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqBeneficiosB9301} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqBeneficiosB9302} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>CATs</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoCats} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqCats01} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqCats02} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqCats03} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.arqCats04} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>Agrupamentos</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={parametrosFields.particaoAgrupVinculos} onChange={_handleChange} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={parametrosFields.particaoAgrupBeneficios} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>Peso Cálculo FAP</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={parametrosFields.pesoIndiceComposto} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={parametrosFields.pesoCusto} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={parametrosFields.pesoFrequencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={parametrosFields.pesoGravidade} onChange={_handleChange} />
              </Grid>

              <Grid item sm={12}>
                <h5>Cálculos</h5>
                <Divider style={{ marginTop: 20, marginBottom: 10 }} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoIndices} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoPercentis} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parametrosFields.particaoCalculoFap} onChange={_handleChange} />
              </Grid>
              <Grid item sm={8} />
            </Form>
          </Grid>
        )}
      </Grid>

      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
}
