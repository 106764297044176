import { getApi } from "../ApiManager";

const contexto = "/procuracoes";

const ProcuracoesApi = {
  obterProcuracoes: (params) => getApi().get(`${contexto}`, { params }),
  obterProcuracao: (protocolo) => getApi().get(`${contexto}/${protocolo}`),
  removerProcuracao: (protocolo) => getApi().delete(`${contexto}/${protocolo}`),
  criarParecer: (protocolo, body) => getApi().post(`${contexto}/${protocolo}/parecer`, body),
  obterEmpresasProcuracoes: () => getApi().get(`${contexto}/empresas`),
  criarProcuracao: (params, file) => {
    return getApi().upload(contexto, params, file);
  }
};

export default ProcuracoesApi;
