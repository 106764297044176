import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";

import usePrevious from "./usePrevious";

const isEqual = (a1 = [], a2 = []) => JSON.stringify(a1) === JSON.stringify(a2);

export const FormErrorPanel = ({ messages = [] }) => {
  const previous = usePrevious(messages);

  if (!messages || messages.length === 0) {
    return null;
  }

  if (!isEqual(messages, previous)) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <BrMessage error>
      {/* TODO: colocar overflow com scrooll pra qndo a lista de erros for mto grande */}
      <ul className="m-1" style={{ fontSize: 12 }}>
        {messages.map((error, index) => {
          return <li key={`err-${index}`}>{error.mensagem}</li>;
        })}
      </ul>
    </BrMessage>
  );
};

const CustomFormButtons = ({ submitTitle, onCancel, cancelTitle, isLoading, disabled, icon}) => (
  <Grid item style={{ paddingTop: 34, marginLeft: "auto" }}>
    <Grid container spacing={1}>
      {onCancel && (
        <Grid item>
          <BrButton secondary onClick={onCancel}>
            {cancelTitle}
          </BrButton>
        </Grid>
      )}

      <Grid item>
        <BrButton submit loading={isLoading} disabled={disabled} primary>
          {icon && <span className={`fa ${icon} fa-md`}></span>}
          {submitTitle || "Enviar"}
        </BrButton>
      </Grid>
    </Grid>
  </Grid>
);

const CustomForm = ({ formTitle, onSubmit, submitTitle, onCancel, cancelTitle, loading = false, disabled = false, errors = [], submitIcon, children, ...rest }, ref) => {
  const [formIsLoading, setFormIsLoading] = React.useState(loading);

  function onFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    setFormIsLoading(true);
    onSubmit(event);
    setFormIsLoading((f) => false);
  }

  return (
    <form autoComplete="off" noValidate onSubmit={onFormSubmit} ref={ref}>
      <FormErrorPanel messages={errors} />

      <Grid container spacing={2}>
        {formTitle && (
          <Grid item sm={12}>
            <h2>{formTitle}</h2>
            <Divider />
          </Grid>
        )}

        {children}
        <CustomFormButtons
          {...{            
            submitTitle,
            onCancel,
            cancelTitle,
            icon: submitIcon,
            isLoading: loading || formIsLoading,
            disabled
          }}
        />
      </Grid>
    </form>
  );
};

const RefCustomForm = React.forwardRef(CustomForm);
export default RefCustomForm;
