import React from "react";
import BrNotification from "@govbr/react-components/lib/BrNotification";
import BrButton from "@govbr/react-components/lib/BrButton";
import BrTabs from "@govbr/react-components/lib/BrTabs";

import { TipoPapel, TipoPapelDescricao } from "utils/TipoUtils";
import { formataCPF } from "utils/StrUtils";

function getPapeisDescricaoByPapeisValue(papeis = []) {
  return Object.keys(TipoPapel)
    .filter((k) => !!papeis && papeis.includes(TipoPapel[k]) && !!TipoPapelDescricao[k])
    .map((k) => TipoPapelDescricao[k]);
}

export const UserMessages = () => {
  // abas de mensagens e notificacoes ainda inutilizadas
  const [activeTab, setActiveTab] = React.useState("tabNotificacoes");

  return (
    <BrNotification.Body>
      <BrTabs label="tabs" value={activeTab} onChange={(tab) => setActiveTab(tab)}>
        <BrTabs.Tabs label="Teste de Tabs">
          <BrTabs.Tab id="tabNotificacoes" label="Notificações" iconClass="fas fa-bell" />
          <BrTabs.Tab id="tabMensagens" label="Mensagens" iconClass="fas fa-envelope" />
        </BrTabs.Tabs>
        <BrTabs.Content>
          <BrTabs.Panel contentFor="tabNotificacoes"></BrTabs.Panel>
        </BrTabs.Content>
      </BrTabs>
    </BrNotification.Body>
  );
};

const UserTabs = ({ papeis }) => {
  const nomesPapeis = getPapeisDescricaoByPapeisValue(papeis);

  if (!nomesPapeis.length) {
    return null;
  }

  return (
    <BrNotification.Body>
      <BrTabs label="tabs" value={"tabPapeis"}>
        <BrTabs.Tabs>
          <BrTabs.Tab id="tabPapeis" label="Papéis" iconClass="fas fa-users" />
        </BrTabs.Tabs>
        <BrTabs.Content>
          <BrTabs.Panel contentFor="tabPapeis">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {nomesPapeis.map((p, index) => (
                <span key={`papel-${index}`}>{p}</span>
              ))}
            </div>
          </BrTabs.Panel>
        </BrTabs.Content>
      </BrTabs>
    </BrNotification.Body>
  );
};

const UserMenu = ({ triggerRef, isOpenUserMenu = true, handleLogout, user }) => {
  const { email, firstName, papeis, document } = user;
  const [openCloseUserMenu, setOpenCloseUserMenu] = React.useState(isOpenUserMenu);

  return (
    <BrNotification style={{ maxWidth: 375 }} triggerRef={triggerRef} id="notifications-menu" open={openCloseUserMenu} mini={false} onClose={() => setOpenCloseUserMenu(false)}>
      <BrNotification.Header userEmail={email} userName={firstName + " " + formataCPF(document)} />

      <UserTabs papeis={papeis} />

      <BrNotification.Footer>
        <BrButton small secondary onClick={handleLogout}>
          Sair
        </BrButton>
      </BrNotification.Footer>
    </BrNotification>
  );
};

export default UserMenu;
