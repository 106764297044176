import React from "react";
import moment from "moment";
import { Switch } from "@material-ui/core";
import BrInput from "@govbr/react-components/lib/BrInput";
import BrSelect from "@govbr/react-components/lib/BrSelect";
import BrDatepicker from "@govbr/react-components/lib/BrDatepicker";
import BrTextarea from "@govbr/react-components/lib/BrTextarea";
import BrCheckbox from "@govbr/react-components/lib/BrCheckbox";
import BrRadio from "@govbr/react-components/lib/BrRadio";

import { FieldType } from "./types";
import { convertFieldToJsonValue, isUndefined } from "./formUtils";

const _handleSelectFile = (file, field, onSelectCallback) => {
  let f = null;
  //todo não suporta multiple
  if (field.multiple) {
    f = file.target.files;
  } else {
    f = file.target.files[0];
  }
  field.value = f;
  onSelectCallback(field);
};
const _handleChange = (event, field, onChangeCallback = (f) => {}) => {
  const isInputEvent = event && event.target;
  const nextValue = isInputEvent ? event.target.value : event;
  const previusValue = field.value;
  if (nextValue !== previusValue) {
    field.value = nextValue;
  } else {
    return;
  }
  onChangeCallback(field);
};

const _handleCheckbox = (event, checked, field, onChangeCallback = (f) => {}) => {
  if (checked) {
    field.value.push(event);
  } else {
    field.value = field.value.filter(function (e) {
      return e !== event;
    });
  }
  onChangeCallback(field);
};

const _handleCheck = (event, field, onCheckCallback = (f) => {}) => {
  const isCheckEvent = event && typeof event.target.checked != "undefined";
  if (isCheckEvent) {
    field.value = !Boolean(field.value);
    onCheckCallback(field);
  } else {
    _handleChange(event, field, onCheckCallback);
  }
};

const _getSelectValue = (items, selected) => (isUndefined(selected) || isUndefined(items) ? "" : items.find((i) => i.value === selected) || "");

const filterOptionsNumberMask = (opts, { inputValue }) => opts.filter((o) => o.value.toString().indexOf(inputValue.replace(/[^0-9]/g, "")) >= 0);

const ReadMode = ({ field, ...rest }) => {
  let value = !!field.mask ? field.value : convertFieldToJsonValue(field);
  // eslint-disable-next-line default-case
  switch (field.type) {
    case FieldType.DATE:
      value = value ? moment(value).format("DD/MM/YYYY") : "";
      break;
  }
  return (
    <div className="br-input" style={{ display: "flex", flexDirection: "column" }}>
      <label htmlFor={field.id}>{field.label}</label>
      <span className={"text-up-04 text-info"} {...rest}>
        {value}
      </span>
    </div>
  );
};

const TODOInput = () => <div>{"<TODO />"}</div>;

const InputContainer = ({ field, children }) => {
  return <div key={"container" + field.id}>{children}</div>;
};

const InputCurrency = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrInput
        id={field.id}
        value={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        mode={"number"}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={2}
        thousandSeparator={"."}
        prefix={"R$ "}
        defaultValue={0.0}
        erro={field.erro()}
      />
    </InputContainer>
  );
};

const InputDecimal = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrInput
        id={field.id}
        value={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        mode={"number"}
        decimalSeparator={","}
        decimalScale={field.decimalScale ? field.decimalScale : 1}
        fixedDecimalScale={field.decimalScale ? field.decimalScale : 1}
        thousandSeparator={"."}
        defaultValue={0.0}
        erro={field.erro()}
      />
    </InputContainer>
  );
};

const InputNumberMask = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrInput
        id={field.id}
        value={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        mode={"number"}
        mask={field.mask}
        erro={field.erro()}
      />
    </InputContainer>
  );
};

const InputSelect = ({ field, onSelect }) => {
  return (
    <InputContainer field={field}>
      <BrSelect
        disabled={field.disabled}
        id={field.id}
        filterOptions={field.filterOptions ? filterOptionsNumberMask : undefined}
        value={field.multiple ? field.value : _getSelectValue(field.options, field.value)}
        label={field.label}
        items={field.options ? field.options : []}
        onChange={(event) => _handleChange(event, field, onSelect)}
        erro={field.erro()}
        placeholder={!isUndefined(field.placeholder) ? field.placeholder : "Selecione uma opção"}
        required={field.required}
        multiple={field.multiple}
      />
    </InputContainer>
  );
};

const InputChips = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <TODOInput />
    </InputContainer>
  );
};

const InputRichTextEditor = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <TODOInput />
    </InputContainer>
  );
};

const InputDate = ({ field, onChange }) => {
  field.value = isUndefined(field.value) || !field.value ? null : field.value;

  // forca objeto date pq BrDatepicker exibe e converte datas string para o dia anterior devido ao timezone br
  const aux = moment(field.value, moment.ISO_8601, true).format();
  if (aux !== "Invalid date") {
    field.value = aux;
  }

  return (
    <InputContainer field={field}>
      <BrDatepicker
        type={"text"}
        id={field.id}
        date={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        mode={field.mode || "single"}
        erro={field.erro()}
        allowInput
      />
    </InputContainer>
  );
};

const InputDateRange = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <TODOInput />
    </InputContainer>
  );
};

const InputDateTime = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <TODOInput />
    </InputContainer>
  );
};

const InputDateTimeRange = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <TODOInput />
    </InputContainer>
  );
};

const InputSwitch = ({ field, onChange }) => {
  const switchValue = Boolean(field.value);
  return (
    <InputContainer field={field}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label aria-checked={switchValue} htmlFor={field.id}>
          {field.label}
        </label>
        <Switch
          value={switchValue}
          color="primary"
          checked={switchValue}
          onChange={(event) => _handleCheck(event, field, onChange)}
          name={field.id}
          inputProps={{ "aria-label": "controlled" }}
          size="medium"
          disabled={field.disabled}
        />
      </div>
    </InputContainer>
  );
};

const InputNumeric = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrInput
        id={field.id}
        value={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        mode={"number"}
        erro={field.erro()}
      />
    </InputContainer>
  );
};

const InputFile = ({ field, onSelect }) => {
  return (
    <InputContainer field={field}>
      <div>
        <label htmlFor={"lb_anexo" + field.id}>Envio de arquivos:</label>
        <div className="text-info file_btn">
          <label id={"lb_anexo" + field.id} htmlFor={field.id} style={{ cursor: field.disabled ? "not-allowed" : "pointer" }}>
            <i className="fa fa-upload"></i>
            {field.multiple ? "Selecione os arquivos" : "Selecione o arquivo"}
          </label>
        </div>
        <input
          disabled={field.disabled}
          key={field.id}
          accept={field.accept ? field.accept : "*"}
          type="file"
          id={field.id}
          name={field.id}
          className="file_input"
          multiple={field.multiple}
          onChange={(file) => _handleSelectFile(file, field, onSelect)}
        />
        {field.erro() && (
          <div className="feedback color-danger mt-1">
            <i className="fas fa-times-circle" />
            <span>{field.erro()}</span>
          </div>
        )}
      </div>
    </InputContainer>
  );
};

const InputTextArea = ({ field, onChange }) => {
  const [countDown, setCountDown] = React.useState(field?.maxLength || 0);

  const _updateCountDown = React.useCallback((fieldParam) => {
    !!fieldParam?.maxLength && setCountDown(() => fieldParam.maxLength - fieldParam.value.length);
  }, []);

  const _textAreaOnChange = (textAreaField) => {
    _updateCountDown(textAreaField);
    !!onChange && onChange(textAreaField);
  };

  React.useEffect(() => {
    _updateCountDown(field);
  }, [_updateCountDown, field]);

  return (
    <InputContainer field={field}>
      <BrTextarea
        id={field.id}
        label={field.label}
        disabled={field.disabled}
        help={field.help}
        placeholder={field.placeholder}
        erro={field.erro()}
        value={field.value}
        maxLength={field.maxLength}
        onChange={(event) => _handleChange(event, field, _textAreaOnChange)}
      />
      {!!field.maxLength && <span style={{ display: "flex", flexDirection: "row-reverse" }}>{countDown} restantes</span>}
    </InputContainer>
  );
};

const InputText = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrInput
        id={field.id}
        value={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        placeholder={field.placeholder}
        required={field.required}
        readOnly={field.readOnly}
        disabled={field.disabled}
        erro={field.erro()}
      />
    </InputContainer>
  );
};

const InputCheckbox = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrCheckbox
        id={field.id}
        label={field.label}
        required={field.required}
        readOnly={field.readOnly}
        disabled={field.disabled}
        erro={field.erro()}
        items={field.options ? field.options : []}
        onChange={(event, checked) => _handleCheckbox(event, checked, field, onChange)}
        values={Array.isArray(field.value) ? field.value : []}
      />
    </InputContainer>
  );
};

const InputRadio = ({ field, onChange }) => {
  return (
    <InputContainer field={field}>
      <BrRadio
        id={field.id}
        value={field.value}
        label={field.label}
        onChange={(event) => _handleChange(event, field, onChange)}
        items={field.options ? field.options : []}
        orientation="horizontal"
        erro={field.erro()}
      />
    </InputContainer>
  );
};

export const InputGeneric = ({ field, onChange, ...rest }) => {
  if (!field) {
    return <React.Fragment></React.Fragment>;
  }

  if (field.readMode) {
    return <ReadMode field={field} {...rest} />;
  }

  switch (field.type) {
    case FieldType.CURRENCY:
      return <InputCurrency field={field} onChange={onChange} />;

    case FieldType.DECIMAL:
      return <InputDecimal field={field} onChange={onChange} />;

    case FieldType.NUM_MASK:
      return <InputNumberMask field={field} onChange={onChange} />;

    case FieldType.SELECT:
      return <InputSelect field={field} onSelect={onChange} />;

    case FieldType.DATE:
      return <InputDate field={field} onChange={onChange} />;

    case FieldType.DATETIME:
      return <InputDateTime field={field} onChange={onChange} />;

    case FieldType.DATE_RANGE:
      field.mode = "range";
      return <InputDateRange field={field} onChange={onChange} />;

    case FieldType.DATETIME_RANGE:
      return <InputDateTimeRange field={field} onChange={onChange} />;

    case FieldType.SELECT_ONE_BUTTON:
      return <InputSwitch field={field} onChange={onChange} />;

    case FieldType.NUMERIC:
      return <InputNumeric field={field} onChange={onChange} />;

    case FieldType.FILE:
      return <InputFile field={field} onSelect={onChange} />;

    case FieldType.TEXTAREA:
      return <InputTextArea field={field} onChange={onChange} />;

    case FieldType.RADIO:
      return <InputRadio field={field} onChange={onChange} />;

    case FieldType.RICHTEXTEDITOR:
      return <InputRichTextEditor field={field} onChange={onChange} />;

    case FieldType.CHIPS:
      return <InputChips field={field} onChange={onChange} />;

    case FieldType.CHECKBOX:
      return <InputCheckbox field={field} onChange={onChange} />;

    case FieldType.TEXT:
    default:
      if (FieldType.TEXT !== field.type) {
        field.type = FieldType.TEXT;
      }
      return <InputText field={field} onChange={onChange} />;
  }
};
