import { convertToSelectOptions, createField, FieldType } from "components/Form";

export const createRelatorioGerencialModel = ({ cnae = "", vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    value: vigencias[0],
    required: true,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  }),
  cnae: createField("cnae", "CNAE: (Apenas relatório de insumos)", {
    type: FieldType.TEXT,
    required: false,
    maxLength: 7,
    value: cnae
  })
});
