import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";
import { TipoMensagem } from "utils/TipoUtils";

export const createModelConsultar = ({ vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  cnpj: createField("cnpj", "CNPJ:", {
    mask: MaskType.CNPJ,
    required: false,
    errorMessage: "Informe o CNPJ completo"
  }),
  cnae: createField("cnae", "CNAE:", {
    type: FieldType.NUMERIC,
    required: false
  })
});

export const createModelMensagem = (fields = {}) => ({
  id: createField("id", "id:", {
    type: FieldType.NUMERIC,
    value: fields.id,
    readOnly: true,
    required: false
  }),
  anoVigencia: createField("anoVigencia", "Vigência:", {
    value: fields.anoVigencia,
    mask: "####",
    readMode: !!fields.anoVigencia,
    errorMessage: "Informe o ano da vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    mask: MaskType.CNPJ_RAIZ,
    value: fields.cnpjRaiz !== undefined ? String(fields.cnpjRaiz).padStart(8, "0") : "",
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    disabled: TipoMensagem[fields.tipo] !== TipoMensagem.MENSAGEM_INFORMATIVA
  }),
  cnpj: createField("cnpj", "CNPJ:", {
    mask: MaskType.CNPJ,
    value: fields.cnpj !== undefined ? String(fields.cnpj).padStart(14, "0") : "",
    required: false,
    errorMessage: "Informe o CNPJ completo",
    disabled: TipoMensagem[fields.tipo] !== TipoMensagem.MENSAGEM_INFORMATIVA
  }),
  cnae: createField("cnae", "CNAE:", {
    mask: MaskType.CNAE,
    value: fields.cnae !== undefined ? String(fields.cnae).padStart(7, "0") : "",
    required: false, 
    disabled: TipoMensagem[fields.tipo] !== TipoMensagem.MENSAGEM_INFORMATIVA 
  }),
  irrestrito: createField("irrestrito", "Irrestrito:", {
    value: Boolean(fields.irrestrito),
    type: FieldType.SELECT_ONE_BUTTON,
    required: true,
    disabled: TipoMensagem[fields.tipo] !== TipoMensagem.MENSAGEM_INFORMATIVA 
  }),
  tipo: createField("tipo", "Tipo:", {
    type: FieldType.SELECT,
    options: Object.keys(TipoMensagem).map((k) => ({
      value: k,
      label: TipoMensagem[k]
    })),
    value: fields.tipo
  }),
  texto: createField("texto", "Texto da Mensagem:", {
    type: FieldType.TEXTAREA,
    value: fields.texto
  }),
  inicioValidade: createField("inicioValidade", "Início Validade:", {
    type: FieldType.DATE,
    value: fields.inicioValidade,
    required: false
  }),
  fimValidade: createField("fimValidade", "Fim Validade:", {
    type: FieldType.DATE,
    value: fields.fimValidade,
    required: false
  })
});
