import { TipoReprocessamento } from "utils/TipoUtils";

import { getApi } from "../ApiManager";

const contexto = "/reprocessamentos";
const ReprocessamentosApi = {
  obter: (params = {}) => getApi().get(contexto, { params }),
  obterReprocessamentos: (cnpjRaiz, anoVigencia) => getApi().get(`${contexto}/calculos`, { params: { anoVigencia, cnpjRaiz } }),
  recalcular: ({ anoVigencia, cnpjRaiz, numeroProcesso }) => getApi().post(contexto, { anoVigencia, cnpjRaiz, numeroProcesso }),
  editar: ({ anoVigencia, cnpjRaiz, numeroProcesso }) => getApi().put(contexto, { anoVigencia, cnpjRaiz, numeroProcesso }),
  publicar: ({ anoVigencia, cnpjRaiz }) => getApi().put(contexto, { anoVigencia, cnpjRaiz, status: TipoReprocessamento.PUBLICADO }),
  cancelarPublicacao: ({ anoVigencia, cnpjRaiz }) => getApi().put(contexto, { anoVigencia, cnpjRaiz, status: TipoReprocessamento.REPROCESSADO }),
  cancelarReprocessamento: ({ anoVigencia, cnpjRaiz }) => getApi().put(contexto, { anoVigencia, cnpjRaiz, status: TipoReprocessamento.CANCELADO }),
  excluirReprocessamento: ({ anoVigencia, cnpjRaiz }) => getApi().put(contexto, { anoVigencia, cnpjRaiz, status: TipoReprocessamento.EXCLUIDO })
};

export default ReprocessamentosApi;
