export const StatusType = { ERROR: "error", SUCCESS: "success", OK: null };

export const MaskType = {
  CPF: "###.###.###-##",
  CNPJ: "##.###.###/####-##",
  COMPETENCIA: "##/####",
  NB: "##########",
  CBC: "###",
  DATA: "##/##/####",
  AGENCIA: "####",
  CEP: "####-###",
  FONE: "(##)####-####",
  CELFONE: "(##)#####-####",
  HORA_MINUTO: "##:##",
  CNAE: "##.##-#/##",
  PROTOCOLO: "#####.######/####-##",
  CNPJ_RAIZ: "##.###.###"
};

export const FieldType = {
  TEXT: "text",
  TEXTAREA: "textarea",
  DATE: "date",
  DATETIME: "datetime",
  DATE_RANGE: "date_range",
  DATETIME_RANGE: "datetime_range",
  SELECT: "select",
  AUTOCOMPLETE: "autocomplete",
  CURRENCY: "currency",
  NUM_MASK: "num_mask",
  SELECT_ONE_BUTTON: "select_one_button",
  NUMERIC: "numeric",
  FILE: "file",
  RICHTEXTEDITOR: "richtexteditor",
  CHIPS: "chips",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  DECIMAL:"decimal"
};
