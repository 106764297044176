import { getApi } from "../ApiManager";

const contexto = "/execucoes";
const contextoMensagens = "/mensagens";

const LogExecucaoApi = {
  obterLogsExecucao: async (params) => {
    const config = { params: { ...params } };

    const Api = getApi();
    return await Api.get(contexto, config);
  },
  obterLogsMensagensExecucao: (id) => {
    const Api = getApi();
    return Api.get(`${contexto}/${id}${contextoMensagens}`);
  }
};

export default LogExecucaoApi;
