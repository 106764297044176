import React from "react";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, InputGeneric } from "components/Form";
import AnexosDataGrid from "components/AnexosDataGrid";
import Loading from "components/Loading";

import AnexosApi from "services/api/AnexosApi";

import { getAnexosColumns } from "../utils";
import { anexoModel } from "../model";

const columns = getAnexosColumns();

export const Anexos = ({ ...props }) => {
  const resultadoJulgamento = props?.resultadoJulgamento || {};

  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [fields, setFields] = React.useState(() => anexoModel());
  const [rows, setRows] = React.useState([]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
  }

  React.useEffect(() => {
    (async () => {
      _setLoading(true);
      await obterAnexos();
      _setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const res = await AnexosApi.anexarArquivo({ idResultadoJulgamento: resultadoJulgamento.id }, fields.anexo.value);
      if (!res.erros) {
        await obterAnexos();
      } else {
        setFormErrors(res.erros);
      }
    }
    setFields(() => anexoModel({}));
    _setLoading(false);
  }

  async function obterAnexos() {
    _setLoading(true);

    const params = {
      idResultadoJulgamento: resultadoJulgamento.id
    };
    const res = await AnexosApi.obterAnexosAdm(params);
    if (res.erros) {
      setFormErrors(res.erros);
      setRows([]);
    } else {
      setRows(res);
      setFormErrors([]);
    }
    _setLoading(false);
  }

  async function removerAnexo(id) {
    let res = await AnexosApi.removerAnexos(id);
    if (!res.erros) {
      await obterAnexos();
    } else {
      setFormErrors(res.erros);
    }
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Form onSubmit={(e) => _handleSubmit(e)} loading={false} errors={formErrors} submitTitle={"Anexar arquivo"}>
            {fields.anexo && (
              <Grid item sm={6}>
                <InputGeneric field={fields.anexo} onChange={_handleChange} />
              </Grid>
            )}
          </Form>
        </Grid>
        <Grid item sm={12}>
          <h6>Relação de arquivos anexados </h6>
          <AnexosDataGrid onEdit={null} onDelete={(id) => removerAnexo(id)} onDeleteMessage={"Deseja remover o arquivo anexado?"} rows={rows} columns={columns} density="compact" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
