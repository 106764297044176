import React from "react";
import { Grid, Link } from "@material-ui/core";
import classnames from "classnames";
//import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";

import ModalMessage from "components/ModalMessage";
import CustomCard from "components/CustomCard";
import Loading from "components/Loading";

import { isResultadoJulgamento } from "utils/TipoUtils";
import { useCnpjConsulta } from "hooks/useCnpjConsulta";
import EmpresasApi from "services/api/EmpresasApi";

import Secao1Card from "./components/Secao1Card";
import Secao2Card from "./components/Secao2Card";
import Secao3Card from "./components/Secao3Card";
import Secao4Card from "./components/Secao4Card";

const Cards = {
  INICIO: "INICIO",
  ELEMENTOS: "ELEMENTOS",
  ANEXOS: "ANEXOS",
  REVISAO: "REVISAO"
};

export const AnalisaContestacao = (props) => {
  if (!props?.location?.state?.contestacao || props?.location?.state?.acao !== "ANALISAR") {
    props.history.replace("/404-requisicao-invalida");
  }
  const contestacaoParam = props?.location?.state?.contestacao || {};

  const [cnpjConsulta, cnpjFormatado] = useCnpjConsulta(contestacaoParam);
  const [card, setCard] = React.useState(Cards.INICIO);
  const [empresa, setEmpresa] = React.useState({});
  const [calculoJudicial, setCalculoJudicial] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [showSuccessModal, setShowSuccessModal] = React.useState();

  const _redirectBackToContestacoes = React.useCallback(() => {
    props?.history.replace("/contestacoes", { ...props?.location?.state });
  }, [props.history, props.location]);

  React.useEffect(() => {
    (async () => {
      let res = await EmpresasApi.obterEmpresas(cnpjConsulta, contestacaoParam.anoVigencia);
      !res.erros && setEmpresa(res);

      res = await EmpresasApi.obterCalculos(cnpjConsulta, contestacaoParam.anoVigencia);
      if (!res.erros) {
        const calcJudicial = res.find((calc) => !!calc.protocolo && isResultadoJulgamento(calc.tipoProcessamento)) || {};
        setCalculoJudicial(calcJudicial);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _handleCloseModal() {
    setShowSuccessModal(false);
    _redirectBackToContestacoes();
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <CustomCard title={`Análise de elementos contestados do cálculo FAP na vigência ${contestacaoParam.anoVigencia} do estabelecimento ${cnpjFormatado}`}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <ol className={"text-up-01 text-info"}>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.INICIO)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.INICIO })}>Dados Principais</span>
                    </Link>
                  </li>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.ELEMENTOS)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.ELEMENTOS })}>Elementos Contestados</span>
                    </Link>
                  </li>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.ANEXOS)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.ANEXOS })}>Anexos da Contestação</span>
                    </Link>
                  </li>
                  <li>
                    <Link component="button" variant="body2" onClick={() => setCard(Cards.REVISAO)}>
                      <span className={classnames("text-up-01", { "text-bold": card === Cards.REVISAO })}>Finalizar Análise da Contestação</span>
                    </Link>
                  </li>
                </ol>
              </Grid>
              <Grid item sm={8}>
                {/* <BrMessage warning>
                  //TODO: escrever mensagem de introdução no painel de analise contestacao 
                  Lorem ipsum aliquet cubilia mauris pharetra justo cursus quisque molestie, iaculis vehicula platea venenatis class quis quisque magna, litora nec volutpat
                  elementum enim semper primis mattis. aliquet hac lorem viverra egestas duis est viverra, habitant donec condimentum vehicula nostra id, leo quis curae mollis
                  morbi cursus. lacinia imperdiet commodo lobortis consectetur fermentum dictum dictumst ullamcorper, dictum eleifend suscipit leo pharetra dictum rutrum. ut cursus
                  etiam rutrum senectus vitae malesuada pharetra, vestibulum dictum purus mi habitant ut, nunc felis fringilla vitae placerat vehicula. luctus rhoncus dapibus
                  hendrerit vulputate auctor aliquam laoreet nisi cursus, per vitae quis viverra pellentesque dolor suscipit nulla.
                </BrMessage>
                */}
              </Grid>
              <Grid item sm={4} style={{ marginLeft: 30 }}>
                <BrButton small secondary onClick={_redirectBackToContestacoes}>
                  Voltar para consulta de contestações
                </BrButton>
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>

        <Grid item sm={12}>
          {card === Cards.INICIO && <Secao1Card contestacao={{ ...contestacaoParam, cnpjConsulta, cnpjFormatado, empresa, resultadoJulgamento: calculoJudicial }} />}

          {card === Cards.ELEMENTOS && <Secao2Card contestacao={{ ...contestacaoParam, cnpjConsulta, cnpjFormatado }} setLoading={setLoading} />}

          {card === Cards.ANEXOS && <Secao3Card contestacao={contestacaoParam} setLoading={setLoading} />}

          {card === Cards.REVISAO && <Secao4Card contestacao={contestacaoParam} setLoading={setLoading} onSubmitContestacao={() => setShowSuccessModal(true)} />}
        </Grid>
      </Grid>
      <ModalMessage show={showSuccessModal} message={"Análise da contestação finalizada com sucesso!"} close={_handleCloseModal} />
    </React.Fragment>
  );
};
