import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric, isUndefined } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { formataCNPJ, formataDateEnBr, isDataAMaiorB } from "utils/StrUtils";
import { useVigencias } from "hooks/useVigencias";
import VigenciasApi from "services/api/VigenciasApi";
import { PREFS } from "services/PreferenceManager";

import { consultarContestacoes, mapToEstabsContestacoes, TipoAcaoContestacao } from "./ContestacaoUtils";
import { createConsultaModel } from "./model";
import ResumoContestacaoModal from "./components/ResumoContestacaoModal";
import SituacaoVirtualButton from "./components/SituacaoVirtualButton";

const getMessagePrazo1Instancia = (vigencia) => {
  return vigencia?.dataFimContestacao && "Atenção! Prazo para contestações encerra-se em " + formataDateEnBr(vigencia.dataFimContestacao);
};

export const ConsultaContestacao = ({ userData, ...props }) => {
  const { vigencias } = useVigencias();

  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);

  const formElement = React.useRef();
  const routeState = React.useMemo(() => props?.location?.state || { fields: undefined }, [props.location]);
  const [fields, setFields] = React.useState(() => createConsultaModel({ fields: routeState.fields, vigencias, empresas: userData.empresas }));

  const [resumoContestacao, setResumoContestacao] = React.useState(null);

  const [contestacoesCache, setContestacoesCache] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [vigencia, setVigencia] = React.useState(null);

  React.useEffect(() => {
    if (vigencias.length > 0) {
      setFields(() => createConsultaModel({ fields: routeState.fields, vigencias, empresas: userData.empresas }));
    }
  }, [vigencias, routeState, userData.empresas]);

  React.useEffect(() => {
    if (!!routeState.fields && formElement.current) {
      formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  }, [formElement, routeState]);

  function _redirectToContestacao(contestacao, acao) {
    const state = { contestacao, fields, acao, vigencia };
    if (TipoAcaoContestacao.DESISTIR === acao) {
      props.history.push("/contestacoes-eletronicas/desistir", state);
    } else if (!contestacao) {
      state.vigencias = vigencias;
      props.history.push("/contestacoes-eletronicas/criar", state);
    } else {
      props.history.push("/contestacoes-eletronicas/contestar", state);
    }
  }

  const renderContestacao = React.useCallback(({ value }) => (value ? <SituacaoVirtualButton contestacao={value} onClick={() => setResumoContestacao(value)} /> : null), []);

  const columns = React.useMemo(
    () => [
      { field: "anoVigencia", hideSortIcons: true, filterable: false, headerAlign: "center", align: "center", headerName: "Vigência", description: "Ano Vigência", minWidth: 90 },
      {
        field: "cnpj",
        headerAlign: "center",
        align: "center",
        headerName: "Estabelecimento",
        description: "CNPJ do Estabelecimento",
        flex: 1,
        minWidth: 170,
        type: "string",
        valueFormatter: ({ value }) => formataCNPJ(value)
      },
      {
        field: "cAndamento1",
        hideSortIcons: true,
        filterable: false,
        headerAlign: "center",
        align: "center",
        headerName: "Contestações em Andamento",
        description: "Contestações em Andamento",
        flex: 1,
        renderCell: renderContestacao
      },
      {
        field: "cTransmitida1",
        hideSortIcons: true,
        filterable: false,
        headerAlign: "center",
        align: "center",
        headerName: "Contestações Transmitidas",
        description: "Contestações Transmitidas",
        flex: 1,
        renderCell: renderContestacao
      },
      {
        field: "cAndamento2",
        hideSortIcons: true,
        filterable: false,
        headerAlign: "center",
        align: "center",
        headerName: "Recursos em Andamento",
        description: "Recursos em Andamento",
        flex: 1,
        renderCell: renderContestacao
      },
      {
        field: "cTransmitida2",
        hideSortIcons: true,
        filterable: false,
        headerAlign: "center",
        align: "center",
        headerName: "Recursos Transmitidos",
        description: "Recursos Transmitidos",
        flex: 1,
        renderCell: renderContestacao
      }
    ],
    [renderContestacao]
  );

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
    setRows([]);
    setVigencia(null);
    _setLoading(false);
  }

  function filterContestacao(ct, filtros) {
    if (!ct) {
      return false;
    } else if (filtros.instancia && filtros.instancia !== ct.instancia.codigo) {
      return false;
    } else if (filtros.situacao && filtros.situacao !== ct.situacao.codigo) {
      return false;
    } else if (!isUndefined(filtros.prazo2InstanciaAberto) && Boolean(filtros.prazo2InstanciaAberto) !== ct.prazo2InstanciaAberto) {
      return false;
    } else if (filtros.protocolo && filtros.protocolo !== ct.protocolo) {
      return false;
    }
    return true;
  }

  async function consultarEstabsContestacoes(filtros = {}) {
    const cacheKey = `${filtros.cnpjRaiz}-${filtros.anoVigencia}`;
    let res = contestacoesCache[cacheKey];
    if (!res) {
      res = await consultarContestacoes(filtros);
      if (res.erros) {
        return res;
      } else {
        setContestacoesCache({ [cacheKey]: res });
      }
    }

    const resMapped = mapToEstabsContestacoes(res);
    const resFiltered = resMapped.map((r) => {
      return {
        ...r,
        cAndamento1: [r.cAndamento1].find((ct) => filterContestacao(ct, filtros)),
        cTransmitida1: [r.cTransmitida1].find((ct) => filterContestacao(ct, filtros)),
        cAndamento2: [r.cAndamento2].find((ct) => filterContestacao(ct, filtros)),
        cTransmitida2: [r.cTransmitida2].find((ct) => filterContestacao(ct, filtros))
      };
    });

    return resFiltered;
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      const res = await consultarEstabsContestacoes(requestBody);
      if (res.erros) {
        setFormErrors(res.erros);
        setRows([]);
        setVigencia(null);
      } else {
        // TODO: cache na página para evitar obter vigencia repetidamente
        const resVigencia = await VigenciasApi.obterVigencia(requestBody.anoVigencia);
        setVigencia(() => resVigencia);
        setRows(() => res);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Form ref={formElement} onSubmit={_handleSubmit} errors={formErrors} submitIcon="fa-search" submitTitle="Consultar">
            <Grid item sm={2}>
              <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
            </Grid>
            <Grid item sm={7}>
              <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.prazo2InstanciaAberto} onChange={_handleChange} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.instancia} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.situacao} onChange={_handleChange} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.protocolo} onChange={_handleChange} />
            </Grid>
          </Form>
        </Grid>

        <Grid item sm={12}>
          <Divider />
        </Grid>

        {vigencia && isDataAMaiorB(vigencia.dataFimContestacao) && (
          <Grid item sm={12}>
            <BrMessage warning>{getMessagePrazo1Instancia(vigencia)}</BrMessage>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={6} style={{ display: "flex", alignItems: "end" }}>
          <h5 style={{ margin: 0 }}>Contestações</h5>
        </Grid>
        <Grid item sm={6} style={{ textAlign: "right" }}>
          <BrButton small secondary onClick={() => _redirectToContestacao(null, TipoAcaoContestacao.CRIAR)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            Contestação
          </BrButton>
        </Grid>
        <Grid item sm={12}>
          <CustomDataGrid pageSizePreference={PREFS.CONTESTACAO_PAGE_SIZE} getRowId={(r) => r.cnpj} rows={rows} columns={columns} density="compact" rowHeight={70} />
        </Grid>
      </Grid>

      {resumoContestacao && (
        <ResumoContestacaoModal contestacao={resumoContestacao} onConfirm={(resumo, acao) => _redirectToContestacao(resumo, acao)} onClose={() => setResumoContestacao(null)} />
      )}
    </React.Fragment>
  );
};
