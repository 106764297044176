import React from "react";
import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";

const RowActionsCell = (props) => {
  const { row, actions, onEditRow, onDeleteRow } = props;

  const handleEditClick = (event) => {
    event.stopPropagation();
    onEditRow(row);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDeleteRow(row);
  };

  const handleAction = (event, action) => {
    event.stopPropagation();
    action(row);
  };

  if (!!actions && actions.length > 0) {
    return (
      <React.Fragment>
        {actions.map((a, index) => (
          <IconButton key={`grid-action-key-${index}`} title={a.title} size="small" aria-label={a.title} onClick={(e) => handleAction(e, a.action)}>
            <span className={a.icon}></span>
          </IconButton>
        ))}
      </React.Fragment>
    );
  }

  return (
    <div>
      {onEditRow && (
        <IconButton title="Editar" color="inherit" size="small" aria-label="editar" onClick={handleEditClick}>
          <span className="text-info fa fa-edit"></span>
        </IconButton>
      )}
      {onDeleteRow && (
        <IconButton title="Remover" size="small" aria-label="remover" onClick={handleDeleteClick}>
          <span className="text-danger fa fa-trash"></span>
        </IconButton>
      )}
    </div>
  );
};
export default RowActionsCell;

RowActionsCell.propTypes = {
  row: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func.isRequired
    })
  ),
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};
