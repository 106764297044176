import { convertToSelectOptions, createField, FieldType } from "components/Form";
import { formataCnae, formataIndicador, formataNumberEnBr, formataReal } from "utils/StrUtils";

import RelatoriosApi from "services/api/RelatoriosApi";

function formataIndicadorComPlaceholder(value) {
  return "undefined" !== typeof value ? formataIndicador(value) : "-";
}

function formataNumberEnBrComPlaceholder(value) {
  return "undefined" !== typeof value ? formataNumberEnBr(value) : "-";
}

const percentilColumns = [
  {
    field: "cnae",
    headerName: "Subclasse CNAE",
    description: "Subclasse CNAE",
    valueFormatter: ({ value }) => formataCnae(value),
    flex: 1,
    type: "number"
  },
  {
    field: "percentilFrequenciaAcidentario",
    headerName: "Percentil de Frequência Acidentário",
    description: "Percentil de Frequência Acidentário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    flex: 1,
    type: "number"
  },
  {
    field: "percentilFrequenciaPrevidenciario",
    headerName: "Percentil de Frequência Previdenciário",
    description: "Percentil de Frequência Previdenciário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    flex: 1,
    type: "number"
  },
  {
    field: "percentilGravidade",
    headerName: "Percentil de Gravidade",
    description: "Percentil de Gravidade",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    flex: 1,
    type: "number"
  },
  {
    field: "percentilCusto",
    headerName: "Percentil de Custo",
    description: "Percentil de Custo",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    flex: 1,
    type: "number"
  }
];

const ratCnaeColumns = [
  {
    field: "cnae",
    headerName: "SubClasse CNAE",
    description: "SubClasse CNAE",
    valueFormatter: ({ value }) => formataCnae(value),
    minWidth: 130,
    type: "number"
  },
  {
    field: "quantidadeCat",
    headerName: "CATs",
    description: "CATs",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeCatDiferenteTrajeto",
    headerName: "CATs Não Trajeto",
    description: "CATs Não Trajeto",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeCatTrajeto",
    headerName: "CATs Trajeto",
    description: "CATs Trajeto",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeNexoTecnico",
    headerName: "Nexos Técnico",
    description: "Nexos Técnico",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeB91",
    headerName: "B91",
    description: "B91",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeB92",
    headerName: "B92",
    description: "B92",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeB93",
    headerName: "B93",
    description: "B93",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "quantidadeB94",
    headerName: "B94",
    description: "B94",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "valorTotalBeneficiosPagos",
    headerName: "Valor Total de Benefícios Pagos",
    description: "Valor Total de Benefícios Pagos",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    renderCell: ({ value }) => formataReal(value),
    minWidth: 170,
    type: "number"
  },
  {
    field: "mediaVinculos",
    headerName: "Número Médio de Vínculos - 24 Competências",
    description: "Número Médio de Vínculos (24 Competências)",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 165,
    type: "number"
  },
  {
    field: "quantidadeTotalSubclassesCNAE",
    headerName: "Total Subclasses CNAE",
    description: "Total Subclasses CNAE",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 135,
    type: "number"
  },
  {
    field: "valorTotalMassaSalarial",
    headerName: "Valor Total Remuneração Período-base - 26 Competências",
    description: "Valor Total da Remuneração no Período-base (26 Competências)",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    renderCell: ({ value }) => formataReal(value),
    minWidth: 180,
    type: "number"
  },
  {
    field: "taxaMediaRotatividade",
    headerName: "Taxa Média Rotatividade",
    description: "Taxa Média Rotatividade",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 145,
    type: "number"
  },
  {
    field: "taxaRotatividadeNacional",
    headerName: "Taxa Média Rotatividade Nacional",
    description: "Taxa Média Rotatividade Nacional",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 145,
    type: "number"
  },
  {
    field: "indiceCusto",
    headerName: "Índice Custo",
    description: "Índice Custo",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 105,
    type: "number"
  },
  {
    field: "ordemCusto",
    headerName: "Ordem Custo",
    description: "Ordem Custo",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "percentilCusto",
    headerName: "Percentil Custo",
    description: "Percentil Custo",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 120,
    type: "number"
  },
  {
    field: "indiceGravidade",
    headerName: "Índice Gravidade",
    description: "Índice Gravidade",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 140,
    type: "number"
  },
  {
    field: "ordemGravidade",
    headerName: "Ordem Gravidade",
    description: "Ordem Gravidade",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 140,
    type: "number"
  },
  {
    field: "percentilGravidade",
    headerName: "Percentil Gravidade",
    description: "Percentil Gravidade",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 140,
    type: "number"
  },
  {
    field: "indiceFrequenciaAcidentario",
    headerName: "Índice Frequência Acidentário",
    description: "Índice Frequência Acidentário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 140,
    type: "number"
  },
  {
    field: "ordemFrequenciaAcidentario",
    headerName: "Ordem Frequência Acidentário",
    description: "Ordem Frequência Acidentário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 140,
    type: "number"
  },
  {
    field: "percentilFrequenciaAcidentario",
    headerName: "Percentil Frequência Acidentário",
    description: "Percentil Frequência Acidentário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 150,
    type: "number"
  },
  {
    field: "indiceFrequenciaPrevidenciario",
    headerName: "Índice Frequência Previdenciário",
    description: "Índice Frequência Previdenciário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 155,
    type: "number"
  },
  {
    field: "ordemFrequenciaPrevidenciario",
    headerName: "Ordem Frequência Previdenciário",
    description: "Ordem Frequência Previdenciário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 155,
    type: "number"
  },
  {
    field: "percentilFrequenciaPrevidenciario",
    headerName: "Percentil Frequência Previdenciário",
    description: "Percentil Frequência Previdenciário",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 155,
    type: "number"
  },
  {
    field: "indiceComposto",
    headerName: "Índice Composto",
    description: "Índice Composto",
    valueFormatter: ({ value }) => formataIndicadorComPlaceholder(value),
    minWidth: 140,
    type: "number"
  },
  {
    field: "ratOriginal",
    headerName: "RAT Original",
    description: "RAT Original",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 120,
    type: "number"
  }
];

const ratFinalColumns = [
  {
    field: "cnae",
    headerName: "Subclasse CNAE",
    description: "Subclasse CNAE",
    valueFormatter: ({ value }) => formataCnae(value),
    minWidth: 130,
    flex: 1,
    type: "number"
  },
  {
    field: "ratOriginal",
    headerName: "RAT Original",
    description: "RAT Original",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    flex: 1,
    type: "number"
  },
  {
    field: "ratVinculos50",
    headerName: "RAT Nº Médio Vínculos <50",
    description: "RAT Número Médio de Vínculos <50",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 142,
    flex: 1,
    type: "number"
  },
  {
    field: "taxaMortalidadeNacional",
    headerName: "Taxa Mortalidade Nacional",
    description: "Taxa Mortalidade Nacional",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 140,
    flex: 1,
    type: "number"
  },
  {
    field: "taxaMortalidadeSubClasse",
    headerName: "Taxa Mortalidade Subclasse",
    description: "Taxa Mortalidade Subclasse",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 140,
    flex: 1,
    type: "number"
  },
  {
    field: "taxaRotatividadeNacional",
    headerName: "Taxa Média Rotatividade Nacional",
    description: "Taxa Média Rotatividade Nacional",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 142,
    flex: 1,
    type: "number"
  },
  {
    field: "taxaMediaRotatividade",
    headerName: "Taxa Média Rotatividade Subclasse CNAE",
    description: "Taxa Média Rotatividade Subclasse CNAE",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    minWidth: 142,
    flex: 1,
    type: "number"
  },
  {
    field: "relacaoCancerigena",
    headerName: "Relações Cancerígenas",
    description: "Relações Cancerígenas",
    type: "boolean",
    minWidth: 148,
    flex: 1,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "ratFinal",
    headerName: "RAT Final",
    description: "RAT Final",
    valueFormatter: ({ value }) => formataNumberEnBrComPlaceholder(value),
    flex: 1,
    type: "number"
  }
];
export const TipoRelatorioRat = [
  {
    tipo: "Percentis",
    nome: "Percentis_Subclasse_CNAE_Art_202A",
    descricao: "Percentis por Subclasse CNAE (Art. 202-A, § 5°, Decreto n° 3.048, de 1999)",
    params: {},
    colunas: percentilColumns,
    endpoint: RelatoriosApi.percentisCnae
  },
  { tipo: "TotalCnae", nome: "Todas_Subclasses_CNAE", descricao: "Todas as Subclasses CNAE", params: {}, colunas: ratCnaeColumns, endpoint: RelatoriosApi.ratCnae },
  { tipo: "RatFinal", nome: "RAT_Final", descricao: "RAT Final", params: {}, colunas: ratFinalColumns, endpoint: RelatoriosApi.ratFinal }
];

export const createVigenciaModel = ({ vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    value: vigencias[0],
    required: true,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  })
});
