import React from "react";
import { Grid } from "@material-ui/core";

import Form, { InputGeneric } from "components/Form";

const FormAnoVigencia = ({ onSubmit, onChange, fields = {}, formError = [] }) => (
  <Form onSubmit={onSubmit} errors={formError} submitTitle={"Consultar"} submitIcon={"fa-search"}>
    <Grid id="grid-vigencias" item sm={6}>
      <InputGeneric field={fields.anoVigencia} onChange={onChange} />
    </Grid>
  </Form>
);

export default FormAnoVigencia;
