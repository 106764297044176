import { formataCnae, formataCNPJ, formataCNPJRaiz, formataDateEnBr, formataIndicador, formataNumberEnBr } from "utils/StrUtils";
import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";
import { isVigenciaFapPorEstabelecimento } from "utils/VigenciaUtils";

export const createReprocessamentoConsultaModel = ({ vigencias = [], fields }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias.filter((v) => !isVigenciaFapPorEstabelecimento(v))),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência",
    value: !!fields?.anoVigencia ? String(fields.anoVigencia) : ""
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ",
    value: fields?.cnpjRaiz
  })
});

export const createReprocessamentoModel = ({ fields, vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias.filter((v) => !isVigenciaFapPorEstabelecimento(v))),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência",
    value: fields?.anoVigencia,
    readMode: !!fields?.anoVigencia
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ",
    value: formataCNPJRaiz(fields?.cnpjRaiz),
    readMode: !!fields?.cnpjRaiz
  }),
  numeroProcesso: createField("numeroProcesso", "Número do Processo:", {
    value: fields?.numeroProcesso,
    maxLength: 50
  })
});

export const columns = [
  { headerName: "CNPJ Raiz", field: "cnpjRaiz", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataCNPJRaiz(value) },
  { headerName: "Vigência", field: "anoVigencia", headerAlign: "right", align: "right", flex: 1, minWidth: 120 },
  { headerName: "CNAE", field: "cnae", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataCnae(value?.subClasse) },
  { headerName: "Nº Processo", field: "numeroProcesso", headerAlign: "right", align: "right", flex: 1 },
  { headerName: "Data Reprocessado", field: "dataAlteracao", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value, 1) },
  { headerName: "Status", field: "status", headerAlign: "right", align: "right", flex: 1, minWidth: 200, valueGetter: ({ value }) => value?.descricao }
];

export const columnsDetalhe = [
  { headerName: "CNPJ", field: "cnpj", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataCNPJ(value) },
  { headerName: "CNAE", field: "cnae", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataCnae(value?.subClasse) },
  {
    headerName: "Valor Total de Benefícios Pagos",
    field: "valorTotalBeneficiosPagos",
    headerAlign: "right",
    align: "right",
    flex: 1,

    valueGetter: ({ value }) => formataNumberEnBr(value),
    hide: true
  },
  {
    headerName: "Massa Salarial",
    field: "valorTotalMassaSalarial",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataNumberEnBr(value),
    hide: true
  },
  {
    headerName: "Taxa Média de Rotatividade",
    field: "taxaMediaRotatividade",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataIndicador(value),
    hide: true
  },
  {
    headerName: "Número Médio de Vínculos",
    field: "mediaVinculos",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataNumberEnBr(value),
    hide: true
  },
  { headerName: "Quantidade B91", field: "quantidadeB91", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataNumberEnBr(value), hide: true },
  { headerName: "Quantidade B92", field: "quantidadeB92", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataNumberEnBr(value), hide: true },
  { headerName: "Quantidade B93", field: "quantidadeB93", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataNumberEnBr(value), hide: true },
  { headerName: "Quantidade B94", field: "quantidadeB94", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataNumberEnBr(value), hide: true },
  { headerName: "Quantidade Cat", field: "quantidadeCat", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataNumberEnBr(value), hide: true },
  {
    headerName: "Quantidade Nexo Técnico",
    field: "quantidadeNexoTecnico",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataNumberEnBr(value),
    hide: true
  },
  {
    headerName: "Total de Estabelecimentos Válidos na subclasse CNAE",
    field: "quantidadeEmpresasValidasCnae",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 180,
    valueGetter: ({ value }) => formataNumberEnBr(value),
    hide: true
  },
  {
    headerName: "Total de Estabelecimentos na subclasse CNAE",
    field: "quantidadeEmpresasTotalCnae",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 180,
    valueGetter: ({ value }) => formataNumberEnBr(value),
    hide: true
  },
  { headerName: "Coeficiente de Frequência", field: "indiceFrequencia", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  {
    headerName: "Número de Ordem de Frequencia",
    field: "ordemFrequencia",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataIndicador(value),
    hide: true
  },
  {
    headerName: "Percentil de Frequencia",
    field: "percentilFrequencia",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataIndicador(value),
    hide: true
  },
  { headerName: "Coeficiente de Gravidade", field: "indiceGravidade", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  {
    headerName: "Número de Ordem de Gravidade",
    field: "ordemGravidade",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataIndicador(value),
    hide: true
  },
  {
    headerName: "Percentil de Gravidade",
    field: "percentilGravidade",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: ({ value }) => formataIndicador(value),
    hide: true
  },
  { headerName: "Coeficiente de Custo", field: "indiceCusto", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  { headerName: "Número de Ordem de Custo", field: "ordemCusto", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value), hide: true },
  { headerName: "Percentil de Custo", field: "percentilCusto", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value), hide: true },
  { headerName: "Índice Composto", field: "indiceComposto", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  { headerName: "Alíquota FAP", field: "fap", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataIndicador(value) },
  { headerName: "Data Reprocessado", field: "dataProcessamento", headerAlign: "right", align: "right", flex: 1, valueGetter: ({ value }) => formataDateEnBr(value), hide: true }
];
