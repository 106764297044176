import React from "react";
import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { FormErrorPanel, InputGeneric } from "components/Form";
import Loading from "components/Loading";

import { getInstanciaDescricao } from "utils/ContestacaoUtils";
import useLockBodyScroll from "hooks/useLockBodyScroll";
import useEscKey from "hooks/useEscKey";

import { novoRecurso, reabrirContestacao, TipoAcaoContestacao, TipoSituacaoVirtual } from "../ContestacaoUtils";
import { createResumoConstestacaoModel } from "../model";
import ContestacoesApi from "services/api/ContestacoesApi";
import { formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";

const ResumoContestacaoModal = ({ contestacao, onConfirm, onClose }) => {
  useLockBodyScroll();
  useEscKey(() => onClose());

  const [loadingOverlay, setLoadingOverlay] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState([]);

  const fields = createResumoConstestacaoModel(contestacao);

  function _handleClickContinuar() {
    onConfirm({ ...contestacao }, TipoAcaoContestacao.EDITAR);
  }

  async function _handleClickReabrir() {
    setLoadingOverlay("modal-resumo-contestacao");
    const res = await reabrirContestacao(contestacao);

    if (res.erros) {
      setFormErrors(res.erros);
      setLoadingOverlay(null);
    } else {
      onConfirm({ ...res }, TipoAcaoContestacao.REABRIR);
    }
  }

  function _handleClickDesistir() {
    onConfirm({ ...contestacao }, TipoAcaoContestacao.DESISTIR);
  }

  async function _handleClickNovoRecurso() {
    setLoadingOverlay("modal-resumo-contestacao");
    const res = await novoRecurso(contestacao);

    if (res.erros) {
      setFormErrors(res.erros);
      setLoadingOverlay(null);
    } else {
      onConfirm({ ...res }, TipoAcaoContestacao.RECURSO);
    }
  }

  async function _handleClickRelatorioPdf() {
    setLoadingOverlay("modal-resumo-contestacao");
    const { cnpj, cnpjRaiz, id, anoVigencia } = contestacao;
    const cnpjConsulta = cnpj ? formataCNPJ14Digitos(cnpj) : formataCNPJ8Digitos(cnpjRaiz);
    const res = await ContestacoesApi.downloadRelatorioPdfContestacao(id, cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormErrors(res.erros);
    }
    setLoadingOverlay(null);
  }

  return (
    <BrModal visible={true} closable={true} onClose={onClose}>
      <Loading show={loadingOverlay !== null} overlay overlayTarget={loadingOverlay} />
      <Grid id="modal-resumo-contestacao" container spacing={1}>
        <Grid item sm={12} style={{ display: "flex", flexDirection: "column" }}>
          <span className="text-up-02 text-bold">{"Contestação " + getInstanciaDescricao(contestacao)}</span>
          <strong>{"Estabelecimento: " + fields.cnpj.value}</strong>
          <Divider />
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <FormErrorPanel messages={formErrors} />
            </Grid>

            <Grid item sm={6} style={{ display: fields.protocolo.value ? "block" : "none" }}>
              <InputGeneric field={fields.protocolo} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={6}>
              <InputGeneric field={fields.situacao} className={"text-up-02 text-info"} />
            </Grid>

            <Grid item sm={6}>
              <InputGeneric field={fields.dataInicial} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={6} style={{ display: fields.dataTransmissao.value ? "block" : "none" }}>
              <InputGeneric field={fields.dataTransmissao} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={6} style={{ display: fields.dataDOU.value ? "block" : "none" }}>
              <InputGeneric field={fields.dataDOU} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={6} style={{ display: fields.deferimento.value ? "block" : "none" }}>
              <InputGeneric field={fields.deferimento} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={6} style={{ display: fields.email.value ? "block" : "none" }}>
              <InputGeneric field={fields.email} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={6} style={{ display: fields.dataDesistencia.value ? "block" : "none" }}>
              <InputGeneric field={fields.dataDesistencia} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={12} style={{ display: fields.justificativaDesistencia.value ? "block" : "none" }}>
              <InputGeneric field={fields.justificativaDesistencia} className={"text-up text-info"} style={{ whiteSpace: "pre-wrap", overflow: "scroll", maxHeight: 150 }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} style={{ textAlign: "center" }}>
          <BrModalFooter>
            <Grid container>
              <Grid item sm={4}>
                <BrButton small secondary onClick={onClose}>
                  Cancelar
                </BrButton>
              </Grid>

              <Grid item sm={8} style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                {TipoSituacaoVirtual.EM_ANDAMENTO === contestacao.situacaoVirtual && (
                  <BrButton small primary onClick={_handleClickContinuar}>
                    Continuar
                  </BrButton>
                )}
                {TipoSituacaoVirtual.REABRIR === contestacao.situacaoVirtual && (
                  <BrButton small primary onClick={_handleClickReabrir}>
                    Reabrir
                  </BrButton>
                )}
                {[TipoSituacaoVirtual.REABRIR, TipoSituacaoVirtual.TRANSMITIDA].includes(contestacao.situacaoVirtual) && (
                  <BrButton small primary onClick={_handleClickDesistir}>
                    Desistir
                  </BrButton>
                )}
                {TipoSituacaoVirtual.CONTESTAR_2A === contestacao.situacaoVirtual && (
                  <BrButton small primary onClick={_handleClickNovoRecurso}>
                    Abrir Recurso
                  </BrButton>
                )}
                {![TipoSituacaoVirtual.EM_ANDAMENTO].includes(contestacao.situacaoVirtual) && (
                  <BrButton small primary onClick={_handleClickRelatorioPdf}>
                    Relatório PDF
                  </BrButton>
                )}
              </Grid>
            </Grid>
          </BrModalFooter>
        </Grid>
      </Grid>
    </BrModal>
  );
};

export default ResumoContestacaoModal;
