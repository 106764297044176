import React, { useState } from "react";
import BrMenu, { MENU_TYPES } from "components/BrMenu";

const Menu = ({ menuFolders = [], openMainMenu = false, setOpenMainMenu, activeItem, handleNavigate }) => {
  const [openFolder, setOpenFolder] = useState("");

  const handleToggleMenuFolder = (isOpen, id, url, items) => {
    setOpenFolder(isOpen ? "" : id);
    if (!items) {
      handleNavigate(url);
    }
  };

  const renderMenuItem = (item, index) => {
    const { label, url, items } = item;
    const active = url === activeItem;

    if (item.isAuthorized()) {
      return (
        <BrMenu.Item key={index} active={active} url={url} onClick={handleNavigate} label={label}>
          {items && items.length > 0 && <BrMenu.Items open={active}>{items.map(renderMenuItem)}</BrMenu.Items>}
        </BrMenu.Item>
      );
    } else {
      return null;
    }
  };

  return (
    <BrMenu
      id="menu-principal-id"
      show={openMainMenu}
      type={MENU_TYPES.container}
      onClose={() => setOpenMainMenu(false)}
      label="Menu principal do sistema"
      style={{ minWidth: "20vw" }}
      folders={menuFolders.map((folder, index) => {
        const { id, items, url, ...rest } = folder;
        const isOpen = id === openFolder;

        if (folder.isAuthorized()) {
          return (
            <BrMenu.Folder onToggle={() => handleToggleMenuFolder(isOpen, id, url, items)} key={index} url={url} {...rest}>
              {items && items.length > 0 && <BrMenu.Items open={isOpen}>{items.map(renderMenuItem)}</BrMenu.Items>}
            </BrMenu.Folder>
          );
        } else {
          return null;
        }
      })}
      footer={
        <BrMenu.Footer>
          <BrMenu.FooterBlock title="Acesso Rápido">
            <a className="item" href="https://www.gov.br/pt-br/orgaos-do-governo" target="_blank" rel="noopener noreferrer">
              Órgãos do Governo&nbsp;
              <i className="fas fa-external-link-square-alt" aria-hidden="true" />
            </a>
          </BrMenu.FooterBlock>
        </BrMenu.Footer>
      }
    />
  );
};

export default Menu;
