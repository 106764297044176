import { formataCNPJ, formataCNPJ14Digitos, formataCNPJ8Digitos, formataCNPJRaiz } from "utils/StrUtils";
import { isUndefined } from "components/Form";

export function useCnpjConsulta(props = {}) {
  let cnpjConsulta = "";
  let cnpjFormatado = "";

  if (!props || (isUndefined(props.cnpj) && isUndefined(props.cnpjRaiz))) {
    return [cnpjConsulta, cnpjFormatado];
  }

  [cnpjConsulta, cnpjFormatado] = !props.cnpj
    ? [formataCNPJ8Digitos(props.cnpjRaiz), formataCNPJRaiz(props.cnpjRaiz)]
    : [formataCNPJ14Digitos(props.cnpj), formataCNPJ(props.cnpj)];

  return [cnpjConsulta, cnpjFormatado];
}
