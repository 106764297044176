import { createField, FieldType, MaskType } from "components/Form";
import { convertToSelectOptions } from "components/Form/formUtils";

import { formataCNPJ, formataCNPJRaiz, formataCPF, formataIsoDateTimeBR } from "utils/StrUtils";
import { getCurrentMonthDate, getLastMonthDate } from "utils/DateUtils";
import { TipoContexto, TipoPapelDescricao } from "utils/TipoUtils";

function formataPapeis({ value }) {
  return value
    ? value
        .split(",")
        .map((v) => TipoPapelDescricao[v.trim()])
        .join(", ")
    : "";
}

function formataLog(log) {
  if (!log) {
    return "";
  }
  let obj = {};
  try {
    obj = JSON.parse(log);
  } catch (e) {
    return log;
  }

  return (
    <dl style={{ lineHeight: "normal", margin: 0, padding: "0 2px" }}>
      {Object.keys(obj).map((k, index) => (
        <dt key={`log-${k}${index}`}>
          <b>{`${k}: `}</b>
          {typeof obj[k] !== "object" && obj[k].toString()}
        </dt>
      ))}
    </dl>
  );
}

export const createModelConsultar = (fields = {}) => ({
  dataInicio: createField("dataInicio", "Data Início:", {
    type: FieldType.DATE,
    value: getLastMonthDate(),
    required: false
  }),
  dataFim: createField("dataFim", "Data Fim:", {
    type: FieldType.DATE,
    value: getCurrentMonthDate(),
    required: false
  }),
  nomeUsuario: createField("nomeUsuario", "Nome:", {
    type: FieldType.TEXT,
    value: fields.nomeUsuario,
    required: false
  }),
  cpfUsuario: createField("cpfUsuario", "CPF:", {
    mask: MaskType.CPF,
    value: fields.cpfUsuario,
    errorMessage: "Informe o CPF completo",
    required: false
  }),
  papel: createField("papel", "Perfil:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoPapelDescricao),
    value: fields.papel,
    required: false,
    placeholder: "Selecione um perfil"
  }),
  contexto: createField("contexto", "Contexto:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(TipoContexto),
    value: fields.contexto,
    required: false,
    placeholder: "Selecione um contexto"
  })
});

export const logAuditoriaDataGridColumns = [
  {
    field: "nomeUsuario",
    headerName: "Nome",
    description: "Nome do Usuário",
    flex: 0.5,
    hide: true,
    align: "left",
    headerAlign: "center"
  },
  {
    field: "cpfUsuario",
    headerName: "CPF",
    description: "CPF",
    valueFormatter: ({ value }) => formataCPF(value),
    flex: 0.5,
    minWidth: 110,
    hide: false,
    headerAlign: "center"
  },
  {
    field: "papeisUsuario",
    headerName: "Perfil",
    description: "Perfil",
    valueFormatter: formataPapeis,
    flex: 0.5,
    hide: true,
    headerAlign: "center"
  },
  {
    field: "ipUsuario",
    headerName: "IP",
    description: "IP",
    valueFormatter: ({ value }) => value,
    flex: 0.5,
    hide: true,
    headerAlign: "center"
  },
  {
    field: "dataHoraOperacao",
    headerName: "Data Hora",
    description: "Data Hora da Operação",
    valueFormatter: ({ value }) => formataIsoDateTimeBR(value),
    flex: 0.5,
    headerAlign: "center"
  },
  {
    field: "contexto",
    headerName: "Contexto",
    description: "Contexto",
    valueGetter: ({ value }) => TipoContexto[value],
    flex: 0.5,
    headerAlign: "center"
  },
  {
    field: "funcionalidade",
    headerName: "Funcionalidade",
    description: "Funcionalidade",
    flex: 0.6,
    valueGetter: ({ row }) => row.descricaoFuncionalidade,
    headerAlign: "center"
  },
  {
    field: "anoVigencia",
    headerName: "Vigência",
    description: "Vigência Impactada",
    flex: 0.4,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "processo",
    headerName: "Processo",
    description: "Nº Processo Impactado",
    flex: 0.5,
    hide: true,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "cnpjCompleto",
    headerName: "CNPJ",
    description: "CNPJ Impactado (completo ou raiz)",
    valueFormatter: ({ value, row }) => formataCNPJ(value) || formataCNPJRaiz(row.cnpjRaiz),
    flex: 0.5,
    minWidth: 155,
    headerAlign: "center"
  },
  {
    field: "infoAnterior",
    headerName: "Valores Antigos",
    description: "Valores Anteriores à Operação",
    renderCell: ({ value }) => formataLog(value),
    flex: 1.4,
    hideSortIcons: true,
    filterable: false,
    headerAlign: "center"
  },
  {
    field: "infoPosterior",
    headerName: "Valores Novos",
    description: "Valores Posteriores à Operação",
    renderCell: ({ value }) => formataLog(value),
    flex: 1.4,
    hideSortIcons: true,
    filterable: false,
    headerAlign: "center"
  }
];

export const logAuditoriaStyle = {
  root: {
    scroll: "none",
    minHeight: "510px",
    "& .MuiDataGrid-viewport, & .MuiDataGrid-renderingZone, & .MuiDataGrid-row, & .MuiDataGrid-cell": {
      maxHeight: "fit-content!important"
    },
    "& .MuiDataGrid-window": {
      overflow: "overlay!important"
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "1.5!important",
      overflowWrap: "anywhere",
      whiteSpace: "normal",
      padding: "5px",
      color: "rgba(0,0,0,.87)"
    },
    color: "rgba(0,0,0,.87)",
    fontFamily: ["Rawline"],
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#ededed"
    },

    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${"#e0e0e0"}`
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0
    },
    ".MuiDataGrid-root .MuiDataGrid-cell": {
      textOverflow: "none!important"
    }
  }
};
