import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import ReprocessamentosApi from "services/api/ReprocessamentosApi";
import { useVigencias } from "hooks/useVigencias";

import { columns, createReprocessamentoConsultaModel } from "./model";
import { ConsultaReprocessamentosForm } from "./components/ConsultaReprocessamentosForm";
import { DetalhaReprocessamento } from "./DetalhaReprocessamento";
import ReprocessamentoFormModal from "./components/ReprocessamentoFormModal";

const Reprocessamentos = () => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(true);

  const [fields, setFields] = React.useState();
  const [formErrors, setFormErros] = React.useState([]);
  const formElement = React.useRef();

  const [row, setRow] = React.useState();
  const [rows, setRows] = React.useState([]);

  const [exibeSucesso, setExibeSucesso] = React.useState();
  const [newRow, setNewRow] = React.useState();

  const _setLoading = (show) => {
    show && setFormErros([]);
    setLoading(show);
  };

  const _setRows = (res) => {
    if (!!res.erros) {
      setFormErros(res.erros);
      setRows([]);
    } else {
      setFormErros([]);
      setRows(res);
    }
  };

  const _updateRows = React.useCallback(
    (updatedRow) => {
      const { anoVigencia, cnpjRaiz } = updatedRow;
      _setRows(rows.map((r) => (r.anoVigencia === anoVigencia && r.cnpjRaiz === cnpjRaiz ? updatedRow : r)));
    },
    [rows]
  );

  const _handleLoadPage = React.useCallback(
    (reprocessamento) => {
      setFields(() => createReprocessamentoConsultaModel({ vigencias, fields: reprocessamento }));
      if (!!reprocessamento && !!formElement.current) {
        formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
      } else {
        _setLoading(false);
      }
    },
    [vigencias]
  );

  const detailAction = React.useMemo(
    () => ({
      title: "Detalhar Reprocessamento",
      icon: "text-info fa fa-search",
      action: (selectedRow) => {
        setRow(selectedRow);
        setLoading(true);
      }
    }),
    []
  );

  async function _handleSubmitNewForm(newReprocessamento) {
    setExibeSucesso(true);
    setNewRow(false);
    await _handleLoadPage(newReprocessamento);
  }

  async function _handleChangeSearchForm() {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmitSearchForm(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      const res = await ReprocessamentosApi.obter(requestBody);
      _setRows(res);
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  React.useEffect(() => {
    _handleLoadPage();
  }, [_handleLoadPage]);

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {!row && (
        <React.Fragment>
          <ConsultaReprocessamentosForm fields={fields} formRef={formElement} formErrors={formErrors} onSumit={_handleSubmitSearchForm} onChange={_handleChangeSearchForm} />

          <Grid container>
            <Grid item sm={6}>
              <h5>Reprocessamentos</h5>
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 20, textAlign: "right" }}>
              <BrButton secondary onClick={() => setNewRow(true)}>
                Novo Reprocessamento
              </BrButton>
            </Grid>
            <Grid item sm={12}>
              <CustomDataGrid getRowId={(row) => row.cnpjRaiz} columns={columns} rows={rows} actions={[detailAction]} />
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {!!row && <DetalhaReprocessamento reprocessamento={row} setLoading={setLoading} onUpdateReprocessamento={_updateRows} onClose={() => setRow(null)} />}

      {!!newRow && <ReprocessamentoFormModal vigencias={vigencias} onConfirm={_handleSubmitNewForm} onClose={() => setNewRow(null)} />}

      <ModalMessage show={!!exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
};

export default Reprocessamentos;
