import React from "react";
import BrCard from "@govbr/react-components/lib/BrCard";

const CustomCard = ({ title = "", iconComponent, children }) => (
  <BrCard
    title={
      <React.Fragment>
        {title}
        {iconComponent}
      </React.Fragment>
    }
  >
    {children}
  </BrCard>
);

export default CustomCard;
