import ConfigManager from "./ConfigManager";
import SessionManager, { SessionError } from "./SessionManager";

const CONTEXT_LOGIN = "/oauth2/authorize";
const CONTEXT_TOKEN = "/oauth2/token";
const CONTEXT_REVOKE = "/oauth2/revoke";
const CONTEXT_LOGOUT = "/oidc/logout";

const MSG_ERR_AUTH_MISCODE = "Falha ao iniciar sessão. Tente novamente.";
const MSG_ERR_AUTH_TOKEN_FAULT = "Falha ao obter token de acesso";
const MSG_ERR_AUTH_REFRESH_FAULT = "Falha ao obter refresh token.";
const MSG_ERR_AUTH_IDP_INVALID = "IDP inválido.";
const MSG_ERR_AUTH_NOT_FOUND = "Sessão não encontrada.";
const MSG_ERR_NO_TOKEN_LOGOUT = "Sessão inválida. Saindo...";

function redirectTo(url) {
  window.location.href = url;
}

function replaceTo(url) {
  window.history.replaceState(null, null, url);
}

function convertToUrlParams(obj) {
  const params = Object.keys(obj)
    .filter((k) => !!obj[k])
    .map((p) => `${p}=${obj[p]}`);
  return params.join("&");
}

function getQueryParams() {
  const queryParams = new URLSearchParams(window.location.search);
  return { code: queryParams.get("code"), session_state: queryParams.get("session_state") };
}

function buildAuthUrl(path = null, params = "") {
  let url = ConfigManager.authUrl();
  if (path) {
    url += path;
  }
  if (params) {
    url += "?".concat(params);
  }
  return url;
}

function generateLoginUrl(idp = 0) {
  idp = idp !== "" ? idp : 0;
  SessionManager.clean();
  const fidp = ConfigManager.idp(idp);
  if (!fidp && !fidp.idp) {
    throw new Error(MSG_ERR_AUTH_IDP_INVALID);
  }

  const params = {
    response_type: "code",
    code_challenge_method: "S256",
    code_challenge: SessionManager.generateCodeChallenge(fidp.idp),
    fidp: fidp.idp,
    client_id: ConfigManager.clientId(),
    scope: ConfigManager.scope(),
    redirect_uri: window.location.origin
  };

  return buildAuthUrl(CONTEXT_LOGIN, convertToUrlParams(params));
}

async function handleLoginPkce(codeVerifier) {
  let code, sessionState, refreshToken, grantType;

  const isRefreshToken = !codeVerifier;
  if (isRefreshToken) {
    grantType = "refresh_token";
    refreshToken = SessionManager.getRefreshToken();
    sessionState = SessionManager.getSessionState();
  } else {
    const qParams = getQueryParams();
    if (!qParams.code) {
      throw new Error(MSG_ERR_AUTH_MISCODE);
    }
    code = qParams.code;
    sessionState = qParams.session_state;
    grantType = "authorization_code";
  }

  try {
    const params = convertToUrlParams({
      grant_type: grantType,
      refresh_token: refreshToken,
      code_verifier: codeVerifier,
      code: code,
      client_id: ConfigManager.clientId(),
      redirect_uri: window.location.origin
    });

    const response = await fetch(buildAuthUrl(CONTEXT_TOKEN), {
      method: "POST",
      cache: "no-cache",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      redirect: "follow",
      body: params
    });

    if (!response.ok) {
      let fetchError = new Error(response.statusText || response.status);
      fetchError.response = response;
      throw fetchError;
    }

    let session = await response.json();
    session.session_state = sessionState;
    SessionManager.save(session);
    console.debug(`${grantType}-success`);
  } catch (error) {
    const { message, response } = error;
    const resDataJson = response ? await response.json() : "";
    console.debug(`${grantType}-error`, { message, content: resDataJson });

    if (error instanceof SessionError) {
      throw error;
    }
    throw isRefreshToken ? new Error(MSG_ERR_AUTH_REFRESH_FAULT) : new Error(MSG_ERR_AUTH_TOKEN_FAULT);
  }
}

async function handleLogout() {
  try {
    const fidp = ConfigManager.idp(SessionManager.getIdp());
    const urlLogout = fidp ? fidp.logoutUrl.concat(window.location.origin) : window.location.origin;

    const formEl = document.createElement("form");
    formEl.name = "formLogout";
    formEl.method = "POST";
    formEl.action = buildAuthUrl(CONTEXT_LOGOUT);

    const tokenInputEl = document.createElement("input");
    tokenInputEl.type = "hidden";
    tokenInputEl.name = "id_token_hint";
    tokenInputEl.value = SessionManager.getToken();

    const callbackInputEl = document.createElement("input");
    callbackInputEl.type = "hidden";
    callbackInputEl.name = "post_logout_redirect_uri";
    callbackInputEl.value = urlLogout;

    formEl.appendChild(tokenInputEl);
    formEl.appendChild(callbackInputEl);
    document.body.appendChild(formEl);

    formEl.submit();
  } catch (error) {
    console.error(`logout-error`, { message: error.message });
  }
}

function handleRevokePkce() {
  const params = convertToUrlParams({
    token_type_hint: "access_token",
    client_id: ConfigManager.clientId(),
    token: SessionManager.getAccessToken()
  });

  return fetch(buildAuthUrl(CONTEXT_REVOKE), {
    method: "POST",
    cache: "no-cache",
    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
    redirect: "follow",
    body: params
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText || response.status);
      }
    })
    .catch((error) => {
      console.error(`revoke-error`, { message: error.message });
    });
}

class AuthManager {
  constructor() {
    throw new Error("AuthManager Abstract class.");
  }

  static _isLogoutRequested = false;

  static isLoggedIn() {
    return SessionManager.isValidSession();
  }

  static doLogin(idp = 0) {
    redirectTo(generateLoginUrl(idp));
  }

  static doLogout() {
    if (!AuthManager._isLogoutRequested) {
      AuthManager._isLogoutRequested = true;
      if (!SessionManager.getToken()) {
        AuthManager.doLogin(SessionManager.getIdp());
        SessionManager.setFallbackNoTokenLogout();
        return;
      }
      handleRevokePkce().then(() => {
        handleLogout();
        SessionManager.clean();
      });
    }
  }

  static doKickOut(ms = 4000) {
    setTimeout(() => {
      AuthManager.doLogout();
    }, ms);
  }

  static async customPostSignIn() {
    console.debug("custom-post-signin");
    return true;
  }

  static async doSignIn() {
    try {
      if (AuthManager.isLoggedIn()) {
        return true;
      }

      const codeVerifier = SessionManager.getSessionId();
      const qParams = getQueryParams();
      if (codeVerifier && qParams.code) {
        await handleLoginPkce(codeVerifier);

        await AuthManager.customPostSignIn();

        if (SessionManager.isFallbackNoTokenLogout()) {
          throw new SessionError(MSG_ERR_NO_TOKEN_LOGOUT);
        }

        replaceTo(window.location.origin);
        return true;
      } else {
        console.debug("login-not-initiated");
        return false;
      }
    } catch (error) {
      console.error("Erro ao iniciar sessão", error);
      AuthManager.doKickOut();
      throw error;
    }
  }

  static async doRefreshSignIn() {
    if (!AuthManager.isLoggedIn()) {
      throw new Error(MSG_ERR_AUTH_NOT_FOUND);
    }

    await handleLoginPkce();

    return {};
  }
}

export default AuthManager;
