import React from "react";

import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import RelatoriosDataGrid from "components/RelatoriosDataGrid";
import { BoxMessage } from "components/BoxMessage";
import Loading from "components/Loading";

import PainelControleApi from "services/api/PainelControleApi";

const StatusIcon = ({ value = true, title = "" }) => {
  if (value === true || String(value).toUpperCase() === "UP") {
    return <i title={title || value} className="text-success fa fa-check-circle fa-lg"></i>;
  }
  return <i title={value} className="text-danger fa fa-times-circle fa-lg"></i>;
};

const columns = [
  {
    field: "name",
    headerName: "Serviço",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: ({ value }) => <StatusIcon value={value} />,
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    headerAlign: "center",
    align: "center",
    flex: 1
  },
  {
    field: "error",
    headerName: "Erro",
    cellClassName: "dataGrid-cell-error",
    sortable: false,
    filterable: false,
    hideSortIcons: true,
    flex: 3
  }
];

const MonitorServicos = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState([]);

  function _setLoading(show) {
    show && setFormErrors([]);
    setLoading(show);
  }

  React.useEffect(() => {
    if (loading) {
      (async () => {
        const res = await PainelControleApi.obterStatusServicos();
        if (!res.components) {
          setFormErrors({ ...res, type: "error" });
          setRows([]);
        } else {
          const services = res.components || [];
          const resServices = Object.keys(services).map((k) => ({
            name: k,
            status: services[k]?.status,
            error: services[k]?.details?.error
          }));
          setRows(resServices);
        }
        _setLoading(false);
      })();
    }
  }, [loading]);

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <BoxMessage message={formErrors} />
        </Grid>

        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={10}>
              <h5>Monitor de Serviços</h5>
            </Grid>

            <Grid item style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
              <BrButton primary onClick={() => _setLoading(true)}>
                <i className="fa fa-history"></i>
                Recarregar
              </BrButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <RelatoriosDataGrid rows={rows} columns={columns} rowHeight={90} getRowClassName={(params) => (params.row.status === "DOWN" ? "dataGrid-row-danger" : null)} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MonitorServicos;
