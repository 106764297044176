import { getApi, paramsSerializer } from "../ApiManager";

const contextoEsocial = "extracoes/esocial";
const contextoGfipweb = "extracoes/gfipweb";
const contextoVigencias = "vigencias";

const ExtracoesApi = {
  obterVinculosEsocial: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`/${contextoVigencias}/${anoVigencia}/${contextoEsocial}/tipos/vinculos`);
  },
  obterRecisoesEsocial: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`/${contextoVigencias}/${anoVigencia}/${contextoEsocial}/tipos/rescisoes`);
  },
  obterFatosGeradoresGfipweb: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`/${contextoVigencias}/${anoVigencia}/${contextoGfipweb}/tipos/fatos-geradores`);
  },
  obterRecisoesGfipweb: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`/${contextoVigencias}/${anoVigencia}/${contextoGfipweb}/tipos/rescisoes`);
  },
  obterRelatorioGfipweb: (anoVigencia, cnpj, params) => getApi().get(`/${contextoVigencias}/${anoVigencia}/${contextoGfipweb}/${cnpj}`, { params, paramsSerializer }),
  obterRelatorioEsocial: (anoVigencia, cnpj, params) => getApi().get(`/${contextoVigencias}/${anoVigencia}/${contextoEsocial}/${cnpj}`, { params, paramsSerializer })
};

export default ExtracoesApi;
