import React from "react";
import { Grid } from "@material-ui/core";

import { checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import LogExecucaoApi from "services/api/LogExecucaoApi";

import { columns, createExecucaoConsultaModel } from "./model";
import { ConsultaLogsExecucaoForm } from "./components/ConsultaLogsExecucaoForm";
import { DetalhaLogsExecucao } from "./DetalhaLogsExecucao";

const LogExecucao = () => {
  const [loading, setLoading] = React.useState(true);

  const [fields, setFields] = React.useState(() => createExecucaoConsultaModel());
  const [formErrors, setFormErrors] = React.useState([]);
  const formElement = React.useRef();

  const [row, setRow] = React.useState();
  const [rows, setRows] = React.useState([]);

  const [exibeSucesso, setExibeSucesso] = React.useState();

  const _setLoading = (show) => {
    show && setFormErrors([]);
    setLoading(show);
  };

  const _setRows = (res) => {
    if (!!res.erros) {
      setFormErrors(res.erros);
      setRows([]);
    } else {
      setFormErrors([]);
      setRows(res);
    }
  };

  const detailAction = React.useMemo(
    () => ({
      title: "Detalhar Log de Recálculo",
      icon: "text-info fa fa-search",
      action: (selectedRow) => {
        setRow(selectedRow);
        setLoading(true);
      }
    }),
    []
  );

  async function _handleChangeSearchForm() {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmitSearchForm(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      const res = await LogExecucaoApi.obterLogsExecucao(requestBody);
      _setRows(res);
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  const _handleLoadPage = React.useCallback(() => {
    if (!!formElement.current) {
      formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    } else {
      _setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    _handleLoadPage();
  }, [_handleLoadPage]);

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {!row && (
        <React.Fragment>
          <ConsultaLogsExecucaoForm fields={fields} formRef={formElement} formErrors={formErrors} onSumit={_handleSubmitSearchForm} onChange={_handleChangeSearchForm} />

          <Grid container>
            <Grid item sm={6}>
              <h5>Execuções</h5>
            </Grid>
            <Grid item sm={12}>
              <CustomDataGrid columns={columns} rows={rows} actions={[detailAction]} toolbar={"details"} />
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {!!row && <DetalhaLogsExecucao logExecucao={row} setLoading={setLoading} onClose={() => setRow(null)} />}

      <ModalMessage show={!!exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
};

export default LogExecucao;
