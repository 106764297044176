import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";
import ModalMessage from "components/ModalMessage";

import MensagensApi from "services/api/MensagensApi";
import { formataCnae, formataCNPJ, formataCNPJRaiz } from "utils/StrUtils";
import { TipoMensagem } from "utils/TipoUtils";

import FormMensagem from "./components/FormMensagem";
import FormConsultaMensagens from "./components/FormConsultaMensagens";

const columns = [
  {
    field: "anoVigencia",
    headerName: "Vigência",
    minWidth: 120,
    align: "right",
    headerAlign: "center"
  },
  {
    field: "tipo",
    headerName: "Tipo",
    valueFormatter: ({ value }) => TipoMensagem[value],
    minWidth: 245,
    headerAlign: "center"
  },
  {
    field: "texto",
    headerName: "Mensagem",
    minWidth: 400,
    flex: 1,
    headerAlign: "center"
  },
  {
    field: "cnpjRaiz",
    headerName: "Raiz",
    valueFormatter: ({ value }) => formataCNPJRaiz(value),
    minWidth: 10,
    headerAlign: "center"
  },
  {
    field: "cnpj",
    headerName: "CNPJ",
    valueFormatter: ({ value }) => formataCNPJ(value),
    minWidth: 150,
    headerAlign: "center"
  },
  {
    field: "irrestrito",
    type: "boolean",
    hide: true,
    headerName: "Irrestrito",
    minWidth: 120,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "cnae",
    hide: true,
    headerName: "CNAE",
    valueFormatter: ({ value }) => formataCnae(value),
    minWidth: 10,
    headerAlign: "center"
  },
  {
    field: "inicioValidade",
    type: "date",
    hide: true,
    headerName: "Início Validade",
    minWidth: 150,
    headerAlign: "center"
  },
  {
    field: "fimValidade",
    headerName: "Fim Validade",
    hide: true,
    minWidth: 150,
    headerAlign: "center"
  }
];

function getMensagensAtualizadas(isNew, msg, lista) {
  const isMesmaVigencia = lista.filter((m) => m.anoVigencia === msg.anoVigencia).length > 0;
  if (!isMesmaVigencia) {
    return lista;
  }
  let mensagensAux = [...lista];
  if (isNew) {
    mensagensAux.unshift(msg);
  } else {
    mensagensAux.forEach((m, i) => {
      if (m.id === msg.id) {
        mensagensAux[i] = { ...msg };
      }
    });
  }
  return mensagensAux;
}

export default function Mensagens() {
  const [loading, setLoading] = React.useState(false);
  const [loadingOverlay, setLoadingOverlay] = React.useState(null);

  const [formError, setFormError] = React.useState([]);
  const [exibeFormulario, setExibeFormulario] = React.useState(false);
  const [exibeSucesso, setExibeSucesso] = React.useState(false);

  const [mensagens, setMensagens] = React.useState([]);
  const [mensagemSelecionada, setMensagemSelecionada] = React.useState();

  function _setLoading(show, overlay = null) {
    setLoading(show);
    setLoadingOverlay(overlay);
    if (show) {
      setFormError([]);
    }
  }

  async function _consultarMensagens(fieldsJson) {
    _setLoading(true);
    const res = await MensagensApi.obterTodas({ ...fieldsJson });
    if (!res.erros) {
      setMensagens(res);
    } else {
      setMensagens([]);
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  async function _salvarMensagem(fieldsJson) {
    _setLoading(true);

    const res = fieldsJson.id ? await MensagensApi.alterar(fieldsJson) : await MensagensApi.criar(fieldsJson);
    if (!res.erros) {
      setMensagens(() => getMensagensAtualizadas(!fieldsJson.id, res, mensagens));
      setExibeSucesso(true);
      setExibeFormulario(false);
      setMensagemSelecionada(null);
    } else {
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  async function _criarMensagem() {
    setExibeFormulario(true);
    setFormError([]);
  }

  async function _editarMensagem(row) {
    setMensagemSelecionada(row);
    setExibeFormulario(true);
    setFormError([]);
  }

  async function _removerMensagem(row) {
    _setLoading(true);

    const res = await MensagensApi.remover(row);
    if (!res.erros) {
      setMensagens(() => mensagens.filter((r) => r.id !== row.id));
      setExibeSucesso(true);
    } else {
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  function _resetMensagem() {
    setExibeFormulario(false);
    setMensagemSelecionada(null);
    setFormError([]);
  }

  return (
    <React.Fragment>
      <Loading overlay overlayTarget={loadingOverlay} show={loading} />
      <Grid container style={{ display: exibeFormulario ? "none" : "" }}>
        <Grid item sm={12}>
          <FormConsultaMensagens onSubmit={_consultarMensagens} loading={loading} formError={formError} />
        </Grid>
        <Grid item sm={12}>
          <Divider style={{ marginTop: 5 }} />
        </Grid>
      </Grid>

      {!exibeFormulario ? (
        <Grid container>
          <Grid item sm={6}>
            <h5>Mensagens</h5>
          </Grid>
          <Grid item sm={6} style={{ paddingTop: 20, textAlign: "right" }}>
            <BrButton secondary onClick={_criarMensagem}>
              Criar Mensagem
            </BrButton>
          </Grid>
          <Grid item sm={12}>
            <CustomDataGrid
              getRowId={(row) => row.id}
              rows={mensagens}
              columns={columns}
              onEdit={_editarMensagem}
              onDelete={_removerMensagem}
              onDeleteMessage="Deseja realmente remover a mensagem?"
              toolbar="details"
              density="compact"
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <FormMensagem mensagem={mensagemSelecionada} onSubmit={_salvarMensagem} onCancel={_resetMensagem} loading={loading} formError={formError} />
        </Grid>
      )}

      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
}
