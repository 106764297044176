import React from "react";
import { Divider, Grid } from "@material-ui/core";

import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import BrButton from "@govbr/react-components/lib/BrButton";

import useEscKey from "hooks/useEscKey";
import { formataCNPJ, formataCompetencia, formataDateEnBr } from "utils/StrUtils";
import { LabelValue } from "components/LabelValue";

const DetalheExtracao = ({ show = false, extracao = {}, empresa = {}, onClose }) => {
  useEscKey(() => onClose());

  return show ? (
    <BrModal id="modal-extracao" visible={true} closable={true} onClose={onClose}>
      <span className="text-up-02 - text-bold">Informações da Extração</span>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />

      <Grid container spacing={1}>
        <Grid item sm={6}>
          <LabelValue label="Vigência" value={extracao.anoVigencia} orientation={"upLabel"} />
        </Grid>

        <Grid item sm={6}>
          <LabelValue
            label="Período-base utilizado para o cálculo"
            value={formataDateEnBr(extracao.inicioPeriodoBase) + " à " + formataDateEnBr(extracao.fimPeriodoBase)}
            orientation={"upLabel"}
          />
        </Grid>

        <Grid item sm={12}>
          <span className="text-up-01 - text-bold">Datas</span>
        </Grid>

        {extracao.dataExtracaoGFIP && (
          <Grid item sm={6}>
            <span className="label">GFIP: </span>
            <br />
            <span className="text-bold">{formataDateEnBr(extracao.dataExtracaoGFIP)}</span>
            <span className="text-base"> - {extracao.origemExtracaoGFIP}</span>
          </Grid>
        )}

        {empresa.competenciaGfip && (
          <Grid item sm={6}>
            <span className="label">Competência GFIP: </span>
            <br />
            <span className="text-bold">
              {formataCompetencia(empresa.competenciaGfip)}
            </span>
          </Grid>
        )}

      {empresa.cnpjGfip && (
          <Grid item sm={6}>
            <span className="label">CNPJ GFIP: </span>
            <br />
            <span className="text-bold">
              {formataCNPJ(empresa.cnpjGfip)}
            </span>
          </Grid>
        )}
         {empresa.numeroGfip && (
          <Grid item sm={6}>
            <span className="label">Número GFIP: </span>
            <br />
            <span className="text-bold">
              {empresa.numeroGfip}
            </span>
          </Grid>
        )}

        {extracao.dataExtracaoBeneficio && (
          <Grid item sm={6}>
            <span className="label">Benefícios: </span>
            <br />
            <span className="text-bold">{formataDateEnBr(extracao.dataExtracaoBeneficio)}</span>
            <span className="text-base">{` - ${extracao.origemExtracaoBeneficio}`}</span>
          </Grid>
        )}

        {extracao.dataExtracaoESocial && (
          <Grid item sm={6}>
            <span className="label">eSocial: </span>
            <br />
            <span className="text-bold">
              {formataDateEnBr(extracao.dataExtracaoESocial)}
            </span>
            <span className="text-base">
              {` - ${extracao.origemExtracaoESocial}`}
            </span>
          </Grid>
        )}

        {extracao.dataExtracaoCAT && (
          <Grid item sm={6}>
            <span className="label">CAT: </span>
            <br />
            <span className="text-bold">{formataDateEnBr(extracao.dataExtracaoCAT)}</span>
            <span className="text-base"> - {extracao.origemExtracaoCAT}</span>
          </Grid>
        )}

        {extracao.dataExtracaoCnis && (
          <Grid item sm={6}>
            <span className="label">CNIS: </span>
            <br />
            <span className="text-bold">{formataDateEnBr(extracao.dataExtracaoCnis)}</span>
            <span className="text-base">{` - ${extracao.origemExtracaoCNIS}`}</span>
          </Grid>
        )}

        {extracao.dataExtracaoExpectativaVida && (
          <Grid item sm={6}>
            <span className="label">Expectativa de vida: </span>
            <br />
            <span className="text-bold">
              {formataDateEnBr(extracao.dataExtracaoExpectativaVida)}
            </span>
            <span className="text-base">
              {` - ${extracao.fonteExpectativaVida} ${extracao.anoReferenciaExpectativaVida}`}
            </span>
          </Grid>
        )}

        {extracao.dataExtracaoEstabelecimentos && (
          <Grid item sm={6}>
            <span className="label">Estabelecimentos: </span>
            <br />
            <span className="text-bold">{formataDateEnBr(extracao.dataExtracaoEstabelecimentos)}</span>
            <span className="text-base">{` - ${extracao.origemExtracaoEstabelecimentos}`}</span>
          </Grid>
        )}
      </Grid>
      <BrModalFooter>
        <BrButton onClick={onClose}>Fechar</BrButton>
      </BrModalFooter>
    </BrModal>
  ) : null;
};

export default DetalheExtracao;
