import React from "react";
import { Grid } from "@material-ui/core";

import CustomCard from "components/CustomCard";
import { isUndefined } from "components/Form";
import { TagMessage } from "components/TagMessages";
import { LabelValue } from "components/LabelValue";

import { formataEndereco } from "../model";

const DadosEmpresa = ({ empresa, messages }) => {
  const { razaoSocial, cnpj, cnpjRaiz, dataInicioAtividade, dataSituacaoRFB } = empresa;
  const isRaiz = isUndefined(cnpj);

  return (
    <CustomCard title={isRaiz ? "Dados da Empresa" : "Dados do Estabelecimento"} iconComponent={<TagMessage messages={messages} />}>
      <Grid container spacing={1} alignItems="baseline">
        <LabelValue className="text-up-01 text-bold" value={razaoSocial} />
        <LabelValue columns={6} label="CNPJ" value={isRaiz ? cnpjRaiz : cnpj} dataType={isRaiz ? "cnpjRaiz" : "cnpj"} />
        <LabelValue columns={6} label="Início da Atividade" value={dataInicioAtividade} dataType="date" />
        <LabelValue columns={6} label="Endereço" value={formataEndereco(empresa)} />
        <LabelValue columns={6} label="Última atualização na RFB na extração" value={dataSituacaoRFB} dataType="date" />
      </Grid>
    </CustomCard>
  );
};

export default DadosEmpresa;
