import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import ModalMessage from "components/ModalMessage";
import Loading from "components/Loading";

import { formataCNPJ8Digitos } from "utils/StrUtils";
import { useVigencias } from "hooks/useVigencias";
import JulgamentoApi from "services/api/JulgamentoApi";

import { getResultadosJulgamentosColumns, TipoAcaoResultadoJulgamento } from "./utils";
import { createConsultaResultadosJulgamentosModel } from "./model";

const columns = getResultadosJulgamentosColumns();

export const ConsultaJulgamento = (props) => {
  const fieldsLocation = props.location.state?.fields;

  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(false);

  const [formErrors, setFormErrors] = React.useState([]);
  const [fields, setFields] = React.useState();
  const formElement = React.useRef();

  const [exibeSucesso, setExibeSucesso] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (vigencias.length > 0) {
      setFields(() => createConsultaResultadosJulgamentosModel(fieldsLocation, vigencias));

      if (fieldsLocation) {
        async function fetchData() {
          const r = await consultarResultadosJulgamentos({
            cnpjRaiz: formataCNPJ8Digitos(fieldsLocation.cnpjRaiz.value),
            anoVigencia: fieldsLocation.anoVigencia.value
          });
          if (r.erros) {
            setFormErrors(r.erros);
          } else {
            setRows(r);
          }
        }
        fetchData();
      }
    }
  }, [vigencias, fieldsLocation]);

  function _redirectToResultadoJulgamento(acao, resultadoJulgamento) {
    const state = { resultadoJulgamento, fields, acao, vigencias };
    if (!resultadoJulgamento) {
      props.history.push("/resultado-julgamento/criar", state);
    } else {
      props.history.push("/resultado-julgamento/editar", state);
    }
  }

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    setFields(() => ({ ...fields }));
    setRows([]);
    _setLoading(false);
  }

  async function consultarResultadosJulgamentos(filtros = {}) {
    return await JulgamentoApi.obter(filtros.cnpjRaiz, filtros.anoVigencia);
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      const res = await consultarResultadosJulgamentos(requestBody);
      if (res.erros) {
        setFormErrors(res.erros);
        setRows([]);
      } else {
        setRows(() => res);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  async function reabrir(row) {
    _setLoading(true);
    const res = await JulgamentoApi.reabrir(row.id);
    if (res.erros) {
      setFormErrors(res.erros);
    } else {
      const resRows = await consultarResultadosJulgamentos({ cnpjRaiz: formataCNPJ8Digitos(row.cnpjRaiz), anoVigencia: row.anoVigencia });
      if (resRows.erros) {
        setFormErrors(resRows.erros);
      } else {
        setExibeSucesso(true);
        setRows(resRows);
      }
    }
    _setLoading(false);
  }

  async function remover(row) {
    _setLoading(true);
    const res = await JulgamentoApi.remover(row.id);
    if (res.erros2) {
      setFormErrors(res.erros);
    } else {
      const resRows = await consultarResultadosJulgamentos({ cnpjRaiz: formataCNPJ8Digitos(row.cnpjRaiz), anoVigencia: row.anoVigencia });
      if (resRows.erros) {
        setFormErrors(resRows.erros);
      } else {
        setExibeSucesso(true);
        setRows(resRows);
      }
    }
    _setLoading(false);
  }

  const reabrirAction = {
    title: "Reabrir",
    icon: "text-info fa fa-reply",
    action: (selectedRow) => {
      reabrir(selectedRow);
    }
  };

  const editarAction = {
    title: "Editar",
    icon: "text-info fa fa-edit",
    action: (selectedRow) => {
      _redirectToResultadoJulgamento(TipoAcaoResultadoJulgamento.EDITAR, selectedRow);
    }
  };

  const removeAction = {
    title: "Excluir",
    icon: "text-danger fa fa-trash",
    action: (selectedRow) => {
      remover(selectedRow);
    }
  };

  if (!fields) {
    return null;
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Form ref={formElement} onSubmit={_handleSubmit} errors={formErrors} submitIcon="fa-search" submitTitle="Consultar">
            <Grid item sm={2}>
              <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
            </Grid>
          </Form>
        </Grid>

        <Grid item sm={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={6} style={{ display: "flex", alignItems: "end" }}>
          <h5 style={{ margin: 0 }}>Resultado de Julgamento</h5>
        </Grid>
        <Grid item sm={6} style={{ textAlign: "right" }}>
          <BrButton small secondary onClick={() => _redirectToResultadoJulgamento(TipoAcaoResultadoJulgamento.CRIAR)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            Novo resultado de julgamento
          </BrButton>
        </Grid>
        <Grid item sm={12}>
          <CustomDataGrid pageSize={5} rows={rows} columns={columns} density="compact" actions={[reabrirAction, editarAction, removeAction]} />
        </Grid>
      </Grid>
      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
};
