import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { InputGeneric } from "components/Form";
import ModalConfirm from "components/ModalConfirm";

import SituacaoVigencia from "./SituacaoVigencia";

const FormVigencia = ({ onSubmit, onCancel, onDelete, onChange, onReprocessa, submitTitle, formError, proximaVigencia, fields }) => {
  const [exibeConfirmacao, setExibeConfirmacao] = React.useState(false);
  const [messageDelete, setMessageDelete] = React.useState("");
  const [exibeConfirmacaoReprocessamento, setExibeConfirmacaoReprocessamento] = React.useState(false);

  const [messageReprocessa, setMessageReprocessa] = React.useState("");

  function _onDelete() {
    setMessageDelete(
      `Deseja realmente remover a vigência ${fields.anoVigencia.value}?
      Essa operação não poderá ser desfeita e irá remover todas as regras e parâmetros da vigência.`
    );
    setExibeConfirmacao(true);
  }
  function _onReprocessa() {
    setMessageReprocessa(
      `Deseja reiniciar o processamento da vigência ${fields.anoVigencia.value}?
      A rotina do cálculo deverá ser executada novamente.`
    );
    setExibeConfirmacaoReprocessamento(true);
  }

  function _onConfirm(_onConfirmCallback) {
    _onConfirmCallback();
    setExibeConfirmacao(false);
    setExibeConfirmacaoReprocessamento(false)
  }

  return (
    <Grid container>
      <Grid item sm={12}>
        <Form
          formTitle={proximaVigencia ? "Criar Próxima Vigência" : `Dados Vigência ${fields.anoVigencia.value}`}
          submitTitle={submitTitle}
          cancelTitle={"Cancelar"}
          onSubmit={onSubmit}
          onCancel={onCancel}
          errors={formError}
        >
          {proximaVigencia && (
            <React.Fragment>
              <Grid item sm={6}>
                <InputGeneric field={{ id: "proximaVigencia", readMode: true, value: proximaVigencia, label: "Próxima Vigência:" }} />
              </Grid>
              <Grid item sm={6}>
                <SituacaoVigencia value={"NOVA"} />
              </Grid>
            </React.Fragment>
          )}
          {!proximaVigencia && (
            <React.Fragment>
              <Grid item sm={6}>
                <InputGeneric field={fields.anoVigencia} />
              </Grid>
              <Grid item sm={6}>
                <SituacaoVigencia value={fields.situacao.value} />
              </Grid>
            </React.Fragment>
          )}

          <Grid item sm={12}>
            <h5>Datas de extração dos insumos do cálculo FAP</h5>
            <Divider style={{ marginTop: 20, marginBottom: 10 }} />
          </Grid>

          <Grid item sm={3}>
            <InputGeneric field={fields.inicioPeriodoBase} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.fimPeriodoBase} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.inicioExtracaoGfip} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.inicioExtracaoBeneficios} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.extracaoExpectativaVida} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.anoReferenciaExpectativaVida} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.inicioExtracaoCat} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.inicioExtracaoCnis} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.inicioExtracaoEsocial} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.inicioExtracaoEstabelecimentos} onChange={onChange} />
          </Grid>

          <Grid item sm={12}>
            <h5>Consulta do FAP</h5>
            <Divider style={{ marginTop: 20, marginBottom: 10 }} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.consultaCompetencia} onChange={onChange} />
          </Grid>

          <Grid item sm={12}>
            <h5>Insumos do FAP</h5>
            <Divider style={{ marginTop: 20, marginBottom: 10 }} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.dataInicioContestacao} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.dataFimContestacao} onChange={onChange} />
          </Grid>

          <Grid item sm={12}>
            <h5>Informações adicionais</h5>
            <Divider style={{ marginTop: 20, marginBottom: 10 }} />
          </Grid>
          <Grid item sm={2}>
            <InputGeneric field={fields.portariaFap} onChange={onChange} />
          </Grid>
          <Grid item sm={2}>
            <InputGeneric field={fields.versaoCnae} onChange={onChange} />
          </Grid>
          <Grid item sm={2}>
            <InputGeneric field={fields.anoDecretoRAT} onChange={onChange} />
          </Grid>
          <Grid item sm={2}>
            <InputGeneric field={fields.decretoCnae} onChange={onChange} />
          </Grid>
          <Grid item sm={3}>
            <InputGeneric field={fields.anoTaxaMortalidade} onChange={onChange} />
          </Grid>

          <Grid item sm={8} style={{ paddingTop: 34 }}>
            {onDelete && (
              <BrButton secondary onClick={_onDelete}>
                Remover vigência selecionada
              </BrButton>
            )}
            {onReprocessa && (<span style={{ paddingLeft:10 }}> 
              <BrButton secondary onClick={_onReprocessa}>
                Reiniciar o processamento da vigência selecionada
              </BrButton></span>
            )}
          </Grid>
        </Form>
      </Grid>
      <ModalConfirm show={exibeConfirmacao} cancel={() => setExibeConfirmacao(false)} onConfirm={() => _onConfirm(onDelete)} message={messageDelete} />
      <ModalConfirm show={exibeConfirmacaoReprocessamento} cancel={() => setExibeConfirmacaoReprocessamento(false)} onConfirm={() => _onConfirm(onReprocessa)} message={messageReprocessa} />
    </Grid>
  );
};

export default FormVigencia;
