import { getApi, handleDownload, paramsSerializer } from "../ApiManager";

const ctxVigencia = "/vigencias";
const ctxEmpresa = "/empresa";
const contexto = "/contestacoes";

const ContestacoesApi = {
  obterContestacoes: (params = { situacao: [], instancia: null }) => {
    const { cnpjRaiz, cnpj, ...rest } = params;
    rest.cnpj = cnpj || cnpjRaiz;
    return getApi().get(`${contexto}`, { params: rest, paramsSerializer });
  },
  obterAnalistas: (params = {}) => getApi().get(`${contexto}/analistas`, { params, paramsSerializer }),
  obterDeferimentosPorElemento: (elemento) => getApi().get(`${contexto}/deferimentos`, { params: { elemento } }),
  obterPareceresPorElemento: (tipoElemento, anoVigencia) => getApi().get(`/pareceres`, { params: { tipoElemento, anoVigencia } }),
  obterSumario: (idContestacao) => getApi().get(`${contexto}/${idContestacao}/sumario`),
  finalizar: (idContestacao) => getApi().post(`${contexto}/${idContestacao}`, null, { params: { acao: "FINALIZAR" } }),
  reiniciar: (idContestacao) => getApi().post(`${contexto}/${idContestacao}`, null, { params: { acao: "REINICIAR" } }),
  reiniciarElementos: (idContestacao, tipoElemento, idsElementos = []) => getApi().put(`${contexto}/${idContestacao}/elementos`, idsElementos, { params: { tipoElemento } }),
  analisar: (idContestacao, idElemento, { tipoElemento, ...body }) => getApi().put(`${contexto}/${idContestacao}/elementos/${idElemento}`, body, { params: { tipoElemento } }),
  desistirAdm: (contestacoes, { justificativaDesistencia, dataEncerraEfeitoSupensivo, dataDesistencia }) => {
    const body = { contestacoes, justificativaDesistencia, dataEncerraEfeitoSupensivo, dataDesistencia };
    return getApi().put(`${contexto}/desistir`, body);
  },
  homologarDesistencias: (idsContestacoes = []) => getApi().put(`${contexto}/homologa-desistencias`, idsContestacoes),
  criarContestacao: (cnpj, anoVigencia) => getApi().post(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/contestar`),
  criarRecurso: (cnpj, anoVigencia, idContestacao) => getApi().post(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${idContestacao}/contestar`),
  transmitir: (cnpj, anoVigencia, idContestacao, params) => getApi().put(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${idContestacao}/transmitir`, params),
  desistir: (cnpj, anoVigencia, idContestacao, params) => getApi().put(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${idContestacao}/desistir`, params),
  reabrir: (cnpj, anoVigencia, idContestacao) => getApi().post(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${idContestacao}/reabrir`),
  obterPorId: (cnpj, anoVigencia, idContestacao) => getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${idContestacao}`),
  obterElementosContestados: (cnpjRaiz, anoVigencia, idContestacao, elemento) =>
    getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${idContestacao}/${elemento}`),
  obterElementosNaoContestados: (cnpjRaiz, anoVigencia, idContestacao, elemento) =>
    getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${idContestacao}/${elemento}`, { params: { contestados: false } }),
  criarElementoContestado: (cnpjRaiz, anoVigencia, idContestacao, elemento, body) =>
    getApi().post(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${idContestacao}/${elemento}`, body),
  alterarElementoContestado: (cnpjRaiz, anoVigencia, idContestacao, elemento, idElemento, body) =>
    getApi().put(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${idContestacao}/${elemento}/${idElemento}`, body),
  removerElementoContestado: (cnpjRaiz, anoVigencia, idContestacao, elemento, idElemento) =>
    getApi().delete(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${idContestacao}/${elemento}/${idElemento}`),
  obterElementoContestadoPorId: (cnpjRaiz, anoVigencia, idContestacao, elemento, idElemento) =>
    getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpjRaiz}${contexto}/${idContestacao}/${elemento}/${idElemento}`),
  liberar: (ids) => getApi().post(`${contexto}`, ids, { params: { acao: "LIBERAR" } }),
  revisar: (ids) => getApi().post(`${contexto}`, ids, { params: { acao: "REVISAR" } }),
  encerrarEfeitosSuspensivos: (ids) => getApi().post(`${contexto}`, ids, { params: { acao: "ENCERRAR_EFEITO_SUSPENSIVO" } }),
  reabrirAdm: (ids) => getApi().post(`${contexto}`, ids, { params: { acao: "REABRIR" } }),
  distribuir: (ids, cpfResponsavel) => getApi().post(`${contexto}`, ids, { params: { acao: "DISTRIBUIR", cpfResponsavel } }),
  iniciarProximaRemessaContestacoes: async ({ instancia }) => getApi().post(`${contexto}`, null, { params: { acao: "INICIAR", instancia } }),
  downloadRelatorioPdfContestacao: (id, cnpj, anoVigencia) => getApi().get(`${ctxVigencia}/${anoVigencia}${ctxEmpresa}/${cnpj}${contexto}/${id}/imprimir`).then(handleDownload),
  downloadRelatorioPdfDespacho: (ids) => getApi().post(`${contexto}/imprimir-despacho`, ids).then(handleDownload),
  downloadRelatorioPdfDesistenciaContestacao: (id) => getApi().get(`${contexto}/${id}/relatorio-desistencia`).then(handleDownload)
};

export default ContestacoesApi;
