import React from "react";
import { Divider, Grid } from "@material-ui/core";

import { isUndefined } from "components/Form";
import { LabelValue } from "components/LabelValue";
import PdfIconButton from "components/PdfIconButton";
import CustomCard from "components/CustomCard";

import { formataDateEnBr } from "utils/StrUtils";
import { TipoCalculo } from "utils/TipoUtils";

import DetalheExtracao from "./DetalheExtracaoModal";
import TooltipMouseOver from "./TooltipMouseOver";
import BrButton from "@govbr/react-components/lib/BrButton";

function getCssSituacaoFap(valorFap = 0) {
  if (valorFap < 1) {
    return "text-success";
  } else if (valorFap > 1) {
    return "text-danger";
  } else {
    return "text-info";
  }
}

const DadosFapResumido = ({ fap: calculo, empresa, extracao, onClickPdf }) => {
  const [exibeExtracao, setExibeExtracao] = React.useState(false);

  const { fap = 0, dataProcessamento, tipoProcessamento, motivoNeutro, anoVigencia = "", mensagemMotivoNeutro } = calculo;
  const { bloqueado, reprocessadoPorEstabelecimento } = empresa;

  return (
    <CustomCard title={`FAP ${anoVigencia}`} iconComponent={<PdfIconButton onClick={onClickPdf} />}>
      {!isUndefined(fap) && (
        <React.Fragment>
          <Grid container spacing={1}>
            <LabelValue value={fap} className={`${getCssSituacaoFap(fap)} text-up-04`} dataType={"index"} />

            <LabelValue value={TipoCalculo[tipoProcessamento]} className="text-up-01 text-bold" />

            <Grid item sm={12}>
              <span style={{ fontSize: 12 }}>
                Realizado em <b>{formataDateEnBr(dataProcessamento)}</b>
              </span>
            </Grid>

            {motivoNeutro && <Grid item sm={12}>
              <TooltipMouseOver icon={"fa-exclamation-triangle"} message={mensagemMotivoNeutro} title="Motivo Neutro" /></Grid>
            }

            {bloqueado &&
              <Grid item sm={12}> <TooltipMouseOver icon={"fa-exclamation-triangle"} message={"FAP Bloqueado"} title="FAP Bloqueado" /></Grid>
            }

            {reprocessadoPorEstabelecimento &&
              <Grid item sm={12}> <TooltipMouseOver icon={"fa-exclamation-triangle"} message={"FAP Reprocessado Por Estabelecimento"} title="FAP Reprocessado Por Estabelecimento" /></Grid>
            }

            <Grid item sm={12} className="text-center">
              <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              <BrButton small className="text-info text-bold" style={{ cursor: "pointer" }} onClick={() => setExibeExtracao(true)}>
                Informações da Extração
              </BrButton>
            </Grid>
          </Grid>

          <DetalheExtracao show={exibeExtracao} extracao={extracao} empresa={empresa} onClose={() => setExibeExtracao(false)} />
        </React.Fragment>
      )}
    </CustomCard>
  );
};

export default DadosFapResumido;
