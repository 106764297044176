const MIN_VIGENCIA_FAP_POR_ESTABELECIMENTO = 2016;
const MAX_VIGENCIA_NEXO_TECNICO = 2017;
const MIN_VIGENCIA_CONTESTACAO_POR_ESTABELECIMENTO = 2017;
const MAX_VIGENCIA_CAT_POR_TRAJETO = 2017;
const MIN_VIGENCIA_DESBLOQUEIO_BONUS_MALUS = 2018;
const VIGENCIAS_PERCENTIS = [2010, 2011, 2012, 2013, 2014, 2016];

export function isVigenciaFapPorEstabelecimento(anoVigencia = "") {
  return anoVigencia && Number(anoVigencia) >= MIN_VIGENCIA_FAP_POR_ESTABELECIMENTO;
}

export function isVigenciaNexoTecnico(anoVigencia) {
  return Number(anoVigencia) <= MAX_VIGENCIA_NEXO_TECNICO;
}

export function isVigenciaContestacaoPorEstabelecimento(anoVigencia = "") {
  return anoVigencia && Number(anoVigencia) >= MIN_VIGENCIA_CONTESTACAO_POR_ESTABELECIMENTO;
}

export function isVigenciaCatPorTrajeto(anoVigencia = "") {
  return anoVigencia && Number(anoVigencia) <= MAX_VIGENCIA_CAT_POR_TRAJETO;
}

export function isVigenciaDesbloqueioBonusMalus(anoVigencia = "") {
  return anoVigencia && Number(anoVigencia) >= MIN_VIGENCIA_DESBLOQUEIO_BONUS_MALUS;
}

export function isVigenciaPercentis(anoVigencia = "") {
  return anoVigencia && VIGENCIAS_PERCENTIS.includes(Number(anoVigencia));
}
