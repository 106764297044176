import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";
import BrTabs from "@govbr/react-components/lib/BrTabs";

import CustomDataGrid from "components/CustomDataGrid";
import CustomCard from "components/CustomCard";

import { getTipoElementoUrl, TipoElementoContestacao } from "utils/TipoUtils";
import { isVigenciaNexoTecnico } from "utils/VigenciaUtils";
import { isSegundaInstancia } from "utils/ContestacaoUtils";
import ContestacoesApi from "services/api/ContestacoesApi";

import { getElementosContestadosColumns } from "../utils";
import AnalisaElementoForm from "./AnalisaElementoForm";

const Secao2Card = ({ contestacao, setLoading = () => {} }) => {
  const [rows, setRows] = React.useState([]);
  const [rowsError, setRowsError] = React.useState();
  const [tipoElemento, setTipoElemento] = React.useState();
  const [elementoContestado, setElementoContestado] = React.useState();
  const [elementosRowsIdsSelected, setElementosRowsIdsSelected] = React.useState([]);
  const [contestacaoSumario, setContestacaoSumario] = React.useState();

  const campoInstanciaContestacao = isSegundaInstancia(contestacao) ? "segundaInstancia" : "primeiraInstancia";

  async function _handleChangeTipoElemento(selectedTipoElemento) {
    setLoading(true);
    setTipoElemento(selectedTipoElemento);
    setElementoContestado(null);

    let res = await ContestacoesApi.obterElementosContestados(contestacao.cnpjConsulta, contestacao.anoVigencia, contestacao.id, getTipoElementoUrl(selectedTipoElemento));
    if (res.erros) {
      setRowsError(res.erros[0].mensagem);
      setRows([]);
    } else {
      setRowsError("");
      setRows(
        res.map((row) => ({
          ...row,
          id: row[campoInstanciaContestacao]?.id,
          deferimento: row[campoInstanciaContestacao]?.deferimento,
          tipoElemento: selectedTipoElemento,
          anoVigencia: contestacao.anoVigencia
        }))
      );
    }
    setLoading(false);
  }

  React.useEffect(() => {
    (async () => await _handleChangeTipoElemento(TipoElementoContestacao.CAT.id))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => await obterSumario(contestacao.id))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function obterSumario(id) {
    setLoading(true);
    const res = await ContestacoesApi.obterSumario(id);
    setContestacaoSumario(() => (!!res.erros ? [] : res));
    setLoading(false);
  }

  function obterTituloeTotalElementosContestados(tipo) {
    if (!contestacaoSumario) return tipo.label + "(0)";
    const filter = contestacaoSumario.filter((e) => e.tipoElemento.codigo === tipo.id);
    return tipo.label + " (" + filter[0].totais + ")";
  }

  async function _handleEditElementoContestado(row) {
    setElementoContestado(row);
  }

  async function _handleResetElementosContestados() {
    setLoading(true);
    const res = await ContestacoesApi.reiniciarElementos(contestacao.id, tipoElemento, elementosRowsIdsSelected);
    if (res.erros) {
      setRows([]);
      setRowsError(res.erros[0].mensagem);
    } else {
      await _handleChangeTipoElemento(tipoElemento);
    }
    setLoading(false);
  }

  async function _handleSubmitAnalisaElementoForm(requestBody) {
    await _handleChangeTipoElemento(tipoElemento);
  }

  const elementosContestadosColumns = getElementosContestadosColumns(tipoElemento);

  return (
    <CustomCard title="Elementos contestados">
      <Grid container>
        <Grid item sm={12} style={{ marginLeft: 30 }}>
          <BrTabs value={tipoElemento} onChange={_handleChangeTipoElemento}>
            <BrTabs.Tabs>
              <BrTabs.Tab id={TipoElementoContestacao.CAT.id} label={obterTituloeTotalElementosContestados(TipoElementoContestacao.CAT)} />
              {isVigenciaNexoTecnico(contestacao.anoVigencia) && (
                <BrTabs.Tab id={TipoElementoContestacao.NEXO_TECNICO.id} label={obterTituloeTotalElementosContestados(TipoElementoContestacao.NEXO_TECNICO)} />
              )}
              <BrTabs.Tab id={TipoElementoContestacao.BENEFICIO.id} label={obterTituloeTotalElementosContestados(TipoElementoContestacao.BENEFICIO)} />
              <BrTabs.Tab id={TipoElementoContestacao.MASSA_SALARIAL.id} label={obterTituloeTotalElementosContestados(TipoElementoContestacao.MASSA_SALARIAL)} />
              <BrTabs.Tab id={TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id} label={obterTituloeTotalElementosContestados(TipoElementoContestacao.NUMERO_MEDIO_VINCULOS)} />
              <BrTabs.Tab id={TipoElementoContestacao.ROTATIVIDADE.id} label={obterTituloeTotalElementosContestados(TipoElementoContestacao.ROTATIVIDADE)} />
            </BrTabs.Tabs>

            {!elementoContestado && (
              <BrTabs.Content>
                <BrTabs.Panel contentFor={tipoElemento}>
                  <CustomDataGrid
                    onEdit={_handleEditElementoContestado}
                    rows={rows}
                    columns={elementosContestadosColumns}
                    density="compact"
                    pageSize={5}
                    noRowsLabel={rowsError}
                    checkboxSelection
                    disableSelectionOnClick
                    selectionModel={elementosRowsIdsSelected}
                    onSelectionModelChange={(ids) => setElementosRowsIdsSelected(ids)}
                  />
                  <Grid item sm={12} className="text-right" style={{ paddingTop: 10 }}>
                    <BrButton small secondary onClick={_handleResetElementosContestados} disabled={!elementosRowsIdsSelected.length > 0}>
                      Reiniciar Análise dos Elementos
                    </BrButton>
                  </Grid>
                </BrTabs.Panel>
              </BrTabs.Content>
            )}
          </BrTabs>
        </Grid>
      </Grid>

      {!!elementoContestado && (
        <Grid container>
          <Grid item sm={12} style={{ marginLeft: 30 }}>
            <AnalisaElementoForm
              elementoContestado={elementoContestado}
              contestacao={contestacao}
              onSubmit={_handleSubmitAnalisaElementoForm}
              onCancel={() => setElementoContestado(null)}
              setLoading={setLoading}
            />
          </Grid>
        </Grid>
      )}
    </CustomCard>
  );
};

export default Secao2Card;
