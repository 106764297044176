import { getApi } from "../ApiManager";

const contextoVigencia = "/vigencias";

const VigenciasApi = {
  obterVigenciasAnos: () => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/anos`);
  },
  obterExtracao: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}/extracoes`);
  },
  obterVigencia: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}`);
  },
  alterarVigencia: (anoVigencia, dados) => {
    const Api = getApi();
    return Api.put(`${contextoVigencia}/${anoVigencia}`, dados);
  },
  criarVigencia: (anoVigencia, dados) => {
    const Api = getApi();
    return Api.post(`${contextoVigencia}/${anoVigencia}`, dados);
  },
  removerVigencia: (anoVigencia) => {
    const Api = getApi();
    return Api.delete(`${contextoVigencia}/${anoVigencia}`);
  },
  obterRegasCalculo: (anoVigencia, fase) => {
    const Api = getApi();
    const config = { params: { faseCalculo: fase } };
    return Api.get(`${contextoVigencia}/${anoVigencia}/regras`, config);
  },
  obterParametros: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}/parametros`);
  },
  alterarParametros: (anoVigencia, dados) => {
    const Api = getApi();
    return Api.put(`${contextoVigencia}/${anoVigencia}/parametros`, dados);
  },
  alterarRegaCalculo: (body, params) => {
    const Api = getApi();
    return Api.put(`${contextoVigencia}/${params.anoVigencia}/regras/${params.numeroRegra}`, body);
  },
  criarRegaCalculo: (body, params) => {
    const Api = getApi();
    return Api.post(`${contextoVigencia}/${params.anoVigencia}/regras/${params.numeroRegra}`, body);
  },
  removerRegaCalculo: (params) => {
    const Api = getApi();
    const config = { params: { faseCalculo: params.faseCalculo.codigo } };
    return Api.delete(`${contextoVigencia}/${params.anoVigencia}/regras/${params.numeroRegra}`, config);
  },
  obterExpectativaVida: (anoVigencia) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}/expectativa-vida`);
  },
  reiniciarProcessamento: (anoVigencia) => {
    const Api = getApi();
    return Api.put(`${contextoVigencia}/${anoVigencia}/reiniciar-processamento`);
  },
  carregarExpectativaVida: (anoVigencia, file) => {
    return getApi().upload(`${contextoVigencia}/${anoVigencia}/expectativa-vida`, {}, file);
  },
  removerExpectativaVida: (anoVigencia) => {
    const Api = getApi();
    return Api.delete(`${contextoVigencia}/${anoVigencia}/expectativa-vida`);
  }
};

export default VigenciasApi;
