import { todosPapeis, TipoPapel } from "./utils/TipoUtils";

export const DadosMenu = [
  {
    id: "home",
    label: "Início",
    roles: todosPapeis,
    url: "/",
    isHome: true,
    breadcrumbs: ["home"]
  },
  {
    id: "consultaFap",
    label: "Consulta do FAP",
    roles: [
      TipoPapel.CONSULTA_CGBRP,
      TipoPapel.GESTOR_CGBRP,
      TipoPapel.GESTOR_CRPS,
      TipoPapel.GESTOR_DATAPREV,
      TipoPapel.PRESIDENTE_JUNTA,
      TipoPapel.PRESIDENTE_CAMARA,
      TipoPapel.ANALISTA_CGBRP,
      TipoPapel.PROCURACAO,
      TipoPapel.EMPRESA
    ],
    url: "/consultar-fap",
    breadcrumbs: ["home", "consultaFap"],
    homeIcon: "fa-file-invoice",
    description: "Consulte o valor do FAP para o estabelecimento"
  },
  {
    id: "contestacaoEletronica",
    label: "Contestação Eletrônica",
    roles: [TipoPapel.EMPRESA, TipoPapel.PROCURACAO],
    url: "/contestacoes-eletronicas",
    breadcrumbs: ["home", "contestacaoEletronica"],
    homeIcon: "fa-folder-open",
    description: "Conteste o valor do FAP para o estabelecimento"
  },
  {
    id: "contestacoes",
    label: "Contestações",
    roles: [TipoPapel.CONSELHEIRO_CRPS, TipoPapel.GESTOR_CRPS, TipoPapel.PRESIDENTE_CAMARA, TipoPapel.PRESIDENTE_JUNTA],
    items: [
      {
        id: "gestaoContestacao",
        label: "Gestão de Contestação",
        roles: [TipoPapel.GESTOR_CRPS],
        url: "/contestacoes/distribuir-reabrir",
        breadcrumbs: ["home", "gestaoContestacao"],
        homeIcon: "fa-share-square",
        description: "Distribua para análise ou reabra as contestações"
      },
      {
        id: "analisaContestacao",
        label: "Análise de Contestação",
        roles: [TipoPapel.CONSELHEIRO_CRPS],
        url: "/contestacoes",
        breadcrumbs: ["home", "analisaContestacao"],
        homeIcon: "fa-keyboard",
        description: "Analise os elementos contestados pela empresa"
      },
      {
        id: "autorizaRecalculo",
        label: "Autorização de Recálculo",
        roles: [TipoPapel.PRESIDENTE_CAMARA, TipoPapel.PRESIDENTE_JUNTA],
        url: "/contestacoes/autorizar-recalculo",
        breadcrumbs: ["home", "autorizaRecalculo"],
        homeIcon: "fa-handshake",
        description: "Autorize o recálculo para os FAP contestados"
      },
      {
        id: "imprimeDespacho",
        label: "Impressão de Despacho",
        roles: [TipoPapel.PRESIDENTE_CAMARA, TipoPapel.PRESIDENTE_JUNTA],
        url: "/contestacoes/imprimir-despacho",
        breadcrumbs: ["home", "imprimeDespacho"]
      },
      {
        id: "desisteContestacao",
        label: "Desistência de Contestação",
        roles: [TipoPapel.GESTOR_CRPS],
        url: "/contestacoes/desistencias",
        breadcrumbs: ["home", "desisteContestacao"]
      },
      {
        id: "homologaContestacao",
        label: "Homologação de Desistência",
        roles: [TipoPapel.PRESIDENTE_CAMARA, TipoPapel.PRESIDENTE_JUNTA],
        url: "/contestacoes/desistencias/homologar",
        breadcrumbs: ["home", "homologaContestacao"]
      },
      {
        id: "cadastraPublicacaoDou",
        label: "Publicação no DOU",
        roles: [TipoPapel.GESTOR_CRPS],
        url: "/contestacoes/publicacoes-dou",
        breadcrumbs: ["home", "cadastraPublicacaoDou"],
        homeIcon: "fa-rocket",
        description: "Cadastre os relatórios de publicações no DOU"
      },
      {
        id: "pareceres",
        label: "Cadastro de Parecer",
        url: "/painel-controle/pareceres",
        roles: [TipoPapel.GESTOR_CRPS],
        breadcrumbs: ["home", "pareceres"]
      }
    ]
  },
  {
    id: "resultadoJulgamento",
    label: "Resultado de Julgamento",
    roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.ANALISTA_CGBRP],
    url: "/resultado-julgamento",
    breadcrumbs: ["home", "resultadoJulgamento"],
    homeIcon: "fa-gavel",
    description: "Cadastre os resultados de julgamentos"
  },
  {
    id: "painelControle",
    label: "Painel de Controle",
    roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_CRPS, TipoPapel.ANALISTA_CGBRP, TipoPapel.CONSELHEIRO_CRPS, TipoPapel.GESTOR_DATAPREV],
    items: [
      {
        id: "datasPrazos",
        label: "Datas e Prazos",
        url: "/datas-prazos",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_DATAPREV],
        homeIcon: "fa-calendar",
        breadcrumbs: ["home", "datasPrazos"],
        description: "Cadastre as datas e prazos das vigências"
      },
      {
        id: "parametros",
        label: "Parâmetros",
        url: "/painel-controle/parametros",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_DATAPREV],
        homeIcon: "fa-sliders-h",
        breadcrumbs: ["home", "parametros"],
        description: "Cadastre os parâmetros das vigências"
      },
      {
        id: "regrasCalculo",
        label: "Regras do Cálculo",
        url: "/painel-controle/regras-do-calculo",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_DATAPREV],
        homeIcon: "fa-cog",
        breadcrumbs: ["home", "regrasCalculo"],
        description: "Cadastre, ative ou desative as regras para os cálculos"
      },
      {
        id: "mensagens",
        label: "Mensagens Informativas",
        url: "/painel-controle/mensagens",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.ANALISTA_CGBRP, TipoPapel.GESTOR_DATAPREV],
        homeIcon: "fa-envelope",
        breadcrumbs: ["home", "mensagens"],
        description: "Cadastre as mensagens do sistema para as vigências"
      },
      {
        id: "expectativa-vida",
        label: "Expectativa de Vida",
        url: "/painel-controle/expectativa-vida",
        roles: [TipoPapel.GESTOR_CGBRP],
        breadcrumbs: ["home", "expectativa-vida"]
      },
      {
        id: "taxa-mortalidade-anual",
        label: "Taxa de Mortalidade Anual",
        url: "/painel-controle/taxa-mortalidade-anual",
        roles: [TipoPapel.GESTOR_CGBRP],
        breadcrumbs: ["home", "taxa-mortalidade-anual"]
      },
      {
        id: "taxa-mortalidade-cnae",
        label: "Taxa de Mortalidade CNAE",
        url: "/painel-controle/taxa-mortalidade-cnae",
        roles: [TipoPapel.GESTOR_CGBRP],
        breadcrumbs: ["home", "taxa-mortalidade-cnae"]
      },
      {
        id: "reprocessamentos",
        label: "Reprocessamento por Estabelecimento",
        url: "/painel-controle/reprocessamentos",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.ANALISTA_CGBRP],
        breadcrumbs: ["home", "reprocessamentos"]
      },
      {
        id: "extratorInsumosEsocial",
        label: "Extrator de Insumos eSocial",
        url: "/painel-controle/extrator-insumos-esocial",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_CRPS, TipoPapel.ANALISTA_CGBRP, TipoPapel.CONSELHEIRO_CRPS, TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "extratorInsumosEsocial"]
      },
      {
        id: "extratorInsumosGfip",
        label: "Extrator de Insumos GFIPWeb",
        url: "/painel-controle/extrator-insumos-gfipweb",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_CRPS, TipoPapel.ANALISTA_CGBRP, TipoPapel.CONSELHEIRO_CRPS, TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "extratorInsumosGfip"]
      },
      {
        id: "logAuditoria",
        label: "Consulta de Logs de Auditoria",
        url: "/painel-controle/log-auditoria",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_CRPS, TipoPapel.GESTOR_DATAPREV],
        homeIcon: "fa-user-secret",
        breadcrumbs: ["home", "logAuditoria"],
        description: "Consulte os logs das operações auditadas no sistema"
      },
      {
        id: "logExecucao",
        label: "Consulta de Logs de Execuções",
        url: "/painel-controle/log-execucao",
        roles: [TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "logExecucao"],
        description: "Consulte os logs de execuções do recálculo"
      },
      {
        id: "monitorServicos",
        label: "Monitor de Serviços",
        url: "/painel-controle/monitor-servicos",
        roles: [TipoPapel.GESTOR_DATAPREV],
        homeIcon: "fa-desktop",
        breadcrumbs: ["home", "monitorServicos"],
        description: "Consulte o status da conectividade de serviços que o FAP utiliza"
      }
    ]
  },
  {
    id: "procuracoes",
    label: "Procurações Eletrônicas",
    url: "/procuracoes",
    roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.EMPRESA, TipoPapel.GESTOR_DATAPREV],
    homeIcon: "fa-unlock-alt",
    breadcrumbs: ["home", "procuracoes"],
    description: "Gerencie as procurações eletrônicas"
  },
  {
    id: "relatorios",
    label: "Relatórios",
    roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_CRPS, TipoPapel.CONSELHEIRO_CRPS, TipoPapel.GESTOR_DATAPREV],
    items: [
      {
        id: "relatoriosRat",
        label: "Relatórios RAT",
        url: "/relatorios/relatorios-rat",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "relatoriosRat"]
      },
      {
        id: "relatoriosGerenciais",
        label: "Relatórios Gerenciais",
        url: "/relatorios/relatorios-gerenciais",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_CRPS, TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "relatoriosGerenciais"]
      },
      {
        id: "relatorioProdutividade",
        label: "Relatório de Produtividade",
        url: "/relatorios/relatorio-produtividade",
        roles: [TipoPapel.GESTOR_CRPS, TipoPapel.CONSELHEIRO_CRPS, TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "relatorioProdutividade"]
      },
      {
        id: "resultadosAmostras",
        label: "Resultados e Amostras",
        url: "/relatorios/resultados-amostras",
        roles: [TipoPapel.GESTOR_CGBRP, TipoPapel.GESTOR_DATAPREV],
        breadcrumbs: ["home", "resultadosAmostras"]
      }
    ]
  }
];
