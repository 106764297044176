import { FieldType } from "./types";
import { isUndefined, validationStateField } from "./formUtils";

function Field(
  id,
  label,
  {
    value = "",
    type = FieldType.TEXT,
    required = true,
    disabled = false,
    mask = null,
    regex = null,
    options = [],
    validated = false,
    maxLength = null,
    readOnly = false,
    readMode = false,
    errorMessage = "",
    placeholder = "",
    multiple = false,
    ...rest
  }
) {
  this.id = id;
  this.label = label;
  this.required = required;
  this.disabled = disabled;
  this.maxLength = maxLength;
  this.readOnly = readOnly;
  this.placeholder = placeholder;
  this.options = options;
  this.regex = regex;
  this.validated = validated;
  this.errorMessage = errorMessage;
  this.readMode = readMode;
  this.mask = mask;
  this.multiple = multiple;

  this.value = isUndefined(value) ? "" : value;
  this.type = mask ? FieldType.NUM_MASK : type;
  if (this.type === FieldType.SELECT_ONE_BUTTON) {
    this.value = Boolean(value);
  }
  if (this.multiple && this.value.length === 0) {
    this.value = [];
  }

  if (rest && Object.keys(rest).length > 0) {
    Object.keys(rest).forEach((key) => {
      this[key] = rest[key];
    });
  }
}

Field.prototype.erro = function () {
  return validationStateField(this);
};

Field.prototype.isValid = function () {
  return this.validated && !this.erro();
};

export default Field;
