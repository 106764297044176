import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { InputGeneric, checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";
import { BoxMessage } from "components/BoxMessage";
import CustomDataGrid from "components/CustomDataGrid";
import ModalConfirm from "components/ModalConfirm";
import Loading from "components/Loading";

import { formataCNPJRaiz, formataCPF, formataDateEnBr } from "utils/StrUtils";
import { handleDownload } from "services/ApiManager";
import ProcuracoesApi from "services/api/ProcuracoesApi";

import { createModel, filtroModel, parecerModel } from "./model";
import ResumoProcuracaoModal from "./components/ResumoProcuracaoModal";
import CamposProcuracao from "./components/CamposProcuracao";

const customSort = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

const actions = {
  LISTAR: "LISTAR",
  CRIAR: "CRIAR",
  PARECER: "PARECER"
};

const MSG_SUCESSO = "Operação realizada com sucesso!";
const MSG_ERROR_PARECER_FORBIDDEN = "Situação não permite parecer!";

const isProcuracaoPendente = (procuracao = {}) => procuracao.situacao?.codigo === "PENDENTE";

export default function Procuracoes({ userData }) {
  const [empresasVinculadas = [], todasEmpresas = [], isAcessoEmpresa] = React.useMemo(() => {
    const empresasProcuracoes = userData.empresasProcuracoes.filter((e) => !!e.incorporada);
    const empresasVinculadasIncorporadas = []
      .concat(userData.empresasVinculadas)
      .concat(empresasProcuracoes)
      .filter((item, pos, self) => self.indexOf(item) === pos)
      .sort((a, b) => customSort(a.nome, b.nome));
    return [userData.empresasVinculadas, empresasVinculadasIncorporadas, userData.isAcessoEmpresa];
  }, [userData]);

  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState(actions.LISTAR);

  const [parecerFields, setParecerFields] = React.useState();
  const [consultaFields, setConsultaFields] = React.useState(filtroModel());
  const [novaFields, setNovaFields] = React.useState();
  const [formMessage, setFormMessage] = React.useState();

  const [procuracoes, setProcuracoes] = React.useState([]);
  const [procuracao, setProcuracao] = React.useState();
  const [procuracaoRemover, setProcuracaoRemover] = React.useState();

  function _setLoading(show) {
    setLoading(show);
    show && setFormMessage();
  }

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      var consultaFieldsAux = consultaFields;
      if (isAcessoEmpresa) {
        consultaFieldsAux.situacao.value = null;
      } else {
        consultaFieldsAux.situacao.value = "PENDENTE";
      }
      setConsultaFields(() => ({ ...consultaFields }));
      await consultarProcuracoes({ situacao: consultaFieldsAux.situacao.value });
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function _handleReset(event) {
    setProcuracao(null);
    setProcuracaoRemover(null);
    setAction(actions.LISTAR);
  }

  async function consultarProcuracoes(requestBody) {
    const res = await ProcuracoesApi.obterProcuracoes(requestBody);
    if (res.erros) {
      setProcuracoes([]);
      setFormMessage(res);
    } else {
      !requestBody && setFormMessage({ mensagem: MSG_SUCESSO, type: "success" });
      setProcuracoes(res);
      _handleReset();
    }
  }

  function _handleConsultaFormChange(event) {
    setConsultaFields(() => ({ ...consultaFields }));
  }

  function _handleNovaFormChange(event) {
    setNovaFields(() => createModel(empresasVinculadas, todasEmpresas, novaFields));
  }

  function _handleParecerFormChange(event) {
    setParecerFields(() => ({ ...parecerFields }));
  }

  async function _handleNovaProcuracaoClick() {
    setFormMessage();
    setNovaFields(() => createModel(empresasVinculadas, todasEmpresas));
    setAction(actions.CRIAR);
  }

  async function _handleConsultaFormSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(consultaFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(consultaFields);
      await consultarProcuracoes(requestBody);
    }
    setConsultaFields(() => ({ ...consultaFields }));
    _setLoading(false);
  }

  async function _handleNovaFormSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(novaFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(novaFields);
      const { dataInicio, tipoProcuracao, cnpjRaizOutorgado, cnpjRaizOutorgante, cpfOutorgado, dataFim, justificativa } = requestBody;
      const params = {
        dataInicio,
        tipoProcuracao,
        cnpjRaizOutorgado,
        cnpjRaizOutorgante,
        cpfOutorgado,
        dataFim,
        justificativa
      };

      const resNova = await ProcuracoesApi.criarProcuracao(params, novaFields.arquivo.value);
      if (resNova.erros) {
        setFormMessage(resNova);
      } else {
        await consultarProcuracoes();
      }
    }
    setNovaFields(() => ({ ...novaFields }));
    _setLoading(false);
  }

  async function _handleRemoveSubmit() {
    _setLoading(true);
    const resRemove = await ProcuracoesApi.removerProcuracao(procuracaoRemover.protocolo);
    if (resRemove.erros) {
      setFormMessage(resRemove);
    } else {
      await consultarProcuracoes();
    }
    _setLoading(false);
  }

  async function _handleParecerSubmit(event) {
    _setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(parecerFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(parecerFields);
      let resParecer = await ProcuracoesApi.criarParecer(procuracao.protocolo, requestBody);

      if (resParecer.erros) {
        setFormMessage(resParecer);
      } else {
        await consultarProcuracoes();
      }
    }
    setParecerFields(() => ({ ...parecerFields }));
    _setLoading(false);
  }

  async function visualizar(row) {
    _setLoading(true);
    const res = await ProcuracoesApi.obterProcuracao(row.protocolo);
    if (res.erros) {
      setFormMessage(res);
    } else {
      setProcuracao(res);
    }
    _setLoading(false);
  }

  async function remover(obj) {
    setProcuracaoRemover(obj);
  }

  function download({ nomeArquivo, arquivo }) {
    _setLoading(true);
    handleDownload({ nome: nomeArquivo, base64: arquivo });
    _setLoading(false);
  }

  async function emitirParecer(obj) {
    if (isProcuracaoPendente(obj)) {
      _setLoading(true);
      const res = await ProcuracoesApi.obterProcuracao(obj.protocolo);
      if (res.erros) {
        setFormMessage(res);
      } else {
        setParecerFields(() => parecerModel());
        setAction(actions.PARECER);
        setProcuracao(res);
      }
      _setLoading(false);
    } else {
      setFormMessage({ mensagem: MSG_ERROR_PARECER_FORBIDDEN, type: "warning" });
    }
  }

  const columns = [
    { field: "dataCadastro", headerName: "Data Solicitação", description: "Data da Solicitação", type: "date", flex: 1, valueFormatter: ({ value }) => formataDateEnBr(value) },
    { field: "tipoProcuracao", headerName: "Tipo", description: "Tipo da Procuração", flex: 1, valueGetter: ({ value }) => value.descricao },
    { field: "cnpjRaizOutorgante", headerName: "CNPJ Raiz Outorgante", description: "CNPJ Raiz do Outorgante", flex: 1, valueGetter: ({ value }) => formataCNPJRaiz(value) },
    { field: "cnpjRaizOutorgado", headerName: "CNPJ Raiz Outorgado", description: "CNPJ Raiz do Outorgado", flex: 1, valueGetter: ({ value }) => formataCNPJRaiz(value) },
    { field: "cpfOutorgado", headerName: "CPF Outorgado", description: "CPF do Outorgado", flex: 1, valueGetter: ({ value }) => formataCPF(value) },
    { field: "dataInicio", headerName: "Data Início", description: "Data de Início da Validade", type: "date", flex: 1, valueFormatter: ({ value }) => formataDateEnBr(value) },
    { field: "dataFim", headerName: "Data Fim", description: "Data de Fim da Validade", type: "date", flex: 1, valueFormatter: ({ value }) => formataDateEnBr(value) },
    { field: "situacao", headerName: "Situação", description: "Situação da Procuração", flex: 1, valueGetter: ({ value }) => value.descricao }
  ];

  const dataGridActions = isAcessoEmpresa
    ? [
        {
          title: "Visualizar Procuração",
          icon: "text-info fa fa-search",
          action: visualizar
        },
        {
          title: "Remover Procuração",
          icon: "text-danger fa fa-trash",
          action: remover
        }
      ]
    : [
        {
          title: "Visualizar Procuração",
          icon: "text-info fa fa-search",
          action: visualizar
        },
        {
          title: "Emitir Parecer",
          icon: "text-info fa fa-gavel",
          action: emitirParecer
        }
      ];

  return (
    <React.Fragment>
      <Loading show={loading} overlay />

      <Grid container>
        <Grid item sm={12}>
          <BoxMessage message={formMessage} />
        </Grid>
      </Grid>

      {action === actions.LISTAR && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Form onSubmit={_handleConsultaFormSubmit} submitTitle={"Consultar"} submitIcon={"fa-search"}>
              <Grid item sm={3}>
                <InputGeneric field={consultaFields.tipoProcuracao} onChange={_handleConsultaFormChange} />
              </Grid>

              {!isAcessoEmpresa && (
                <Grid item sm={3}>
                  <InputGeneric field={consultaFields.cnpjRaizOutorgante} onChange={_handleConsultaFormChange} />
                </Grid>
              )}

              <Grid item sm={3}>
                <InputGeneric field={consultaFields.cnpjRaizOutorgado} onChange={_handleConsultaFormChange} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={consultaFields.cpfOutorgado} onChange={_handleConsultaFormChange} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={consultaFields.situacao} onChange={_handleConsultaFormChange} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={consultaFields.dataInicio} onChange={_handleConsultaFormChange} />
              </Grid>

              <Grid item sm={3}>
                <InputGeneric field={consultaFields.dataFim} onChange={_handleConsultaFormChange} />
              </Grid>
            </Form>
          </Grid>

          <Grid item sm={6} style={{ display: "flex", alignItems: "end" }}>
            <h5 style={{ margin: 0 }}>Procurações Eletrônicas</h5>
          </Grid>

          {isAcessoEmpresa && (
            <Grid item sm={6} style={{ textAlign: "right" }}>
              <Grid item sm={12} style={{ paddingTop: 23, textAlign: "right" }}>
                <BrButton small secondary onClick={_handleNovaProcuracaoClick}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  Incluir Procuração
                </BrButton>
              </Grid>
            </Grid>
          )}

          <Grid item sm={12}>
            <CustomDataGrid actions={dataGridActions} rows={procuracoes} columns={columns} />
          </Grid>

          {procuracao && <ResumoProcuracaoModal procuracao={procuracao} onDownload={download} onClose={_handleReset} />}

          {procuracaoRemover && <ModalConfirm show={true} onConfirm={_handleRemoveSubmit} cancel={_handleReset} message={"Deseja remover a procuração?"} />}
        </Grid>
      )}

      {action === actions.PARECER && parecerFields && procuracao && (
        <Grid container>
          <Grid item sm={12}>
            <h5>Parecer do Gestor</h5>
            <Divider style={{ marginTop: 15, marginBottom: 30 }} />
          </Grid>
          <Grid item sm={12}>
            <Form onSubmit={_handleParecerSubmit} submitTitle={"Salvar"} onCancel={_handleReset} cancelTitle={"Cancelar"}>
              <Grid item sm={12}>
                <CamposProcuracao procuracao={procuracao} onDownload={download} />
              </Grid>
              <Grid item sm={12}>
                <InputGeneric field={parecerFields.parecer} onChange={_handleParecerFormChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={parecerFields.tipoDeferimento} onChange={_handleParecerFormChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}

      {action === actions.CRIAR && (
        <Grid container>
          <Grid item sm={12}>
            <h5>Nova Procuração Eletrônica</h5>
            <Divider style={{ marginTop: 15, marginBottom: 30 }} />
          </Grid>
          <Grid item sm={12}>
            <Form onSubmit={_handleNovaFormSubmit} submitTitle={"Salvar"} onCancel={_handleReset} cancelTitle={"Cancelar"}>
              <Grid item sm={3}>
                <InputGeneric field={novaFields.tipoProcuracao} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={novaFields.cnpjRaizOutorgante} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={novaFields.cnpjRaizOutorgado} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={novaFields.cpfOutorgado} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={novaFields.dataInicio} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={novaFields.dataFim} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={12}>
                <InputGeneric field={novaFields.justificativa} onChange={_handleNovaFormChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={novaFields.arquivo} onChange={_handleNovaFormChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
