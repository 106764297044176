import React from "react";
import  BrButton  from "@govbr/react-components/lib/BrButton";

const PdfIconButton = ({ title = "Download PDF", onClick = () => {} }) => {
  return (
    <BrButton circle small title={title} aria-label={title} onClick={onClick}>
      <span className="text-info fa fa-file-pdf fa-md"></span>
    </BrButton>
  );
};

export default PdfIconButton;
