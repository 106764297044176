import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";
import useEscKey from "hooks/useEscKey";
import CustomCard from "components/CustomCard";
import SumarioContestacaoDataGrid from "./SumarioContestacaoDataGrid";
import { formataProtocoloContestacao } from "utils/StrUtils";

const ModalSumario = ({ show = false, contestacaoSumario = [], onClose }) => {
  useEscKey(() => onClose());

  return show ? (
    <div className="modal-table">
      <div className="modal-table-content">
        <CustomCard title={"Resumo da Contestação " + formataProtocoloContestacao(contestacaoSumario.protocolo)}>
          <Grid item sm={12}>
            <SumarioContestacaoDataGrid noRowsLabel={"Nenhum resultado encontrado"} getRowId={(row) => row.tipoElemento} rows={contestacaoSumario} />
          </Grid>

          <Grid item sm={12} className="text-center" style={{ paddingTop: 10 }}>
            <BrButton small onClick={() => onClose()}>
              Fechar
            </BrButton>
          </Grid>
        </CustomCard>
      </div>
    </div>
  ) : null;
};

export default ModalSumario;
