import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import CustomDataGrid from "components/CustomDataGrid";
import CustomCard from "components/CustomCard";

import useLockBodyScroll from "hooks/useLockBodyScroll";
import useEscKey from "hooks/useEscKey";

const ContestacoesPublicadasDataGridModal = ({
  data = {},
  onClose,
  getRowId,
  successMessages = [],
  errorMessages = [],
  onClickEncerrarEfeitosSuspensivos,
  onClickReenviarEmails
}) => {
  const [selectedItems, setSelectedItems] = React.useState([]);

  useEscKey(() => onClose());
  useLockBodyScroll();

  function _handleSelectItems(ids) {
    setSelectedItems(ids);
  }

  return (
    <div className="modal-table">
      <div className="modal-table-content">
        <CustomCard title={data.title}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {errorMessages.map((m, i) => {
                return (
                  <BrMessage key={"m_error_" + i} error>
                    {m.mensagem}
                  </BrMessage>
                );
              })}
              {successMessages.map((m, i) => {
                return (
                  <BrMessage key={"m_sucess_" + i} success>
                    {m.mensagem}
                  </BrMessage>
                );
              })}
            </Grid>

            <Grid item sm={12}>
              <CustomDataGrid
                getRowId={getRowId}
                rows={data.rows}
                columns={data.columns}
                density="compact"
                toolbar="details"
                headerHeight={100}
                checkboxSelection
                disableSelectionOnClick
                selectionModel={selectedItems}
                onSelectionModelChange={_handleSelectItems}
              />
            </Grid>

            <Grid item style={{ marginLeft: "auto" }}>
              <BrButton title="Reenviar email" onClick={() => onClickReenviarEmails(selectedItems, data.publicacao)} disabled={selectedItems.length === 0}>
                Reenviar e-mails
              </BrButton>
              <BrButton
                title="Encerrar Efeitos Suspensivos das Contestações Selecionadas"
                onClick={() => onClickEncerrarEfeitosSuspensivos(selectedItems, data.publicacao)}
                disabled={selectedItems.length === 0}
              >
                Encerrar Efeitos Suspensivos
              </BrButton>
              <BrButton secondary onClick={() => onClose()}>
                Fechar
              </BrButton>
            </Grid>
          </Grid>
        </CustomCard>
      </div>
    </div>
  );
};

export default ContestacoesPublicadasDataGridModal;
