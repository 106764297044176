import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, convertToSelectOptions, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { isVigenciaContestacaoPorEstabelecimento } from "utils/VigenciaUtils";
import { formataCNPJ, formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";
import { TipoSituacaoContestacao } from "utils/TipoUtils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";
import EmpresasApi from "services/api/EmpresasApi";

import { createAutorizaRecalculoModel } from "./model";
import { getAutorizaRecalculoColumns, getInstanciaPresidenteLogado } from "./utils";

const columns = getAutorizaRecalculoColumns();

export const AutorizaRecalculo = ({ userData }) => {
  const { vigencias } = useVigencias();

  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [fields, setFields] = React.useState({});

  const [rows, setRows] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState();

  React.useEffect(() => {
    async function _initForm() {
      setLoading(true);
      if (vigencias.length > 0) {
        const init = createAutorizaRecalculoModel({ fields: {}, vigencias: vigencias });
        setFields(init);
        setRows([]);
      }
      setLoading(false);
    }
    _initForm();
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
    show && setSuccessMessage(null);
  }

  async function _handleChange(field) {
    if (fields.estabelecimentos.id !== field.id) {
      _setLoading(true);
      const ativaEstabs = isVigenciaContestacaoPorEstabelecimento(fields.anoVigencia.value);
      toggleEstabelecimentos(fields.estabelecimentos, ativaEstabs);
      const resEstab = await obterEstabelecimentos(fields.cnpjRaiz.value, fields.anoVigencia.value, ativaEstabs);
      if (resEstab.erros) {
        setFormErrors(resEstab.erros);
      } else {
        fields.estabelecimentos.options = resEstab;
      }
      setRows([]);
      _setLoading(false);
    }
    setFields(() => ({ ...fields }));
  }

  const toggleEstabelecimentos = (estabelecimentos = {}, ativaEstabs = false) => {
    if (ativaEstabs) {
      estabelecimentos.required = false;
      estabelecimentos.disabled = false;
    } else {
      estabelecimentos.required = false;
      estabelecimentos.disabled = true;
    }
    estabelecimentos.options = [];
    estabelecimentos.value = "";
  };

  async function obterEstabelecimentos(cnpjConsulta, anoVigencia, ativaEstabs = false) {
    const isCnpjRaiz = cnpjConsulta.length === 8;
    if (isCnpjRaiz && ativaEstabs) {
      const resEstab = await EmpresasApi.obterEstabelecimentos(cnpjConsulta, anoVigencia);
      if (resEstab.erros) {
        return resEstab;
      }
      return convertToSelectOptions(resEstab, formataCNPJ, formataCNPJ14Digitos);
    }
    return [];
  }

  async function _handleChangeEstabelecimentos(field) {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      await obterContestacoes();
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  async function obterContestacoes() {
    const requestBody = convertFormFieldsToJsonObj(fields);
    const params = {
      situacao: TipoSituacaoContestacao.LIBERADA_PARA_AUTORIDADE.value,
      anoVigencia: fields.anoVigencia.value,
      cnpj: isVigenciaContestacaoPorEstabelecimento(fields.anoVigencia.value) && requestBody.estabelecimentos ? requestBody.estabelecimentos : requestBody.cnpjRaiz
    };

    params.instancia = getInstanciaPresidenteLogado(userData.papeis);

    const res = await ContestacoesApi.obterContestacoes(params);
    if (!res.erros) {
      setRows(res);
      setFormErrors([]);
    } else {
      setFormErrors(res.erros);
      setRows([]);
    }
  }

  const downloadAction = React.useMemo(
    () => ({
      title: "Baixar Relátorio Completo",
      icon: "text-info fa fa-file-pdf",
      action: (row) => _generateReportContestacao(row)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function _generateReportContestacao(contestacao) {
    _setLoading(true);
    const { cnpj, cnpjRaiz, id, anoVigencia } = contestacao;
    const cnpjConsulta = cnpj ? formataCNPJ14Digitos(cnpj) : formataCNPJ8Digitos(cnpjRaiz);
    const res = await ContestacoesApi.downloadRelatorioPdfContestacao(id, cnpjConsulta, anoVigencia);
    if (res.erros) {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  async function liberar() {
    _setLoading(true);
    const res = await ContestacoesApi.liberar(selectedRows);
    if (!res.erros) {
      const idsArray = Array.isArray(selectedRows) ? selectedRows : [selectedRows];
      setRows(() => rows.filter((r) => !idsArray.includes(r.id)));
      setSuccessMessage(`${idsArray.length > 1 ? "Contestações liberadas" : "Contestação liberada"} para recálculo com sucesso!`);
    } else {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  async function revisar() {
    _setLoading(true);
    const res = await ContestacoesApi.revisar(selectedRows);
    if (!res.erros) {
      const idsArray = Array.isArray(selectedRows) ? selectedRows : [selectedRows];
      setRows(() => rows.filter((r) => !idsArray.includes(r.id)));
      setSuccessMessage(`${idsArray.length > 1 ? "Contestações marcadas" : "Contestação marcada"} para revisão com sucesso!`);
    } else {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {!!successMessage && <BrMessage success>{successMessage}</BrMessage>}
        </Grid>

        <Grid item sm={12} id="form-autoriza-recalculo">
          <Form onSubmit={_handleSubmit} errors={formErrors} submitTitle="Pesquisar">
            <Grid item sm={2}>
              <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.estabelecimentos} onChange={_handleChangeEstabelecimentos} />
            </Grid>
          </Form>
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid
            actions={[downloadAction]}
            getRowId={(r) => r.id}
            rows={rows}
            columns={columns}
            density="compact"
            rowHeight={65}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Grid container spacing={2} style={{ justifyContent: "right" }}>
          <Grid item>
            <BrButton primary onClick={liberar} disabled={!selectedRows.length > 0}>
              Liberar para Recálculo
            </BrButton>
          </Grid>
          <Grid item>
            <BrButton primary onClick={revisar} disabled={!selectedRows.length > 0}>
              Marcar para Revisão
            </BrButton>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
