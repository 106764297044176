import React from "react";
import { Grid } from "@material-ui/core";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import Loading from "components/Loading";

import { TipoAcaoContestacao } from "Routes/ContestacaoEletronica/ContestacaoUtils";
import { useVigencias } from "hooks/useVigencias";
import ContestacoesApi from "services/api/ContestacoesApi";

import { getDesistenciaColumns, isSituacaoDesistida, TipoSituacaoConsultaDesistencias } from "./utils";
import { createConsultaDesisteAdmConstestacaoModel } from "./model";

export const ConsultaDesisteAdmContestacao = ({ location, history }) => {
  const { vigencias } = useVigencias();
  const [loading, setLoading] = React.useState(false);

  const fieldsLocation = React.useMemo(() => location.state?.fields, [location]);

  const [formErrors, setFormErrors] = React.useState([]);
  const [fields, setFields] = React.useState(() => createConsultaDesisteAdmConstestacaoModel({ fields: fieldsLocation }));
  const [rows, setRows] = React.useState([]);
  const formElement = React.useRef();

  function _setLoading(show) {
    show && setFormErrors([]);
    setLoading(show);
  }

  function _handleChange() {
    setFields(() => ({ ...fields }));
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      if (requestBody.situacao) {
        requestBody.situacao = TipoSituacaoConsultaDesistencias[requestBody.situacao].value;
      }
      const res = await ContestacoesApi.obterContestacoes(requestBody);
      if (res.erros) {
        setFormErrors(res.erros);
        setRows([]);
      } else {
        setRows(res);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  React.useEffect(() => {
    (async () => {
      _setLoading(true);
      if (!!vigencias && vigencias.length > 0) {
        const res = await ContestacoesApi.obterAnalistas();
        const analistas = !!res.erros ? [] : res;
        setFields(() => createConsultaDesisteAdmConstestacaoModel({ fields: fieldsLocation, vigencias, analistas }));

        if (!!fieldsLocation && formElement.current) {
          formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
      }
      _setLoading(false);
    })();
  }, [vigencias, fieldsLocation]);

  const rowAction = () => {
    if (isSituacaoDesistida(fields?.situacao?.value)) {
      return {
        title: "Gerar Relatório de Desistência",
        icon: "text-info fa fa-file-pdf",
        action: (rowContestacao) => {
          downloadRelatorioDesistenciaContestacao(rowContestacao.id);
        }
      };
    } else {
      return {
        title: "Cadastrar Desistência",
        icon: "text-info fa fa-recycle",
        action: (rowContestacao) => {
          const state = { contestacao: rowContestacao, fields, acao: TipoAcaoContestacao.DESISTIR };
          history.push("/contestacoes/desistencias/desistir", state);
        }
      };
    }
  };

  async function downloadRelatorioDesistenciaContestacao(id) {
    _setLoading(true);
    const res = await ContestacoesApi.downloadRelatorioPdfDesistenciaContestacao(id);
    if (res && res.erros) {
      setFormErrors(res.erros);
    }
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Form ref={formElement} submitTitle="Pesquisar" onSubmit={_handleSubmit} errors={formErrors}>
            <Grid item sm={2}>
              <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
            </Grid>
            <Grid item sm={4}>
              <InputGeneric field={fields.situacao} onChange={_handleChange} />
            </Grid>
            <Grid item sm={2}>
              <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.cnpj} onChange={_handleChange} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.cpfResponsavel} onChange={_handleChange} />
            </Grid>
            <Grid item sm={2}>
              <InputGeneric field={fields.instancia} onChange={_handleChange} />
            </Grid>
          </Form>
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid actions={[rowAction()]} rows={rows} columns={getDesistenciaColumns()} density="compact" toolbar="details" pageSize={5} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
