import React from "react";
import { Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";
import BrTabs from "@govbr/react-components/lib/BrTabs";

import { isUndefined } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import { BoxMessage } from "components/BoxMessage";
import { TagMessage } from "components/TagMessages";
import CustomCard from "components/CustomCard";

import { filterByTipo, getTipoElementoUrl, TipoElementoContestacao, TipoMensagem } from "utils/TipoUtils";
import { formataCNPJ14Digitos, formataCNPJ8Digitos } from "utils/StrUtils";
import { isVigenciaNexoTecnico } from "utils/VigenciaUtils";
import { isPrimeiraInstancia } from "utils/ContestacaoUtils";
import ContestacoesApi from "services/api/ContestacoesApi";
import EmpresasApi from "services/api/EmpresasApi";

import { ContestaElementoForm } from "./ContestaElementoForm";
import {
  getBeneficiosContestadosColumns,
  getBeneficiosNaoContestadosColumns,
  getCatContestadasColumns,
  getCatNaoContestadasColumns,
  getMassasSalariaisContestadasColumns,
  getMassasSalariaisNaoContestadasColumns,
  getNexosTecnicosContestadosColumns,
  getNexosTecnicosNaoContestadosColumns,
  getTaxaRotatividadeContestadasColumns,
  getTaxaRotatividadeNaoContestadasColumns,
  getVinculosContestadosColumns,
  getVinculosNaoContestadosColumns,
  mapRowId
} from "../NovaContestacaoUtils";
import { PREFS } from "services/PreferenceManager";

const Actions = {
  EDITAR_ELEMENTO: "EDITAR_ELEMENTO",
  NOVO_ELEMENTO: "NOVO_ELEMENTO",
  JUSTIFICAR_ELEMENTO: "JUSTIFICAR_ELEMENTO",
  LISTAR_CONTESTADOS: "LISTAR_CONTESTADOS"
};

function getElementosContestadosColumns(tipoElemento) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return getCatContestadasColumns();
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return getNexosTecnicosContestadosColumns();
    case TipoElementoContestacao.BENEFICIO.id:
      return getBeneficiosContestadosColumns();
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return getMassasSalariaisContestadasColumns();
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return getVinculosContestadosColumns();
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return getTaxaRotatividadeContestadasColumns();
    default:
      return [];
  }
}

function getElementosNaoContestadosColumns(tipoElemento) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return getCatNaoContestadasColumns();
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return getNexosTecnicosNaoContestadosColumns();
    case TipoElementoContestacao.BENEFICIO.id:
      return getBeneficiosNaoContestadosColumns();
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return getMassasSalariaisNaoContestadasColumns();
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return getVinculosNaoContestadosColumns();
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return getTaxaRotatividadeNaoContestadasColumns();
    default:
      return [];
  }
}

function getColumns(action, tipoElemento) {
  switch (action) {
    case Actions.LISTAR_CONTESTADOS:
      return getElementosContestadosColumns(tipoElemento);
    case Actions.NOVO_ELEMENTO:
      return getElementosNaoContestadosColumns(tipoElemento);
    default:
      return [];
  }
}

function getMensagensContestados(tipoElemento, mensagens) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_CATS_CONTESTADAS, mensagens);
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_NEXO_TECNICO_CONTESTADOS, mensagens);
    case TipoElementoContestacao.BENEFICIO.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_BENEFICIOS_CONTESTADOS, mensagens);
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_MASSAS_SALARIAIS_CONTESTADAS, mensagens);
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_VINCULOS_CONTESTADOS, mensagens);
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_TAXAS_ROTATIVIDADE_CONTESTADAS, mensagens);
    default:
      return [];
  }
}

function getMensagensNaoContestados(tipoElemento, mensagens) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_CATS_NAO_CONTESTADAS, mensagens);
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_NEXO_TECNICO_NAO_CONTESTADOS, mensagens);
    case TipoElementoContestacao.BENEFICIO.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_BENEFICIOS_NAO_CONTESTADOS, mensagens);
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_MASSAS_SALARIAIS_NAO_CONTESTADAS, mensagens);
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_VINCULOS_NAO_CONTESTADOS, mensagens);
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_TAXA_ROTATIVIDADE_NAO_CONTESTADAS, mensagens);
    default:
      return [];
  }
}

function getMensagensContestacao(tipoElemento, mensagens) {
  switch (tipoElemento) {
    case TipoElementoContestacao.CAT.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_CAT_CONTESTACAO, mensagens);
    case TipoElementoContestacao.NEXO_TECNICO.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_NEXO_TECNICO_CONTESTACAO, mensagens);
    case TipoElementoContestacao.BENEFICIO.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_BENEFICIO_CONTESTACAO, mensagens);
    case TipoElementoContestacao.MASSA_SALARIAL.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_MASSA_SALARIAL_CONTESTACAO, mensagens);
    case TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_VINCULO_CONTESTACAO, mensagens);
    case TipoElementoContestacao.ROTATIVIDADE.id:
      return filterByTipo(TipoMensagem, TipoMensagem.MENSAGEM_CONTESTACAO_TAXA_ROTATIVIDADE_CONTESTACAO, mensagens);
    default:
      return [];
  }
}

function getMensagens(action, tipoElemento, mensagens) {
  switch (action) {
    case Actions.LISTAR_CONTESTADOS:
      return getMensagensContestados(tipoElemento, mensagens);
    case Actions.NOVO_ELEMENTO:
      return getMensagensNaoContestados(tipoElemento, mensagens);
    case Actions.JUSTIFICAR_ELEMENTO:
    case Actions.EDITAR_ELEMENTO:
      return getMensagensContestacao(tipoElemento, mensagens);
    default:
      return [];
  }
}

function getCardTitle(action, contestacaoParam, tipoElemento) {
  switch (action) {
    case Actions.LISTAR_CONTESTADOS:
      return "Elementos contestados";
    case Actions.NOVO_ELEMENTO:
      return "Selecione o elemento a contestar";
    case Actions.JUSTIFICAR_ELEMENTO:
    case Actions.EDITAR_ELEMENTO:
      const elementoLabel = TipoElementoContestacao[tipoElemento].label;
      return `Fase: Contestação de ${isPrimeiraInstancia(contestacaoParam) ? "1ª" : "2ª"} Instância (${elementoLabel})`;
    default:
      return "";
  }
}

function mapElementoContestadoRow(row, campoInstanciaContestacao) {
  const elementoContestadoRow = row[campoInstanciaContestacao];
  return { ...elementoContestadoRow, tipoElemento: row.tipoElemento };
}

export const Secao2Card = ({ contestacaoParam, setLoading }) => {
  const { anoVigencia } = contestacaoParam;
  const campoInstanciaContestacao = isPrimeiraInstancia(contestacaoParam) ? "primeiraInstancia" : "segundaInstancia";
  const cnpjConsulta = isUndefined(contestacaoParam.cnpj) ? formataCNPJ8Digitos(contestacaoParam.cnpjRaiz) : formataCNPJ14Digitos(contestacaoParam.cnpj);

  const [tipoElemento, setTipoElemento] = React.useState();
  const [action, setAction] = React.useState(Actions.LISTAR_CONTESTADOS);

  const [messages, setMessages] = React.useState([]);

  const [elementosContestadosRows, setElementosContestadosRows] = React.useState([]);
  const [elementosContestadosRowsError, setElementosContestadosRowsError] = React.useState();
  const [elementosNaoContestadosRows, setElementosNaoContestadosRows] = React.useState([]);
  const [elementosNaoContestadosRowsError, setElementosNaoContestadosRowsError] = React.useState();

  const [elementoSelected, setElementoSelected] = React.useState();
  const [formErrors, setFormErrors] = React.useState([]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChangeTipoElemento(selectedTipoElemento) {
    _setLoading(true);
    const res = await ContestacoesApi.obterElementosContestados(cnpjConsulta, anoVigencia, contestacaoParam.id, getTipoElementoUrl(selectedTipoElemento));
    if (res.erros) {
      setElementosContestadosRowsError(res.erros[0].mensagem);
      setElementosContestadosRows([]);
    } else {
      setElementosContestadosRowsError(res.length > 0 ? "" : "Nenhuma linha");
      setElementosContestadosRows(res.map((row) => mapElementoContestadoRow(row, campoInstanciaContestacao)));
    }

    setElementoSelected(null);
    setTipoElemento(selectedTipoElemento);
    setAction(Actions.LISTAR_CONTESTADOS);
    _setLoading(false);
  }

  function _handleConfirmForm(res) {
    const elementoContestadoRow = mapElementoContestadoRow(res, campoInstanciaContestacao);

    if (action === Actions.JUSTIFICAR_ELEMENTO) {
      const elementosContestadosRowsAux = [elementoContestadoRow];
      elementosContestadosRowsAux.push(...elementosContestadosRows);
      setElementosContestadosRows(elementosContestadosRowsAux);
      setElementosNaoContestadosRows(() => elementosNaoContestadosRows.filter((row) => row.id !== res.id));
      setAction(Actions.NOVO_ELEMENTO);
    } else {
      const elementosContestadosRowsAux = elementosContestadosRows.map((row) => (row.id !== elementoContestadoRow.id ? row : elementoContestadoRow));
      setElementosContestadosRows(elementosContestadosRowsAux);
      setAction(Actions.LISTAR_CONTESTADOS);
    }
    setElementoSelected(null);
    _setLoading(false);
  }

  function _handleCancelForm() {
    setAction(action === Actions.JUSTIFICAR_ELEMENTO ? Actions.NOVO_ELEMENTO : Actions.LISTAR_CONTESTADOS);
    setElementoSelected(null);
    _setLoading(false);
  }

  async function obterMensagens() {
    const resMensagens = await EmpresasApi.obterMensagens(cnpjConsulta, anoVigencia);
    if (!resMensagens.erros) {
      setMessages(resMensagens);
    }
  }

  React.useEffect(() => {
    (async () => await obterMensagens())();
    (async () => await _handleChangeTipoElemento(TipoElementoContestacao.CAT.id))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function novoElemento() {
    _setLoading(true);
    const res = await ContestacoesApi.obterElementosNaoContestados(cnpjConsulta, anoVigencia, contestacaoParam.id, getTipoElementoUrl(tipoElemento));
    if (res.erros) {
      setElementosNaoContestadosRowsError(res.erros[0].mensagem);
      setElementosNaoContestadosRows([]);
    } else {
      setElementosNaoContestadosRowsError(res.length > 0 ? "" : "Nenhuma linha");
      setElementosNaoContestadosRows(res.map(mapRowId));
    }

    setAction(Actions.NOVO_ELEMENTO);
    _setLoading(false);
  }

  async function editarContestado(e) {
    _setLoading(true);
    const res = await ContestacoesApi.obterElementoContestadoPorId(cnpjConsulta, anoVigencia, contestacaoParam.id, getTipoElementoUrl(tipoElemento), e.id);
    if (res.erros) {
      setFormErrors(res.errors);
    } else {
      setElementoSelected(res);
    }
    setAction(Actions.EDITAR_ELEMENTO);
    _setLoading(false);
  }

  async function removerContestado(rowSelected) {
    setLoading(true);
    const res = await ContestacoesApi.removerElementoContestado(cnpjConsulta, anoVigencia, contestacaoParam.id, getTipoElementoUrl(tipoElemento), rowSelected.id);
    if (res.erros) {
      setFormErrors(res.errors);
    } else {
      setElementosContestadosRows(() => elementosContestadosRows.filter((row) => row.id !== rowSelected.id));
    }
    setLoading(false);
  }

  const contestarAction = {
    title: "Contestar este item",
    icon: "text-info fa fa-plus",
    action: (row) => {
      setAction(Actions.JUSTIFICAR_ELEMENTO);
      setElementoSelected(row);
    }
  };

  const elementosColumns = getColumns(action, tipoElemento);
  const filteredMessages = getMensagens(action, tipoElemento, messages);
  const cardTitle = getCardTitle(action, contestacaoParam, tipoElemento);
  const isHeaderLarger = [TipoElementoContestacao.CAT.id, TipoElementoContestacao.BENEFICIO.id, TipoElementoContestacao.ROTATIVIDADE.id].includes(tipoElemento);

  return (
    <CustomCard title={cardTitle} iconComponent={<TagMessage messages={filteredMessages} />}>
      {!elementoSelected && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <BoxMessage message={formErrors} />
          </Grid>

          <Grid item sm={12} style={{ paddingLeft: 30 }}>
            <BrTabs value={tipoElemento} onChange={_handleChangeTipoElemento}>
              <BrTabs.Tabs>
                <BrTabs.Tab id={TipoElementoContestacao.CAT.id} label={TipoElementoContestacao.CAT.label} />
                {isVigenciaNexoTecnico(anoVigencia) && <BrTabs.Tab id={TipoElementoContestacao.NEXO_TECNICO.id} label={TipoElementoContestacao.NEXO_TECNICO.label} />}
                <BrTabs.Tab id={TipoElementoContestacao.BENEFICIO.id} label={TipoElementoContestacao.BENEFICIO.label} />
                <BrTabs.Tab id={TipoElementoContestacao.MASSA_SALARIAL.id} label={TipoElementoContestacao.MASSA_SALARIAL.label} />
                <BrTabs.Tab id={TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.id} label={TipoElementoContestacao.NUMERO_MEDIO_VINCULOS.label} />
                <BrTabs.Tab id={TipoElementoContestacao.ROTATIVIDADE.id} label={TipoElementoContestacao.ROTATIVIDADE.label} />

                {action === Actions.LISTAR_CONTESTADOS && (
                  <Grid item sm={12} className="text-right">
                    <BrButton primary small onClick={novoElemento}>
                      Contestar novo elemento
                    </BrButton>
                  </Grid>
                )}

                {action === Actions.NOVO_ELEMENTO && (
                  <Grid item sm={12} className="text-right">
                    <BrButton secondary small onClick={() => setAction(Actions.LISTAR_CONTESTADOS)}>
                      Voltar para contestados
                    </BrButton>
                  </Grid>
                )}
              </BrTabs.Tabs>

              <BrTabs.Content>
                <BrTabs.Panel contentFor={tipoElemento}>
                  {action === Actions.LISTAR_CONTESTADOS && (
                    <CustomDataGrid
                      pageSizePreference={PREFS.CONTESTACAO_ELEMENTO_PAGE_SIZE}
                      columns={elementosColumns}
                      rows={elementosContestadosRows}
                      noRowsLabel={elementosContestadosRowsError}
                      onEdit={editarContestado}
                      onDelete={removerContestado}
                      onDeleteMessage="Deseja realmente remover o elemento da contestação?"
                      headerHeight={80}
                      density="compact"
                    />
                  )}
                  {action === Actions.NOVO_ELEMENTO && (
                    <CustomDataGrid
                      pageSizePreference={PREFS.CONTESTACAO_ELEMENTO_PAGE_SIZE}
                      columns={elementosColumns}
                      rows={elementosNaoContestadosRows}
                      noRowsLabel={elementosNaoContestadosRowsError}
                      actions={[contestarAction]}
                      {...(isHeaderLarger && { headerHeight: 115 })}
                      density="compact"
                    />
                  )}
                </BrTabs.Panel>
              </BrTabs.Content>
            </BrTabs>
          </Grid>
        </Grid>
      )}

      {!!elementoSelected && (
        <Grid container>
          <Grid item sm={12} style={{ paddingLeft: 30 }}>
            <ContestaElementoForm
              contestacaoParam={contestacaoParam}
              elementoParam={elementoSelected}
              action={action}
              setLoading={setLoading}
              onConfirm={_handleConfirmForm}
              onCancel={_handleCancelForm}
            />
          </Grid>
        </Grid>
      )}
    </CustomCard>
  );
};
