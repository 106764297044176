import BrModal, { BrModalFooter } from "@govbr/react-components/lib/BrModal";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import { InputGeneric } from "components/Form";

import useEscKey from "hooks/useEscKey";
import useLockBodyScroll from "hooks/useLockBodyScroll";

import { createResumoProcuracaoModel } from "../model";

const ResumoProcuracaoModal = ({ procuracao, onClose, onDownload }) => {
  useLockBodyScroll();
  useEscKey(() => onClose());

  const fields = createResumoProcuracaoModel(procuracao);

  return (
    <BrModal visible={true} closable={true} onClose={onClose}>
      <Grid container spacing={1}>
        <Grid item sm={12} style={{ display: "flex", flexDirection: "column" }}>
          <span className="text-up-02 text-bold">{"Procuração Eletrônica"}</span>
          <Divider />
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <InputGeneric field={fields.tipoProcuracao} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={fields.cnpjRaizOutorgante} className={"text-up-02 text-info"} />
            </Grid>

            <Grid item sm={4} style={{ display: fields.cnpjRaizOutorgado.value ? "block" : "none" }}>
              <InputGeneric field={fields.cnpjRaizOutorgado} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4} style={{ display: fields.cpfOutorgado.value ? "block" : "none" }}>
              <InputGeneric field={fields.cpfOutorgado} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={fields.dataCadastro} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4} style={{ display: fields.dataInicio.value ? "block" : "none" }}>
              <InputGeneric field={fields.dataInicio} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4} style={{ display: fields.dataFim.value ? "block" : "none" }}>
              <InputGeneric field={fields.dataFim} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4} style={{ display: fields.dataExclusao.value ? "block" : "none" }}>
              <InputGeneric field={fields.dataExclusao} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={4}>
              <InputGeneric field={fields.situacao} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={12} style={{ display: fields.justificativa.value ? "block" : "none" }}>
              <InputGeneric field={fields.justificativa} className={"text-up text-info"} style={{ whiteSpace: "pre-wrap", overflow: "scroll", maxHeight: 150 }} />
            </Grid>

            <Grid item sm={4} style={{ display: fields.tipoDeferimento.value ? "block" : "none" }}>
              <InputGeneric field={fields.tipoDeferimento} className={"text-up-03 text-info"} />
            </Grid>

            <Grid item sm={12} style={{ display: fields.parecer.value ? "block" : "none" }}>
              <InputGeneric field={fields.parecer} className={"text-up text-info"} style={{ whiteSpace: "pre-wrap", overflow: "scroll", maxHeight: 150 }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <BrModalFooter>
            <Grid container style={{ justifyContent: "end" }}>
              <Grid item>
                {procuracao.arquivo && (
                  <BrButton small onClick={() => onDownload(procuracao)}>
                    <i className="fa fa-download" aria-hidden="true"></i>Baixar Anexo
                  </BrButton>
                )}
              </Grid>
              <Grid item>
                <BrButton small secondary onClick={onClose}>
                  Voltar
                </BrButton>
              </Grid>
            </Grid>
          </BrModalFooter>
        </Grid>
      </Grid>
    </BrModal>
  );
};

export default ResumoProcuracaoModal;
