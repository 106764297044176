import React from "react";
import { Grid } from "@material-ui/core";
import Form, { InputGeneric } from "../../../components/Form";
import { checkFormValidity, convertFormFieldsToJsonObj } from "../../../components/Form/formUtils";
import { createModelConsultar } from "./model";

const FormLogAuditoria = ({ onSubmit, formError, loading }) => {
  const [fields, setFields] = React.useState();

  React.useEffect(() => {
    setFields(createModelConsultar());
  }, []);

  function _handleChangeFormConsulta(event) {
    setFields(() => ({ ...fields }));
  }

  function _handleSubmitFormConsulta(event) {
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      onSubmit(requestBody);
    }
    setFields(() => ({ ...fields }));
  }

  return (
    <React.Fragment>
      {fields && (
        <Grid container>
          <Grid item sm={12} >
            <Form
              onSubmit={_handleSubmitFormConsulta}
              submitTitle={"Consultar"}
              submitIcon={"fa-search"}
              errors={formError}
              loading={loading}
            >
              <Grid item sm={2}>
                <InputGeneric field={fields.dataInicio} onChange={_handleChangeFormConsulta} />
              </Grid>
              <Grid item sm={2}>
                <InputGeneric field={fields.dataFim} onChange={_handleChangeFormConsulta} />
              </Grid>
              <Grid item sm={3}>
                <InputGeneric field={fields.cpfUsuario} onChange={_handleChangeFormConsulta} />
              </Grid>
              <Grid item sm={5}>
                <InputGeneric field={fields.nomeUsuario} onChange={_handleChangeFormConsulta} />
              </Grid>
              <Grid item sm={5}>
                <InputGeneric field={fields.contexto} onChange={_handleChangeFormConsulta} />
              </Grid>
              <Grid item sm={5}>
                <InputGeneric field={fields.papel} onChange={_handleChangeFormConsulta} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default FormLogAuditoria;
