import React, { createContext } from "react";

import Loading from "components/Loading";

import { createMenus } from "utils/MenuUtils";
import SessionManager from "services/SessionManager";
import { DadosMenu } from "DadosMenu";
import AuthManager from "services/AuthManager";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const user = React.useMemo(() => {
    try {
      //TODO: refatorar buildUser objeto pra sua própria classe e fora da SessionManager e do AppProvider
      return SessionManager.buildUserData();
    } catch (error) {
      AuthManager.doKickOut(10000);
      throw error;
    }
  }, []);

  const { menus, flatMenus } = React.useMemo(() => createMenus(DadosMenu, user.papeis), [user]);

  let value = {
    user: { ...user },
    menus,
    flatMenus
  };

  const isLoading = !user.papeis;

  return (
    <AppContext.Provider value={value}>
      {isLoading && <Loading overlay />}
      {!isLoading && children}
    </AppContext.Provider>
  );
};

export function useMenu() {
  const { menus, flatMenus } = React.useContext(AppContext);
  return { menus, flatMenus };
}

export function useUser() {
  const { user } = React.useContext(AppContext);
  return { user };
}
