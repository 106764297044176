import crypto from "crypto";

import { base64URLEncode, parseToUser } from "utils/JWTUtils";
import { clear, getItem, setItem } from "./StoreManager";
import { TipoPapel, geridPapeis } from "utils/TipoUtils";
import { formataCPF11Digitos } from "utils/StrUtils";

export const MSG_ERR_GERID_NOT_ALLOWED = "Usuário não possui permissão cadastrada no GERID para acesso ao FAP.";
export const MSG_ERR_GOVBR_NOT_ALLOWED = "MSG_ERR_GOVBR_NOT_ALLOWED";
export const MSG_ERR_INVALID_AUTH = "Autenticação inválida.";
export const MSG_ERR_FAIL_AUTH_VALIDATION = "Falha ao validar autenticação.";

const GERID = "GERID";
const GOVBR = "GOVBR";

const isGeridIdp = (idp) => String(idp).toUpperCase().indexOf(GERID) >= 0;
const isGovbrIdp = (idp) => String(idp).toUpperCase().indexOf(GOVBR) >= 0;

const isGeridUser = ({ tipo, papeis = [] }) => GERID === tipo && papeis && papeis.length > 0;
const isGovbrUser = ({ tipo, empresas = [] }) => GOVBR === tipo && empresas && empresas.length > 0;

const customSort = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export class SessionError extends Error {
  constructor(message = "Sessão Inválida.") {
    super(message);
    this.name = "SessionError";
  }
}

const SHA256 = "sha256";
const KEY_FALLBACK_LOGOUT = "fallbackLogout";
const KEYS = {
  idToken: "idToken",
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  sessionId: "sessionId",
  sessionState: "sessionState",
  idp: "idp",
  user: "user",
  empresasProcuracoes: "empresasProcuracoes"
};

function generateCodeVerifier() {
  const rand = crypto.randomBytes(32);
  return base64URLEncode(rand);
}

function validateUserSession(user, sessionIdp) {
  if (isGeridIdp(sessionIdp)) {
    if (!isGeridUser(user)) {
      throw new SessionError(MSG_ERR_GERID_NOT_ALLOWED);
    }
  } else if (isGovbrIdp(sessionIdp)) {
    if (!isGovbrUser(user)) {
      throw new SessionError(MSG_ERR_GOVBR_NOT_ALLOWED);
    }
  } else {
    throw new SessionError(MSG_ERR_INVALID_AUTH);
  }
}
class SessionManager {
  constructor() {
    throw new SessionError("SessionManager Abstract class.");
  }

  static getIdp() {
    return getItem(KEYS.idp);
  }
  static getToken() {
    return getItem(KEYS.idToken);
  }
  static getAccessToken() {
    return getItem(KEYS.accessToken);
  }
  static getRefreshToken() {
    return getItem(KEYS.refreshToken);
  }
  static getSessionId() {
    return getItem(KEYS.sessionId);
  }
  static getSessionState() {
    return getItem(KEYS.sessionState);
  }
  static getEmpresasProcuracoes() {
    const aux = getItem(KEYS.empresasProcuracoes);
    return !!aux ? JSON.parse(aux) : [];
  }
  static setEmpresasProcuracoes(empresasProcuracoes = []) {
    setItem(KEYS.empresasProcuracoes, JSON.stringify(empresasProcuracoes), true);
  }

  static buildUserData() {
    const idp = SessionManager.getIdp();
    const id_token = SessionManager.getToken();
    const user = parseToUser(id_token);

    const empresasVinculadas = user.empresas || [];
    const empresasProcuracoes = SessionManager.getEmpresasProcuracoes();
    const todasEmpresas = empresasVinculadas.concat(empresasProcuracoes);
    const todosCnpjs = todasEmpresas.map((e) => e.cnpj);

    user.empresas = todasEmpresas.filter((e, i) => todosCnpjs.indexOf(e.cnpj) === i).sort((a, b) => customSort(a.nome, b.nome));
    user.papeis = geridPapeis.filter((p) => !!user.papeis && user.papeis.includes(p));

    validateUserSession(user, idp);

    const userDataPapeis = [];

    user.isAcessoGerid = isGeridIdp(idp) && isGeridUser(user);
    user.isAcessoEmpresa = !user.isAcessoGerid;

    user.isAcessoGerid && userDataPapeis.push(...user.papeis);
    user.isAcessoEmpresa && empresasVinculadas.length > 0 && userDataPapeis.push(TipoPapel.EMPRESA);
    user.isAcessoEmpresa && empresasProcuracoes.length > 0 && userDataPapeis.push(TipoPapel.PROCURACAO);

    return {
      isAcessoGerid: user.isAcessoGerid,
      isAcessoEmpresa: user.isAcessoEmpresa,
      firstName: user.nome || "",
      email: user.email || "",
      document: formataCPF11Digitos(user.cpf),
      documentType: user.cpf ? "CPF" : "",
      totalNotifications: null,
      avatar: "",
      expireAt: user.date || "",
      empresas: user.isAcessoEmpresa ? user.empresas : [],
      empresasProcuracoes: user.isAcessoEmpresa ? empresasProcuracoes.sort((a, b) => customSort(a.nome, b.nome)) : [],
      empresasVinculadas: user.isAcessoEmpresa ? empresasVinculadas.sort((a, b) => customSort(a.nome, b.nome)) : [],
      papeis: userDataPapeis
    };
  }

  static generateCodeChallenge(idp) {
    const codeVerifier = generateCodeVerifier();
    setItem(KEYS.sessionId, codeVerifier, true);
    setItem(KEYS.idp, String(idp), true);
    return base64URLEncode(crypto.createHash(SHA256).update(codeVerifier).digest());
  }

  static clean() {
    clear();
  }

  static isValidSession() {
    return !!SessionManager.getIdp() && !!SessionManager.getSessionId() && !!SessionManager.getToken() && !!SessionManager.getAccessToken();
  }

  static save(session = { id_token: "", access_token: "", refresh_token: "", session_state: "" }) {
    setItem(KEYS.idToken, session.id_token);
    setItem(KEYS.accessToken, session.access_token);
    setItem(KEYS.refreshToken, session.refresh_token, false);
    setItem(KEYS.sessionState, session.session_state);

    if (!SessionManager.isValidSession()) {
      throw new SessionError(MSG_ERR_FAIL_AUTH_VALIDATION);
    }
  }

  static setFallbackNoTokenLogout() {
    setItem(KEY_FALLBACK_LOGOUT, true, true);
  }

  static isFallbackNoTokenLogout() {
    return Boolean(getItem(KEY_FALLBACK_LOGOUT));
  }
}

export default SessionManager;
