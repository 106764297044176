import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import { Grid } from "@material-ui/core";

import CustomDataGrid from "components/CustomDataGrid";
import ModalConfirm from "components/ModalConfirm";
import Loading from "components/Loading";

import { formataDateEnBr, formataNumberEnBr } from "utils/StrUtils";
import AnexosApi from "services/api/AnexosApi";

const defaultColumns = [
  {
    field: "dataEnvio",
    headerName: "Data Envio",
    description: "Data Envio do anexo",
    headerAlign: "center",
    align: "center",
    minWidth: 170,
    type: "date",
    valueGetter: ({ value }) => formataDateEnBr(value)
  },
  {
    field: "tipoElemento",
    headerName: "Elemento",
    description: "Tipo do Elemento ao que o anexo se refere",
    minWidth: 120,
    flex: 1,
    valueGetter: ({ value }) => value?.descricao
  },
  { field: "nome", headerName: "Nome", description: "Nome do arquivo anexado", minWidth: 250, flex: 2 },
  {
    field: "quantidadeBytes",
    headerName: "Tamanho(bytes)",
    description: "Tamanho do anexo em bytes",
    headerAlign: "right",
    align: "right",
    minWidth: 170,
    valueGetter: ({ value }) => formataNumberEnBr(value)
  }
];

const AnexosDataGrid = ({ rows = [], columns = defaultColumns, noRowsLabel, onDelete, successMessage, errorMessage, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [row, setRow] = React.useState();

  const downloadAction = React.useMemo(
    (obj) => ({
      title: "Download do anexo",
      icon: "text-info fa fa-download",
      action: async (row) => {
        setLoading(true);
        if (row.calculoFapId) {
          await AnexosApi.downloadAnexo(row.anoVigencia, row.cnpj, row.calculoFapId, row.id);
        } else {
          await AnexosApi.downloadAnexoAdm(row.id);
        }
        setLoading(false);
      }
    }),
    []
  );

  const deleteAction = React.useMemo(
    (obj) => ({
      title: "Remover anexo",
      icon: "text-danger fa fa-trash",
      action: (row) => setRow(row)
    }),
    []
  );

  const _handleDelete = async () => {
    setLoading(true);
    await onDelete(row.id);
    setRow(null);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Loading show={loading} overlay />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {!!successMessage && <BrMessage success>{successMessage}</BrMessage>}
          {!!errorMessage && <BrMessage error>{errorMessage}</BrMessage>}
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid rows={rows} columns={columns} density="compact" pageSize={5} toolbar={null} noRowsLabel={noRowsLabel} actions={[downloadAction, deleteAction]} />
        </Grid>
      </Grid>

      {!!row && <ModalConfirm show={true} message={"Deseja remover este arquivo?"} onConfirm={_handleDelete} cancel={() => setRow(null)} />}
    </React.Fragment>
  );
};

export default AnexosDataGrid;
