import { getApi } from "../ApiManager";

const contextoAnual = "/taxas-mortalidades-anuais";
const contextoCnae = "/taxas-mortalidades-cnaes/anos";

const TaxaMortalidadeApi = {
  obterTaxasMortalidades: () => {
    const Api = getApi();
    return Api.get(`${contextoAnual}`);
  },
  alterarTaxaMortalidade: (params, dados) => {
    const Api = getApi();
    return Api.put(`${contextoAnual}/${params.ano}`, dados);
  },
  criarTaxaMortalidade: (dados) => {
    const Api = getApi();
    return Api.post(`${contextoAnual}`, dados);
  },
  removerTaxaMortalidade: (ano) => {
    const Api = getApi();
    return Api.delete(`${contextoAnual}/${ano}`);
  },
  obterTaxasMortalidadesCnae: (ano) => {
    const Api = getApi();
    return Api.get(`${contextoCnae}/${ano}/cnaes`);
  },
  carregarTaxasMortalidadesCnae: (ano, file) => {
    return getApi().upload(`${contextoCnae}/${ano}/taxa-mortalidade-cnae`, {}, file);
  }
};
export default TaxaMortalidadeApi;
