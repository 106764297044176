import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Link } from "@material-ui/core";

export const MENU_TYPES = {
  scrim: "scrim",
  context: "context"
};

const Folder = ({ isLogo, logo, children, label, onToggle, url }) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (onToggle) {
      onToggle(url);
    }
  };
  return (
    <div className="folder">
      <Link onClick={handleClick} href={url} className={classnames("header", { logo: isLogo })} style={{ textDecoration: "none" }}>
        <div className="content">
          {isLogo && <img src={logo} alt="Logo" />}
          {label && label}
        </div>
        {children && (
          <div className="support">
            <i className="fas fa-angle-down" aria-hidden="true" />
          </div>
        )}
      </Link>
      {children}
    </div>
  );
};

const Items = ({ children, open }) => <ul {...(open === false && { hidden: "hidden" })}>{children}</ul>;

const Item = ({ label, children, active, onClick, url }) => {
  const hasSubitens = children !== null && children !== undefined;

  const handleClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick(url);
    }
  };

  return (
    <li>
      <Link onClick={handleClick} href={url} className="item" {...(hasSubitens && { linked: "" })} {...(active && { linked: "" })} style={{ textDecoration: "none" }}>
        <div className="content">{label}</div>
        {hasSubitens && (
          <div className="support">
            <i className={classnames("fas", { "fa-angle-right": !active }, { "fa-angle-left": active })} aria-hidden="true" />
          </div>
        )}
      </Link>
      {active && children}
    </li>
  );
};

const Footer = ({ children }) => <footer className="footer">{children}</footer>;

const FooterBlock = ({ title, children }) => (
  <div className="footer-block">
    <div className="title">{title}</div>
    <div className="content">{children}</div>
  </div>
);

const BrMenu = ({ id, type, show, label, onClose, children, closeOnClickOutside, folders, footer, ...rest }) => {
  const menuProps = {
    ...(show && { show: "" })
  };

  const brMenuRef = useRef();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useOnClickOutside(brMenuRef, () => {
    if (closeOnClickOutside) {
      handleClose();
    }
  });

  return (
    <div className={`${type}-menu`} {...menuProps}>
      <nav ref={brMenuRef} className="br-menu" id={id} aria-label={label} {...rest}>
        <div className="folders">
          {type === MENU_TYPES.scrim && (
            <div className="close-menu">
              <button
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                }}
                className="br-button circle small"
                type="button"
                aria-label={`Fechar ${label}`}
              >
                <i className="fas fa-times" aria-hidden="true" />
              </button>
            </div>
          )}
          {folders}
        </div>
        {footer}
      </nav>
    </div>
  );
};

BrMenu.propTypes = {
  /** Identificador do menu */
  id: PropTypes.string.isRequired,

  /** Tipo do menu */
  type: PropTypes.oneOf([MENU_TYPES.scrim, MENU_TYPES.context]),

  /** Controla a exibição do menu */
  show: PropTypes.bool.isRequired,

  /** Label usado para questões de acessibilidade */
  label: PropTypes.string.isRequired,

  /** Callback executado ao clicar no botão de fechar */
  onClose: PropTypes.func,

  /** Define se o menu deve ser fechado ao clicar fora do mesmo */
  closeOnClickOutside: PropTypes.bool
};

BrMenu.defaultProps = {
  type: MENU_TYPES.scrim,
  closeOnClickOutside: true,
  onClose: undefined
};

BrMenu.Folder = Folder;
BrMenu.Items = Items;
BrMenu.Item = Item;
BrMenu.Footer = Footer;
BrMenu.FooterBlock = FooterBlock;

export default BrMenu;
