import React from "react";
import { Grid } from "@material-ui/core";
import Form, { InputGeneric, checkFormValidity, convertFormFieldsToJsonObj } from "components/Form";

import { useVigencias } from "hooks/useVigencias";
import { createModelConsultar } from "./model";

const FormConsultaMensagens = ({ onSubmit, formError, loading }) => {
  const { vigencias } = useVigencias();

  const [fields, setFields] = React.useState();

  React.useEffect(() => {
    async function _initForm() {
      if (vigencias.length > 0) {
        const init = createModelConsultar({ vigencias });
        setFields(init);
      }
    }
    _initForm();
  }, [vigencias]);

  function _handleChangeFormConsulta(event) {
    setFields(() => ({ ...fields }));
  }

  function _handleSubmitFormConsulta(event) {
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);
      onSubmit(requestBody);
    }
    setFields(() => ({ ...fields }));
  }

  return (
    <React.Fragment>
      {fields && (
        <React.Fragment>
          <Form onSubmit={_handleSubmitFormConsulta} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={formError} loading={loading}>
            <Grid item sm={2}>
              <InputGeneric field={fields.anoVigencia} onChange={_handleChangeFormConsulta} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.cnpjRaiz} onChange={_handleChangeFormConsulta} />
            </Grid>
            <Grid item sm={3}>
              <InputGeneric field={fields.cnpj} onChange={_handleChangeFormConsulta} />
            </Grid>
            <Grid item sm={2}>
              <InputGeneric field={fields.cnae} onChange={_handleChangeFormConsulta} />
            </Grid>
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FormConsultaMensagens;
