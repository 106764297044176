import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";

import usePrevious from "../Form/usePrevious";

const isEqual = (a1 = [], a2 = []) => JSON.stringify(a1) === JSON.stringify(a2);

export const BoxMessage = ({ message = {} }) => {
  message.type = message.type === undefined ? "error" : message.type;

  const previous = usePrevious(message);

  if (message.type === "error" && (!message.erros || message.length === 0)) {
    return null;
  }

  if (!isEqual(message, previous)) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    message && (
      <BrMessage {...{ [message.type]: true }}>
        {message.mensagem && message.type !== "error" && (
          <span>
            <b>{message.mensagem}</b>
          </span>
        )}
        {message.erros && (
          <ul className="m-1" style={{ paddingLeft: 20, maxHeight: 80, overflow: "auto" }}>
            {message.erros.map((e, index) => {
              return (
                <li key={`e-${index}`}>
                  {e.campo && message.exibeCampo && <span>{e.campo} - </span>} {e.mensagem}
                </li>
              );
            })}
          </ul>
        )}
      </BrMessage>
    )
  );
};
