import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import AppLogin from "./AppLogin";

import LogAuditoriaApi from "./services/api/LogAuditoriaApi";
import ProcuracoesApi from "services/api/ProcuracoesApi";
import SessionManager from "./services/SessionManager";
import AuthManager from "./services/AuthManager";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@govbr/dsgov/dist/css/dsgov.css";

import "./assets/fonts/rawline/rawline.css";
import "./index.css";

async function doSaveEmpresasProcuracoes() {
  if ("fapgovbridp" === SessionManager.getIdp()) {
    const res = await ProcuracoesApi.obterEmpresasProcuracoes();
    if (res.erros) {
      console.error("Erro ao empresas vinculadas por procurações eletrônicas", res.erros);
    } else {
      const empresasProcuracoes = res.map((e) => ({ cnpj: e.cnpj, nome: e.nome || "", incorporada: "INCORPORACAO" === e?.tipoProcuracao?.codigo }));
      SessionManager.setEmpresasProcuracoes(empresasProcuracoes);
    }
  }
}

async function doAuditSignIn() {
  if ("geridsprevidp" === SessionManager.getIdp()) {
    await LogAuditoriaApi.registrarLogin();
  }
}

async function doAuditSignOut() {
  if ("geridsprevidp" === SessionManager.getIdp()) {
    await LogAuditoriaApi.registrarLogout();
  }
  AuthManager.doLogout();
}

async function doPostLogin() {
  await doAuditSignIn();
  await doSaveEmpresasProcuracoes();
}

ReactDOM.render(
  <AppLogin onPostSignIn={doPostLogin}>
    <App doLogout={doAuditSignOut} />
  </AppLogin>,
  document.getElementById("root")
);
