import React, { useState, useEffect } from "react";
import Form, { InputGeneric } from "../../components/Form";
import VigenciasApi from "../../services/api/VigenciasApi";
import { Grid } from "@material-ui/core";
import BrLoading from "@govbr/react-components/lib/BrLoading";
import BrButton from "@govbr/react-components/lib/BrButton";

import { checkFormValidity, convertFormFieldsToJsonObj, isUndefined } from "../../components/Form/formUtils";
import { createModel, createRegraModel } from "./model";
import { Divider } from "@material-ui/core";
import ModalMessage from "../../components/ModalMessage";
import PainelControleApi from "../../services/api/PainelControleApi";

import CustomDataGrid from "../../components/CustomDataGrid";
import { useVigencias } from "hooks/useVigencias";

const actions = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST"
};

//teste

export default function RegrasCalculo() {
  const [loading, setLoad] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(null);
  const [formFields, setFormFields] = useState(createModel());
  const [formError, setFormError] = useState([]);
  const [regraFields, setRegraFields] = useState(null);
  const [regrasCalculo, setRegrasCalculo] = useState([]);
  const [action, setAction] = useState(actions.GET);
  const [exibeSucesso, setExibeSucesso] = useState(false);
  const [errosCriticasOptions, setErrosCriticasOptions] = useState([]);
  const { vigencias } = useVigencias();

  useEffect(() => {
    async function _initForm() {
      setLoading(true, "grid-vigencias");
      if (vigencias) {
        const init = createModel({
          anoVigencia: { options: vigencias.map((e) => ({ value: e, label: String(e) })), value: null }
        });
        setFormFields(init);
        setFormError([]);
      }
    }
    _initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vigencias]);

  useEffect(() => {
    async function _initForm() {
      setLoading(true);
      const resErrosCriticas = await PainelControleApi.obterErrosCriticas();
      if (!resErrosCriticas.erros) {
        setErrosCriticasOptions(resErrosCriticas.map((e) => ({ value: e.codigo, label: String(e.descricao) })));
      } else {
        setFormError(resErrosCriticas.erros);
      }
      setLoading(false);
    }
    _initForm();
  }, []);

  function setLoading(show, overlay) {
    setLoad(show);
    setLoadingOverlay(overlay ? overlay : null);
  }

  function _handleChange(event) {
    setFormFields(() => ({ ...formFields }));
  }

  async function obterRegras() {
    let res = await VigenciasApi.obterRegasCalculo(formFields.anoVigencia.value, formFields.faseCalculo.value);
    if (!res.erros) {
      setFormError([]);
      setRegrasCalculo(res);
      setAction(actions.GET);
    } else {
      setFormError(res.erros);
      setRegrasCalculo([]);
    }
  }

  async function handleSubmit(event) {
    setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(formFields);
    if (isFormCheckValidity && isCheckValidity) {
      obterRegras();
    }
    setFormFields({ ...formFields });
    setLoading(false);
  }

  async function handleReset(event) {
    setFormError([]);
    setAction(actions.GET);
  }

  async function editarRegra(regra) {
    setRegraFields(createRegraModel(regra, errosCriticasOptions, actions.PUT));
    setAction(actions.PUT);
    setFormError([]);
  }

  async function novaRegra() {
    let regra = {
      anoVigencia: formFields.anoVigencia.value,
      faseCalculo: formFields.faseCalculo.value
    };
    setRegraFields(createRegraModel(regra, errosCriticasOptions, actions.POST));
    setAction(actions.POST);
    setFormError([]);
  }

  async function salvar(event) {
    setLoading(true);
    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(regraFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(regraFields);
      let res = null;

      let params = {
        anoVigencia: requestBody.anoVigencia,
        numeroRegra: requestBody.numeroRegra
      };

      if (!isUndefined(requestBody.erroCritica) && requestBody.erroCritica !== "") {
        requestBody.erroCritica = { codigo: requestBody.erroCritica };
      }

      delete requestBody.anoVigencia;
      delete requestBody.numeroRegra;

      if (action === actions.PUT) {
        res = await VigenciasApi.alterarRegaCalculo(requestBody, params);
      } else {
        res = await VigenciasApi.criarRegaCalculo(requestBody, params);
      }
      if (!res.erros) {
        setExibeSucesso(true);
        handleReset();
        obterRegras();
      } else {
        setFormError(res.erros);
      }
    }
    setRegraFields(() => ({ ...regraFields }));
    setLoading(false);
  }

  async function removerRegra(regra) {
    setLoading(true);
    let res = await VigenciasApi.removerRegaCalculo(regra);
    if (!res.erros) {
      setExibeSucesso(true);
      handleReset();
      obterRegras();
    } else {
      setFormError(res.erros);
    }
    setLoading(false);
  }

  const columns = [
    { field: "nome", headerName: "Nome", minWidth: 150, flex: 1 },
    { field: "anoVigencia", headerName: "Vigência", minWidth: 150 },
    { field: "faseCalculo", headerName: "Fase", felx: 1, minWidth: 250, valueGetter: ({ value }) => value.numero + " - " + value.descricao },
    { field: "numeroRegra", headerName: "Número", minWidth: 150 },
    { field: "tipoRegra", headerName: "Tipo", minWidth: 150 },
    { field: "ativa", headerName: "Ativa", minWidth: 120, type: "boolean" }
  ];

  return (
    <React.Fragment>
      <BrLoading size={"md"} overlay overlayTarget={loadingOverlay} show={loading} label="Aguarde, carregando..." />
      <Grid container>
        <Grid container style={{ display: action === actions.GET ? "" : "none" }}>
          <Grid item sm={8}>
            <Form onSubmit={handleSubmit} submitTitle={"Consultar"} submitIcon={"fa-search"} errors={formError}>
              <Grid id="grid-vigencias" item sm={3}>
                <InputGeneric field={formFields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={formFields.faseCalculo} onChange={_handleChange} />
              </Grid>
            </Form>
          </Grid>
        </Grid>
        {action === actions.GET && (
          <Grid container>
            <Grid item sm={12}>
              <Divider style={{ marginTop: 20 }} />
            </Grid>
            <Grid item sm={6}>
              <h5>Regras do Cálculo</h5>
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 23, textAlign: "right" }}>
              <BrButton small secondary onClick={() => novaRegra()}>
                Criar Nova Regra
              </BrButton>
            </Grid>
            <Grid item sm={12}>
              <CustomDataGrid
                onEdit={editarRegra}
                onDelete={removerRegra}
                onDeleteMessage="Deseja realmente remover a regra?"
                getRowId={(row) => row.numeroRegra}
                rows={regrasCalculo}
                columns={columns}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {action !== actions.GET && regraFields && (
        <Grid container>
          <Grid item sm={12}>
            <h5>{action === actions.POST ? "Criar Nova Regra" : "Atualizar Regra"}</h5>
            <Divider style={{ marginTop: 15, marginBottom: 30 }} />
          </Grid>
          <Grid item sm={12}>
            <Form
              onSubmit={salvar}
              submitTitle={action === actions.POST ? "Criar Nova Regra" : "Atualizar Regra"}
              errors={formError}
              onCancel={handleReset}
              cancelTitle={"Cancelar"}
            >
              <Grid item sm={4}>
                <InputGeneric field={regraFields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.faseCalculo} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.numeroRegra} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.nome} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.tipoRegra} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.erroCritica} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.campoAtribuicao} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.variaveis} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={regraFields.ativa} onChange={_handleChange} />
              </Grid>
              <Grid item sm={12}>
                <InputGeneric field={regraFields.algoritmo} onChange={_handleChange} />
              </Grid>
              <Grid item sm={8} />
            </Form>
          </Grid>
        </Grid>
      )}
      <ModalMessage show={exibeSucesso} close={() => setExibeSucesso(false)} />
    </React.Fragment>
  );
}
