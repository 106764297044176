import { convertToSelectOptions, createField, FieldType, MaskType } from "components/Form";

import { formataCNPJ, formataCNPJRaiz, formataCPF11Digitos, formataProtocoloContestacao } from "utils/StrUtils";
import { TipoInstancia, TipoSituacaoGestaoProcessoContestacao } from "utils/TipoUtils";
import { getInstanciaSelectOptions, getSituacaoDescricao } from "utils/ContestacaoUtils";

import { TipoSituacaoConsultaDesistencias, TipoSituacaoDespachoContestacao } from "./utils";

export const createRemessaContestacoesModel = () => ({
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    options: getInstanciaSelectOptions(),
    value: TipoInstancia.primeira.value,
    required: true,
    errorMessage: "Selecione a instância"
  })
});

export const createConsultaContestacoesModel = ({ vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(vigencias),
    required: false,
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  cnpj: createField("cnpj", "CNPJ Completo:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ,
    required: false,
    errorMessage: "Informe o CNPJ completo",
    placeholder: "Informe o CNPJ completo"
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    options: getInstanciaSelectOptions(),
    filterOptions: true,
    required: false,
    errorMessage: "Selecione a instância",
    placeholder: "Selecione a instância"
  })
});

export const createDeferimentoModel = ({ fields = {}, deferimentos = [], pareceres = [], ultimoParecer = {} }) => ({
  id: createField("id", "", {
    type: FieldType.NUMERIC,
    required: false,
    value: fields?.id
  }),
  //BENEFICIO
  valorMensalAlterado: createField("valorMensalAlterado", "Renda Mensal Inicial (R$)", {
    type: FieldType.CURRENCY,
    errorMessage: "Informe o valor mensal",
    value: fields?.valorMensalAlterado,
    required: false
  }),
  dataInicioAlterado: createField("dataInicioAlterado", "Data de Início", {
    type: FieldType.DATE,
    value: fields?.dataInicioAlterado,
    required: false
  }),
  dataCessacaoAlterado: createField("dataCessacaoAlterado", "Data de Cessação", {
    type: FieldType.DATE,
    value: fields?.dataCessacaoAlterado,
    required: false
  }),
  acidenteTrajetoAlterado: createField("acidenteTrajetoAlterado", "Óbito por Acidente de Trajeto", {
    value: Boolean(fields?.acidenteTrajetoAlterado),
    type: FieldType.SELECT_ONE_BUTTON,
    required: false
  }),
  //MASSA_SALARIAL
  valorMassaSalarialAlterado: createField("valorMassaSalarialAlterado", "Valor (R$)", {
    type: FieldType.CURRENCY,
    value: fields?.valorMassaSalarialAlterado,
    required: false
  }),
  //ROTATIVIDADE
  admissoesAlterado: createField("admissoesAlterado", "Admissões", {
    type: FieldType.NUMERIC,
    value: fields?.admissoesAlterado,
    required: false
  }),
  rescisoesAlterado: createField("rescisoesAlterado", "Rescisões", {
    type: FieldType.NUMERIC,
    value: fields?.rescisoesAlterado,
    required: false
  }),
  numeroInicialVinculosAlterado: createField("numeroInicialVinculosAlterado", "Número de Vínculos no Início do Ano", {
    type: FieldType.NUMERIC,
    value: fields?.numeroInicialVinculosAlterado,
    required: false
  }),
  //NUMERO_MEDIO_VINCULOS
  vinculosAlterado: createField("vinculosAlterado", "Quantidade", {
    type: FieldType.NUMERIC,
    required: false
  }),
  //TODOS
  deferimento: createField("deferimento", "Decisão do Analista:", {
    type: FieldType.RADIO,
    options: convertToSelectOptions(
      deferimentos,
      (e) => e.label,
      (e) => e.value
    ),
    value: fields?.deferimento?.codigo,
    errorMessage: "Selecione uma decisão"
  }),
  pareceres: createField("pareceres", "Catalogo de Pareceres:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(
      pareceres,
      (e) => e.label,
      (e) => e.value
    ),
    value: fields?.parecer ? null : ultimoParecer?.idParecer,
    required: false,
    placeholder: "Selecione um parecer"
  }),
  parecer: createField("parecer", "Parecer do Analista:", {
    type: FieldType.TEXTAREA,
    maxLength: 10000,
    value: fields?.parecer ? fields.parecer : ultimoParecer?.parecer
  })
});

export const createConsultaDesisteAdmConstestacaoModel = ({ fields = {}, vigencias = [], analistas = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value,
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  situacao: createField("situacao", "Situação:", {
    type: FieldType.SELECT,
    value: fields?.situacao?.value,
    options: convertToSelectOptions(Object.keys(TipoSituacaoConsultaDesistencias), (k) => TipoSituacaoConsultaDesistencias[k].label),
    filterOptions: true,
    errorMessage: "Selecione uma situação",
    placeholder: "Selecione uma situação"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    value: fields?.cnpjRaiz?.value,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  cnpj: createField("cnpj", "CNPJ Completo:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ,
    value: fields?.cnpj?.value,
    required: false,
    errorMessage: "Informe o CNPJ completo",
    placeholder: "Informe o CNPJ completo"
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    value: fields?.instancia?.value,
    options: getInstanciaSelectOptions(),
    filterOptions: true,
    required: false,
    errorMessage: "Selecione a instância",
    placeholder: "Selecione a instância"
  }),
  cpfResponsavel: createField("cpfResponsavel", "Analísta Responsável:", {
    type: FieldType.SELECT,
    value: fields?.cpfResponsavel?.value,
    options:
      fields?.cpfResponsavel?.options ||
      convertToSelectOptions(
        analistas,
        (a) => a.nome,
        (a) => formataCPF11Digitos(a.cpf)
      ),
    required: false,
    placeholder: "Selecione um analísta"
  })
});

export const createDesisteAdmConstestacaoModel = (contestacao = {}) => ({
  cnpj: createField("cnpj", "CNPJ:", {
    required: false,
    readMode: true,
    value: !!contestacao.cnpj ? formataCNPJ(contestacao.cnpj) : formataCNPJRaiz(contestacao.cnpjRaiz)
  }),
  protocolo: createField("protocolo", "Protocolo:", {
    required: false,
    readMode: true,
    value: formataProtocoloContestacao(contestacao.protocolo)
  }),
  situacao: createField("situacao", "Situação:", {
    required: false,
    readMode: true,
    value: getSituacaoDescricao(contestacao)
  }),
  dataInicial: createField("dataInicial", "Data de Início:", {
    required: false,
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataInicial
  }),
  dataTransmissao: createField("dataTransmissao", "Data da Transmissão:", {
    required: false,
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataTransmissao
  }),
  dataDOU: createField("dataDOU", "Data Publicação no DOU:", {
    required: false,
    readMode: true,
    type: FieldType.DATE,
    value: contestacao.dataDOU
  }),
  dataDesistencia: createField("dataDesistencia", "Data Desistência:", {
    type: FieldType.DATE,
    value: new Date()
  }),
  dataEncerraEfeitoSupensivo: createField("dataEncerraEfeitoSupensivo", "Data Encerramento do Efeito Suspensivo:", {
    type: FieldType.DATE,
    value: new Date()
  }),
  email: createField("email", "E-mail do Responsável:", {
    required: false,
    readMode: true,
    value: contestacao.email
  }),
  justificativaDesistencia: createField("justificativaDesistencia", "Informações Desistência:", {
    type: FieldType.TEXTAREA,
    maxLength: 10000,
    value: contestacao?.justificativaDesistencia || ""
  })
});

export const createConsultaHomologaDesistenciaAdmConstestacaoModel = ({ fields = {}, vigencias = [], analistas = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value,
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência"
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    value: fields?.cnpjRaiz?.value,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  cnpj: createField("cnpj", "CNPJ Completo:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ,
    value: fields?.cnpj?.value,
    required: false,
    errorMessage: "Informe o CNPJ completo",
    placeholder: "Informe o CNPJ completo"
  }),
  cpfResponsavel: createField("cpfResponsavel", "Analísta Responsável:", {
    type: FieldType.SELECT,
    value: fields?.cpfResponsavel?.value,
    options:
      fields?.cpfResponsavel?.options ||
      convertToSelectOptions(
        analistas,
        (a) => a.nome,
        (a) => formataCPF11Digitos(a.cpf)
      ),
    required: false,
    placeholder: "Selecione um analísta"
  })
});

export const createConsultaImpressaoDespachoAdmConstestacaoModel = ({ fields = {}, vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value || "",
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência",
    required: false
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  estabelecimentos: createField("estabelecimentos", "Estabelecimentos:", {
    type: FieldType.SELECT,
    value: fields?.estabelecimentos?.value || "",
    filterOptions: true,
    required: false,
    placeholder: "Selecione o estabelecimento",
    errorMessage: "Selecione o estabelecimento"
  }),
  situacao: createField("situacao", "Situação:", {
    type: FieldType.SELECT,
    value: fields?.situacao?.value,
    required: false,
    options: convertToSelectOptions(TipoSituacaoDespachoContestacao),
    errorMessage: "Selecione uma situação",
    placeholder: "Selecione uma situação"
  })
});

export const createAutorizaRecalculoModel = ({ fields = {}, vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value || "",
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência",
    required: false
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  estabelecimentos: createField("estabelecimentos", "Estabelecimentos:", {
    type: FieldType.SELECT,
    value: fields?.estabelecimentos?.value || "",
    filterOptions: true,
    required: false,
    placeholder: "Selecione o estabelecimento",
    errorMessage: "Selecione o estabelecimento"
  })
});

export const createConsultaGestaoContestacaoModel = ({ fields = {}, vigencias = [] }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: fields?.anoVigencia?.value || "",
    options: fields?.anoVigencia?.options || convertToSelectOptions(vigencias),
    errorMessage: "Selecione a vigência",
    placeholder: "Selecione a vigência",
    required: false
  }),
  cnpjRaiz: createField("cnpjRaiz", "CNPJ Raiz:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CNPJ_RAIZ,
    required: false,
    errorMessage: "Informe os 8 primeiros dígitos do CNPJ",
    placeholder: "Informe os 8 primeiros dígitos do CNPJ"
  }),
  estabelecimentos: createField("estabelecimentos", "Estabelecimentos:", {
    type: FieldType.SELECT,
    value: fields?.estabelecimentos?.value || "",
    filterOptions: true,
    required: false,
    placeholder: "Selecione o estabelecimento",
    errorMessage: "Selecione o estabelecimento"
  }),
  situacao: createField("situacao", "Situação:", {
    type: FieldType.SELECT,
    value: fields?.situacao?.value,
    required: true,
    options: convertToSelectOptions(Object.keys(TipoSituacaoGestaoProcessoContestacao), (k) => TipoSituacaoGestaoProcessoContestacao[k].label),
    errorMessage: "Selecione uma situação",
    placeholder: "Selecione uma situação"
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    value: fields?.instancia?.value,
    options: getInstanciaSelectOptions(),
    filterOptions: true,
    required: false,
    errorMessage: "Selecione a instância",
    placeholder: "Selecione a instância"
  }),
  ordenarMaiorMassaSalarial: createField("ordenarMaiorMassaSalarial", "Ordenar por Maior Massa Salarial:", {
    type: FieldType.SELECT_ONE_BUTTON,
    value: Boolean(fields.ordenarMaiorMassaSalarial)
  }),
  cpfResponsavel: createField("cpfResponsavel", "CPF do Responsável:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CPF,
    value: fields?.cpfResponsavel?.value,
    required: false
  })
});

export const createDistribuirModel = () => ({
  cpfResponsavel: createField("cpfResponsavel", "CPF do Responsável:", {
    type: FieldType.NUM_MASK,
    mask: MaskType.CPF,
    required: true
  })
});

export const createConsultaPublicacoesModel = (fields = { vigencias: [], anoVigencia: {} }) => ({
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    options: convertToSelectOptions(fields.vigencias),
    value: fields.anoVigencia,
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência"
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    options: getInstanciaSelectOptions(),
    value: fields.instancia,
    required: false,
    errorMessage: "Selecione a instância",
    placeholder: "Selecione a instância"
  }),
  desistencia: createField("desistencia", "Somente Desistências:", {
    type: FieldType.SELECT_ONE_BUTTON,
    value: Boolean(fields.desistencia)
  })
});

export const createCadastraPublicacaoModel = (fields = { vigencias: [] }) => ({
  id: createField("id", "id:", {
    value: fields.id,
    required: false
  }),
  anoVigencia: createField("anoVigencia", "Vigência:", {
    type: FieldType.SELECT,
    value: !!fields.anoVigencia ? "" + fields.anoVigencia : "",
    options: convertToSelectOptions(fields.vigencias),
    errorMessage: "Selecione o ano da vigência",
    placeholder: "Selecione o ano da vigência",
    disabled: !!fields.id
  }),
  instancia: createField("instancia", "Instância:", {
    type: FieldType.SELECT,
    options: getInstanciaSelectOptions(),
    value: fields.instancia,
    errorMessage: "Selecione a instância",
    placeholder: "Selecione a instância",
    disabled: !!fields.id
  }),
  desistencia: createField("desistencia", "Desistência:", {
    type: FieldType.SELECT_ONE_BUTTON,
    value: Boolean(fields.desistencia),
    disabled: !!fields.id
  }),
  dataPublicacao: createField("dataPublicacao", "Data Publicação no DOU:", {
    type: FieldType.DATE,
    value: fields.dataPublicacao,
    errorMessage: "Informe uma data de publicação",
    placeholder: "Informe uma data de publicação"
  }),
  dataPrazoContestacao: createField("dataPrazoContestacao", "Data Limite para Recurso:", {
    type: FieldType.DATE,
    value: fields.dataPrazoContestacao,
    errorMessage: "Informe o limite para abertura de recurso",
    placeholder: "Informe o limite para abertura de recurso"
  })
});

export function anexoModel() {
  const id = Math.random().toString(36);
  return {
    anexo: createField(id, "Anexo:", {
      type: FieldType.FILE,
      required: true,
      multiple: false,
      value: ""
    })
  };
}
