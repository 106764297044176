import React from "react";
import { Divider, Grid } from "@material-ui/core";
import BrButton from "@govbr/react-components/lib/BrButton";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric, isUndefined } from "components/Form";
import Loading from "components/Loading";

import { isVigenciaContestacaoPorEstabelecimento } from "utils/VigenciaUtils";
import VigenciasApi from "services/api/VigenciasApi";

import { consultarEstabelecimentos, novaContestacao, TERMO_CIENCIA_NOVA_CONTESTACAO, TipoAcaoContestacao } from "./ContestacaoUtils";
import { createNovaContestacaoModel } from "./model";

export const CriaContestacao = ({ userData, ...props }) => {
  if (!props?.location?.state?.acao && TipoAcaoContestacao.CRIAR !== props?.location?.state?.acao) {
    props.history.replace("/404-requisicao-invalida");
  }
  const routeState = React.useMemo(() => props?.location?.state || { fields: undefined, vigencias: [] }, [props.location]);

  const [loading, setLoading] = React.useState(false);
  const [termoCiencia, setTermoCiencia] = React.useState(TERMO_CIENCIA_NOVA_CONTESTACAO);
  const [formErrors, setFormErrors] = React.useState([]);

  const [fields, setFields] = React.useState(() => createNovaContestacaoModel({ fields: routeState.fields, vigencias: routeState.vigencias, empresas: userData.empresas }));

  const _redirectBackToContestacoes = React.useCallback(() => {
    props?.history.replace("/contestacoes-eletronicas", { ...props?.location?.state });
  }, [props.history, props.location]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  function _handleClickConfirmTermoCiencia() {
    setTermoCiencia(null);
  }

  async function _handleChange(field) {
    fields.estabelecimentos.value = "";

    const anoVigencia = fields.anoVigencia.value;
    const empresa = fields.cnpjRaiz.value;
    if (!isUndefined(empresa) && isVigenciaContestacaoPorEstabelecimento(anoVigencia)) {
      _setLoading(true);
      fields.estabelecimentos.disabled = false;
      const res = await consultarEstabelecimentos(empresa, anoVigencia);
      fields.estabelecimentos.options = res.erros ? [] : res;

      setFormErrors(() => (res.erros ? res.erros : []));
    } else {
      fields.estabelecimentos.disabled = true;
    }

    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  React.useEffect(() => {
    (async () => await _handleChange())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function _handleChangeEstabelecimentos(field) {
    setFields(() => ({ ...fields }));
  }

  async function getVigencia(anoVigencia) {
    const resVigencia = await VigenciasApi.obterVigencia(anoVigencia);
    if (!resVigencia.erros) {
      return resVigencia;
    } else {
      return {};
    }
  }

  async function _handleSubmit(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(fields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(fields);

      const vigencia = await getVigencia(requestBody.anoVigencia);

      const res = await novaContestacao(requestBody);
      if (!res.erros) {
        const state = { contestacao: res, fields, acao: TipoAcaoContestacao.EDITAR, vigencia };
        return await props.history.replace("/contestacoes-eletronicas/contestar", state);
      } else {
        setFormErrors(res.erros);
      }
    }
    setFields(() => ({ ...fields }));
    _setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading overlay show={loading} />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <span className="text-up-02 text-bold">{"Nova Contestação "}</span>
          <Divider />
        </Grid>

        {!termoCiencia && (
          <Grid item sm={12} id="form-nova-contestacao">
            <Form onSubmit={_handleSubmit} errors={formErrors} submitTitle="Nova Contestação">
              <Grid item sm={2}>
                <InputGeneric field={fields.anoVigencia} onChange={_handleChange} />
              </Grid>
              <Grid item sm={6}>
                <InputGeneric field={fields.cnpjRaiz} onChange={_handleChange} />
              </Grid>
              <Grid item sm={4}>
                <InputGeneric field={fields.estabelecimentos} onChange={_handleChangeEstabelecimentos} />
              </Grid>
              <Grid item sm={2} style={{ paddingTop: 34 }}>
                <BrButton onClick={_redirectBackToContestacoes}>Cancelar</BrButton>
              </Grid>
            </Form>
          </Grid>
        )}

        {termoCiencia && (
          <React.Fragment>
            <Grid item sm={12}>
              <div className="text-up-01">{termoCiencia}</div>
            </Grid>
            <Grid item sm={12} style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item sm={6}>
                  <BrButton onClick={_redirectBackToContestacoes}>Cancelar</BrButton>
                </Grid>

                <Grid item sm={6}>
                  <BrButton primary onClick={_handleClickConfirmTermoCiencia}>
                    Confirmar
                  </BrButton>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};
