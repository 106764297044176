import React from "react";
import { Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { checkFormValidity, convertFormFieldsToJsonObj, FormErrorPanel, InputGeneric } from "components/Form";
import Loading from "components/Loading";

import RelatoriosGerenciaisApi from "services/api/RelatoriosGerenciaisApi";
import { useVigencias } from "hooks/useVigencias";

import { createRelatorioGerencialModel } from "./model";
import RelatoriosDataGrid from "components/RelatoriosDataGrid";

const isRelatorioElementosPorCnae = (tipoRelatorio) => "ELEMENTOS_POR_CNAE" === tipoRelatorio?.codigo;

const RelatoriosGerenciais = () => {
  const { vigencias } = useVigencias();

  const [loading, setLoading] = React.useState(true);
  const [formError, setFormError] = React.useState([]);
  const [vigenciaField, setVigenciaField] = React.useState();
  const [listaRelatorios, setListaRelatorios] = React.useState([]);

  React.useEffect(() => {
    if (vigencias.length > 0) {
      obterRelatorios();
      setVigenciaField(() => createRelatorioGerencialModel({ vigencias }));
    }
    return setLoading(false);
  }, [vigencias]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormError([]);
  }

  async function obterRelatorios() {
    setLoading(true);
    const resListaRelatorios = await RelatoriosGerenciaisApi.obterRelatorios();
    if (resListaRelatorios.erros) {
      setFormError(resListaRelatorios.errors);
      setListaRelatorios([]);
    } else {
      setFormError([]);
      setListaRelatorios(resListaRelatorios);
    }
    setLoading(false);
  }

  function _handleChange() {
    setVigenciaField(() => ({ ...vigenciaField }));
  }

  async function _handleChangeVigencia() {
    _setLoading(true);
    setTimeout(async () => {
      const isCheckValidity = checkFormValidity(vigenciaField);
      setVigenciaField(() => ({ ...vigenciaField }));
      if (isCheckValidity) {
        await obterRelatorios();
      }
      return _setLoading(false);
    }, 700);
  }

  async function _handleClickDownload(tipo) {
    _setLoading(true);
    const requestBody = convertFormFieldsToJsonObj(vigenciaField);
    const { codigo } = tipo;
    const { anoVigencia, cnae } = requestBody;

    const res = await RelatoriosGerenciaisApi.downloadRelatorio(anoVigencia, codigo, cnae);
    if (res.erros) {
      setFormError(res.erros);
    }
    _setLoading(false);
  }

  const rows = listaRelatorios.filter((r) => (!!vigenciaField.cnae.value ? isRelatorioElementosPorCnae(r) : !isRelatorioElementosPorCnae(r)));

  const columns = [
    {
      field: "descricao",
      headerName: "Relatório",
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Baixar CSV",
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      minWidth: 90,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params) => (
        <IconButton title="Baixar CSV" color="inherit" aria-label="editar" onClick={(event) => _handleClickDownload(params.row)}>
          <span className="text-info fa fa-download"></span>
        </IconButton>
      )
    }
  ];

  return (
    <React.Fragment>
      <Loading overlay show={loading} />

      {vigenciaField && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <FormErrorPanel messages={formError} />
          </Grid>

          <Grid item sm={2}>
            <InputGeneric field={vigenciaField.anoVigencia} onChange={_handleChangeVigencia} />
          </Grid>

          <Grid item sm={3}>
            <InputGeneric field={vigenciaField.cnae} onChange={_handleChange} />
          </Grid>

          <Grid item sm={12}>
            <Grid container>
              <Grid item sm={12}>
                <h5>Relatórios Gerenciais - {vigenciaField.anoVigencia.value}</h5>
              </Grid>

              {rows.length > 0 && (
                <Grid item sm={12}>
                  <RelatoriosDataGrid rows={rows} columns={columns} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default RelatoriosGerenciais;
