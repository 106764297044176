import { getApi } from "../ApiManager";

const contexto = "painel-controle/";
const PainelControleApi = {
  obterStatusServicos: () => getApi().get("/actuator/health"),
  obterErrosCriticas: () => {
    const Api = getApi();
    return Api.get(`${contexto}erros-criticas`);
  }
};

export default PainelControleApi;
