import { getApi } from "../ApiManager";

const contexto = "/publicacoes";

const PublicacoesApi = {
  obterPublicacoes: (anoVigencia, instancia, desistencia) => getApi().get(`${contexto}`, { params: { anoVigencia, instancia, desistencia } }),
  criarPublicacao: (params) => getApi().post(`${contexto}`, params),
  alterarPublicacao: (id, params) => getApi().put(`${contexto}/${id}`, params),
  removerPublicacao: (id) => getApi().delete(`${contexto}/${id}`),
  confirmarPublicacao: (id) => getApi().post(`${contexto}/${id}`),
  emailPublicacao: (ids) => getApi().post(`${contexto}/email`, ids)
};

export default PublicacoesApi;
