import React from "react";
import { Divider, Grid, Link } from "@material-ui/core";
import classnames from "classnames";
import BrButton from "@govbr/react-components/lib/BrButton";
import BrTabs from "@govbr/react-components/lib/BrTabs";

import Form, { checkFormValidity, convertFormFieldsToJsonObj, InputGeneric } from "components/Form";
import CustomDataGrid from "components/CustomDataGrid";
import { LabelValue } from "components/LabelValue";
import CustomCard from "components/CustomCard";
import Loading from "components/Loading";

import { isVigenciaDesbloqueioBonusMalus, isVigenciaNexoTecnico, isVigenciaPercentis } from "utils/VigenciaUtils";
import JulgamentoApi from "services/api/JulgamentoApi";

import { ResumoJulgamento } from "./components/Resumo";
import { CriaJulgamento } from "./CriaJulgamento";
import { Anexos } from "./components/Anexos";
import {
  Actions,
  getBeneficiosRevisadosColumns,
  getCatRevisadasColumns,
  getMassasSalariaisRevisadasColumns,
  getNexosTecnicosRevisadosColumns,
  getTaxaRotatividadeRevisadasColumns,
  getVinculosRevisadosColumns,
  TipoElementos,
  getTrocaCnaeColumns,
  getPercentisRevisadasColumns,
  getDesbloqueioColumns
} from "./utils";
import { elementoModel } from "./model";

const styleCardDisabled = (status, card) => ((card === Cards.ELEMENTOS) || (card === Cards.ANEXOS)) && (status !== "AGUARDANDO_LIBERACAO") ? 
{ "pointer-events": "none", opacity: .5, display: "block"} : null;

const Cards = {
  IDENTIFICACAO: { value: "IDENTIFICACAO", label: "Identificação" },
  ELEMENTOS: { value: "ELEMENTOS", label: "Revisão de Elementos" },
  ANEXOS: { value: "ANEXOS", label: "Anexos" },
  RESUMO: { value: "RESUMO", label: "Resumo" }
};

export const EditarJulgamento = (props) => {
  const julgamentoParam = props.location.state?.resultadoJulgamento || {};
  const { history, location } = props;

  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [elementoFields, setElementoFields] = React.useState(elementoModel(julgamentoParam, {}, TipoElementos.CATS));
  const [revisadosColumns, setRevisadosColumns] = React.useState([]);
  const [elementosRevisados, setElementosRevisados] = React.useState([]);
  const [card, setCard] = React.useState(Cards.IDENTIFICACAO);
  const [elementoTab, setElementoTab] = React.useState(TipoElementos.CATS);
  const [action, setAction] = React.useState(Actions.LISTAR_REVISADOS);

  const elementosRevisadosColumns = React.useMemo(() => revisadosColumns, [revisadosColumns]);

  const _redirectBack = React.useCallback(() => {
    history.replace("/resultado-julgamento", { ...location.state });
  }, [history, location]);

  function _setLoading(show) {
    setLoading(show);
    show && setFormErrors([]);
  }

  async function _handleChange(field) {
    if (elementoTab === TipoElementos.BENEFICIOS) {
      elementoFields.valorMensal.required = elementoFields?.deferimento?.value === "ALTERACAO";
    }
    if (elementoTab === TipoElementos.PERCENTIS) {
      elementoFields.cnpj.required = elementoFields.cnpjRaiz.value === "";
      elementoFields.cnpj.disabled = elementoFields.cnpjRaiz.value !== "";

      elementoFields.cnpjRaiz.required = elementoFields.cnpj.value === "";
      elementoFields.cnpjRaiz.disabled = elementoFields.cnpj.value !== "";
    }
    setElementoFields(() => ({ ...elementoFields }));
  }

  async function _handleTabChange(elementoTab) {
    setElementoTab(elementoTab);
    listarRevisados(elementoTab);
  }

  function revisarNovoElemento() {
    setElementoFields(elementoModel(julgamentoParam, {}, elementoTab));
    setAction(Actions.CRIAR_ELEMENTO);
  }

  async function _setCard(card) {
    setCard(card);
    card.value === Cards.ELEMENTOS.value && _handleTabChange(TipoElementos.CATS);
  }

  async function listarRevisados(elementoTab) {
    setAction(Actions.LISTAR_REVISADOS);
    _setLoading(true);
    const resRevisados = await JulgamentoApi.obterElementosRevisados(julgamentoParam.id, elementoTab.value);
    if (resRevisados.erros) {
      setFormErrors(resRevisados.errors);
      setElementosRevisados([]);
    } else {
      setElementosRevisados(resRevisados.map((r) => r.resultadoJulgamento));
    }
    switch (elementoTab) {
      case TipoElementos.CATS:
        setRevisadosColumns(getCatRevisadasColumns);
        break;
      case TipoElementos.BENEFICIOS:
        setRevisadosColumns(getBeneficiosRevisadosColumns);
        break;
      case TipoElementos.MASSA_SALARIAL:
        setRevisadosColumns(getMassasSalariaisRevisadasColumns);
        break;
      case TipoElementos.NEXOS_TECNICOS:
        setRevisadosColumns(getNexosTecnicosRevisadosColumns);
        break;
      case TipoElementos.VINCULOS:
        setRevisadosColumns(getVinculosRevisadosColumns);
        break;
      case TipoElementos.ROTATIVIDADE:
        setRevisadosColumns(getTaxaRotatividadeRevisadasColumns);
        break;
      case TipoElementos.TROCA_CNAE:
        setRevisadosColumns(getTrocaCnaeColumns);
        break;
      case TipoElementos.PERCENTIS:
        setRevisadosColumns(getPercentisRevisadasColumns);
        break;
      case TipoElementos.DESBLOQUEIO:
        setRevisadosColumns(getDesbloqueioColumns);
        break;

      default:
        break;
    }
    _setLoading(false);
  }

  async function cofirmarRevisarElemento(event) {
    _setLoading(true);

    const form = event.currentTarget;
    const isFormCheckValidity = form.checkValidity();
    const isCheckValidity = checkFormValidity(elementoFields);
    if (isFormCheckValidity && isCheckValidity) {
      const requestBody = convertFormFieldsToJsonObj(elementoFields);
      const res = await JulgamentoApi.criarElementoRevisado(julgamentoParam.id, elementoTab.value, requestBody);

      if (res.erros) {
        setFormErrors(res.erros);
      } else {
        listarRevisados(elementoTab);
      }
    } else {
      setElementoFields(() => ({ ...elementoFields }));
    }
    _setLoading(false);
  }

  function cancelarRevisarElemento() {
    _setLoading(true);
    setElementoFields(elementoModel(julgamentoParam, {}, elementoTab));
    setAction(Actions.LISTAR_REVISADOS);
    _setLoading(false);
  }

  async function removerElemento(elemento) {
    _setLoading(true);
    const res = await JulgamentoApi.removerElementoRevisado(elemento.idResultadoJulgamento, elementoTab.value, elemento.id);
    if (res.erros) {
      setFormErrors(res.erros);
    } else {
      setFormErrors([]);
      listarRevisados(elementoTab);
    }
    _setLoading(false);
  }

  return (
    <Grid container spacing={1}>
      <Loading overlay show={loading} />
      <Grid item sm={5}>
        <CustomCard title={"Revisão dos elementos do cálculo"}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <ol className={"text-up-01 text-info"}>
                {Object.keys(Cards).map((c) => (
                  <li key={`item-menu-rj-${c}`} style={styleCardDisabled(julgamentoParam?.status?.codigo, Cards[c])}>
                    <Link component="button" variant="body2" onClick={() => _setCard(Cards[c])}>
                      <span className={classnames("text-up-01", { "text-bold": card.value === c })}>{Cards[c].label}</span>
                    </Link>
                  </li>
                ))}
              </ol>
            </Grid>

            <Grid item sm={12} style={{ marginLeft: 30 }}>
              <BrButton small secondary onClick={_redirectBack}>
                Voltar para consulta
              </BrButton>
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>

      <Grid item sm={7}>
        <CustomCard title={"Dados do Resultado Julgamento"}>
          <Grid container spacing={1} alignItems="baseline">
            <LabelValue columns={6} label="Vigência" value={julgamentoParam.anoVigencia} orientation={"upLabel"} />
            <LabelValue columns={6} label="CNPJ Raiz" value={julgamentoParam.cnpjRaiz} orientation={"upLabel"} dataType="cnpjRaiz" />
            <LabelValue columns={6} label="Data Alteração" value={julgamentoParam.dataAlteracao} orientation={"upLabel"} dataType="date" />
            <LabelValue
              columns={6}
              label="Responsável"
              value={julgamentoParam.responsavel?.nome || julgamentoParam.responsavel?.cpf}
              orientation={"upLabel"}
              dataType={julgamentoParam.responsavel?.nome ? null : "cpf"}
            />
          </Grid>
        </CustomCard>
      </Grid>

      <Grid item sm={12}>
        {card === Cards.ELEMENTOS && (
          <CustomCard>
            <BrTabs value={elementoTab} onChange={(value) => _handleTabChange(value)} label="Elementos a Revisar">
              <BrTabs.Tabs label="Teste de Tabs">
                <BrTabs.Tab id={TipoElementos.CATS} label="CAT" />
                <BrTabs.Tab id={TipoElementos.BENEFICIOS} label="Benefícios" />
                <BrTabs.Tab id={TipoElementos.MASSA_SALARIAL} label="Massa Salarial" />
                <BrTabs.Tab id={TipoElementos.VINCULOS} label="Número Médio de Vínculos" />
                <BrTabs.Tab id={TipoElementos.ROTATIVIDADE} label="Taxa Média de Rotatividade" />
                {isVigenciaPercentis(julgamentoParam.anoVigencia) && <BrTabs.Tab id={TipoElementos.PERCENTIS} label="Percentis" />}
                {isVigenciaNexoTecnico(julgamentoParam.anoVigencia) && <BrTabs.Tab id={TipoElementos.NEXOS_TECNICOS} label="Nexo Técnico Previdenciário" />}
                {isVigenciaDesbloqueioBonusMalus(julgamentoParam.anoVigencia) && <BrTabs.Tab id={TipoElementos.DESBLOQUEIO} label="Desbloqueio de Bônus/Malus" />}
                <BrTabs.Tab id={TipoElementos.TROCA_CNAE} label="Troca de CNAE" />
              </BrTabs.Tabs>

              <BrTabs.Content>
                <BrTabs.Panel contentFor={elementoTab}>
                  {action === Actions.LISTAR_REVISADOS && (
                    <Grid container spacing={1}>
                      <Grid item sm={12}>
                        <h6>Elementos revisados</h6>
                        <CustomDataGrid
                          onDelete={removerElemento}
                          onDeleteMessage="Deseja realmente remover o elemento revisado?"
                          headerHeight={70}
                          rows={elementosRevisados}
                          columns={elementosRevisadosColumns}
                          density="compact"
                        />
                      </Grid>
                      <Grid item sm={12} className="text-right">
                        <BrButton small secondary onClick={revisarNovoElemento}>
                          Revisar novo elemento
                        </BrButton>
                      </Grid>
                    </Grid>
                  )}

                  {(action === Actions.CRIAR_ELEMENTO || action === Actions.EDITAR_ELEMENTO) && (
                    <Grid item sm={12}>
                      <Form
                        onSubmit={(e) => cofirmarRevisarElemento(e)}
                        loading={false}
                        errors={formErrors}
                        submitTitle={action === Actions.CRIAR_ELEMENTO ? "Revisar" : "Alterar"}
                        cancelTitle={"Cancelar"}
                        onCancel={() => cancelarRevisarElemento()}
                      >
                        <Grid item sm={12}>
                          <Grid container spacing={1}>
                            <h6>Revisar {elementoTab.label} </h6>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>

                            {elementoFields.nb && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.nb} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.valorMensal && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.valorMensal} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.dataInicio && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.dataInicio} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.dataCessacao && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.dataCessacao} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.cnpj && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.cnpj} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.cnae && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.cnae} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.cnpjRaiz && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.cnpjRaiz} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.competencia && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.competencia} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.valorMassaSalarial && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.valorMassaSalarial} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.vinculos && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.vinculos} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.numeroCat && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.numeroCat} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.ano && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.ano} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.numeroInicialVinculos && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.numeroInicialVinculos} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.admissoes && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.admissoes} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.rescisoes && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.rescisoes} onChange={_handleChange} />
                              </Grid>
                            )}
                            {elementoFields.deferimento && (
                              <Grid item sm={4}>
                                <InputGeneric field={elementoFields.deferimento} onChange={_handleChange} />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Form>
                    </Grid>
                  )}
                </BrTabs.Panel>
              </BrTabs.Content>
            </BrTabs>
          </CustomCard>
        )}
      </Grid>

      <Grid item sm={12}>
        {card === Cards.IDENTIFICACAO && (
          <CustomCard title={card.label}>
            <CriaJulgamento {...props} />
          </CustomCard>
        )}

        {card === Cards.ANEXOS && (
          <CustomCard title={card.label}>
            <Anexos resultadoJulgamento={julgamentoParam} />
          </CustomCard>
        )}

        {card === Cards.RESUMO && (
          <CustomCard title={card.label}>
            <ResumoJulgamento resultadoJulgamento={julgamentoParam} />
          </CustomCard>
        )}
      </Grid>
    </Grid>
  );
};
