const isDevelopment = process.env.NODE_ENV === "development";

export class InvalidParamError extends Error {
  constructor(message = "Invalid Parameter.") {
    super(message);
    this.name = "InvalidParamError";
  }
}

let STORE = [];

const getItem = (key) => {
  if (typeof key !== "string") {
    throw new InvalidParamError();
  }
  return STORE[key] || localStorage.getItem(key) || "";
};

const setItem = (key, value = "", persist = false) => {
  if (typeof key !== "string") {
    throw new InvalidParamError();
  }
  const auxValue = typeof value !== "undefined" && value !== null ? `${value}` : "";
  const isPersist = persist || isDevelopment;

  if (isPersist) {
    localStorage.setItem(key, auxValue);
  } else {
    STORE[key] = auxValue;
  }
};

const clear = () => {
  Object.keys(STORE).forEach((k) => (STORE[k] = null)); //clean values for string keys
  STORE = [];
  localStorage.clear();
};

export { getItem, setItem, clear };
