import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import BrCard from "@govbr/react-components/lib/BrCard";

const MenuAcessoRapido = ({ menu }) => (
  <Grid item sm={4}>
    <BrCard>
      <div style={{ height: "70px" }}>
        <Link to={menu.url} title={menu.description}>
          <Grid container spacing={3} alignItems="center" style={{ paddingTop: 15 }}>
            <Grid item sm={2}>
              <span className={"fa fa-2x text-info " + menu.homeIcon}></span>
            </Grid>
            <Grid item sm={10}>
              <div className="text-up-01 text-bold">{menu.label}</div>
            </Grid>
          </Grid>
        </Link>
      </div>
    </BrCard>
  </Grid>
);

const AcessoRapido = ({ menus = [] }) => (
  <Grid container spacing={3} alignItems={"center"}>
    {menus
      .filter((m) => m.homeIcon && m.isRoute() && m.isAuthorized())
      .map((m) => (
        <MenuAcessoRapido menu={m} key={`menu_${m.id}`} />
      ))}
  </Grid>
);
export default AcessoRapido;
