import React from "react";
import FullScreenError from "./components/FullScreenError";
import LandingPage from "./pages/Landing";

import ConfigManager from "./services/ConfigManager";
import AuthManager from "./services/AuthManager";

const AppLogin = ({ children, onPostSignIn }) => {
  const [message, setMessage] = React.useState("");
  const [loggedIn, setLoggedIn] = React.useState(null);

  if (onPostSignIn) {
    AuthManager.customPostSignIn = onPostSignIn;
  }

  React.useEffect(() => {
    ConfigManager.carregaConfiguracoesExternas()
      .then(AuthManager.doSignIn)
      .then(setLoggedIn)
      .catch((error) => {
        setMessage(error.message);
        setLoggedIn(false);
      });
  }, []);

  return (
    <React.Fragment>
      {message && <FullScreenError>{message}</FullScreenError>}
      {loggedIn && children}
      {loggedIn === false && <LandingPage redirectToGerid={() => AuthManager.doLogin()} redirectToGovbr={() => AuthManager.doLogin(1)} />}
    </React.Fragment>
  );
};

export default AppLogin;
