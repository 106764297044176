import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BrBreadcrumb from "@govbr/react-components/lib/BrBreadcrumb";

const buildBreadcrumbs = (menus = [], pathname = "", onNavigateCallback = (route) => {}) => {
  let currentMenu = menus.find((m) => m.url === pathname);

  if (!currentMenu) {
    currentMenu = menus
      .filter((m) => !!m.url && !m.isHome)
      // ordena pela maior url pra no passo seguinte encontrar o pathname mais similar
      .sort((a, b) => b.url.localeCompare(a.url))
      .find((m) => pathname.indexOf(m.url) >= 0);
  }

  if (!currentMenu) {
    return [{ isHome: true, onClick: () => onNavigateCallback("/") }];
  }

  return currentMenu.breadcrumbs.map((id) => {
    const menu = menus.find((m) => m.id === id);
    const { label, url } = menu;
    return {
      isHome: !!menu.isHome,
      label,
      onClick: () => onNavigateCallback(url)
    };
  });
};

function Breadcrumb({ menus }) {
  const history = useHistory();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const handleNavigate = useCallback(
    (route) => {
      history.push(route);
    },
    [history]
  );

  useEffect(() => {
    const url = location.pathname;
    if (url) {
      const bc = buildBreadcrumbs(menus, url, handleNavigate);
      setBreadcrumbs(bc);
    }
  }, [location.pathname, handleNavigate, menus]);

  return (
    <BrBreadcrumb>
      {breadcrumbs.map((crumb, index) => (
        <BrBreadcrumb.Crumb key={index} {...crumb} />
      ))}
    </BrBreadcrumb>
  );
}

export default Breadcrumb;
