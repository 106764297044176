import { getApi, handleDownload } from "../ApiManager";

const contextoVigencia = "/vigencias";
const contextoEmpresa = "/empresa";
const contexto = "/calculos";

const CalculosApi = {
  obterAvisosImportantes: async (params) => {
    let { anoVigencia = "", cnpj = "", idCalculo = "" } = params;
    return getApi().get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${idCalculo}/mensagens`);
  },
  obterCats: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/cats`);
  },
  obterCatsBloqueioBonificacao: (id, anoVigencia, cnpj) => {
    let config = {
      params: { bloqueioBonificacao: true }
    };
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/cats`, config);
  },
  obterCatsBloqueioMalus: (id, anoVigencia, cnpj) => {
    let config = {
      params: { bloqueioMalus: true }
    };
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/cats`, config);
  },
  obterBeneficios: (id, anoVigencia, cnpj, especie) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/beneficios/${especie ? especie : ""}`);
  },
  obterBeneficiosBloqueioMalus: (id, anoVigencia, cnpj) => {
    let config = {
      params: { bloqueioMalus: true }
    };
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/beneficios`, config);
  },
  obterBeneficiosBloqueioBonificacao: (id, anoVigencia, cnpj) => {
    let config = {
      params: { bloqueioBonificacao: true }
    };
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/beneficios`, config);
  },
  obterNexosTecnicos: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/nexos-tecnicos`);
  },
  obterMassasSalariais: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/massa-salarial`);
  },
  obterVinculos: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/vinculos`);
  },
  obterTaxasRotatividades: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/rotatividade`);
  },
  obterIndicadores: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/indicadores`);
  },
  obterCalculo: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}`);
  },
  obterPercentilCusto: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/percentil-custo`);
  },
  obterPercentilFrequencia: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/percentil-frequencia`);
  },
  obterPercentilGravidade: (id, anoVigencia, cnpj) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/percentil-gravidade`);
  },
  obterFapSimplificado: (anoVigencia, cnpjRaiz) => {
    const Api = getApi();
    return Api.get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpjRaiz}/resumo`);
  },
  downloadRelatorioPdfCalculoFap: (id, cnpj, anoVigencia) =>
    getApi().get(`${contextoVigencia}/${anoVigencia}${contextoEmpresa}/${cnpj}${contexto}/${id}/imprimir`).then(handleDownload)
};

export default CalculosApi;
