import React from "react";
import BrMessage from "@govbr/react-components/lib/BrMessage";
import BrButton from "@govbr/react-components/lib/BrButton";
import { Grid } from "@material-ui/core";

import { FormErrorPanel } from "components/Form";
import { LabelValue } from "components/LabelValue";
import CustomDataGrid from "components/CustomDataGrid";
import ModalConfirm from "components/ModalConfirm";
import CustomCard from "components/CustomCard";

import { formataCNPJ8Digitos } from "utils/StrUtils";
import { TipoReprocessamento } from "utils/TipoUtils";
import ReprocessamentosApi from "services/api/ReprocessamentosApi";

import ReprocessamentoFormModal from "./components/ReprocessamentoFormModal";
import { columnsDetalhe } from "./model";

const Operation = {
  RECALC: "RECALC",
  PUBLISH: "PUBLISH",
  UNPUBLISH: "UNPUBLISH",
  EDIT: "EDIT",
  CANCELAR: "CANCELAR",
  EXCLUIR: "EXCLUIR",
  CANCELAR_CANCELAMENTO: "CANCELAR_CANCELAMENTO",
  CANCELAR_EXCLUSAO: "CANCELAR_EXCLUSAO"
};

export const DetalhaReprocessamento = ({ setLoading, onClose, onUpdateReprocessamento, ...props }) => {
  const [reprocessamento, setReprocessamento] = React.useState(formataCNPJRaiz(props.reprocessamento) || {});
  const [rows, setRows] = React.useState([]);

  const [operation, setOperation] = React.useState();
  const [formErrors, setFormErrors] = React.useState();
  const [successMessage, setSuccessMessage] = React.useState();

  const _setLoading = (show) => {
    show && setFormErrors([]);
    show && setSuccessMessage(null);
    setLoading(show);
  };
  function formataCNPJRaiz(reprocessamento) {
    reprocessamento.cnpjRaiz = formataCNPJ8Digitos(reprocessamento.cnpjRaiz);
    return reprocessamento;
  }
  const getMessageByOperation = React.useCallback(
    (op) => {
      switch (op) {
        case Operation.RECALC:
          return `Tem certeza que deseja enviar a empresa para ser reprocessada por estabelecimento novamente?`;
        case Operation.PUBLISH:
          return `Realmente deseja publicar o reprocessamento da empresa CNPJ ${reprocessamento.cnpjRaiz} para vigência ${reprocessamento.anoVigencia}?`;
        case Operation.UNPUBLISH:
          return `Realmente deseja cancelar a publicação do reprocessamento da empresa CNPJ ${reprocessamento.cnpjRaiz} e vigência ${reprocessamento.anoVigencia}?`;
        case Operation.CANCELAR:
          return `Realmente deseja cancelar o reprocessamento da empresa CNPJ ${reprocessamento.cnpjRaiz} e vigência ${reprocessamento.anoVigencia}?`;
        case Operation.EXCLUIR:
          return `Realmente deseja excluir o reprocessamento da empresa CNPJ ${reprocessamento.cnpjRaiz} e vigência ${reprocessamento.anoVigencia}?`;
        case Operation.CANCELAR_CANCELAMENTO:
          return `Realmente deseja desfazer o cancelamento do reprocessamento da empresa CNPJ ${reprocessamento.cnpjRaiz} e vigência ${reprocessamento.anoVigencia}?`;
        case Operation.CANCELAR_EXCLUSAO:
          return `Realmente deseja desfazer a exclusão do reprocessamento da empresa CNPJ ${reprocessamento.cnpjRaiz} e vigência ${reprocessamento.anoVigencia}?`;
        default:
          return null;
      }
    },
    [reprocessamento]
  );

  React.useEffect(() => {
    (async () => {
      if (![TipoReprocessamento.REPROCESSANDO, TipoReprocessamento.ERRO_REPROCESSAMENTO].includes(reprocessamento?.status?.codigo)) {
        const cnpjConsulta = formataCNPJ8Digitos(reprocessamento.cnpjRaiz);
        const res = await ReprocessamentosApi.obterReprocessamentos(cnpjConsulta, reprocessamento.anoVigencia);
        if (res.erros) {
          setFormErrors(res.erros);
        } else {
          setRows(res);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleCancelOperation = () => {
    setOperation(null);
  };

  const _handleConfirmOperation = async (reprocessamentoParam = {}) => {
    _setLoading(true);
    let res = reprocessamentoParam ? { ...reprocessamentoParam } : { ...reprocessamento };

    switch (operation) {
      case Operation.RECALC:
        res = await ReprocessamentosApi.recalcular(reprocessamento);
        break;
      case Operation.PUBLISH:
        res = await ReprocessamentosApi.publicar(reprocessamento);
        break;
      case Operation.UNPUBLISH:
      case Operation.CANCELAR_CANCELAMENTO:
      case Operation.CANCELAR_EXCLUSAO:
        res = await ReprocessamentosApi.cancelarPublicacao(reprocessamento);
        break;
      case Operation.CANCELAR:
        res = await ReprocessamentosApi.cancelarReprocessamento(reprocessamento);
        break;
      case Operation.EXCLUIR:
        res = await ReprocessamentosApi.excluirReprocessamento(reprocessamento);
        break;
      default:
        break;
    }

    if (res.erros) {
      setFormErrors(res.erros);
    } else {
      res = formataCNPJRaiz(res);
      setReprocessamento(() => res);
      onUpdateReprocessamento(res);
      setSuccessMessage("Operação realizada com sucesso.");
    }
    setOperation(null);
    _setLoading(false);
  };

  const _handleEdit = () => {
    setOperation(Operation.EDIT);
  };

  const _handleRecalc = () => {
    setOperation(Operation.RECALC);
  };

  const _handlePublish = () => {
    setOperation(Operation.PUBLISH);
  };

  const _handleUnpublish = (operation) => {
    setOperation(operation);
  };

  const _handleCancelar = () => {
    setOperation(Operation.CANCELAR);
  };

  const _handleExcluir = () => {
    setOperation(Operation.EXCLUIR);
  };

  const STATUS = reprocessamento?.status?.codigo;
  const OPERATION_MESSAGE = getMessageByOperation(operation);

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {!!successMessage && <BrMessage success>{successMessage}</BrMessage>}

          {TipoReprocessamento.REPROCESSANDO === STATUS && (
            <BrMessage warning>{`Aguarde enquanto o CNPJ ${reprocessamento.cnpjRaiz} e Ano Vigência ${reprocessamento.anoVigencia} é reprocessado.`}</BrMessage>
          )}

          <FormErrorPanel messages={formErrors} />
        </Grid>

        <Grid item sm={12}>
          <CustomCard title={"Dados do Reprocessamento"}>
            <Grid container>
              <Grid item sm={5} style={{ paddingLeft: 30 }}>
                <LabelValue orientation={"inlineLabel"} label={"CNPJ Raiz"} value={reprocessamento?.cnpjRaiz} dataType="cnpjRaiz" />
                <LabelValue orientation={"inlineLabel"} label={"Ano Vigência"} value={reprocessamento?.anoVigencia} />
                <LabelValue orientation={"inlineLabel"} label={"CNAE"} value={reprocessamento?.cnae?.subClasse} dataType="cnae" />
                <LabelValue orientation={"inlineLabel"} label={"Nº Processo"} value={reprocessamento?.numeroProcesso} />
                <LabelValue orientation={"inlineLabel"} label={"Data Reprocessado"} value={reprocessamento?.dataAlteracao} dataType="date" />
                <LabelValue orientation={"inlineLabel"} label={"Status"} value={reprocessamento?.status?.descricao} />
              </Grid>

              <Grid item sm={7} style={{ display: "flex", alignItems: "end" }}>
                <Grid container spacing={2}>
                  {TipoReprocessamento.REPROCESSADO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={_handleEdit}>
                        Alterar Nº Processo
                      </BrButton>
                    </Grid>
                  )}
                  {[TipoReprocessamento.REPROCESSADO, TipoReprocessamento.ERRO_REPROCESSAMENTO].includes(STATUS) && (
                    <Grid item>
                      <BrButton primary onClick={_handleRecalc}>
                        Recalcular
                      </BrButton>
                    </Grid>
                  )}
                  {TipoReprocessamento.REPROCESSADO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={_handleCancelar}>
                        Cancelar
                      </BrButton>
                    </Grid>
                  )}
                  {TipoReprocessamento.REPROCESSADO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={_handleExcluir}>
                        Excluir
                      </BrButton>
                    </Grid>
                  )}
                  {TipoReprocessamento.REPROCESSADO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={_handlePublish}>
                        Publicar
                      </BrButton>
                    </Grid>
                  )}
                  {TipoReprocessamento.PUBLICADO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={() => _handleUnpublish(Operation.UNPUBLISH)}>
                        Cancelar Publicação
                      </BrButton>
                    </Grid>
                  )}
                  {TipoReprocessamento.CANCELADO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={() => _handleUnpublish(Operation.CANCELAR_CANCELAMENTO)}>
                        Desfazer Cancelamento
                      </BrButton>
                    </Grid>
                  )}
                  {TipoReprocessamento.EXCLUIDO === STATUS && (
                    <Grid item>
                      <BrButton primary onClick={() => _handleUnpublish(Operation.CANCELAR_EXCLUSAO)}>
                        Desfazer Exclusão
                      </BrButton>
                    </Grid>
                  )}
                  <Grid item style={{ display: "flex", marginLeft: "auto" }}>
                    <BrButton secondary onClick={onClose}>
                      Voltar
                    </BrButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>

        <Grid item sm={12}>
          <CustomDataGrid columns={columnsDetalhe} rows={rows} getRowId={(row) => row.cnpj} toolbar={"details"} headerHeight={70} />
        </Grid>
      </Grid>

      {!!OPERATION_MESSAGE && <ModalConfirm show={true} message={OPERATION_MESSAGE} onConfirm={_handleConfirmOperation} cancel={_handleCancelOperation} />}

      {Operation.EDIT.startsWith(operation) && (
        <ReprocessamentoFormModal reprocessamento={reprocessamento} onConfirm={_handleConfirmOperation} onClose={() => setOperation(null)} />
      )}
    </React.Fragment>
  );
};
